<template>
    <v-select :items="bookies" v-model="selected" @change="getNewColumnFlucs" style="" class="bookieSelect mt-n2" :hide-selected="true" dense hide-details :label="null">
      <!-- <template v-slot:selection="{ item }">
        <div style="margin-left: 5px; margin-right: 10px; zoom: 80%; margin-bottom:4px;" :class="'logo-' + item.value"></div>
      </template> -->
      <template v-slot:item="{ item }">
        <div style="margin-left: 5px; margin-right: 10px"><RaceBookmakers :bookieCode="item.value"/></div>
        <!-- <span>{{ item.text }}</span> -->
      </template>
    </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BOOKMAKER_NAMES_LOOKUP, DELETED_BOOKIES, PRICE_CHANGE_BOOKIES } from '../common/constants'
import RaceBookmakers from './RaceBookmakers'

export default {
  name: 'BookieSelectHeader',
  props: {
    column: Object,
    columnNumber: Number,
    acMode: Boolean,
    id: String
  },
  computed: {
    ...mapGetters(['getSelectedColumns', 'getSelectedColumnsName']),
    bookies: function () {
      return this.items.map(x => {
        return {
          value: x.value,
          text: BOOKMAKER_NAMES_LOOKUP[x.value]
        }
      })
    },
    selected: {
      get () {
        return this.column.filters
      },
      set (value) {
        return this.updateFilters({ value })
      }
    },
    items () {
      const deletedBookies = DELETED_BOOKIES.map(bookie => bookie.source)
      const filteredColumns = PRICE_CHANGE_BOOKIES.filter(c => !deletedBookies.includes(c.value))
      return filteredColumns
    }
  },
  components: {
    RaceBookmakers
  },
  methods: {
    ...mapActions(['updateSelectedColumns', 'fetchColumnFlucs']),
    updateFilters: function (value) {
      const columns = this.getSelectedColumns
      if (columns[this.columnNumber].filters) {
        columns[this.columnNumber].filters = value
      }
      this.updateSelectedColumns({ columns, name: this.getSelectedColumnsName })
    },
    getNewColumnFlucs: function () {
      this.fetchColumnFlucs({ id: this.id, source: this.selected.value, type: 'WIN_FIXED_ODDS' })
    }
  }
}
</script>

<style scoped>
  .bookieSelect {
    z-index: 200;
    height: 20px !important;
    max-height: 20px !important;
    font-size: 13px;
    position: fixed;
    width: 42px;
  }
</style>
