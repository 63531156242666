<template>
  <div class="apolloColumn">
    <div v-if="marketPercentage">
      {{ marketPercentageValue }}
    </div>
    <div v-else-if="lastPriceChange">
      {{ lastPriceChangeValue }}
    </div>
    <div v-else :class="overflowedList ? 'raceTableColumnContainer-overflowed' : 'raceTableColumnContainer'">
      <div :class="['raceTableColumnMainContainer', 'columnWidth-' + source]">
        <div :class="acMode ? 'raceTableColumn-priceAcMode' : 'raceTableColumn'" :style="getColumnStyle(index)">
          <div :class="['bestPriceCol-content']">
            <div v-if="editMode" class="raceHeaderCol">
              <div :class="['raceTableColumn-header']" >
                <div :class="['bestPriceColumn save-font']">
                  <span class="text-success" style="text-transform: uppercase;">{{ title }}</span>
                </div>
                <div class="columnActions editingDecorations">
                  <AddColumnButton :columnLength="columnLength" :position="index"/>
                  <DeleteColumnButton :columnNumber="index"/>
                  <EditColumnButton :columnNumber="index"/>
                </div>
              </div>
            </div>
            <div :class="acMode ? 'bestPriceCol-columnAcMode' : 'bestPriceCol-column'" v-for="(competitor, i) in getRaceCompetitors" :key="`${competitor.tabNo}:${i}`" :style="getColumnStyle(index)">
              <BestPriceBookie v-if="!competitor.scratched && !editMode" :bookies="getBestPrices(competitor)" :competitor="competitor" :handleClick="clickPrice" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closestTo, format } from 'date-fns'
import AddColumnButton from './menus/AddColumnMenu'
import DeleteColumnButton from './menus/DeleteColumnMenu'
import EditColumnButton from './menus/EditColumnMenu'
import BestPriceBookie from './BestPriceBookie'
import { mapState, mapGetters } from 'vuex'
import gql from 'graphql-tag'

export default {
  props: {
    id: String,
    source: String,
    overflowedList: Boolean,
    editMode: Boolean,
    index: Number,
    title: String,
    columnLength: Number,
    getColumnStyle: Function,
    column: Object,
    clickPrice: Function,
    marketPercentage: Boolean,
    lastPriceChange: Boolean
  },
  components: {
    AddColumnButton,
    DeleteColumnButton,
    EditColumnButton,
    BestPriceBookie
  },
  data: () => ({
    bestPrices: null,
    competitors: [],
    priceSets: null,
    race: null
  }),
  methods: {
    gql,
    getBestPrices: function (competitor) {
      const prices = this.getBestPriceSets(competitor.tabNo, this.filters)
      return { price: prices[0]?.price || 0, prices, ...competitor }
    }
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['getRaceCompetitors', 'getRace', 'getBestPriceSets']),
    filters () {
      return this.column?.filters || []
    },
    marketPercentageValue () {
      const bestPrices = this.getRaceCompetitors.map(c => {
        return this.getBestPrices(c)
      })
      if (bestPrices) {
        const scratched = this.getRaceCompetitors.filter(c => c.scratched).map(c => c.tabNo)
        const prices = bestPrices.filter(p => !scratched.includes(p.tabNo)).map(p => p.price)
        const percentage = prices.reduce((accumulator, currentValue) => {
          if (currentValue === 0) return 0
          accumulator += 1 / currentValue
          return accumulator
        }, 0)
        return (percentage * 100).toFixed(1)
      }
      return 0
    },
    lastPriceChangeValue () {
      const bestPrices = this.getRaceCompetitors.map(c => {
        return this.getBestPrices(c)
      })
      const dates = []
      bestPrices.forEach(o => {
        o.prices.forEach(p => dates.push(p.updated))
      })
      if (dates.length > 0) return format(closestTo(Date.now(), dates), 'HH:mm:ss')
      return 0
    }
  }
}
</script>

<style>
.bestPriceColumn {
  min-width: 93px;
  width: 93px;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  text-align: left;
  height:60px;
  font-weight:600;
  font-size:0.8rem ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bestPriceCol-loading{
  width:42px;
  border-top:0.5px solid #ddd;
  border-bottom:0.5px solid #ddd;
  border-right:1px solid #e4e4e4;
}
.bestPriceCol-content{
  box-sizing:border-box;
  height: 100%;
  color:#000;
  font-weight:400;
  width:95px;
}
.bestPriceCol-column {
  padding: 0px 0px;
  width: 95px;
  cursor: pointer;
  min-height:32px;
  max-height:32px;
  line-height:32px;
  text-align:left;
  border-bottom:0.5px solid #ddd;
  overflow: hidden;
  background:#fff;
}
.bestPriceCol-columnAcMode {
  padding: 0px 0px;
  width: 95px;
  cursor: pointer;
  min-height:32px;
  max-height:32px;
  line-height:32px;
  text-align:left;
  border-bottom:0.5px solid #333;
  overflow: hidden;
  background:#fff;
}
.columnWidthFooter-BPC {
  min-width:95px !important;
}
</style>
