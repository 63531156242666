<template>
  <apexchart style="padding: 4px" type="line" v-if="acMode === true && priceObject && filteredFlucs && flucs && !editMode" height="25" width="100" ref="chart" :options="flucsOptionsAC" :series="filteredFlucs"></apexchart>
  <apexchart style="padding: 4px" type="line" v-else-if="acMode === false && priceObject && filteredFlucs && flucs && !editMode" height="25" width="100" ref="chart" :options="flucsOptions" :series="filteredFlucs"></apexchart>
  <!-- <trend class="raceFlucs" v-else-if="acMode === false && priceObject && filteredFlucs" :data="priceObject && filteredFlucs"  :stroke-width="1.8" :gradient="gradient" :smooth="radius || false" :width="width" :height="height" :line-width="lineWidth" :stroke-linecap="lineCap" :gradient-direction="gradientDirection" :fill="fill" :type="type" :auto-line-width="autoLineWidth" :show-labels="showLabels" :label-size="labelSize"></trend> -->
</template>

<script>
import { format } from 'date-fns'
import { mapGetters, mapState } from 'vuex'
import { COUNTRY_CODE_LOOKUP } from '../common/constants'
import moment from 'moment-timezone'
import { isEmpty } from '../common/methods'

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea']
]
const gradientsacmode = [
  ['#455A64'],
  ['#455A64'],
  ['#455A64', '#455A64', '#455A64'],
  ['#455A64', '#455A64'],
  ['#455A64', '#455A64', '#455A64'],
  ['#455A64', '#455A64', '#455A64']
]
export default {
  name: 'RaceFlucs',
  data: () => ({
    moment,
    format,
    showLabels: true,
    lineWidth: 2,
    labelSize: 7,
    radius: 0,
    padding: 15,
    height: 30,
    width: 110,
    // selectedTime: 'All',
    lineCap: 'round',
    gradient: gradients[5],
    gradientAcMode: gradientsacmode[5],
    gradientDirection: 'bottom',
    gradients,
    gradientsacmode,
    fill: false,
    type: 'trend',
    autoLineWidth: true
  }),
  computed: {
    ...mapState(['editMode']),
    ...mapState(['selectedRace']),
    ...mapGetters(['getRaceDetails', 'getSelectedRace']),
    race: function () {
      return this.getSelectedRace()
    },
    flucsOptions: function () {
      const flucs = this.flucs.find(f => f.flucs[0]?.tabNo === this.priceObject.tabNo)
      if (!flucs?.flucs) return {}
      const timeArray = flucs?.flucs?.map((b) => {
        const nowDate = new Date(this.race.startTime)
        // Time right now
        const nowTime = new Date(nowDate).getTime()
        // Time of price change
        const flucTime = new Date(b.time).getTime()
        // Time to take away from now
        const radioTime = this.columnTime.split(':').reduce((acc, time) => (60 * acc) + +time)
        const rTime = radioTime * 1000
        // Now time - radio time
        const radioTimeFormat = nowTime - rTime
        // Return all prices
        if (this.columnTime === 'All') {
          return format(new Date(b.time), 'HH:mm:ss')// time <= now && now <= nextTime
        }
        if (this.columnTime === '>9am') {
          if (flucTime > this.formattedDate) {
            return format(new Date(b.time), 'HH:mm:ss')
          }
        }
        // Now time / fluc time / radio time
        // if fluc time is between now time and radio time return
        if (this.columnTime !== 'All' && '>9am') {
          if (flucTime <= nowTime && flucTime >= radioTimeFormat) {
            return format(new Date(b.time), 'HH:mm:ss')
          }
        }
      }).filter(f => f)
      if (timeArray?.length === 1) {
        timeArray[1] = timeArray[0]
      }
      // Return new arrays
      const dates = timeArray || [0]
      const options = {
        chart: {
          animations: {
            enabled: false
          },
          type: 'line',
          width: 100,
          height: 25,
          stroke: {
            curve: 'straight'
          },
          sparkline: {
            enabled: true
          }
        },
        xaxis: {
          categories: dates,
          title: {
            text: 'Time'
          }
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 35
          },
          x: {
            show: true
          },
          marker: {
            show: false
          }
        },
        stroke: {
          width: 2,
          colors: '#5388c4'
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            colorStops: [
              {
                offset: 0,
                color: '#95DA74',
                opacity: 1
              },
              {
                offset: 20,
                color: '#61DBC3',
                opacity: 1
              },
              {
                offset: 40,
                color: '#FAD375',
                opacity: 1
              },
              {
                offset: 60,
                color: '#fab175',
                opacity: 1
              },
              {
                offset: 100,
                color: '#EB656F',
                opacity: 1
              }
            ]
          }
        }
      }
      return options
    },
    flucsOptionsAC: function () {
      const timeArray = this.flucs[this.priceObject.tabNo - 1].flucs.map((b) => {
        const nowDate = new Date(this.race.startTime)
        // Time right now
        const nowTime = new Date(nowDate).getTime()
        // Time of price change
        const flucTime = new Date(b.time).getTime()
        // Time to take away from now
        const radioTime = this.columnTime.split(':').reduce((acc, time) => (60 * acc) + +time)
        const rTime = radioTime * 1000
        // Now time - radio time
        const radioTimeFormat = nowTime - rTime
        // Return all prices
        if (this.columnTime === 'All') {
          return format(new Date(b.time), 'HH:mm:ss')// time <= now && now <= nextTime
        }
        if (this.columnTime === '>9am') {
          if (flucTime > this.formattedDate) {
            return format(new Date(b.time), 'HH:mm:ss')
          }
        }
        // Now time / fluc time / radio time
        // if fluc time is between now time and radio time return
        if (this.columnTime !== 'All' && '>9am') {
          if (flucTime <= nowTime && flucTime >= radioTimeFormat) {
            return format(new Date(b.time), 'HH:mm:ss')
          }
        }
      }).filter(f => f)
      if (timeArray.length === 1) {
        timeArray[1] = timeArray[0]
      }
      // Return new arrays
      const dates = timeArray
      const options = {
        chart: {
          type: 'line',
          width: 100,
          height: 25,
          stroke: {
            curve: 'straight'
          },
          sparkline: {
            enabled: true
          }
        },
        xaxis: {
          categories: dates,
          title: {
            text: 'Time'
          }
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 35
          },
          x: {
            show: true
          },
          marker: {
            show: false
          }
        },
        stroke: {
          width: 4,
          colors: '#5388c4'
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            colorStops: [
              {
                offset: 0,
                color: '#455A64',
                opacity: 1
              },
              {
                offset: 20,
                color: '#455A64',
                opacity: 1
              },
              {
                offset: 40,
                color: '#455A64',
                opacity: 1
              },
              {
                offset: 60,
                color: '#455A64',
                opacity: 1
              },
              {
                offset: 100,
                color: '#455A64',
                opacity: 1
              }
            ]
          }
        }
      }
      return options
    },
    flucsChartData: function () {
      if (!this.flucs || !this.priceObject.tabNo) {
        return []
      }
      const data = this.flucs[this.priceObject.tabNo - 1].flucs.map(y => y.price).filter(p => p)
      if (data.length === 1) {
        data[1] = data[0]
      }
      return data
      // let sparksdata = {}
      // Object.entries(this.flucs).forEach((x) => {
      //   if (x[1] && x[1].length === 1) {
      //     const def = [ ...x[1], ...x[1] ]
      //     sparksdata[x[0]] = def.map(y => y.price).filter(p => p)
      //   } else {
      //     sparksdata[x[0]] = x[1].map(y => y.price).filter(p => p)
      //   }
      // })
      // return sparksdata
    },
    filteredFlucs: function () {
      // create array of arrays
      if (!this.flucs || !this.priceObject.tabNo || this.editMode) {
        return []
      }
      const flucs = this.flucs.find(f => f.flucs[0]?.tabNo === this.priceObject.tabNo)
      if (!flucs?.flucs) return
      const timeArray = flucs?.flucs.map((b) => {
        const nowDate = new Date(this.race.startTime)
        // Time right now
        const nowTime = new Date(nowDate).getTime()
        // Time of price change
        const flucTime = new Date(b.time).getTime()
        // Time to take away from now
        const radioTime = this.columnTime.split(':').reduce((acc, time) => (60 * acc) + +time)
        const rTime = radioTime * 1000
        // Now time - radio time
        const radioTimeFormat = nowTime - rTime
        // Return all prices
        if (this.columnTime === 'All') {
          return b.price// time <= now && now <= nextTime
        }
        if (this.columnTime === '>9am') {
          if (flucTime > this.formattedDate) {
            return b.price
          }
        }
        // Now time / fluc time / radio time
        // if fluc time is between now time and radio time return
        if (this.columnTime !== 'All' && '>9am') {
          if (flucTime <= nowTime && flucTime >= radioTimeFormat) {
            return b.price
          }
        }
      }).filter(f => f)
      if (timeArray?.length === 1) {
        timeArray[1] = timeArray[0]
      }
      // Return new arrays
      return [{
        name: 'Odds',
        data: timeArray
      }]
    },
    formattedDate: function () {
      if (!isEmpty(COUNTRY_CODE_LOOKUP[this.race?.meeting?.track?.state])) {
      var b = moment.tz(this.race.startTime, `Australia/${COUNTRY_CODE_LOOKUP[this.race.meeting.track.state]}`)
      var formattedDate = b.format()
      const newFormatDate = formattedDate.split('T')
      const timeZone = newFormatDate[1]
      const formatTimeZone = timeZone.split('+')
      const timeZoneTime = formatTimeZone[1]
      const newIsoString = newFormatDate[0] + 'T09:00:00' + '+' + timeZoneTime
      const newIsoSeconds = new Date(newIsoString).getTime()
      return newIsoSeconds
      } else {
        return ''
      }
    }
  },
  props: {
    priceObject: Object,
    flucs: Array,
    acMode: Boolean,
    selectedTime: String,
    columnTime: String
  }
}
</script>
