<template>
    <!-- <v-btn width="100px" class="mb-2 pa-0 nextRace box-shadow--container" small @mouseover="mouseOver" style="transform: rotate(-90deg); margin-top: 375px; margin-left: -403%; width: 492px;"> -->
    <v-btn width="100px" class="mb-2 pa-0 nextRace box-shadow--container white--text" small @mouseover="mouseOver" style="transform: rotate(-90deg); margin-top: 298px; margin-left: -236%; width: 328px;" color="#1a237e">
      <span><strong>Show Left Panel</strong></span>
    </v-btn>
</template>

<script>
export default {
  name: 'AutoHide',
  data: () => ({
    //
  }),
  props: {
    mouseOver: Function
  }
}

</script>
