<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask termsModal">
      <div class="modal-wrapper termsModal">
        <div class="modal-container termsModal" style="width:70vw;height:70vh;background:#333">
          <v-card style="overflow-y:scroll;height:100%;">
            <v-card-title class="headline">DynamicOdds - Terms &amp; Conditions</v-card-title>
            <v-card-title style="color:red;" class="subtitle">It is EXTREMELY important that you have read &amp; that you understand our Terms &amp; Conditions</v-card-title>
              <v-card-text class="text-left" :class="this.acMode ? 'termsAcMode' : 'stdMode'">DynamicOdds - Live Odds Comparison &amp; Wagering Interface
                <b>DynamicOdds - Live Odds Comparison &amp; Wagering Interface</b><br><br>
Last Updated: 30/07/2013 10:00:00 - Version 2.06 - Update<br><br>If you choose to access and use the 'DynamicOdds' Web
Site, you agree to be bound by these terms and conditions of the 'DynamicOdds' Web Site. If you do not accept these
terms and conditions, you must not access or use the 'DynamicOdds' Web Site or any information available from
it.<br><br><b>1. Terminology</b><br><br>1.1 The expressions 'we', 'us' and 'our' are a reference to the operator of
this Web Site, 'DynamicOdds'.<br><br>1.2 'DynamicOdds' means Dynamic Web Projects Pty Ltd. which is a duly
registered limited liability Company registered at Brisbane, Queensland, Australia and trading under the name and style
'DynamicOdds'.<br><br>1.3 The 'Member' is any person who is authorised to use an Account.<br><br>1.4 The
'Account' means the facility by which a Member's right to use 'DynamicOdds' service is paid for and monitored.
'DynamicOdds' provides a range of information relating to Thoroughbred Racing, Harness Racing and Greyhound Racing,
Sporting Events, such as (but not limited to) LIVE Odds, RACE Form and RACE Ratings.<br><br>1.5 The term 'Betting
Agencies' refers to Totalisator Agency Boards (TABs), Bookmakers &amp; Betting Exchanges.<br><br><b>2.
    Membership</b><br><br>2.1 This agreement commences on the date that you first visit 'DynamicOdds' and then after
the application to become a Member is approved and continues indefinitely, or until earlier termination in accordance
with this agreement. The Member acknowledges that at the time of entering into this agreement, they are not relying on
any representation made by 'DynamicOdds'.<br><br>2.2 To be able to gain access to information offered on
'DynamicOdds' Web Site, you must become a Member. To become a Member, you must complete your registration form in the
manner described on our Web Site. Membership is only available to persons who are 18 years of age or older. We reserve
the right to terminate your membership at any time if you do not comply with any of these terms and
conditions.<br><br>2.3 To register as a Member you agree to ensure that the registration details that you enter are
true and accurate at all times. You must promptly notify us of any change to your registration details as originally
supplied. You are responsible for maintaining the confidentiality of both your username and password and for any access
to or use of our Web Site and any services available from it using your username and password. You agree to pay for your
requested services in the manner specified on our Web Site.<br><br>2.4 We will at our discretion accept or reject
your membership application. We are not obliged to give reasons for rejecting your application for membership. Such
reasons for rejection of an application would be in relation to false or fraudulent information being
submitted.<br><br>2.5 We may, at our discretion, terminate your membership at any time. We are not obliged to give
reasons for such termination. However such reasons for termination may be in relation to false or fraudulent information
being submitted or impropriate emails or phone calls to either management or support personnel.<br /><br />2.6
‘Turnover Based Access’ and or ‘Free Access’ are at the discretion of ‘Dynamic Odds’ and its ‘Preferred Partners’ and
can be withdrawn at any time without notice to you.<br><br><b>3. Commercial Use</b><br><br>3.1 The 'DynamicOdds' Web
Site and its systems cannot in any way or form be used for commercial purposes. There are systems in place which will
automatically block any detected commercial use. This is to ensure that our members have the fastest possible service
and that our servers are not being overloaded by the commercial sector.<br><br><b>4. Subscriptions</b><br><br>4.1 The subscription Terms and Conditions for 'DynamicOdds' are as set out on our Web Site. Prices are inclusive of
Australian Goods and Services Tax. Subscription fees are payable in advance. A tax invoice will be provided for any
subscription or other fees (Upon Request) to your nominated E-mail address. On receipt of the subscription fees access
will be provided to the information you have subscribed to, during the period to which your payment relates. If you have
a recurring subscription in place and have failed to honour payment for any reason, then without limiting our rights, we
may immediately suspend your access to the services in question.<br><br><b>5. Refund Policy</b><br><br>5.1
Subscriptions are Non-Refundable except where a member has upgraded their subscription in which case the refund will be
to the value of the remainder of the original subscription.<br><br><b>6. Warranties and Liabilities</b><br><br>6.1
The information on our Web Site which we provide to you we do so in good faith. We do not supply advice but general
information only. We reserve the right to update or change this information at any time without notice to you. In
addition, we do not make any representations or warranties for any errors that may occur in information provided to you
or that your access to that information will be uninterrupted or timely. We do not accept responsibility or liability of
any kind for any loss resulting from any action taken or not taken, or your reliance on any information or material that
is posted on or available from our Web Site. You agree to use the information accessible from our Web Site for lawful
purposes only.<br><br>6.2 We do not accept responsibility or liability of any kind for any loss resulting from the
incorrect or non operation of any feature'(s) on our Web Site.<br><br><b>7. Security/Cautions</b><br><br>7.1 It is
your responsibility to make sure that your access to our Web Site, and your access to and use of the information
available from it is not illegal or prohibited by any laws which may apply to you.<br><br>7.2 We advise you to take
your own precautions to ensure that the process that you have in place for accessing this Web Site does not expose your
computer system the risk of damage from viruses, malicious computer code or other forms of interference. We do not
accept responsibility for any interference or damage to your computer system which arises in connection with your use of
our Web Site or any linked Web Site.<br><br>7.3 Any opinions or advice given or supplied by third parties remain the
responsibility of those third parties and we do not guarantee the accuracy or reliability. Where information made
available from our Web Site contains opinions or advice of other parties, we do not necessarily endorse those opinions
or advice. It remains your responsibility to evaluate the accuracy, completeness and usefulness of any of that
information.<br><br>7.4 Advertisers on our Web Site are solely responsible for the content of their advertisements
appearing on our Web Site which also includes hyperlinks to advertisers' own Web Sites. The acceptance of those
advertisements by 'DynamicOdds' is not in any way a recommendation or endorsement by us of the advertisers' products and
each advertiser is solely responsible for any representations made in connection with its advertisement.<br><br>7.5
Data transmission over the Internet cannot be guaranteed as totally secure. As a result of this we cannot guarantee the
security of information which you transmit to us. Accordingly, any information that you transmit to us is transmitted at
your own risk. Once we receive your transmission, we will take reasonable steps to preserve the security of that
information.<br><br>7.6 Maintenance of an unscheduled nature may need to be performed due to equipment failure. If
maintenance is required, we will endeavour to make the service operational again as soon as possible. Refer to Section 6
Sub Paragraph 6.1) regarding liability.<br><br>7.7 All Credit card transactions are processed using 128 bit
encryption.<br><br>7.8 Credit Card Transactions – Please click on the following Link:
http://www.eway.com.au/Company/PCICompliance/Overview.aspx<br><br><b>8. SmartBet</b><br><br>8.1 You will be required
to enter your Betting Agency's login details to utilise SmartBet. These details are encrypted and stored in a data file
on your PC. This data file is automatically removed when uninstalling the 'DynamicOdds' advanced browser.<br><br>8.2
Upon logging into the Betting Agency through SmartBet a Session ID is generated by that Betting Agency. This Session ID
is then used to place your wagers via the DynamicOdds servers.<br><br>8.3 When placing your wagers we do not make
any representations or warranties for any errors or delays that may occur in data transmission or that the information
will be uninterrupted or timely, for example a request or submission that has 'timed out'.<br><br>8.4 Whilst this
site provides information and odds from Betting Agencies in good faith, it cannot be held responsible for any errors or
omissions on the site as we are entirely reliant on the accuracy of&nbsp;information from third party sources, namely
the Betting Agencies.<br><br>8.5 The responsibility lies with the end user to ensure that they are satisfied that
the Odds they have selected and the value of the wager are correct. ('DynamicOdds' strive for 100% accuracy with all
information displayed on our web site)<br><br>8.6 We do not accept responsibility or liability of any kind for any
loss resulting from any action taken or not taken, or your reliance on any information or material that is posted on or
available from our Web Site.<br><br>8.7 Your access to SmartBet only allows you access to the Betting Agencies that
'DynamicOdds' has currently available. 'DynamicOdds' reserves the right to add or remove any Betting Agencies without
notice.<br><br>8.8 Dynamic Web Projects Pty Ltd (Trading as DynamicOdds) accepts no responsibility for any wager
that may be placed and that you acknowledge that there is no assurance of the acceptance or accuracy of any wager in
respect of any race placed through DynamicOdds Website.<br><br><b>9. Copyright</b><br><br>9.1 Copyright for all
material included on our Web Site resides with 'DynamicOdds' its suppliers and agents. Full intellectual property rights
relating to our systems and information incorporated on our Web Site reside with 'DynamicOdds' and its suppliers and no
part of any such systems or information may be used elsewhere except with the consent of 'DynamicOdds'.<br><br>9.2 Members are provided with access to our Web Site and any information on it, strictly for your personal use only. You are
authorised to print a copy of any information contained on our Web Site for your personal use, unless such printing is
specifically prohibited. You may not without our written permission sell, distribute or commercialise any information
obtained from our Web Site.<br><br><b>10. Privacy Policy</b><br><br>10. DynamicOdds will take all reasonable steps to
ensure that the Personal Information which it collects is accurately recorded and is stored in a secure environment
which is accessed only by authorised persons.<br><br>10. DynamicOdds does not give, sell, rent, share or trade
Personal Information regarding its Members to third parties.<br><br><b>11. General</b><br><br>11. We do not accept
any liability for any failure to comply with these terms and conditions where such failure is due to circumstances
beyond our control.<br><br>11. If we waive one or more of these terms and conditions on any one occasion this does
not mean that these said terms and conditions will be waived at any other time and all other terms and conditions will
continue in force in all other respects.<br><br>11. If any of these terms and conditions is held to be invalid,
unenforceable or illegal for any reason and in any jurisdiction, the remaining terms and conditions shall nevertheless
continue in full force in that jurisdiction, and all of these terms and conditions will continue in force in all other
jurisdictions.<br><br>
11. It is acknowledged and agreed that the terms and conditions referred to herein shall remain binding on the member
for the duration of their membership.<br><br><b>12. Software Ownership</b><br><br>12.01 Dynamic Odds Website is powered
by: C.D.K. Software Ltd (NZ) – ODDS COMPARISON &amp; WAGERING PLATFORM including the ‘Front End’ Interface’ and ‘Back
End Scripts’ <br><br>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" dark @click="showTermsFunc(false)">Agree</v-btn>
              </v-card-actions>
            </v-card>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
export default {
  props: {
    showTermsFunc: Boolean,
    acMode: Boolean
  }

}
</script>

<style scoped>
.termsModal {
  z-index: 204;
}
.termsAcMode {
  font-weight:bold
}

</style>
