<template>
  <div class="addColumnButton">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      nudge-left="50"
      nudge-bottom="50"
      left
      bottom
      min-width="400"
      class="ma-3"
      no-action
    >
      <template v-slot:activator="{ on }">
        <v-icon v-if="!largeIcon && columnLength < 30" size="13" color="success" v-on="on" class="column-actions__add">mdi-plus</v-icon>
        <v-icon v-else-if="!largeIcon && columnLength === 30" size="13" color="disabled" disabled v-on="on">mdi-plus</v-icon>
        <v-btn v-if="largeIcon" class="mx-2" fab large color="blue accent-3"  v-on="on" style="">
          <v-icon x-large color="white">mdi-arrow-bottom-left</v-icon>
          <v-icon size="13" class="mr-n4 ml-n2" color="white">mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card v-if="columnLength < 30" class="addColumnMenu" v-moveable>
        <v-toolbar color="primary" dark dense>
          <div style="width:100%;">
            <v-toolbar-title class="float-left"> Add Column </v-toolbar-title>
            <v-btn class="float-right cancelButton" icon small @click="menu = false" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title  class="priceMenu body-2"><v-icon class="mr-1">mdi-cash-usd-outline</v-icon> Price</v-list-item-title>
              </v-list-item-content>
            </template>
            <PriceMenu :addColumn="addColumn"/>
            <v-list-item v-if="hasFeature('bestPriceColumn')">
              <v-chip label color="blue" dark small>Best Odds</v-chip>
              <v-list-item-content>
              </v-list-item-content>
              <v-btn x-small @click="addBestPriceColumn()" class="bestPriceButton">Add</v-btn>
            </v-list-item>
            <v-list-item v-if="hasFeature('bestPriceColumn')">
              <v-chip label color="blue" dark small>Best Tote</v-chip>
              <v-list-item-content>
              </v-list-item-content>
              <v-btn x-small @click="addBestToteColumn()" class="bestPriceButton">Add</v-btn>
            </v-list-item>
            <v-list-item v-if="hasFeature('occColumn')">
              <v-chip label color="blue" dark small>%OCC</v-chip>
              <v-list-item-content>
              </v-list-item-content>
              <v-btn x-small @click="addOCCColumn()" class="bestPriceButton">Add</v-btn>
            </v-list-item>
          </v-list-group>
          <div v-if="hasFeature('betfair')">
            <v-list-group
            no-action
            :value="false"
            >
              <template v-slot:activator>
                <v-list-item-content >
                  <v-list-item-title class="body-2"><v-icon class="mr-1">mdi-chart-areaspline-variant</v-icon> Betfair</v-list-item-title>
                </v-list-item-content>
              </template>
              <BetfairMenu :addColumn="addColumn" flucs/>
              <v-list-item v-if="hasFeature('bestPriceColumn')">
              <v-chip label color="blue" dark small>BetFair Flucs</v-chip>
              <v-list-item-content>
              </v-list-item-content>
              <v-btn x-small @click="handleBfFlucs()" class="bestPriceButton">Add</v-btn>
            </v-list-item>
            </v-list-group>
          </div>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="body-2"><v-icon class="mr-1">mdi-chart-line-variant</v-icon> Flucs</v-list-item-title>
              </v-list-item-content>
            </template>

            <PriceMenu :addColumn="addColumn" flucs/>
          </v-list-group>

          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="spacerMenu body-2"><v-icon class="mr-1">mdi-pan-horizontal</v-icon> Spacer</v-list-item-title>
              </v-list-item-content>
            </template>
            <SpacerMenu :addColumn="addColumn"/>
          </v-list-group>

          <v-list-group
            no-action
            :value="false"
            v-if="hasFeature('runnerInfo')"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="infoMenu body-2"><v-icon class="mr-1">mdi-information-outline</v-icon> Runner Info</v-list-item-title>
              </v-list-item-content>
            </template>
            <RunnerInfoMenu :addColumn="addColumn"/>
          </v-list-group>

          <v-list-group
            no-action
            :value="false"
            v-if="hasFeature('runnerInfo')"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="infoMenu body-2"><v-icon class="mr-1">mdi-numeric</v-icon> Ratings Columns</v-list-item-title>
              </v-list-item-content>
            </template>
            <RatingsColumns :addColumn="addColumn"/>
          </v-list-group>

          <v-list-group
            no-action
            :value="false"
            v-if="hasFeature('customColumns')"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="infoMenu body-2"><v-icon class="mr-1">mdi-pen</v-icon> My Custom Data</v-list-item-title>
              </v-list-item-content>
            </template>
            <CustomDataMenu :addColumn="addColumn"/>
          </v-list-group>

        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import PriceMenu from './PriceMenu'
import SpacerMenu from './SpacerMenu'
import RunnerInfoMenu from './RunnerInfoMenu'
import BetfairMenu from './BetfairMenu'
import CustomDataMenu from './CustomDataMenu'
import RatingsColumns from './RatingsColumns'
import { BOOKMAKER_CODE_NAMES, COLUMN_TYPE_BESTPRICE, COLUMN_TYPE_PRICE } from '../../common/constants'

export default {
  data: () => ({
    menu: false
  }),
  components: {
    PriceMenu,
    SpacerMenu,
    RunnerInfoMenu,
    BetfairMenu,
    CustomDataMenu,
    RatingsColumns
  },
  props: {
    position: Number,
    largeIcon: Boolean,
    columnLength: Number
  },
  computed: {
    ...mapGetters(['getSelectedColumns', 'getSelectedColumnsName', 'hasFeature'])
  },
  methods: {
    ...mapActions(['updateSelectedColumns']),
    handleBfFlucs: function () {
      const column = { source: 'BF', type: 'WIN', display: 1 }
      this.addColumn(column)
    },
    addColumn: function (column) {
      this.menu = !this.menuToggle
      const columns = [ ...this.getSelectedColumns ]
      const p = this.position < 0 ? columns.length + 1 : this.position
      columns.splice(p, 0, column)
      this.updateSelectedColumns({ name: this.getSelectedColumnsName, columns })
    },
    addBestPriceColumn: function () {
      const column = { source: 'BPC', type: 'BEST_PRICE', title: 'Best Odds', display: COLUMN_TYPE_BESTPRICE, filters: ['WIN_FIXED_ODDS'] }
      this.addColumn(column)
    },
    addBestToteColumn: function () {
      const column = { source: 'BTC', type: 'BEST_TOTE', title: 'Best Tote', display: COLUMN_TYPE_PRICE }
      this.addColumn(column)
    },
    addOCCColumn: function () {
      const column = { source: 'OCC', type: 'bookieSelect', title: '%OCC', display: COLUMN_TYPE_PRICE, filters: { value: BOOKMAKER_CODE_NAMES['Sportsbet'] } }
      this.addColumn(column)
    }
  }
}
</script>

<style>
  .addColumnButton {
    display: inline-block;
  }
  .draggable {
    cursor: grab;
  }
  .dragging {
    cursor: grabbing;
  }
</style>
