<template>
  <div class="apolloColumn">
    <div :class="overflowedList ? 'raceTableColumnContainer-overflowed' : 'raceTableColumnContainer'">
      <div class="raceTableColumnMainContainer">
        <div :class="acMode ? 'raceTableColumnDiv-priceAcMode' : 'raceTableColumnDiv'" :style="getColumnStyle(index)">
          <div class="raceTableColumn">
            <div :class="['runnerInfo-content']">
              <div v-if="editMode" class="raceHeaderCol">
                <div :class="['raceTableColumn-header save-font']" >
                  <div :class="['infoColumnEditMode']">
                    {{ title }}
                  </div>
                  <div v-if="editMode" class="columnActions editingDecorations">
                    <AddColumnButton :columnLength="columnLength" :position="index"/>
                    <DeleteColumnButton :columnNumber="index"/>
                    <EditColumnButton :columnNumber="index"/>
                  </div>
                </div>
              </div>
              <div :class="editMode ? 'editColumn' : ''">
                <div v-for="(competitor, i) in getRaceCompetitors" :key="`${competitor.tabNo}:${i}`" :class="[isInWatchList(competitor.tabNo) ? ['highlight columnWidth-' + source] : ['columnWidth-' + source]]" >
                  <div :class="[acMode ? 'runnerInfo-columnAcMode' : 'runnerInfo-column']" :style="getColumnStyle(index)">
                    <v-tooltip v-if="!editMode" right color="black">
                      <template v-slot:activator="{ on: tooltip }">
                        <div :class="['statsClass', classObject]" v-if="selectionStats(selection)" v-on="tooltip"><strong>{{ runnerStatsFieldStart(competitor.tabNo) }}</strong>{{ runnerStatsField(competitor.tabNo) }}</div>
                        <v-text-field prefix="$" type="number" v-else-if="selection === 'my_price_dynamicform'" class="race-grid__text-field pa-0 pl-3 ma-n2" min="0" flat dense :value="runnerInfoField(competitor)" :disabled="competitor.scratched" hide-details @blur="setMyCompetitorPrice({ price: $event.target.value, runnerId: competitor.runner.id })"></v-text-field>
                        <v-text-field type="number" v-else-if="selection === 'rating_dynamicform'" class="race-grid__text-field pa-0 pl-3 ma-n2 customizeRatings" min="0" flat dense :value="runnerInfoField(competitor)" :disabled="competitor.scratched" hide-details @blur="setMyCompetitorRating({ rating: $event.target.value, runnerId: competitor.runner.id })"></v-text-field>
                        <div :class="['statsClass', classObject]" v-else v-on="tooltip">{{ runnerInfoField(competitor) }}</div>
                      </template>
                      <span :class="[classObject]" v-if="selectionStats(selection)"><strong>{{ runnerStatsFieldStart(competitor.tabNo) }}</strong>{{ runnerStatsField(competitor.tabNo) }}</span>
                      <span :class="[classObject]" v-else>{{ runnerInfoField(competitor) }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddColumnButton from './menus/AddColumnMenu'
import DeleteColumnButton from './menus/DeleteColumnMenu'
import EditColumnButton from './menus/EditColumnMenu'
import { mapState, mapGetters, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
  props: {
    id: String,
    overflowedList: Boolean,
    selection: String,
    source: String,
    editMode: Boolean,
    title: String,
    index: Number,
    columnLength: Number,
    getColumnStyle: Function,
    sub: String,
    type: String
  },
  components: {
    AddColumnButton,
    DeleteColumnButton,
    EditColumnButton
  },
  methods: {
    ...mapActions(['setMyCompetitorPrice', 'setMyCompetitorRating']),
    gql,
    runnerInfoField: function (v) {
      const number = v.tabNo
      const competitors = this.runners.find(c => c.no === number)
      const selected = this.selection
      if (selected === 'blinkers' && competitors?.[selected] === true) return 'Yes'
      if (selected === 'barrier_live' && this.getSelectedMeeting.type === 'GREYHOUND' && !v.scratched) return v.barrier
      if (selected === 'barrier_live' && this.getSelectedMeeting.type === 'GREYHOUND' && v.scratched) return '-'

      if (selected === 'rating_dynamicform' || selected === 'my_price_dynamicform') {
        return competitors?.[selected] || 0
      } else {
        return competitors?.[selected] || '-'
      }
    },
    // onInputChangeMyCompetitorPrice ({ price, runner }) {
    //   const runnerId = runner?.runner?.id
    //   console.log(runnerId)
    //   // Vue.set(this.raceGridMyPrice, runnerId, price)
    // },
    // onInputChangeMyCompetitorRating ({ rating, runner }) {
    //   const runnerId = runner?.runner?.id
    //   console.log(runnerId)
    //   // Vue.set(this.raceGridMyRating, runnerId, rating)
    // },
    selectionStats: function (v) {
      if (v.includes('statsDO')) return true
    },
    runnerStatsFieldStart: function (v) {
      const competitors = this.runners.find(c => c.no === v)
      const subSection = this.sub
      const field = competitors?.stats?.[subSection]
      return `${field?.starts || 0}-`
    },
    runnerStatsField: function (v) {
      const competitors = this.runners.find(c => c.no === v)
      const subSection = this.sub
      const field = competitors?.stats?.[subSection]
      return `${field?.plc1 || 0}-${field?.plc2 || 0}-${field?.plc3 || 0}`
    },
    isInWatchList (tabNo) {
      return this.getWatchListRunners.includes(tabNo)
    }
  },
  computed: {
    ...mapGetters(['getRaceCompetitors', 'getWatchListRunners', 'getGridSettings', 'getSelectedMeeting']),
    ...mapState(['acMode']),
    classObject: function () {
      return {
        rightAlignPricesDiv: this.getGridSettings.includes('rightAlignPrices')
      }
    },
    selected () {
      if (this.selection === 'daysSince') return ''
      return this.selection
    },
    ...mapGetters(['getStateDynamicRaceData']),
    runners () {
      return this.getStateDynamicRaceData?.runners || []
    },
    fetchRunnerData () {
      return this.getStateDynamicRaceData
    }
  }
}
</script>

<style>
.columnWidth-INF-stats {
  width: 80px;
  background: #fff;
}
.columnWidthFooter-INF-stats {
  width:80px !important;
}
.columnWidth-INF-jockeyColour {
  text-align:left !important;
  width: 120px
}
.columnWidthFooter-INF-jockeyColour {
  width:120px !important;
}
.columnWidth-INF-gear {
  width: 120px
}
.columnWidthFooter-INF-gear {
  width:120px !important;
}
.columnWidth-INF-trainerLocation {
  width: 120px
}
.columnWidthFooter-INF-trainerLocation {
  width:120px !important;
}
.columnWidth-INF-statsYear {
  width: 125px;
}
.columnWidthFooter-INF-statsYear {
  width:125px !important;
}
.columnWidth-INF-last10Starts {
  width: 100px;
  overflow:hidden
}
.columnWidthFooter-INF-last10Starts {
  width:100px !important;
}
.columnWidth-INF-last4Starts {
  width: 80px;
  overflow:hidden
}
.columnWidthFooter-INF-last4Starts {
  width:80px !important;
}
</style>

<style lang="scss">
  .race-grid {
    padding-bottom: 50px;

    &__table td {
      height: 35px !important;
    }

    &__text-field {
      position: relative;
      top: 12px;
      height: 50px;
      font-size: 0.8rem !important;
      border-style: none !important;
    }

    &__text-field  .v-input__slot::before,
    &__text-field  .v-input__slot::after {
      border-style: none !important;
    }
  }

  .customizeRatings {
    width: 70px;
  }

</style>
