import Vue from 'vue'
import cuid from 'cuid'
import { format, startOfDay, isSameDay, isBefore, fromUnixTime, subDays, getUnixTime, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { getBetTypes, getBetHistory, addToBetHistory, submitBet, getUserBetHistory, getClearedBets, setClearedBets } from '../services/betting'
import { queryBetHistoryRace } from '../common/queries'
import { apolloClient } from '../vue-apollo'
import gql from 'graphql-tag'
import { BET_STATUS_CODE_ENG } from '../common/constants'
import socket from './socket'
import { datadogRum } from '@datadog/browser-rum'

const store = {
  namespaced: true,
  state: {
    betHistory: [],
    allBets: [],
    betHistoryRace: {},
    userBetHistoryByDate: [],
    selectedBet: {},
    betHistorySorted: [],
    bets: [],
    betsWithRaces: [],
    clearedBets: []
  },
  mutations: {
    addBetHistory (state, { betHistory }) {
      state.betHistory = betHistory
    },
    saveAllBets (state, { allBets }) {
      state.allBets = allBets
    },
    clearUserBetHistoryByDate (state) {
      state.userBetHistoryByDate = []
    },
    clearBetHistorySorted (state) {
      state.betHistorySorted = []
    },
    clearBetHistorySearchResults (state) {
      state.betHistorySearchResults = []
    },
    setBetHistoryRace (state, { race }) {
      const key = race.id
      Vue.set(state.betHistoryRace, key, race)
    },
    setUserBetHistoryByDate (state, { userBetHistory }) {
      state.userBetHistoryByDate = userBetHistory
    },
    setSelectedBet (state, selectedBetObj) {
      state.selectedBet = selectedBetObj
    },
    saveBetHistorySorted (state, { bets }) {
      state.betHistorySorted = bets
    },
    setLoadBets (state, m) {
      const bets = state.bets
      m.key = cuid()
      bets.push(m)
      state.bets = bets
    },
    removeBetslipBet (state, bet) {
      const bets = state.bets.filter(b => b.key !== bet.key)
      state.bets = bets
    },
    clearBetslip (state) {
      state.bets = []
    },
    addToLocalBetHistory (state, bet) {
      const updatedBetHistory = state.betHistory ? [...state.betHistory] : []
      const updatedAllBets = state.allBets ? [...state.allBets] : []
      updatedBetHistory.push(bet)
      updatedAllBets.push(bet)
      state.betHistory = updatedBetHistory
      state.allBets = updatedAllBets
    },
    clearBetsWithRaces (state) {
      state.betsWithRaces = []
    },
    setBetsWithRaces (state, bet) {
      const exist = state.betsWithRaces.some(b => b.bet.bet_id === bet.bet.bet_id)
      if (!exist) {
        state.betsWithRaces = [ ...state.betsWithRaces, bet ]
      }
    },
    addClearedBets (state, clearedBets) {
      state.clearedBets = clearedBets
    }
  },
  actions: {
    searchBetHistory (context, { search }) {
      context.commit('clearBetHistorySearchResults')

      if (typeof search === 'undefined') {
        context.commit('clearBetHistorySearchResults')
      } else {
        context.state.betHistorySorted.forEach(bet => {
          if (bet.agencyName.toLowerCase().includes(search.toLowerCase()) || bet.eventDetails.toLowerCase().includes(search.toLowerCase()) || bet.selection.toLowerCase().includes(search.toLowerCase()) || bet.betType.toLowerCase().includes(search.toLowerCase()) || bet.betStatus.toLowerCase().includes(search.toLowerCase())) {
            context.state.betHistorySearchResults.push(bet)
          }
        })
      }
    },
    async addBet (context, { bet, raceId, raceNumber, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId }) {
      const config = context.rootGetters['account/authConfig']
      var date = startOfDay(Date.now())
      date.setUTCHours(0, 0, 0)
      date = date.toISOString()
      addToBetHistory({ bet, raceId, raceNumber, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId, date, config })
    },
    subscribeToBetStatus (context, bet) {
      socket.subscribeBetStatus(bet.bet_id)
    },
    selectBet (context, selectedBetObj) {
      context.commit('setSelectedBet', selectedBetObj)
    },
    // loadBets (context, v) {
    //   context.commit('setLoadBets', v)
    // },
    loadBetSlip (context, v) {
      context.commit('setLoadBets', v)
    },
    clearBets (context) {
      context.commit('clearBetslip')
    },
    fetchBetHistoryByDate (context, { day }) {
      context.commit('clearBetsWithRaces')
      let date = startOfDay(Date.now())
      date.setUTCHours(0, 0, 0)
      const today = date.toISOString()
      // if (day !== 'yesterday') context.dispatch('fetchBetHistoryRange', { date: subDays(date, day) })
      if (day === 'yesterday') {
        const newDate = subDays(date, 1)
        context.dispatch('fetchRacesDataFromBetHistory', { date: newDate.toISOString() })
      } else if (day === '3') {
        for (var i = 0; i < 3; i++) {
          const newDate = subDays(date, i)
          context.dispatch('fetchRacesDataFromBetHistory', { date: newDate.toISOString() })
        }
      } else if (day === '7') {
        for (i = 0; i < 7; i++) {
          const newDate = subDays(date, i)
          context.dispatch('fetchRacesDataFromBetHistory', { date: newDate.toISOString() })
        }
      } else if (day === '30') {
        for (i = 0; i < 30; i++) {
          const newDate = subDays(date, i)
          context.dispatch('fetchRacesDataFromBetHistory', { date: newDate.toISOString() })
        }
      } else if (day === '60') {
        for (i = 0; i < 60; i++) {
          const newDate = subDays(date, i)
          context.dispatch('fetchRacesDataFromBetHistory', { date: newDate.toISOString() })
        }
      } else if (day === '90') {
        for (i = 0; i < 90; i++) {
          const newDate = subDays(date, i)
          context.dispatch('fetchRacesDataFromBetHistory', { date: newDate.toISOString() })
        }
      } else {
        context.dispatch('fetchRacesDataFromBetHistory', { date: today })
      }
      context.dispatch('saveBetHistory', { bets: context.state.allBets })
    },
    async fetchRacesDataFromBetHistory (context, { date }) {
      try {
        const config = context.rootGetters['account/authConfig']
        const userDataBetHistory = await getUserBetHistory(date, config)
        userDataBetHistory.forEach(bet => {
          context.dispatch('fetchBetHistoryRace', { bet })
        })
        context.commit('clearUserBetHistoryByDate')
        context.commit('setUserBetHistoryByDate', { userBetHistory: userDataBetHistory })
      } catch (error) {
      }
    },
    async fetchBetHistoryRace (context, { bet }) {
      if (!bet.raceId) return null
      try {
        const raceID = JSON.stringify(bet.raceId)
        const query = queryBetHistoryRace(raceID)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const race = result.data.race
        if (race) context.commit('setBetHistoryRace', { race })
        const b = context.state.allBets.find(bt => bt.id === bet.id)
        context.commit('setBetsWithRaces', { ...bet, ...b, ...race })
        return race
      } catch (error) {
      }
    },
    async fetchBetTypes (context, { raceNumber, bookieCode, gbsMeetingId }) {
      try {
        const config = context.rootGetters['account/authConfig']
        const sessionId = Vue.$cookies?.get('sessionId')
        const betTypes = await getBetTypes(raceNumber, bookieCode, gbsMeetingId, sessionId, config)
        return betTypes
      } catch (error) {
      }
    },
    async fetchBetHistory (context) {
      try {
        const config = context.rootGetters['account/authConfig']
        const sessionId = Vue?.$cookies?.get('sessionId')
        const betsDate = startOfDay(Date.now()).setUTCHours(0, 0, 0) // ADDED TO FIX BET HISTORY
        const betHistoryDate = subDays(betsDate, 90).toISOString() // ADDED TO FIX BET HISTORY
        const betHistory = await getBetHistory({ date: betHistoryDate, sessionId }, config) // MODIFIED TO FIX BET HISTORY
        const clearedBets = await getClearedBets(startOfDay(new Date()).toISOString(), config)
        context.commit('addClearedBets', clearedBets)
        var date = startOfDay(Date.now())
        date.setUTCHours(0, 0, 0)
        date = date.toISOString()
        const userDataBetHistory = await getUserBetHistory(date, config)
        context.commit('addBetHistory', { betHistory: userDataBetHistory })
        const allBetPromises = betHistory?.data?.history?.map(async (b) => {
          const bet = userDataBetHistory.find(ub => ub.id === b.id)
          const combinedBet = { ...b, ...bet }
          const race = await context.dispatch('fetchBetHistoryRace', { bet: combinedBet })
          return { ...b, ...bet, race: race?.meeting?.track?.name + ' R' + race?.number }
        })
        const allBets = await Promise.all(allBetPromises)
        context.commit('saveAllBets', { allBets })
        context.dispatch('saveBetHistory', { bets: allBets })

        // const filteredBets = context.state.allBets?.filter(bet => isSameDay(startOfDay(new Date()), startOfDay(fromUnixTime(bet.date_time?.seconds))))
        // filteredBets.forEach(bet => context.dispatch('fetchBetHistoryRace', { bet }))
        return betHistory
      } catch (error) {
      }
    },
    async fetchBetHistoryRange (context, { date }) {
      try {
        const config = context.rootGetters['account/authConfig']
        const sessionId = Vue?.$cookies?.get('sessionId')
        const betHistory = await getBetHistory({ sessionId, date }, config)
        const clearedBets = await getClearedBets(startOfDay(new Date()).toISOString(), config)
        context.commit('addClearedBets', clearedBets)
        const allBets = betHistory?.data?.history?.map(b => {
          return { ...b }
        })
        context.commit('saveAllBets', { allBets })
        context.dispatch('saveBetHistory', { bets: allBets })
        return betHistory
      } catch (error) {
      }
    },
    saveBetHistory (context, { bets }) {
      var races = context.getters.getBetHistoryRace
      if (typeof bets !== 'undefined') {
        bets.forEach(bet => {
          const race = races[bet.raceId]
          if (typeof race !== 'undefined') {
            // bet.race = race.meeting.track.name + ' R' + race.number
            race.competitors.forEach(c => {
              if (c.tabNo === bet.runner_no) {
                bet.position = c.finalPosition
              }
            })
          }
          const agency = context.rootGetters.getAvailableAgencies?.find(a => a.agency_id === bet.agency_id)
          bet.agencyName = agency?.name
          bet.dateFormatted = format(fromUnixTime(bet.date_time?.seconds), 'dd/MM/yyyy HH:mm:ss')
          bet.selection = bet.runner_no + '. ' + bet.runner_name
          bet.betType = bet.win_plc + ' ' + bet.bet_type_description
          bet.betStatus = BET_STATUS_CODE_ENG[bet.status]
          bet.eventDetails = typeof race === 'undefined' ? '' : race.meeting.track.name + ' R' + race.number
          bet.meetingType = typeof race === 'undefined' ? '' : race.meeting.type
        })

        bets = bets.sort((a, b) => isBefore(parseISO(new Date(parseInt(a.date_time?.seconds) * 1000).toISOString()), parseISO(new Date(parseInt(b.date_time?.seconds) * 1000).toISOString())) ? 1 : -1)
        context.commit('clearBetHistorySorted')
        context.commit('saveBetHistorySorted', { bets: bets })
      }
    },
    async placeBet (context, { raceNumber, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId, raceId, key }) {
      try {
        const config = context.rootGetters['account/authConfig']
        const sessionId = Vue.$cookies.get('sessionId')
        const result = await submitBet(raceNumber, tabNo, runnerName, bookieCode, betTypeId, stake, price, gbsMeetingId, sessionId, config)
        context.dispatch('addBet', {
          bet: result,
          raceId,
          raceNumber,
          tabNo,
          runnerName,
          bookieCode,
          betTypeId,
          betType,
          stake,
          price,
          gbsMeetingId,
          config
        })
        const seconds = getUnixTime(new Date())
        context.commit('addToLocalBetHistory', { bet: result, raceId, raceNumber, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId, status: result.status_code, date_time: { seconds }, id: result.bet_id })
        context.dispatch('subscribeToBetStatus', result)
        if (result.status_code === 7) {
          context.dispatch('logoutBetting')
        }
        const race = context.rootState?.races?.[raceId]
        const track = race?.meeting?.track?.name
        const date = zonedTimeToUtc(race?.meeting?.date, 'GMT')
        datadogRum.addUserAction('placeBet', {
          raceId,
          track,
          raceNumber,
          date,
          tabNo,
          runnerName,
          bookieCode,
          betType,
          stake,
          price,
          ...result
        })
        return result
      } catch (error) {
        datadogRum.addUserAction('betResult', {
          raceNumber,
          tabNo,
          runnerName,
          bookieCode,
          betType,
          stake,
          price,
          ...error
        })
        context.commit('addToLocalBetHistory', { bet: error, raceId, raceNumber, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId, status: 4, id: null })
        // context.commit('setSessionId', null)
      }
    },
    async setClearedBets (context, betIds) {
      const config = context.rootGetters['account/authConfig']
      const cleared = context.state.clearedBets || []
      const clearedBets = [...cleared, ...betIds]
      context.commit('addClearedBets', clearedBets)
      setClearedBets(startOfDay(new Date()).toISOString(), clearedBets, config)
    }
  },
  getters: {
    getBetHistory (state) {
      return state.betHistory
    },
    getBetHistoryRace (state) {
      return state.betHistoryRace
    },
    getBetHistoryByDate (state) {
      return state.userBetHistoryByDate
    },
    getSelectedBet (state) {
      return state.selectedBet
    },
    getBetSlip (state) {
      return state.bets
    },
    getTodaysBets (state) {
      const bets = state.allBets?.filter(bet => isSameDay(startOfDay(new Date()), startOfDay(fromUnixTime(bet.date_time?.seconds))))
      return bets || []
    },
    getClearedBets (state) {
      return state.clearedBets
    },
    getAllBetHistory (state) {
      return function (option, search) {
        // console.log(option, state.betHistorySorted, state.betHistorySearchResults)

        // bets = bets.sort((a, b) => isBefore(a.date_time?.seconds, b.date_time?.seconds) ? 1 : -1)
        return state.betsWithRaces.sort((a, b) => isBefore(a.date_time?.seconds, b.date_time?.seconds) ? 1 : -1)
        // const today = startOfDay(new Date())
        // if (search === '') {
        //   if (option === 'yesterday') {
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) === 1)
        //   } else if (option === '3') {
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 3)
        //   } else if (option === '7') {
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 7)
        //   } else if (option === '30') {
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 30)
        //   } else if (option === '60') {
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 60)
        //   } else if (option === '90') {
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 90)
        //   } else {
        //     console.log(state.betHistorySorted)
        //     return state.betHistorySorted?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) === 0)
        //   }
        // } else {
        //   if (option === 'yesterday') {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) === 1)
        //   } else if (option === '3') {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 3)
        //   } else if (option === '7') {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 7)
        //   } else if (option === '30') {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 30)
        //   } else if (option === '60') {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 60)
        //   } else if (option === '90') {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) <= 90)
        //   } else {
        //     return state.betHistorySearchResults?.filter(bet => differenceInDays(startOfDay(today), startOfDay(fromUnixTime(bet.date_time?.seconds))) === 0)
        //   }
        // }
      }
    }
  }
}

export default store
