<template>
  <v-list>
    <v-list-group
      v-for="item in items"
      :key="item.title"
      v-model="item.active"
      no-action
      :value="false"
      style="background:rgb(25, 118, 210, 0.1)"
      class="runnerInfoMenu-group"
    >
      <template v-slot:activator>
        <v-list-item-content class="pa-2 infoMenu_title" fixed>
          <v-list-item-title v-text="item.title" class="body-2"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-divider></v-divider>
      <div
        class="pa-3 overflow-y-auto"
        style="background-color: rgba(0, 0, 0, 0.05); max-height: 300px;"
        >

        <v-list-item
            v-for="subItem in item.items"
            :key="subItem.selection"
            class="runnerInfoMenuItem"
          >
          <v-chip label color="blue" dark small class="d-inline">{{subItem.title}}</v-chip>
          <v-list-item-content>
            <v-list-item-title v-text="items.title"></v-list-item-title>
          </v-list-item-content>
          <v-btn class="runnerInfoMenuItem-button" x-small @click="handleClick(subItem)">Add</v-btn>

        </v-list-item>
      </div>
      <v-divider></v-divider>
    </v-list-group>
  </v-list>
</template>
<script>
import { RUNNER_INFO_COLUMNS } from '../../common/constants'
export default {
  data: () => ({
    fav: true,
    menu: false,
    items: [
      {
        type: 'runnerInfo',
        title: 'Basic Info',
        items: RUNNER_INFO_COLUMNS
      }
    ]
  }),
  props: {
    addColumn: Function,
    flucs: Boolean
  },
  methods: {
    handleClick: function (column) {
      this.addColumn(column)
    }
  }
}
</script>

<style>
  .bookieLogo {
    display: flex;
    margin: 0 15px 0 0;
  }
  .runnerInfoMenuItem button {
    margin-right: 5px;
  }
  .v-list-item--active {
    background-color: rgb(25, 118, 210, 0.2);
  }
</style>
