<template>
  <div :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
    <template>
      <v-btn outlined color="normal" x-small class="ma-1 float-left raceFiltersMenuButton" @click.stop="dialog = true" style="overflow: auto; margin: 2px !important;">
        Filters
      </v-btn>
    </template>
    <v-dialog v-model="dialog" max-width="600" :overlay-opacity="opacity" retain-focus>
      <v-card style="max-width:600px;" class="scheduleFilters">
        <v-card-title class="primary white--text">
          <div style="width:100%">
            <div class="float-left">
              <span class="headline">Schedule Filters</span>
              <v-list-item-subtitle>Customize the schedule to view specific races</v-list-item-subtitle>
            </div>
            <v-btn class="float-right" icon small @click.stop="dialog = false" ><v-icon class="white--text closeFilters">mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Countries</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Countries :acMode="this.acMode" :onClick="onCountryClick" :countries="setCountries" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-spacer class="mt-5"></v-spacer>
          <v-btn @click="openMeetingSummary">Show / Hide Meetings</v-btn>
          <v-spacer class="mt-5"></v-spacer>
          <div style="float:left;width:250px;margin-right:20px;">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="codesPanel" :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Codes</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Codes :acMode="this.acMode" :codes="setCodes" :onClick="onCodesClick" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="statusPanel" :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Status</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Statuses :acMode="this.acMode" :onClick="onStatusesClick" :statuses="setStatuses"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="startersPanel" :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Starters</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Starters :acMode="this.acMode" :starters="setStarters" :onClick="onStartersClick"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div style="float:right;width:250px;">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="distancePanel" :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Distance</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Distance :acMode="this.acMode" :distances="setDistance" :onClick="onDistanceClick"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="conditionsPanel" :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Track Condition</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <TrackCondition :acMode="this.acMode" :conditions="setConditions" :onClick="onConditionClick"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="meetingTypesPanel" :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">Meeting Type</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <MeetingType :acMode="this.acMode" :meetingTypes="setMeetingTypes" :onClick="onMeetingTypeClick"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div style="clear:both"></div>
          <v-spacer class="mt-5"></v-spacer>
          <v-card-title class="grey darken-1 white--text speedMap-footer pt-3">
            <v-btn small @click="resetToDefault" :color="!resetConfirm ? '' : 'error'"><v-icon class="mr-2">mdi-refresh</v-icon>{{ !resetConfirm ? 'Reset to Default' : 'Are you sure? Click to reset'}}</v-btn>
        </v-card-title>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Countries from './filters/Countries'
import Codes from './filters/Codes'
import Statuses from './filters/Statuses'
import Starters from './filters/Starters'
import Distance from './filters/Distance'
import TrackCondition from './filters/TrackCondition'
import MeetingType from './filters/MeetingType'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    panel: 0,
    opacity: 0.02,
    dialog: false,
    resetConfirm: false,
    setMeetingTypes: [],
    setConditions: [],
    setCountries: [],
    setCodes: [],
    setStarters: [],
    setStatuses: [],
    setDistance: []
  }),
  props: {
    setFiltersOpen: Function,
    openMeetingSummary: Function
  },
  components: {
    Countries,
    Codes,
    Statuses,
    Starters,
    Distance,
    TrackCondition,
    MeetingType
  },
  watch: {
    dialog: function (d) {
      if (d) {
        this.updateOnOpen()
        this.setFiltersOpen(d)
      } else {
        this.updateOnClose()
        this.setFiltersOpen(d)
      }
    }
  },
  mounted () {
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['hasFeature', 'getIsCodesSelectedFilter', 'getIsCountrySelectedFilter', 'getMeetingTypesSelectedFilter', 'getStatusesFilter', 'getStartersFilter', 'getDistanceFilter', 'getConditionsFilter']),
    countriesFilter: function () {
      return this.getIsCountrySelectedFilter
    }
  },
  methods: {
    ...mapActions(['updateRaceNavFilter', 'resetRaceFilters']),
    updateOnOpen () {
      this.setCountries = this.getIsCountrySelectedFilter
      this.setMeetingTypes = this.getMeetingTypesSelectedFilter
      this.setConditions = this.getConditionsFilter
      this.setStarters = this.getStartersFilter
      this.setDistance = this.getDistanceFilter
      this.setCodes = this.getIsCodesSelectedFilter
      this.setStatuses = this.getStatusesFilter
    },
    updateOnClose () {
      if (this.setMeetingTypes !== this.getMeetingTypesSelectedFilter) {
        this.updateRaceNavFilter({ filter: 'meetingTypes', value: this.setMeetingTypes })
      }
      if (this.setConditions !== this.getConditionsFilter) {
        this.updateRaceNavFilter({ filter: 'conditions', value: this.setConditions })
      }
      if (this.setCountries !== this.getIsCountrySelectedFilter) {
        this.updateRaceNavFilter({ filter: 'countries', value: this.setCountries })
      }
      if (this.setStatuses !== this.getStatusesFilter) {
        this.updateRaceNavFilter({ filter: 'statuses', value: this.setStatuses })
      }
      if (this.setStarters !== this.getStartersFilter) {
        this.updateRaceNavFilter({ filter: 'starters', value: this.setStarters })
      }
      if (this.setDistance !== this.getDistanceFilter) {
        this.updateRaceNavFilter({ filter: 'distances', value: this.setDistance })
      }
      if (this.setCodes !== this.getIsCodesSelectedFilter) {
        this.updateRaceNavFilter({ filter: 'codes', value: this.setCodes })
      }
    },
    onCountryClick (countries) {
      this.setCountries = countries
    },
    onCodesClick (codes) {
      this.setCodes = (codes)
    },
    onMeetingTypeClick (meetingTypes) {
      this.setMeetingTypes = meetingTypes
    },
    onStatusesClick (statuses) {
      this.setStatuses = statuses
    },
    onStartersClick (starters) {
      this.setStarters = starters
    },
    onDistanceClick (distance) {
      this.setDistance = distance
    },
    onConditionClick (conditions) {
      this.setConditions = conditions
    },
    resetToDefault: function () {
      if (this.resetConfirm) {
        this.resetRaceFilters()
        this.updateOnOpen()
      }
      this.resetConfirm = !this.resetConfirm
      if (this.resetConfirm) {
        setTimeout(() => { this.resetConfirm = false }, 5000)
      }
    }
  }
}
</script>

<style>
.raceFiltersAcMode {
  font-weight:bold !important
}
</style>
