
<template>
  <modal class="twitterModal" @resize="watchSize" @opened="dialog = true; watchSizeDelay()" @closed="() => dialog = false" name="twitter-modal" draggable="" resizable :clickToClose=false :minWidth=400 :minHeight=300 width=500px height=1000px>
    <v-card elevation="0">
      <v-toolbar color="primary" dark dense>
        <div style="width:100%;">
          <v-toolbar-title class="float-left">Stewards Feeds</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="float-right" icon small @click="hide"><v-icon class="white--text">mdi-close</v-icon></v-btn>
          <v-btn class="float-right" icon small @click="refresh">
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <v-card-text class="allTwitter">
        <div :key="componentKey" class="twitter-items">
          <TwitterFeed src="https://twitter.com/dynamicodds/lists/1633647053019697153?ref_src=twsrc%5Etfw"></TwitterFeed>
        </div>
      </v-card-text>
    </v-card>
  </modal>
</template>

<script>
export default {
  name: 'TwitterRss',
  data () {
    return {
      dialog: false,
      componentKey: 0,
      src: {
        type: String,
        default: 'https://twitter.com/vuejs?ref_src=twsrc%5Etfw'
      },
      dark: {
        type: Boolean,
        default: false
      },
      dataLinkColor: {
        type: String,
        default: '#2B7BB9'
      }
    }
  },
  methods: {
    watchSize () {
      const modal = document.querySelector('.vm--modal').clientHeight
      document.querySelector('.twitter-items').style.maxHeight = `${modal - 72}px`
      document.querySelector('.twitter-items').style.overflow = scroll
    },
    watchSizeDelay () {
      window.setTimeout(() => {
        const modal = document.querySelector('.vm--modal').clientHeight
        document.querySelector('.twitter-items').style.maxHeight = `${modal - 72}px`
        document.querySelector('.twitter-items').style.overflow = scroll
      }, 100)
      return ''
    },
    hide () {
      this.$modal.hide(
        'twitter-modal'
      )
    },
    refresh () {
      this.componentKey += 1
    }
  }
}
</script>

<style>
.allTwitter {
  overflow-y:auto;
  height:100%;
}
</style>
