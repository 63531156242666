<template >
  <v-btn :style="widthNextToGo" class="pa-0 nextRace body-1 box-shadow--container" small color="success" @click="onClick(race)">
    <slot v-if="race">
      <MeetingIcon :meeting="race.meeting" class="raceIcon" :mobile="mobile" /><span class="raceInfo">{{race.meeting.track.name}} <strong>R{{race.number}}</strong></span>
      <LiveCountdown :key="race.startTime" :startTime="race.startTime" class="raceTimeNtg" style="color:#fff;text-transform: lowercase;margin-left:10px;" />
    </slot>
  </v-btn>
</template>

<script>
import { formattedRaceStartTime } from '../common/methods'
import LiveCountdown from './LiveCountdown'
import MeetingIcon from './MeetingIcon'
import { getUnixTime, parseISO } from 'date-fns'

export default {
  name: 'RaceNavNextToGo',
  components: {
    LiveCountdown,
    MeetingIcon
  },
  props: {
    race: Object,
    onClick: Function,
    mobile: Boolean,
    widthNextToGo: String
  },
  methods: {
    getUnixTime,
    formattedRaceStartTime,
    parseISO
  }
}
</script>

<style>
.raceInfo {
  max-width:160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.raceIcon {
  transform: scale(0.7);
  filter: invert(100);
}
</style>
