<template>
  <div class="deleteColumnButton text-center">
    <v-menu
      nudge-left="20"
      nudge-top="5"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon
              dark
              size="13"
              color="error"
              class="deleteButton column-actions__delete"
              v-on="{ ...tooltip, ...menu }"
            >mdi-minus</v-icon>
          </template>
          <span>Delete Column</span>
        </v-tooltip>
      </template>
      <div class="deleteColumnMenu-content">
        <v-btn color="error" depressed small @click="removeColumn(columnNumber)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    columnNumber: Number
  },
  computed: {
    ...mapGetters(['getSelectedColumns', 'getSelectedColumnsName'])
  },
  methods: {
    ...mapActions(['updateSelectedColumns']),
    removeColumn (i) {
      const columns = [ ...this.getSelectedColumns ]
      columns.splice(i, 1)
      this.updateSelectedColumns({ name: this.getSelectedColumnsName, columns })
    }
  }
}
</script>

<style>
 .deleteColumnButton {
   display: inline-block;
 }
</style>
