<template>
  <v-list>
    <v-divider></v-divider>

      <v-list-item
        v-for="item in items"
        :key="item.source"
        class="runnerInfoMenuItem"
      >
        <v-chip label color="blue" dark small class="d-inline">{{item.title}}</v-chip>

        <v-btn class="runnerInfoMenuItem-button" x-small @click="handleClick(item)">Add</v-btn>

      </v-list-item>
    <v-divider></v-divider>
  </v-list>
</template>

<script>

export default {
  data: () => ({
    fav: true,
    menu: false,
    items: [
      { source: 'custom-comment', type: 'CUSTOM_DATA', selection: 'my comment', title: 'My Comments', display: 2 },
      { source: 'rating-1', type: 'CUSTOM_DATA', selection: 'rating', title: 'My Rating 1', display: 0 },
      { source: 'rating-2', type: 'CUSTOM_DATA', selection: 'rating', title: 'My Rating 2', display: 0 },
      { source: 'rating-3', type: 'CUSTOM_DATA', selection: 'rating', title: 'My Rating 3', display: 0 },
      { source: 'rating-4', type: 'CUSTOM_DATA', selection: 'rating', title: 'My Rating 4', display: 0 },
      { source: 'rating-5', type: 'CUSTOM_DATA', selection: 'rating', title: 'My Rating 5', display: 0 },
      { source: 'rating-6', type: 'CUSTOM_DATA', selection: 'rating', title: 'My Rating 6', display: 0 },
      { source: 'prev-comment', type: 'CUSTOM_DATA', selection: 'my comment', title: 'Prev Comments', display: 2 }
    ]
  }),
  props: {
    addColumn: Function,
    flucs: Boolean
  },
  methods: {
    handleClick: function (column) {
      this.addColumn(column)
    }
  }
}
</script>

<style>
  .bookieLogo {
    display: flex;
    margin: 0 15px 0 0;
  }
  .runnerInfoMenuItem button {
    margin-left: auto;
    margin-right: 5px;
  }
  .v-list-item--active {
    background-color: rgb(25, 118, 210, 0.2);
  }
</style>
