import { fromUnixTime, format, isBefore, isToday, isYesterday, isTomorrow } from 'date-fns'
import countdownLib from 'countdown'
import { TRACKCONDITION, RACESTATUS, NON_BETTING_COLUMN, BETTYPE, BETFAIR_PLACE, BOOKMAKER_CODE_NAMES } from './constants'

export const calculateBfBackCommission = function (price, commRate) {
  return price - (((price - 1) / 100) * commRate)
}

export const calculateBfLayCommission = function (price, commRate) {
  return price + (((price - 1) / 100) * commRate)
}

export const mapBettingSource = function (source) {
  if (source === BOOKMAKER_CODE_NAMES['TAB Fixed VIC']) return 'V'
  if (source === BOOKMAKER_CODE_NAMES['TAB Fixed NSW']) return 'N'
  if (source === BOOKMAKER_CODE_NAMES['TAB Fixed QLD']) return 'Q'
  return source
}

export const getColumnHeader = function (column) {
  if (column.source === 'BF' && column.priceType === 'PLACE') {
    return BETFAIR_PLACE[column.type]
  } else {
    return BETTYPE[column.type]
  }
}
export const formattedRaceStartTime = (seconds) => {
  const formatted = format(seconds, 'HH:mm')
  return formatted
}

export const formatTime = seconds => {
  const formatted = format(fromUnixTime(seconds), 'HH:mm:ss')
  return formatted
}

export const countdown = function (unixSeconds) {
  const time = fromUnixTime(unixSeconds)
  var ts = countdownLib(time)
  var formatted = ''
  if (ts.months > 1) {
    formatted = ts.months + ' months'
  } else if (ts.days === 0) {
    if (ts.hours === 0 && ts.minutes >= 5) {
      formatted = ts.minutes + 'm '
    } else if (ts.hours === 0 && ts.minutes <= 5 && ts.minutes > 0) {
      formatted = ts.minutes + 'm ' + ts.seconds + 's'
    } else if (ts.hours === 0 && ts.minutes === 0) {
      formatted = ts.seconds + 's'
    } else {
      formatted = ts.hours + 'h ' + ts.minutes + 'm'
    }
  } else {
    formatted = ts.days + 'd'
  }

  if (isBefore(time, Date.now())) {
    formatted = '-' + formatted
  }
  return formatted
}

export const countdownObject = function (unixSeconds) {
  const time = fromUnixTime(unixSeconds)
  return countdownLib(time)
}

export const formattDateToWords = date => {
  if (isToday(new Date(date))) {
    return 'Today'
  } else if (isYesterday(new Date(date))) {
    return 'Yesterday'
  } else if (isTomorrow(new Date(date))) {
    return 'Tomorrow'
  } else {
    return date
  }
}

export const filterRacesByCodes = function (races, filters) {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => filters.includes(races[race].meeting.type) || !races[race].meeting.type)
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByCountry = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => filters.includes(races[race].meeting.track.country) || !races[race].meeting.track.country)
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByMeetingType = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        return filters.includes(races[race].meeting.tabIndicator) || (filters.includes('P') && !races[race].meeting.tabIndicator)
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByStarters = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        return races[race].starters >= filters[0] && races[race].starters <= filters[1]
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByDistance = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        return races[race].distance >= filters[0] && races[race].distance <= filters[1]
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByCondition = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        const value = TRACKCONDITION[races[race].trackCondition]
        if (value && !filters.some(filter => value.includes(filter))) {
        } else if (!value) {
          return true
        }
        return value ? (filters.some(filter => value.includes(filter)) || !value) : true
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByStatuses = (races, filters) => {
  if (races && filters) {
    // return Object.keys(races)
    //   .filter(race => {
    //     const value = RACESTATUS[races[race].status]
    //     if (!value || value) {
    //       return true
    //     }
    //     return value ? (filters.some(filter => value.includes(filter)) || !value) : true
    //   })
    //   .map(key => races[key])
    const filteredRaces = races.filter(race => filters.includes(RACESTATUS[race.status]))
    return filteredRaces
  } else {
    return races
  }
}

export const filterRacesByShowHideMeeting = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => !filters.includes(races[race].meeting.id) || !races[race].meeting.id)
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByShowHideRace = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => !filters.includes(races[race].id) || !races[race].id)
      .map(key => races[key])
  } else {
    return races
  }
}

export const getWeatherIcon = (race) => {
  switch (race.weather) {
    case 'Clear':
      return 'weatherIconSun'
    case 'FINE':
      return 'weatherIconSun'
    case 'Fine & Sunny':
      return 'weatherIconSun'
    case 'Fine':
      return 'weatherIconSun'
    case 'Fair':
      return 'weatherIconSun'
    case 'Hot':
      return 'weatherIconSun'
    case 'Sunny':
      return 'weatherIconSun'
    case 'Cloud':
      return 'weatherIconCloud'
    case 'Drizzle':
      return 'weatherIconCloud'
    case 'Fog':
      return 'weatherIconCloud'
    case 'Mist':
      return 'weatherIconCloud'
    case 'Overcast':
      return 'weatherIconCloud'
    case 'OCAST':
      return 'weatherIconCloud'
    case 'Windy':
      return 'weatherIconCloud'
    case 'Heavy Snow':
      return 'weatherIconRain'
    case 'Light Rain':
      return 'weatherIconRain'
    case 'Lightning':
      return 'weatherIconRain'
    case 'Patch Rain':
      return 'weatherIconRain'
    case 'Raining':
      return 'weatherIconRain'
    case 'Showers':
      return 'weatherIconRain'
    case 'Showery':
      return 'weatherIconRain'
    case 'SHWRY':
      return 'weatherIconRain'
    case 'Snowing':
      return 'weatherIconRain'
    case 'Storms':
      return 'weatherIconRain'
    default:
      return null
  }
}

export const getWeatherIconMobile = (race) => {
  switch (race.weather) {
    case 'Clear':
      return 'weatherIconMobileSun'
    case 'Fine':
      return 'weatherIconMobileSun'
    case 'FINE':
      return 'weatherIconMobileSun'
    case 'Fine & Sunny':
      return 'weatherIconMobileSun'
    case 'Fair':
      return 'weatherIconMobileSun'
    case 'Hot':
      return 'weatherIconMobileSun'
    case 'Sunny':
      return 'weatherIconMobileSun'
    case 'Cloud':
      return 'weatherIconMobileCloud'
    case 'Drizzle':
      return 'weatherIconMobileCloud'
    case 'Fog':
      return 'weatherIconMobileCloud'
    case 'Mist':
      return 'weatherIconMobileCloud'
    case 'Overcast':
      return 'weatherIconMobileCloud'
    case 'OCAST':
      return 'weatherIconMobileCloud'
    case 'Windy':
      return 'weatherIconMobileCloud'
    case 'Heavy Snow':
      return 'weatherIconMobileRain'
    case 'Light Rain':
      return 'weatherIconMobileRain'
    case 'Lightning':
      return 'weatherIconMobileRain'
    case 'Patch Rain':
      return 'weatherIconMobileRain'
    case 'Raining':
      return 'weatherIconMobileRain'
    case 'Showers':
      return 'weatherIconMobileRain'
    case 'Showery':
      return 'weatherIconMobileRain'
    case 'SHWRY':
      return 'weatherIconMobileRain'
    case 'Snowing':
      return 'weatherIconMobileRain'
    case 'Storms':
      return 'weatherIconMobileRain'
    default:
      return null
  }
}

export const matchPriceToType = (type, price, exchangeRate) => {
  if (!price) {
    return null
  }
  let p
  switch (type) {
    case 'WIN_FIXED_ODDS':
      p = price.availableToBack?.find(p => p.level === 0)
      return p?.price || null
    case 'back-1':
      p = price.availableToBack?.find(p => p.level === 0)
      return p?.price || null
    case 'back-2':
      p = price.availableToBack?.find(p => p.level === 1)
      return p?.price || null
    case 'back-3':
      p = price.availableToBack?.find(p => p.level === 2)
      return p?.price || null
    case 'back-amount-1':
      p = price.availableToBack?.find(p => p.level === 0)
      return convertPrice(p?.volume, exchangeRate) || null
    case 'back-amount-2':
      p = price.availableToBack?.find(p => p.level === 1)
      return convertPrice(p?.volume, exchangeRate) || null
    case 'back-amount-3':
      p = price.availableToBack?.find(p => p.level === 2)
      return convertPrice(p?.volume, exchangeRate) || null
    case 'lay-1':
      p = price.availableToLay?.find(p => p.level === 0)
      return p?.price || null
    case 'lay-2':
      p = price.availableToLay?.find(p => p.level === 1)
      return p?.price || null
    case 'lay-3':
      p = price.availableToLay?.find(p => p.level === 2)
      return p?.price || null
    case 'lay-amount-1':
      p = price.availableToLay?.find(p => p.level === 0)
      return convertPrice(p?.volume, exchangeRate) || null
    case 'lay-amount-2':
      p = price.availableToLay?.find(p => p.level === 1)
      return convertPrice(p?.volume, exchangeRate) || null
    case 'lay-amount-3':
      p = price.availableToLay?.find(p => p.level === 2)
      return convertPrice(p?.volume, exchangeRate) || null
    case 'last-matched-price':
      return price.lastTradedPrice || null
    case 'total-matched':
      return price.totalTraded || null
    case 'projected-sp':
      return price.sellingPrice || null
    default:
      return null
  }
}

const convertPrice = function (price, exchangeRate) {
  if (!exchangeRate) return price
  return price * exchangeRate
}

export const matchPoolToType = (type, price) => {
  if (!price) {
    return null
  }
  let p
  switch (type) {
    case 'WIN_FIXED_ODDS':
      p = price.availableToBack?.find(p => p.level === 0)
      return p?.volume || null
    case 'back-1':
      p = price.availableToBack?.find(p => p.level === 0)
      return price.totalTraded || null
    case 'back-2':
      p = price.availableToBack?.find(p => p.level === 1)
      return price.totalTraded || null
    case 'back-3':
      p = price.availableToBack?.find(p => p.level === 2)
      return price.totalTraded || null
    case 'back-amount-1':
      p = price.availableToBack?.find(p => p.level === 0)
      return price.totalTraded || null
    case 'back-amount-2':
      p = price.availableToBack?.find(p => p.level === 1)
      return price.totalTraded || null
    case 'back-amount-3':
      p = price.availableToBack?.find(p => p.level === 2)
      return price.totalTraded || null
    case 'lay-1':
      p = price.availableToLay?.find(p => p.level === 0)
      return price.totalTraded || null
    case 'lay-2':
      p = price.availableToLay?.find(p => p.level === 1)
      return price.totalTraded || null
    case 'lay-3':
      p = price.availableToLay?.find(p => p.level === 2)
      return price.totalTraded || null
    case 'lay-amount-1':
      p = price.availableToLay?.find(p => p.level === 0)
      return price.totalTraded || null
    case 'lay-amount-2':
      p = price.availableToLay?.find(p => p.level === 1)
      return price.totalTraded || null
    case 'lay-amount-3':
      p = price.availableToLay?.find(p => p.level === 2)
      return price.totalTraded || null
    case 'last-matched-price':
      return price.totalTraded || null
    case 'total-matched':
      return price.totalTraded || null
    case 'projected-sp':
      return null
    default:
      return null
  }
}

export const canBetOnColumn = function (columnType) {
  return !NON_BETTING_COLUMN[columnType]
}

export const getOCCShift = function (tabNo, columnFlucs) {
  // All Flucs
  const runnerFlucs = columnFlucs?.find(r => r.flucs?.[0]?.tabNo === tabNo) || ''
  if (!runnerFlucs) return
  // Opening Price
  const calcOpenPrice = runnerFlucs?.flucs?.map((b) => b.price)
  var openPrice = calcOpenPrice[0]
  if (openPrice === 0) {
    openPrice = calcOpenPrice[1]
  }
  // Curr Price
  const calcCurrPrice = runnerFlucs?.flucs?.map((d) => d.price)
  const currPrice = calcCurrPrice[calcCurrPrice.length - 1]
  // Open Price % 1/OpenPrice
  const openPricePerc = 1 / openPrice
  // Curr Price % 1/CurrPrice
  const currPricePerc = 1 / currPrice
  // Open% - Curr% * 100
  const occPerc = (openPricePerc - currPricePerc) * 100
  if (Math.round(occPerc * 10) / 10 > 0) {
    return `+${Math.round(occPerc * 10) / 10}`
  }
  // Return Blah
  return Math.round(occPerc * 10) / 10 || 0
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (Object.entries(value).length === 0 && value.constructor === Object)

export const isCoreApiTokenExpired = ({ token }) => {
  // ** coreApiToken expires winthin 30m **
  // AWS Cognito Token expire within an 1hr
  if (isEmpty(token)) return true

  const expiryTimeInMilliseconds = (JSON.parse(atob(token.split('.')[1]))).exp

  const expiryDate = new Date(expiryTimeInMilliseconds * 1000)

  const startdate = new Date(expiryDate)

  const durationInMinutes = 15

  const newExpiryDate = startdate.setMinutes(expiryDate.getMinutes() - durationInMinutes)

  return Math.floor((new Date()).getTime()) >= newExpiryDate
}

export const truncateTxt = ({ txt, max }) => {
  if (txt?.length > max) {
    return txt.substring(0, max) + '...'
  }
  return txt
}

export const sortByKeyValue = ({ key, secondKey, a, b }) => {
  if (isEmpty(secondKey)) {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
    } else {
    if (a[key][secondKey] < b[key][secondKey]) {
      return -1
    }
    if (a[key][secondKey] > b[key][secondKey]) {
      return 1
    }
    return 0
  }
}

export const returnUniqueElementsFromArray = ({ array }) => [...new Set(array)]
