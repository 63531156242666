<template>
  <v-alert :type="getStatus()">{{ getStatusText() }} {{ additionalMessage }}</v-alert>
</template>

<script>
import { BET_STATUS_SUCCESS, BETSTATUS } from '../common/constants'
export default {
  name: 'BetStatus',
  props: {
    status: String,
    additionalMessage: String
  },
  methods: {
    getStatus () {
      return BET_STATUS_SUCCESS[this.status] || 'error'
    },
    getStatusText () {
      return BETSTATUS[this.status] || 'Unknown error'
    }
  }
}
</script>
