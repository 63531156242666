<template>
  <v-container fluid class="pt-0 pb-0">
    <v-skeleton-loader v-if="!meeting" width="100%" height="100%" type="article, actions, date-picker"></v-skeleton-loader>
    <div v-else>
    <v-card class="mx-auto box-shadow--container br--container" elevation="0" max-width="100%" style="background:#fff">
      <MyRaces v-if="myRaces.length > 0" :races="myRaces" :selectedRace="race.id" />
      <v-card-text class="pa-1 pt-2 pb-0">
        <div style="display:flex;flex-wrap:nowrap;flex-direction:row;width:100%;justify-content:flex-start;">
          <div class="accent-4 text--primary font-weight-bold px-2 mr-4 trackName" style="width:450px;white-space: nowrap;padding-top:5px">
            <div :class="'icon-' + meeting.type" style='float:left;margin-right:15px;zoom:75%;scale:0.7'></div>
            <div style='margin-right:10px;font-size:27px;vertical-align: top;display:inline-block;max-width:300px;white-space: nowrap;text-overflow: ellipsis;overflow:hidden;height:25px;'>{{ trackName }}</div>
            <div style='margin-right:15px;font-size:27px;vertical-align: top;display:inline-block;max-width:80px;white-space: nowrap;'>{{ raceNum }}</div>
            <CountryFlags class="alignFlags" :meeting="meeting" :small="false"/>
          </div>
          <div class="ml-2 mr-3" style="overflow:auto;white-space:nowrap;">
            <div style="float:left;margin-right:20px;">
              <v-btn v-for="(r, i) in racesF()" :key="`${r.number}:${i}`" fab x-small :color="navColor(r.number, race.number, r.status, r.results)" class="ml-1 d-inline caption raceButton elevation-2" @click="clickRace(r)">{{r.number}}</v-btn>
            </div>
          </div>
          <div :key="getStatus()" v-if="getStatus() === 'OPEN'" style="text-align:right" class="pt-1 text--primary font-weight-medium title pr-2">
            <span class="raceTimeBrief" d-inline>To Start ({{ formattedRaceStartTime(parseISO(race.startTime)) }})</span>
            <LiveCountdown :key="race.startTime" :startTime="race.startTime" style="font-size:1.6rem" class="raceTime" />
          </div>
          <div v-else class="text--primary font-weight-medium title float-right pr-2">
            <span class="raceStatusText" style="color:red">{{getStatus()}}</span>
          </div>
        </div>
        <div style="clear:both"></div>
        <v-divider inset class="mb-1"></v-divider>

        <div class="d-flex justify-space-between">
          <div class="d-inline pr-2 pl-2">
            <v-btn x-small class="white--text" color="success" @click="handleAddToMyRacesClick">
              <v-icon small class="mr-1">mdi-check</v-icon>Add to my races
            </v-btn>
          </div>
          <div class="d-inline text--primary font-weight-medium subtitle-2 raceNameDesc">
            <div class="subtitle-2 font-weight-light ml-1 d-inline raceDescription black--text"><span>{{ race.name }}</span></div>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
                <span class="subtitle-2 font-weight-light pl-5 black--text" v-on="on">A/C: <span class="subtitle-2 font-weight-bold black--text" >{{ sanitizeRaceClass(race.class) }}</span></span>
              </template>
              <span>Race Age & Class</span>
            </v-tooltip>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
                <span class="subtitle-2 font-weight-light pl-5 black--text" v-on="on">S:<span class="subtitle-2 font-weight-bold black--text" >{{ race.starters }}</span></span>
              </template>
              <span>Starters</span>
            </v-tooltip>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
              <span v-if="showFurlongs" class="subtitle-2 font-weight-light pl-5 raceDistance black--text" v-on="on">D:  <span class="subtitle-2 font-weight-bold ml-1 black--text">{{ furlongCalc(race.distance) }} Fur</span></span>
              <span v-else class="subtitle-2 font-weight-light pl-5 raceDistance black--text" v-on="on">D:  <span class="subtitle-2 font-weight-bold ml-1 black--text">{{ race.distance }} M</span></span>
              </template>
              <span>Distance</span>
            </v-tooltip>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
                <span class="subtitle-2 font-weight-light pl-5 trackCondition black--text" v-on="on">T:<span class="subtitle-2 font-weight-bold ml-1 black--text">{{getCondition(race.trackCondition)}}</span></span>
              </template>
              <span>Track Condition</span>
            </v-tooltip>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
                <span class="subtitle-2 font-weight-light pl-5 black--text" v-on="on">W: <span class="subtitle-2 font-weight-bold ml-1 black--text">{{ race.weather }}</span></span>
              </template>
              <span>Weather</span>
            </v-tooltip>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
                <span class="subtitle-2 font-weight-light pl-5 black--text" v-on="on">P:<span class="subtitle-2 font-weight-bold ml-1 black--text">${{numeral(race.prizeMoney).format('0a')}}</span></span>
              </template>
              <span>Prize Money</span>
            </v-tooltip>
            <v-tooltip right color="black" style="z-index:202; position:relative">
              <template v-slot:activator="{ on }">
                <span class="subtitle-2 font-weight-light pl-5 black--text" v-on="on">R:<span class="subtitle-2 font-weight-bold ml-1 black--text">{{ race.meeting.railPosition }}</span></span>
              </template>
              <span>Rail Position</span>
            </v-tooltip>
          </div>
          <div class="ml-auto">
              <BetBalanceMenu v-if="!getRdcConfig" :setting="selection" :bookieCode="'totalBalance'" :balance="totalBalance" :setHideBalance="handleHideBalance"/>
          </div>
        </div>

        <v-layout pl-2>
          <v-row no-gutters>
            <template>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mt-1 px-0 elevation-0 pb-1" style="display:flex;flex-wrap:nowrap;flex-direction:row;width:100%;justify-content:flex-start;">
                  <v-card color="black" class="mr-2 pa-1" v-if="getRdcConfig">
                    <v-btn
                      color="error"
                      dark
                      x-small
                      class="CloseRaceBtn mr-2"
                    >
                      <v-icon small class="mr-1">mdi-close-circle-outline</v-icon>
                      Close Race
                    </v-btn>
                    <v-btn
                      color="error"
                      dark
                      x-small
                      class="AbandonRaceBtn mr-2"
                    >
                      <v-icon small class="mr-1">mdi-cow</v-icon>
                      Abandon Race
                    </v-btn>
                    <v-btn
                      color="error"
                      dark
                      x-small
                      class="ChangeStartRaceBtn"
                    >
                      <v-icon small class="mr-1">mdi-clock-start</v-icon>
                      Change Start Time
                    </v-btn>
                  </v-card>
                  <div>
                    <v-menu :offset-x="true" elevation="20" :close-on-content-click="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          class="toolsButton"
                        >
                          <v-icon small class="mr-1">mdi-hammer-screwdriver</v-icon>
                          Tools
                        </v-btn>
                      </template>
                      <v-list dense dark class="toolsMenu">
                        <v-list-item v-if="hasFeature('aZList')" @click="showAzList">A to Z Lists</v-list-item>
                        <v-list-item v-else @click="showAzList" disabled>A to Z Lists</v-list-item>
                        <v-list-item v-if="hasFeature('dualAcceptances')" @click="onOpenDualAcceptances">Dual Acceptances</v-list-item>
                        <v-list-item v-if="hasFeature('exotics')" @click="showExotics">Exotics</v-list-item>
                        <v-list-item v-else @click="showExotics" disabled>Exotics</v-list-item>
                        <v-list-item @click="openFullSchedule()">Full Schedule</v-list-item>
                        <v-list-item @click="openGearChanges()">Gear Changes</v-list-item>
                        <v-list-item @click="openRunnerWatchlist" v-if="hasFeature('priceAlerts')">Runner Watchlist</v-list-item>
                        <v-tooltip right color="error" style="z-index:202; position:relative">
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item @click="clickFeature" v-bind="attrs" v-on="on">Stats</v-list-item>
                          </template>
                          <span>Not yet available in BETA</span>
                        </v-tooltip>
                        <v-list-item @click="openScratchings()">Scratching</v-list-item>
                        <v-list-item @click="openTrackReports" >Track Reports</v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <div>
                    <div class="d-inline ml-2" v-if="hasFeature('resultsSummary')">
                      <v-btn v-if="hasFeature('resultsSummary')" @click="raceResultsTable = true" x-small class="white--text raceResultsBtn" color="primary">Results</v-btn>
                    </div>
                    <div class="d-inline ml-2" v-if="hasFeature('raceNotes')">
                      <v-btn v-if="getCustomDataNow(this.race.id)" x-small class="white--text myNotesBtn" color="error"  @click="openRaceNotes">My Notes</v-btn>
                      <v-btn v-else x-small class="white--text myNotesBtn" color="primary"  @click="openRaceNotes">My Notes</v-btn>
                    </div>
                    <v-tooltip v-else-if="!hasFeature('raceNotes')" right color="error" style="z-index:202; position:relative" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small class="white--text" color="disabled"  @click="clickFeature" v-bind="attrs" v-on="on">My Notes</v-btn>
                      </template>
                      <span>Not yet available in BETA</span>
                    </v-tooltip>
                    <div class="d-inline ml-2">
                      <v-btn x-small class="white--text" color="primary" @click="formLink(meeting.externalIDs[0].id, race.number)">Form</v-btn>
                    </div>
                    <div v-if="hasFeature('flucs')" class="d-inline ml-2">
                      <Flucs :race="race" :raceCompetitors="getRaceCompetitors"/>
                      <!--<v-btn x-small class="white--text" color="primary" @click="clickFeature" v-bind="attrs" v-on="on">Flucs</v-btn>-->
                    </div>
                    <v-tooltip v-else right color="error" style="z-index:202; position:relative">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-inline ml-2">
                          <v-btn x-small class="white--text" color="primary" @click="clickFeature" v-bind="attrs" v-on="on">Flucs</v-btn>
                        </div>
                      </template>
                      <span>Not yet available in BETA</span>
                    </v-tooltip>
                    <TwitterRss/>
                    <div class="d-inline ml-2">
                      <SpeedMap :race="race" :meeting="meeting" :speedMap="getSpeedMap" :saveSpeedMap="saveSpeedMap" :setSpeedMap="setSpeedMap" :resetSpeedMap="resetSpeedMap"/>
                    </div>
                    <div v-if="hasFeature('exotics')" class="d-inline ml-2">
                      <Exotics />
                    </div>
                  </div>
                  <div style="flex-grow:2">
                    <v-toolbar-title class="caption black--text d-inline mr-4 ml-10">Layout:</v-toolbar-title>
                    <RaceToolbar :clickFeature="clickFeature" :getSelectedColumnsName="getSelectedColumnsName"/>
                    <modal name="az-modal" width=80% height="960px" draggable="">
                      <AzList @clicked="hideAzList"/>
                    </modal>
                  </div>
                </v-card>
              </v-col>
              <v-responsive v-if="selectedColumns === 2" :key="`width-${selectedColumns}`" width="100%"></v-responsive>
            </template>
          </v-row>
        </v-layout>
      </v-card-text>
    </v-card>
    <modal name="notes-modal" width=750px draggable="">
      <RaceNotes @clicked="hideRaceNotes" :race="race" :meeting="meeting" :message="message"/>
    </modal>
    <v-dialog hide-overlay v-model="raceResultsTable" :width="750" persistent>
      <RaceResults v-if="allowResults" :closeResultsSummary="closeResultsSummary" :race="race" :meeting="meeting" v-moveable :runners="getRaceCompetitors" :showResults="getResultsShowHide"/>
      <v-card v-else class="resultsModal">
        <v-card-title class="headline"><v-icon class="green--text mr-2">mdi-google-downasaur</v-icon> No Results Available</v-card-title>
        <v-card-text class="font-weight-bold">
          Results will be availble when the race has resulted
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="raceResultsTable = false"
          >
            OK
          </v-btn>...
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoginModal v-if="showLoginModal" @close="showLoginModal = false" :loginBetting="loginBetting" :loginTools="loginTools"/>
    </div>

    <modal name="dual-acceptances" draggable width="1000px" height="600px">
      <DualAcceptances />
    </modal>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { formattedRaceStartTime, countdown, isEmpty } from '../common/methods'
import { TRACKCONDITION_TRANSLATION, RESULTS_COLUMNS, BOOKMAKER_CODE_NAMES } from '../common/constants'
import { parseISO, getUnixTime } from 'date-fns'
import RaceToolbar from './RaceToolbar'
import CountryFlags from './CountryFlags'
import RaceResults from './menus/RaceResults'
import RaceNotes from './menus/RaceNotes'
import AzList from './AzList'
import SpeedMap from './SpeedMap'
import numeral from 'numeral'
import Flucs from './Flucs'
import LoginModal from './LoginModal'
import MyRaces from './MyRaces'
import LiveCountdown from './LiveCountdown'
import Exotics from './Exotics.vue'
import DualAcceptances from './DualAcceptances.vue'
import BetBalanceMenu from './menus/BetBalanceMenu'
import TwitterRss from './TwitterRss'

export default {
  name: 'RaceDetail',
  components: {
    CountryFlags,
    RaceToolbar,
    SpeedMap,
    Flucs,
    RaceResults,
    RaceNotes,
    LoginModal,
    MyRaces,
    AzList,
    LiveCountdown,
    Exotics,
    DualAcceptances,
    BetBalanceMenu,
    TwitterRss
  },
  data () {
    return {
      time: 0,
      raceResultsTable: false,
      raceNotes: false,
      isAZlist: false,
      resultsColumns: {},
      showThereIsNotes: false,
      message: '',
      showLoginModal: false,
      loginTools: true
    }
  },
  computed: {
    ...mapGetters(['getSpeedMap', 'getRdcConfig', 'getSelectedColumnsName', 'getGridSettings', 'getSelectedRace', 'hasFeature', 'getRaceCompetitors', 'getCustomData', 'getResultsShowHide', 'getMyRaces', 'getRaceDetails', 'getSelectedMeetingsRaces', 'getSelectedMeeting', 'getColumns', 'getLoadAZListData', 'getAvailableAgencies', 'getAgencySettings']),
    ...mapState(['selectedRace', 'races', 'selectedBookieColumns', 'azList', 'isDualAcceptances', 'dualAcceptances', 'dualAcceptancesHasLoaded']),
    ...mapGetters('account', ['getSessionId', 'getBalances']),
    showFurlongs: function () {
      return this.getGridSettings.includes('displayImperial')
    },
    race: function () {
      return this.getSelectedRace()
    },
    meeting: function () {
      return this.getSelectedRace()?.meeting
    },
    selectedColumns: function () {
      return this.getColumns
    },
    myRaces: function () {
      return this.getMyRaces
    },
    trackName: function () {
      return `${this.meeting.track.name}`
    },
    raceNum: function () {
      return `R${this.race.number}`
    },
    status: function () {
      return this.race?.status
    },
    allowResults: function () {
      if (this.status === 'FINAL' || this.status === 'INTERIM') {
        // this.showResultsRaceStatusChange()
        return true
      } else {
        return false
      }
    },
    resultsCols: function () {
      const resColumns = RESULTS_COLUMNS
      return resColumns
    },
    balances: function () {
      return this.getBalances
    },
    totalBalance: function () {
      var totalBalance = 0.0
      const balances = this.balances?.filter(b => {
        if (b.agency_id === BOOKMAKER_CODE_NAMES['TAB Fixed VIC'] && this.balances.find(b => b.agency_id === 'V')) return false
        if (b.agency_id === BOOKMAKER_CODE_NAMES['TAB Fixed NSW'] && this.balances.find(b => b.agency_id === 'N')) return false
        if (b.agency_id === BOOKMAKER_CODE_NAMES['TAB Fixed QLD'] && this.balances.find(b => b.agency_id === 'Q')) return false
        return true
      })
      if (typeof balances !== 'undefined') {
        balances.forEach(b => {
          const agency = this.getAvailableAgencies.find(a => a.agency_id === b.agency_id)
          if (b.balance && agency?.enabled === true) {
            totalBalance = totalBalance + b.balance
          }
        })
      }

      return totalBalance
    },
    selection: function () {
      if (!this.bettingEnabled) {
        return 'betButton'
      }
      if (!this.hideBalance) {
        return 'totalBalance'
      }
      if (this.hideBalance) {
        return 'tick'
      }
      return null
    },
    hideBalance: function () {
      return this.settings.hideBalance
    },
    settings: function () {
      const agencySettings = this.getAgencySettings
      return agencySettings['totalBalance'] || {}
    },
    bettingEnabled: function () {
      return !!this.sessionId
    },
    sessionId: function () {
      return this.getSessionId
    }
  },
  methods: {
    ...mapActions(['selectRace', 'saveSpeedMap', 'setSpeedMap', 'resetSpeedMap', 'selectNavRace', 'addToMyRaces', 'watchListSwitch', 'selectRaceNav', 'fetchAzList', 'fetchWatchList', 'toggleDualAcceptances', 'setHideBalance', 'fetchExotics', 'fetchCustomData']),
    formattedRaceStartTime,
    numeral,
    countdown,
    parseISO,
    getUnixTime,
    isEmpty,
    showResultsRaceStatusChange () {
      const resShow = this.getResultsShowHide
      if (resShow === 'raceResults') {
        this.raceResultsTable = true
      }
    },
    openRaceNotes () {
      if (this.$modal) {
        this.$modal.show(
          'notes-modal'
        )
      }
    },
    furlongCalc (d) {
      const distanceInFurlongs = d / 201
      return numeral(distanceInFurlongs).format('0,00')
    },
    racesF () {
      return this.getSelectedMeetingsRaces
    },
    hideRaceNotes () {
      if (this.$modal) {
        this.$modal.hide(
          'notes-modal'
        )
      }
    },
    showAzList () {
      if (this.getLoadAZListData) {
        this.fetchAzList()
      }
      if (this.$modal) {
        this.$modal.show(
          'az-modal'
        )
      }
    },
    hideAzList () {
      if (this.$modal) {
        this.$modal.hide(
          'az-modal'
        )
      }
    },
    showExotics () {
      this.fetchExotics()
      if (this.$modal) {
        this.$modal.show(
          'exotics-modal'
        )
      }
    },
    async clickFeature () {
      this.showFeatureAlert(true)
    },
    handleAddToMyRacesClick () {
      if (!this.hasFeature('myRaces')) {
        this.clickFeature()
        return
      }
      this.addToMyRaces({ id: this.race.id })
    },
    clickRace (r) {
      this.selectRace({ id: r.id })
      this.selectNavRace({ id: r.id })
      this.selectRaceNav(1)
    },
    closeResultsSummary () {
      this.raceResultsTable = false
    },
    closeRaceNotes () {
      this.raceNotes = false
    },
    closeAzList () {
      this.isAZlist = false
    },
    formLink: function (raceId, raceNumber) {
      if (process.env.VUE_APP_TITLE === 'DynamicOdds') {
        return window.open(`${process.env.VUE_APP_FORM_LINK}/${raceId}/${raceNumber}`)
      } else {
        const bookiesList = new Set()
        const filteredBookies = this.selectedBookieColumns.filter(v => v.source !== 'BPC' && v.source !== 'BF' && v.source !== 'N' && v.source !== 'V' && v.source !== 'Q')
        filteredBookies.forEach((b) => {
          bookiesList.add(b.source)
        })
        // const bookieParams = Array.from(bookiesList).join(',')
        return window.open(`${process.env.VUE_APP_FORM_LINK}/${raceId}/${raceNumber}`)
      }
    },
    navColor: function (mappedNumber, raceNumber, raceStatus, raceResult) {
      if (mappedNumber === raceNumber) {
        return 'normal'
      } else if (raceStatus === 'OPEN') {
        return 'success'
      } else {
        return 'error'
      }
    },
    openFullSchedule () {
      const sessionID = this.getSessionId
      if (sessionID === null) {
        this.showLoginModal = true
      } else {
        window.open(`https://old.dynamicodds.com/flex2/bin/pages/fs.asp?session_id=${sessionID}`, '', 'width=900,height=700')
      }
    },
    openTrackReports () {
      const sessionID = this.getSessionId
      if (sessionID === null) {
        this.showLoginModal = true
      } else {
        window.open(`https://old.dynamicodds.com/flex2/bin/pages/tr.asp?session_id=${sessionID}`, '', 'width=900,height=700')
      }
    },
    openGearChanges () {
      const sessionID = this.getSessionId
      if (sessionID === null) {
        this.showLoginModal = true
      } else {
        window.open(`https://old.dynamicodds.com/flex2/bin/pages/gc.asp?session_id=${sessionID}`, '', 'width=900,height=700')
      }
    },
    openScratchings () {
      const sessionID = this.getSessionId
      if (sessionID === null) {
        this.showLoginModal = true
      } else {
        window.open(`https://old.dynamicodds.com/flex2/bin/pages/scr.asp?session_id=${sessionID}`, '', 'width=900,height=700')
      }
    },
    openBlackBook () {
      const sessionID = this.getSessionId
      if (sessionID === null) {
        this.showLoginModal = true
      } else {
        window.open(`https://old.dynamicodds.com/flex2/bin/pages/eb.asp?session_id=${sessionID}`, '', 'width=900,height=700')
      }
    },
    raceNumbers (meeting) {
      const numbers = meeting.races.map(r => r.number)
      return numbers
    },
    sliderRaceNumber (race) {
      const raceNumber = race.number - 1
      return raceNumber
    },
    getStatus () {
      return this.race.status
    },
    getCondition (condition) {
      return TRACKCONDITION_TRANSLATION[condition]
    },
    getCustomDataNow (id) {
      const data = this.getCustomData(id)
      if (data && data['race-notes']) {
        this.message = data['race-notes'][id].value
        return true
      } else {
        this.message = ''
      }
    },
    getCustomDataB (id) {
      this.fetchCustomData({ id })
    },
    sanitizeRaceClass (text) {
      return text.replace('HANDICAP=TRUE', 'Handicap')
    },
    async openRunnerWatchlist () {
      await this.fetchWatchList()
      this.watchListSwitch()
    },
    handleHideBalance: function () {
      this.setHideBalance('totalBalance')
    },
    onOpenDualAcceptances: function () {
      if (this.dualAcceptancesHasLoaded) this.toggleDualAcceptances({ boolean: true })
    }
  },
  watch: {
    status: function (newValue, oldValue) {
      const resShow = this.getResultsShowHide
      if (resShow) {
        if (newValue === 'INTERIM') {
          this.raceResultsTable = true
        }
        if (newValue === 'FINAL') {
          this.raceResultsTable = true
        }
      } else {}
    },
    race: function (newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.getCustomDataB(newValue.id)
      }
      const resShow = this.getResultsShowHide
      if (newValue?.id !== oldValue?.id && resShow) {
        if (this.status === 'INTERIM') {
          this.raceResultsTable = true
        } else if (this.status === 'FINAL') {
          this.raceResultsTable = true
        } else {
          this.raceResultsTable = false
        }
      } else {
        // this.raceResultsTable = false
      }
    },
    isDualAcceptances (newState) {
      if (newState) {
        this.$modal.show('dual-acceptances')
      } else {
        this.$modal.hide('dual-acceptances')
      }
    }
  }
}
</script>
<style>
.raceTimeBrief {
  font-size: 1rem;
}
.navRaceTime {
  font-weight: medium;
  float: right;
  display: flex;
}
.icon-HarnessRacing {
  width: 60px;
  height: 30px;
  background: url('~@/assets/race_types/DO_RaceTypeSprite_lrgBlack.png') 0 -65px;
}
.icon-GreyHoundRacing {
  width: 60px;
  height: 30px;
  background: url('~@/assets/race_types/DO_RaceTypeSprite_lrgBlack.png') 0 -130px;
}
.icon-HorseRacing {
  width: 60px;
  height: 30px;
  background: url('~@/assets/race_types/DO_RaceTypeSprite_lrgBlack.png') 0 -0px;
}
.icon-HARNESS {
  width: 60px;
  height: 30px;
  background: url('~@/assets/race_types/DO_RaceTypeSprite_lrgBlack.png') 0 -65px;
}
.icon-GREYHOUND {
  width: 60px;
  height: 30px;
  background: url('~@/assets/race_types/DO_RaceTypeSprite_lrgBlack.png') 0 -130px;
}
.icon-THOROUGHBRED {
  width: 60px;
  height: 30px;
  background: url('~@/assets/race_types/DO_RaceTypeSprite_lrgBlack.png') 0 -0px;
}
.raceDescription {
  max-width:200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
