<template>
  <div>
    <v-card style="height:100%;" elevation="0">
      <v-toolbar color="primary" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title class="raceResults-title">A-Z List</v-toolbar-title>
          <v-btn class="cancelButton" icon small @click="closeAzListModal" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card elevation="0" class="ma-2" style="height:100%;">
        <v-card-title style="display: inline-block; padding: 0px 0px 0px 10px;">
          <div style="display: inline-flex; height: 30px;">
            <p class="titleMenu">Mode:</p>
            <v-radio-group v-model="optionSelected" style="margin-left: 30px; margin-top: 0px;">
              <v-row>
                <v-radio style="margin-left: 2px;" class="modeOption" label="Search" value="search"></v-radio>
                <v-radio class="modeOption" label="A to Z Runners" value="aZRunners"></v-radio>
                <v-radio class="modeOption" label="A to Z Jockeys/Drivers" value="aZJockeysDrivers"></v-radio>
                <v-radio class="modeOption" label="A to Z Trainers" value="aZTrainers" style="margin-bottom: 8px;"></v-radio>
              </v-row>
            </v-radio-group>
          </div>
          <div style="display: flex; height: 32px;">
            <p class="titleMenu">Type:</p>
            <v-row style="margin-left: 30px; margin-top: 5px;">
              <v-checkbox style="margin-left: -5px;" class="raceType scheduleLabels codesFilter" label="Gallops" color="primary" v-model="selected" value="THOROUGHBRED" hide-details></v-checkbox>
              <v-checkbox class="raceType scheduleLabels codesFilter" label="Harness" color="primary" v-model="selected" value="HARNESS"  hide-details></v-checkbox>
              <v-checkbox class="raceType scheduleLabels codesFilter" label="Greys" color="primary" v-model="selected"  value="GREYHOUND" hide-details></v-checkbox>
            </v-row>
          </div>
          <div v-if="optionSelected !== 'search'" style="display: flex; height: 30px;">
            <p class="titleMenu">Letter:</p>
            <v-row style="margin-left: 20px; margin-top: 6px;">
              <v-btn outlined class="letters" x-small v-for="letter in alphabet" :key="letter" :value="letter" @click="selectLetter(letter)" :color="letterSelected === letter ? 'info' : 'normal'">{{ letter }}</v-btn>
            </v-row>
          </div>
          <div v-else style="display: flex; height: 32px;">
            <p class="titleMenu">Search:</p>
            <v-row style="margin-left: 20px; margin-top: 6px;">
              <!--<v-text-field outlined hide-details v-model="search" @input="searchResults"></v-text-field>-->
              <v-text-field
                placeholder="Search"
                outlined
                v-model="search"
                height="25px"
                @keydown.enter="searchResults()"
                class="float-left pb-0 mb-n6"
              ></v-text-field>
              <v-btn @click="searchResults()" height="25px" x-small outlined class="ml-2 float-left"><v-icon>mdi-magnify</v-icon></v-btn>
            </v-row>
          </div>
        </v-card-title>
        <v-card-text>
          <v-data-table style="max-height: 750px; overflow: scroll;" dense :headers="headers" fixed-header :items="azQuery" hide-default-footer :items-per-page=9999 :loading="Object.keys(azQuery).length === 0">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <img :src="raceTypeImg[item.meetingType]" style="max-width:20px" />
                </td>
                <td><strong>{{ item.runner }}</strong></td>
                <td>{{ item.jockeyDriver }}</td>
                <td>{{ item.trainer }}</td>
                <td>{{ item.race }}</td>
                <td>{{ item.date === today ? 'Today' : item.date }}</td>
                <td>{{ item.competitorNumber }}</td>
                <td>{{ item.barrier }}</td>
                <td>{{ item.handicap }}</td>
                <td class="raceInformation" @click="clickRace(item.raceID, item.startTime)">Load Race</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn small @click="exportToPDF">
            <v-icon small style="margin-right: 6px;">mdi-content-save-all</v-icon>
            Export Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { format, startOfDay, isSameDay } from 'date-fns'
import { mapActions, mapGetters, mapState } from 'vuex'
import JsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  props: {
    closeAzList: Function,
    searchOnOpen: String,
    raceDaySearchOpen: Boolean
  },
  data () {
    return {
      search: '',
      raceTypeImg: {
        HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
        GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
        THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
      },
      headers: [
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Runner',
          value: 'runner'
        },
        {
          text: 'Jockey/Driver',
          value: 'jockeryDriver'
        },
        {
          text: 'Trainer',
          value: 'trainer'
        },
        {
          text: 'Race',
          value: 'race'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'No.',
          value: 'tabNo'
        },
        {
          text: 'Bar',
          value: 'barrier'
        },
        {
          text: 'HCP',
          value: 'handicap'
        },
        {
          text: '',
          value: 'race'
        }
      ],
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      codes: ['THOROUGHBRED', 'HARNESS', 'GREYHOUND'],
      letterSelected: 'A',
      optionSelected: 'aZRunners',
      today: format(new Date(), 'dd/MM/yyyy')
    }
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['getAzList', 'getLoadAZListData']),
    azQuery: function () {
      const azList = this.getAzList(this.letterSelected, this.optionSelected, this.codes, this.search)
      if (this.optionSelected === 'aZRunners') {
        azList.sort(function (a, b) {
          if (a.runner < b.runner) { return -1 }
          if (a.runner > b.runner) { return 1 }
          return 0
        })
      } else if (this.optionSelected === 'aZJockeysDrivers') {
        azList.sort(function (a, b) {
          if (a.jockeyDriver < b.jockeyDriver) { return -1 }
          if (a.jockeyDriver > b.jockeyDriver) { return 1 }
          return 0
        })
      } else if (this.optionSelected === 'aZTrainers') {
        azList.sort(function (a, b) {
          if (a.trainer < b.trainer) { return -1 }
          if (a.trainer > b.trainer) { return 1 }
          return 0
        })
      }
      // return meetingsDatedAz(new Date())
      return azList
    },
    selected: {
      get () {
        return this.codes
      },
      set (value, newValue) {
        return this.onClick(value)
      }
    }
  },
  watch: {
    raceDaySearchOpen () {
      if (this.raceDaySearchOpen) return this.searchFromNav()
    }
  },
  created () {
    return this.searchFromNav()
  },
  methods: {
    ...mapActions(['selectRace', 'searchAzLists', 'setDate', 'fetchAzList', 'fetchRaces']),
    closeAzListModal () {
      this.$emit('clicked')
      this.search = ''
      this.optionSelected = 'aZRunners'
    },
    closeAzListHere () {
      this.closeAzList()
      this.search = ''
      this.optionSelected = 'aZRunners'
    },
    selectLetter (letterSelected) {
      this.letterSelected = letterSelected
    },
    async clickRace (raceId, date) {
      this.closeAzListModal()
      await this.setDate(startOfDay(new Date(date)))
      if (isSameDay(startOfDay(Date.now()), date)) {
        this.selectRace({
          id: raceId
        })
      } else {
        await this.fetchRaces()
        this.selectRace({
          id: raceId
        })
      }
    },
    onClick (value) {
      this.codes = value
    },
    async searchFromNav () {
      if (this.raceDaySearchOpen) {
        this.optionSelected = 'search'
      }
      this.search = this.searchOnOpen
      if (this.getLoadAZListData) {
        await this.fetchAzList()
      }
      this.searchAzLists({ search: this.searchOnOpen })
    },
    searchResults () {
      this.searchAzLists({ search: this.search })
    },
    exportToPDF () {
      const doc = new JsPDF()
      let rows = []
      this.azQuery.forEach(az => {
        var temp = [
          az.meetingType.toLowerCase().charAt(0).toUpperCase() + az.meetingType.toLowerCase().slice(1),
          az.runner,
          az.jockeyDriver,
          az.trainer,
          az.race,
          az.date === this.today ? 'Today' : az.date,
          az.competitorNumber,
          az.barrier,
          az.handicap
        ]
        rows.push(temp)
      })
      doc.autoTable({
        head: [['Type', 'Runner', 'Jockey/Driver', 'Trainer', 'Race', 'Date', 'No.', 'Bar', 'HCP']],
        body: rows
      })
      doc.save('azList.pdf')
    }
  }
}
</script>

<style>
  .letters {
    margin-right: 4px;
  }

  .raceInformation {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .modeOption {
    margin-right: 20px;
  }

  .titleMenu {
    font-size: 15px !important;
  }

  .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0px !important;
  }

  .raceType {
    margin-right: 15px !important;
  }
</style>
