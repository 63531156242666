<script>
import { mapState } from 'vuex'

export default {
  name: 'MessageHandler',
  computed: {
    ...mapState({
      message: state => state.socket.message
    })
  },
  // methods: {
  //   ...mapMutations(['updateRaceSummary', 'updatePrice']),
  //   ...mapActions(['updateDetailedRace'])
  // },
  render () {
    return null
  },
  watch: {
    message: function (msg) {
      if (msg) {
        // switch (msg.roomName.split(':')[0]) {
        //   case 'meeting-dated':
        //     const meeting = mapMeeting(msg.payload)
        //     meeting.Races.forEach((race) => {
        //       this.updateRaceSummary({ race, meeting })
        //     })
        //     break
        //   case 'race':
        //     this.updateDetailedRace({ race: msg.payload })
        //     breakS
        //   case 'pricing-update':
        //     this.updatePrice({ price: msg.payload })
        //     break
        // }
      }
    }
  }
}
</script>
