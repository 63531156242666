<template>
  <v-tooltip :bottom="bottom" :left="left" :right="right" :top="top">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        {{ truncateTxt({ txt: text, max: maxCharacters }) }}
      </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
import { truncateTxt } from '../common/methods'

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    maxCharacters: {
      type: Number,
      required: true
    },
    bottom: {
      type: Boolean,
      default: true
    },
    top: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    truncateTxt
  }
}
</script>
