<template>
  <div :class="this.acMode ? 'raceFiltersAcMode mt-n5 pt-0' : 'stdMode mt-n5 pt-0'" style="width:100%;">
    <v-checkbox class="scheduleLabels float-left mr-2 conditionsFilter" label="Fast" color="primary" v-model="selected" value="Fast" hide-details></v-checkbox>
    <v-checkbox class="scheduleLabels float-left mr-2 conditionsFilter" label="Good" color="primary" v-model="selected" value="Good" hide-details></v-checkbox>
    <v-checkbox class="scheduleLabels float-left mr-2 conditionsFilter" label="Dead" color="primary" v-model="selected" value="Dead" hide-details></v-checkbox>
    <v-checkbox class="scheduleLabels float-left mr-2 conditionsFilter" label="Slow" color="primary" v-model="selected" value="Slow" hide-details></v-checkbox>
    <v-checkbox class="scheduleLabels float-left mr-2 conditionsFilter" label="Heavy" color="primary" v-model="selected" value="Heavy" hide-details></v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    conditions: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    selected: {
      get () {
        return this.conditions
      },
      set (value) {
        return this.onClick(value)
      }
    }
  }
}
</script>

<style>
</style>
