<template>
  <div :class="this.acMode ? 'raceFiltersAcMode distanceFilters' : 'stdMode distanceFilters'" style="width:100%">
    <v-text-field label="From:" :rules="rulesDistance" placeholder="1" v-model="min" type="number" class="float-left d-inline" style="max-width:100px;"></v-text-field>
    <v-text-field label="To:" :rules="rulesDistance" placeholder="10000" v-model="max" type="number" class="float-right d-inline" style="max-width:100px;"></v-text-field>
    <span class="caption">Meters</span>
  </div>
</template>

<script>
export default {
  data: () => ({
    rulesDistance: [
      value => !!value || 'Required.',
      value => (value && value.length <= 5) || 'Max 5 characters',
      value => (value <= 10000) || 'Max 10,000 Meters'
    ]
  }),
  props: {
    distances: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    min: {
      get () {
        return this.distances[0]
      },
      set (value) {
        return this.onClick([value, this.max])
      }
    },
    max: {
      get () {
        return this.distances[1]
      },
      set (value) {
        return this.onClick([this.min, value])
      }
    }
  }

}
</script>
