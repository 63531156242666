import gql from 'graphql-tag'

export const meetingsDated = (date, sources = JSON.stringify([])) => {
  return `query {
    meetingsDated(date: ${date}){
      id,
      type,
      date,
      tabIndicator,
      railPosition,
      externalIDs(sources: "gbs"){
        id
      },
      track{
        name,
        country,
        state
      }
      races{
        id,
        class,
        distance,
        name,
        number,
        startTime,
        status,
        trackCondition,
        prizeMoney,
        fixedAvailable,
        starters,
        competitors {
          name,
          tabNo,
          scratchingType
        }
        priceSets(sources: ${sources}){
          source
          prices {
            price
          }
        }
      }
    }
  }`
}
export const subscribeDetailedRace = (id, date) => {
  return `subscription {
    raceUpdated(raceId: ${id}, dated: ${date}){
      id,
      created,
      number,
      name,
      distance,
      startTime,
      class,
      status,
      prizeMoney,
      trackCondition,
      weather,
      competitors{
        created,
        tabNo,
        barrier,
        name,
        jockey,
        trainer,
        age,
        sex,
        country,
        margin,
        scratched,
        finalPosition,
        scratchingType
      },
      meeting{
        id,
        created,
        type,
        date,
        tabIndicator,externalIDs(sources: "gbs"){
          id
        },
        track{
          name,
          country,
          state
        }
      }
    }
  }`
}
export const subscribeRaceUpdates = (date) => {
  return `subscription {
    raceUpdated(dated: ${date}){
      id,
      startTime,
      status,
      meeting{
        id
      }
    }
  }`
}

export const queryRace = (id, sources, includeRatings = false) => {
  // let ratingFields = ''
  // if (includeRatings) {
  //   ratingFields = `
  //     rating {
  //       career
  //       distance
  //       track
  //       distanceTrack
  //       jumps
  //       firstUp
  //       secondUp
  //       firm
  //       good
  //       soft
  //       heavy
  //       synthetic
  //       dry
  //       wet
  //       price
  //     }
  //   `
  // }
  return `query {
    race(id: ${id}){
      id
      class,
      name,
      number,
      startTime,
      status,
      trackCondition,
      weather,
      distance,
      starters,
      prizeMoney,
      priceSets(sources: ${sources}){
        source,
        meetingID,
        raceNumber,
        type,
        poolSize,
        updated,
        prices{
          tabNo,
          price
        }
      }
      competitors{
        barrier,
        country,
        name,
        tabNo,
        jockey,
        trainer,
        scratched,
        owners,
        colour,
        damCountry,
        dam,
        sireCountry,
        sire,
        prizeMoney,
        sex,
        age,
        weightTotal,
        weightAllocated,
        emergencyIndicator,
        apprenticeIndicator,
        allowanceWeight,
        finalPosition,
        speedmapPosition,
        scratchingType,
        runner{
          id
        },
      },
      meeting{
        id,
        date,
        railPosition,
        externalIDs(sources: "gbs"){
          id
        },
        track{
          name,
          country,
          state
        },
        type,
      },
      results(sources: []){
        status,
        source,
        positions{
          tabNo,
          position,
          margin
        },
        dividends{
          type,
          dividendNumbers,
          dividend,
          poolSize,
          status
        }
      }
    }
  }`
}

export const queryRunner = (id) => {
  return `query {
    runner(id: ${id}) {
      id
      competitorsConnection(first:10) {
        competitors {
          tabNo
          barrier
          age
          scratched
          name
          jockey
          margin
          finalPosition
          weightAllocated
          race {
            name
            number
            class
            startTime
            distance
            prizeMoney
            trackCondition
            status
            competitors {
              tabNo
              scratched
            }
            meeting {
              track {
                name
              }
            }
          }
        }
      }
    }
  }`
}

export const queryRunnerDO = (id) => {
  return `query {
    runner(id: ${id}) {
      id
      competitorsConnection(first:1) {
        competitors {
          tabNo
          barrier
          age
          scratched
          name
          jockey
          margin
          finalPosition
          weightAllocated
          formHistory {
            startCount
            date
            track
            raceNumber
            finishingPosition
            starters
            distance
            class
            barrier
            jockey
            trainer
            margin
            trackCondition
            trackRating
            elapsedTime
            weight
            startingPrice
            stewardsComment
            prizeTotal
            placeGetters {
              tabNumber
              finishingPosition
              name
              weight
              startingPrice
            }
          }
        }
      }
    }
  }`
}

export const queryFlucs = (id, source, priceType) => {
  return `query {
    race(id: ${id}){
      id,
      competitors{
        flucs(source: ${source}, priceType: ${priceType}){
          tabNo,
          time,
          price
        }
      }
    }
  }`
}

export const queryFlucsBetFair = (id) => {
  return `query {
    race(id: ${id}){
      id,
      betFairMarkets {
        selections {
          lastTradedFlucs {
            tabNo,
            time,
            price
          }
        }
      }
    }
  }`
}

export const queryPoolHistory = (id, source, priceType) => {
  return `query {
    race(id: ${id}){
      id,
      poolHistory(source: ${source}, priceType: ${priceType}){
        updated,
        poolSize,
        prices{
          tabNo,
          price
        }
      }
    }
  }`
}

export const queryPrices = (id, sources) => {
  return gql` {
    race(id: ${id}){
      id,
      priceSets(sources: ${sources}){
        source,
        meetingID,
        raceNumber,
        type,
        poolSize,
        updated,
        prices{
          tabNo,
          price
        }
      }
    }
  }`
}

export const subscribePrices = (id, sources) => {
  return `subscription {
    priceUpdates(raceId: ${id}, sourceTypes: ${sources}){
      updated,
      source,
      meetingID,
      raceNumber,
      type,
      poolSize,
      prices{
        source,
        tabNo,
        price,
        type
      }
    }
  }`
}

export const queryBestPrices = (id, sources) => {
  return gql` {
    race(id: ${id}){
      id,
      competitors{
        name,
        tabNo
      },
      meeting{
        externalIDs(sources: "gbs"){
          id
        }
      },
      priceSets(sources: ${sources}){
        source,
        meetingID,
        raceNumber,
        type,
        poolSize,
        updated,
        prices{
          tabNo,
          price
        }
      }
    }
  }`
}

export const queryBetFairPrices = (id, type) => {
  return `query {
    race(id: ${id}) {
      id,
      betFairMarkets(priceType: ${type}) {
        priceType,
        totalTraded,
        selections {
          name,
          number,
          lastTradedPrice,
          sellingPrice,
          totalTraded,
          lastTradedFlucs {
            tabNo,
            time,
            price
          },
          isScratched,
          availableToBack {
            price,
            volume,
            level
          },
          availableToLay {
            price,
            volume,
            level
          }
        }
      }
    }
  }`
}

export const subscribeBetFairPrices = (id, types) => {
  return `subscription {
    betFairUpdates (raceId: ${id}, types: [${types}]) {
      priceType,
      totalTraded,
      selections {
          name,
          number,
          lastTradedPrice,
          sellingPrice,
          totalTraded,
          isScratched,
          availableToBack {
            price,
            volume,
            level
          },
          availableToLay {
            price,
            volume,
            level
          }
        }
      isClosed
    }
  }`
}

export const subscribeResultsQuery = id => {
  return `subscription {
    results{
      raceId,
      status,
      source,
      positions{
        tabNo,
        position,
        margin
      }
    }
  }`
}

export const speedMapQuery = id => {
  return `query {
    race(id: ${id}){
      id,
      competitors{
        tabNo,
        name,
        barrier,
        speedmapPosition
      }
    }
  }`
}

export const runnerInfoQuery = (id) => {
  const idString = JSON.stringify(id)
  const a = gql` {
    race(id: ${idString}){
      name
    }
  }`
  return a
}

export const runnerInfoQueryStats = (id, selection, sub) => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        ${sub.sel1} {
          ${sub.sel2} {
            ${sub.sel3} {
              ${sub.sel4}
            }
          }
        },
        tabNo,
        scratched,
        runner {
          id
        }
      }
    }
  }`
}

export const exoticsPriceQuery = id => {
  const idString = JSON.stringify(id)
  return gql`{
    race(id: ${idString}){
      id,
      status,
      betFairMarkets{
        selections{
          number,
          sellingPrice
        }
      },
      results(sources: []){
        source,
        positions{
          position,
          competitor{
            tabNo,
            name,
            barrier,
            finalPosition
            prices(sources: ["OP", "V", "Q", "N", "BF"]){
              source,
              type,
              price
            }
          }
        },
        dividends{
          type,
          dividend,
          dividendNumbers,
          poolSize
        }
      }
    }
  }`
}

export const customColumnQuery = (id) => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      id,
      number,
      competitors{
        tabNo,
        name,
        runner{
          id
        }
      },
      meeting{
        date,
        track{
          name
        }
      }
    }
  }`
}

export const runnerHistoryLastStartQuery = (id) => {
  const idString = JSON.stringify(id)
  return gql` {
    runner(id: ${idString}){
      id,
      competitorsConnection(first: 10) {
        competitors{
          tabNo,
          scratched,
          race{
            id,
            class,
            status,
            number,
            startTime,
            meeting{
              date,
              track{
                name
              }
            }
          }
        }
      }
    }
  }`
}

export const meetingsDatedAz = (date, to) => {
  return `query {
    meetingsDated(firstRaceFrom: ${date}, to: ${to}){
      id,
      date,
      type,
      externalIDs(sources: "gbs") {
        id
      }
      track{
        name,
        country
      }
      races{
        name,
        id,
        distance
        number,
        startTime,
        status
        competitors{
          name,
          jockey,
          trainer,
          barrier,
          weightAllocated,
          tabNo
          runner {
            id
          }
          weightTotal
          scratched
        }
      }
    }
  }`
}

export const queryBetHistoryRace = (id) => {
  return `query {
    race(id: ${id}){
      id,
      number,
      meeting{
        id
        type
        track{
          name
        }
      },
      competitors{
        tabNo,
        finalPosition
      }
    }
  }`
}

export const priceAlertInfoQuery = (meeting, id, sources) => {
  return gql` {
    meeting(id: ${meeting}){
      track{
        name,
        country
      }
      races(raceNumber: ${id}){
        id,
        name,
        status,
        startTime
        competitors{
          tabNo,
          name,
          scratched
        },
        priceSets(sources: ${sources}){
          prices{
            tabNo,
            type,
            price
          }
        }
      }
    }
  }`
}

export const basicRaceQuery = (id) => {
  return gql` {
    race(id: ${id}){
      id,
      number,
      status,
      meeting{
        date,
        track{
          name
        }
      }
    }
  }`
}

export const apiQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
        runnerStatistics {
          career {
            all {
              averagePrizeMoneyWon
            }
          }
        }
      }
    }
  }`
}

export const firstUpQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
        runnerStatistics {
          career {
            spell {
              firstUp {
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
          }
        }
      }
    }
  }`
}

export const careerStatsAllQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      name
      competitors{
        tabNo
        name
        runnerStatistics {
          career {
            all {
              numberOfRuns
              firsts
              seconds
              thirds
              winPercentage
              placePercentage
            }
          }
        }
      }
    }
  }`
}

export const careerStatsJockeyQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
        jockey
        runnerStatistics {
          career {
            detail {
              byJockey {
                key
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
          }
        }
      }
    }
  }`
}

export const careerStatsByDistQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      distance
      competitors{
        tabNo
        runnerStatistics {
          career {
            detail {
              byDistance {
                key
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
          }
        }
      }
    }
  }`
}

export const careerStatsByTrackDistQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      distance
      meeting {
        track {
          name
        }
      }
      competitors{
        tabNo
        runnerStatistics {
          career {
            detail {
              byTrackAndDistance {
                key
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
          }
        }
      }
    }
  }`
}

export const careerStatsByTrackQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      distance
      meeting {
        track {
          name
        }
      }
      competitors{
        tabNo
        runnerStatistics {
          career {
            detail {
              byTrack {
                key
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
          }
        }
      }
    }
  }`
}

export const careerStatsByConditionQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
        runnerStatistics {
          career {
            detail {
              byCondition {
                key
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
          }
        }
      }
    }
  }`
}

export const silksColourQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
       silksColour
      }
    }
  }`
}

export const lastTenStartsQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
        runnerStatistics {
          recent {
            last10Starts
          }
        }
      }
    }
  }`
}

export const runnerCommentsQuery = id => {
  const idString = JSON.stringify(id)
  return gql` {
    race(id: ${idString}){
      competitors{
        tabNo
        comment
      }
    }
  }`
}

export const queryExotics = id => {
  return `query {
    race(id: ${id}){
      id
      exotics(sources: ["Q", "N", "V"]) {
        source
        type
        poolSize
        dividends {
          combination
          price
        }
      }
    }
  }`
}

export const subscribeScratching = () => {
  return `subscription {
    scratching{
      meetingId,
      raceNumber,
      runnerNumber
    }
  }`
}

export const futuresQuery = (dateFrom, dateTo) => {
  return `query {
      futures(startDate: ${dateFrom}, endDate: ${dateTo}) {
        event{
          meetingId,
          country,
          state,
          id,
          acceptanceRaceNumber,
          class,
          date,
          distance,
          entryCondition,
          name,
          raceNumber,
          venue,
          markets{
            type
            selections{
              name,
              runnerNumber,
              marketSelectionId
              prices{
                source
                price
              }
            }
          }
        }
      }
    }`
}
