// Dependencies ===============

import Vue from 'vue'
import { Auth } from '@aws-amplify/auth'
import { datadogLogs } from '@datadog/browser-logs'
import { bettingLogin, getBalances, loginAgency, logoutAgency } from '../services/betting'
import { mapBettingSource, isCoreApiTokenExpired } from '../common/methods'
import { getUserSubStatus, getUserDFSubStatus } from '../services/userAccount'
import axios from 'axios'
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_GRAPHQL_HTTP
})

axiosInstance.interceptors.request.use(async function (config) {
  await window.doApp.$store.dispatch('account/updateAuthToken')
  const token = window.localStorage.getItem('auth_token')
  config.headers.Authorization = `Bearer ${token}`

   return config
}, err => Promise.reject(err))

const store = {
  namespaced: true,
  state: {
    authorized: null,
    user: null,
    agencieBalances: [],
    sessionId: null,
    memberId: null,
    subscriptionInterval: null,
    userSubscription: true,
    sessionInterval: null,
    balancesInterval: null,
    coreApiToken: null,
    isFreetrial: false,
    newSubscriptionInfo: {}
  },
  mutations: {
    user (state, user) {
      state.authorized = !!user && !!user.username
      state.user = user
    },
    setBalances (state, { balances }) {
      state.agencieBalances = balances
    },
    setSessionId (state, sessionId) {
      state.sessionId = sessionId
    },
    setMembetId (state, memberId) {
      state.memberId = memberId
    },
    setSubscriptionInterval (state, { subscriptionInterval }) {
      if (state.subscriptionInterval) {
        clearInterval(state.subscriptionInterval)
      }
      state.subscriptionInterval = subscriptionInterval
    },
    setUserSubscription (state, { subscription }) {
      state.userSubscription = subscription
    },
    setTokenInterval (state, { interval }) {
      if (state.sessionInterval) {
        clearInterval(state.sessionInterval)
      }
      state.sessionInterval = interval
    },
    setBalancesInterval (state, { interval }) {
      if (state.balancesInterval) {
        clearInterval(state.balancesInterval)
      }
      state.balancesInterval = interval
    },
    saveCoreApiToken (state, { token }) {
      state.coreApiToken = token
    },
    setFreeTrial (state, { boolean }) {
      state.isFreetrial = boolean
    },
    setNewSubscriptionInfo (state, info) {
      state.newSubscriptionInfo = info
    }
  },
  actions: {
    async authState ({ commit, dispatch }, state) {
      if (state === 'signedIn') {
        await dispatch('fetchUser')
      } else {
        commit('user', null)
      }
    },
    async fetchUser ({ commit, dispatch }) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        commit('user', user)
        window.localStorage.setItem('auth_token', user.getSignInUserSession().getAccessToken().getJwtToken())
        // commentted below line as once comitted user, it is authorized, and in App.vue there is a watch to update auth token
        // can't see any reason to call the updateAuthToken here, it is duplicated and could cause exchange core api token twice
        // dispatch('updateAuthToken')
        return user
      } catch (err) {
        commit('user', null)
      }
    },
    async logout ({ commit }) {
      await Auth.signOut()
      commit('user', null)
      // window.localStorage.setItem('auth_token', null)
      // window.localStorage.setItem('memberId', null)
      window.localStorage.clear()
    },
    async deleteUser () {
      try {
        await Auth.deleteUser()
        // console.log(result)
      } catch (error) {
        // console.log('Error deleting user', error)
      }
    },
    async updateAuthToken (context) {
      if (!context.state.user || (context.state.user && context.state.user.getSignInUserSession().getAccessToken().payload.exp * 1000 < (new Date()).getTime())) {
        await context.dispatch('fetchUser')
      } else {
        const token = context.state.user
          .getSignInUserSession()
          .getAccessToken()
          .getJwtToken()
        window.localStorage.setItem('auth_token', token)
       // if (isCoreApiTokenExpired({ token: context.state.coreApiToken })) { await context.dispatch('exchangeCoreAPIToken') }
      }
    },
    async updateTokenExpired (context, { callback }) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const currentSession = await Auth.currentSession()
        await cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
          if (err) {
            return
          }
          if (!session || !session.accessToken) return null
          const { accessToken } = session
          window.localStorage.setItem('auth_token', accessToken.jwtToken)
        //  if (isCoreApiTokenExpired({ token: context.state.coreApiToken })) { await context.dispatch('exchangeCoreAPIToken') }
          // callback()
        })
        context.commit('user', cognitoUser)
      } catch (e) {
        context.state.authorized = null
        context.state.user = null
      }
    },
    async confirmSignin (context, { username, password }) {
      try {
        const user = await Auth.signIn(username, password)
        if (user) {
          context.dispatch('fetchUser')
          return { success: true }
        } else {
          return { message: 'Incorrect username or password' }
        }
      } catch (error) {
        return error
      }
    },
    async loginBetting (context) {
      try {
        await context.dispatch('fetchUser')
        const config = context.getters.authConfig
        const { sessionId, memberId } = await bettingLogin(config)
        context.commit('setMembetId', memberId)
        window.localStorage.setItem('memberId', memberId)
        if (sessionId) {
          if (context.rootGetters.hasFeature('newSubscription')) {
            const customerData = {
              userToken: context.getters?.getUser?.getSignInUserSession().getAccessToken()?.jwtToken,
              email: context.getters?.getUser?.attributes?.email,
              phoneNumber: context.getters?.getUser?.attributes?.phone_number,
              userName: context.getters?.getUser?.username,
              notes: 'DO Signup',
              memberId: Number(memberId)
            }
            window.__tbm__widget__sub.exposed.createCustomer(customerData)
          }
          Vue.$cookies.set('sessionId', sessionId, '60s')
          context.commit('setSessionId', sessionId)
          context.dispatch('fetchBalances')
          const sessionInterval = setInterval(() => {
            Vue.$cookies.set('sessionId', sessionId, '60s')
            context.dispatch('confirmSession')
          }, 25000)
          context.commit('setTokenInterval', { interval: sessionInterval })

          const balancesInterval = setInterval(() => {
            context.dispatch('fetchBalances')
          }, 60000)
          context.commit('setBalancesInterval', { interval: balancesInterval })
          return true
        }
      } catch (error) {
        context.dispatch('updateTokenExpired', {
          callback: () => {
            context.dispatch('loginBetting')
          }
        })
      }
    },
    confirmBetting (context) {
      const sessionId = Vue.$cookies.get('sessionId')
      if (sessionId) {
        context.dispatch('loginBetting')
      }
    },
    async fetchBalances (context) {
      try {
        const config = context.getters.authConfig
        const sessionId = context.getters.getSessionId
        if (sessionId) {
          const balances = await getBalances(sessionId, config)
          context.commit('setBalances', balances)
        } else {
        }
      } catch (error) {
        context.commit('setBalancesInterval', { interval: null })
        // context.dispatch('removeSessionCookies')
        context.dispatch('updateTokenExpired', {
          callback: () => {
            context.dispatch('fetchBalances')
          }
        })
      }
    },
    async agencyLogin (context, { username, password, bookieCode }) {
      try {
        const config = context.getters.authConfig
        const sessionId = Vue.$cookies.get('sessionId')
        const code = mapBettingSource(bookieCode)
        if (sessionId) {
          const result = await loginAgency(username, password, code, sessionId, config)
          if (result.data.success) {
            context.dispatch('fetchBalances')
            setTimeout(() => {
              context.dispatch('fetchBalances')
            }, 3000)
          }
          return result.data
        } else {
          return false
          // const sessionId = await bettingLogin(config)
          // if (sessionId) {
          //   Vue.$cookies.set('sessionId', sessionId, 0)
          // }
        }
      } catch (error) {
        context.dispatch('updateTokenExpired', {
          callback: () => {
            context.dispatch('agencyLogin', { username, password, bookieCode })
          }
        })
      }
    },
    async agencyLogout (context, { bookieCode }) {
      try {
        const config = context.getters.authConfig
        const sessionId = Vue.$cookies.get('sessionId')
        const code = mapBettingSource(bookieCode)
        if (sessionId) {
          await logoutAgency(code, sessionId, config)
          context.dispatch('fetchBalances')
          setTimeout(() => {
            context.dispatch('fetchBalances')
          }, 2000)
          return true
        } else {
          return false
        }
      } catch (error) {
        context.dispatch('updateTokenExpired', {
          callback: () => {
            context.dispatch('agencyLogout', { bookieCode })
          }
        })
      }
    },
    async removeSessionCookies (context) {
      Vue.$cookies.remove('sessionId')
      context.commit('setSessionId')
    },
    async logoutBetting (context) {
      context.commit('setBalances', [])
      context.dispatch('removeSessionCookies')
      context.commit('setBalancesInterval', { interval: null })
      context.commit('setTokenInterval', { interval: null })
    },
    confirmSession (context) {
      const sessionId = Vue.$cookies.get('sessionId')
      if (!sessionId) {
        // context.dispatch('loginBetting')
        context.commit('setSessionId')
      } else {
        context.commit('setSessionId', sessionId)
      }
    },
    async fetchUserAccountStatus (context) {
      const memberId = window.localStorage.getItem('memberId')
      if (memberId) {
        try {
          const config = context.getters.authConfig
          const result = await getUserSubStatus(config)
          if (result?.subStatus === 1) {
            context.commit('setUserSubscription', { subscription: true })
          } else {
            context.commit('setUserSubscription', { subscription: false })
            datadogLogs.logger.info(`fetchUserAccountStatus - account.js - username: ${context.state.user.username}\n'subscription_level' was empty`)
            // context.dispatch('handleInvalidUserSubscription')
          }
        } catch (error) {
        }
      }
    },
    async startUserStatusInterval (context) {
      context.dispatch('updateTokenExpired', { callback: () => {} })
      const subscriptionInterval = setInterval(context.dispatch('fetchUserAccountStatus'), 900000)
      context.commit('setSubscriptionInterval', { subscriptionInterval })
    },
    async handleInvalidUserSubscription (context) {
      // const memberId = context.state.memberId
      // context.dispatch('account/logout')
      // Vue.$cookies.set('sessionId', null)
      // window.location = `https://dynamicodds.com/new/pages/purchase3.asp?p_id=3&currency=AUD&m_id=${memberId}&recurring=1`
    },
    async updateUserDetails (context, { email, phoneNumber }) {
      const user = await Auth.currentAuthenticatedUser()

      const result = await Auth.updateUserAttributes(user, {
        email: email,
        phone_number: phoneNumber
      })

      context.dispatch('fetchUser')

      return result
    },
    async changePassword (context, { oldPassword, newPassword }) {
      const result = await Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldPassword, newPassword)
        })
        .then(data => {
          return data
        })
        .catch(err => {
          return err
        })

      return result
    },
    async sendEmailCode (context, { emailCode }) {
      const result = await Auth.verifyCurrentUserAttributeSubmit('email', emailCode)
      return result
    },
    async exchangeCoreAPIToken (context) {
      return new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(async function () {
          try {
            if (context.state.authorized) {
              const recaptureToken = await window.grecaptcha.enterprise.execute(`${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`, { action: 'tokenexchange' })
              const config = context.getters.authConfig
              // exchange token only when it is expired or not existing
              if (isCoreApiTokenExpired({ token: context.state.coreApiToken })) {
                const result = await axiosInstance.get(`token?recaptcha=${recaptureToken}`, config)
                context.commit('saveCoreApiToken', { token: result.data.AccessToken })
                window.localStorage.setItem('coreapi_auth_token', result.data.AccessToken)
              }
            }
            resolve(true)
          } catch (error) {
            reject(error)
          }
        })
      })
    },
    async triggerFreeTrial (context, { boolean }) {
      context.commit('setFreeTrial', { boolean })
    },
    async updateCoreAPIToken (context) {
      if (isCoreApiTokenExpired({ token: context.state.coreApiToken })) {
        await context.dispatch('exchangeCoreAPIToken')
      }
      window.localStorage.setItem('coreapi_auth_token', context.state.coreApiToken)
    },
    async getSubscriptionLevel (context) {
      const memberId = window.localStorage.getItem('memberId')
      if (memberId) {
        try {
          const config = context.getters.authConfig
          const result = await getUserSubStatus(config)
          const user = await Auth.currentUserInfo()
          return {
            ...result,
            ...user
          }
        } catch (err) {
          datadogLogs.logger.info(`GetSubscriptionLevel - account.js - username: ${context.state.user?.username}\n${JSON.stringify(err, undefined, 4)}`, err)
          return {}
        }
      }
    },
    async getDFSubscriptionLevel (context) {
      try {
        const config = context.getters.authConfig
        const result = await getUserDFSubStatus(config)
        return result
      } catch (err) {
        datadogLogs.logger.info(`GetDFSubscriptionLevel - account.js - username: ${context.state.user?.username}\n${JSON.stringify(err, undefined, 4)}`, err)
        return {}
      }
    }
  },
  getters: {
    authToken: (state) => {
      if (!state.user) return null
      const token = state.user
        .getSignInUserSession()
        .getAccessToken()
        .getJwtToken()
      return `${token}`
    },
    authConfig: (state, getters) => {
      let config = {}
      const token = getters.authToken
      if (token) {
        config = {
          headers: { Authorization: `Bearer ${token}` }
        }
      }
      return config
    },
    getUsername (state) {
      if (state.user) {
        return state.user.username
      } else {
        return null
      }
    },
    getBalances (state) {
      return state.agencieBalances
    },
    getSessionId (state) {
      return state.sessionId
    },
    getMemberId (state) {
      return state.memberId || window.localStorage.getItem('memberId')
    },
    getUser (state) {
      if (state.user) {
        return state.user
      } else {
        return null
      }
    },
    getCoreToken (state) {
      return state.coreApiToken
    }
  }
}

// Export =====================

export default store
