<template>
  <div v-if="loading" class="loading">
    Loading...
  </div>
  <v-card id="myelement" v-else style="max-width: 1450px; max-height: 950px;" tile>
      <v-toolbar color="primary" dark dense style="position: sticky; top: 0; z-index: 999;">
        <div style="width: 100%;">
          <v-toolbar-title class="float-left"> {{ title }} </v-toolbar-title>
        </div>
      </v-toolbar>

      <v-container fluid class="speedMap-container">
        <div class="noSpeedTable-container">
          <table class="noSpeedTable" border="1">
            <template v-for="(runner, y) in getExtraRunners()">
              <v-tooltip bottom color="black" open-delay="400" transition="none" :key="'runner' + y">
                <template v-slot:activator="{ on }">
                  <tr :key="'runner' + y" v-on="on">
                    <td v-if="runner.name && runner.scratched" class="runnerName-lateScratching" style="opacity: 0.5; width: 100%;">{{ runner.tabNo + '. ' + runner.name }}</td>
                    <td v-else-if="runner.name && !runner.scratched" v-draggable="{ type: 'speedMapListItem', index: { y } }" @dropped="handleDrop">{{ runner.tabNo + '. ' + runner.name }}</td>
                    <td v-else v-droppable="{ type: 'speedMapList', index: { y } }"></td>
                    <v-divider></v-divider>
                  </tr>
                </template>
                <span class="caption" style="font-family: 'Roboto', sans-serif;">Jockey: {{ runner.jockey }} | Weight: {{ runner.weight }}kg</span>
              </v-tooltip>
            </template>
          </table>
        </div>
        <table class="speedTable" border="1">
          <template v-for="(col, y) in getMappedRunners()">
            <tr :key="'runner' + y">
              <v-tooltip v-for="(runner, x) in col" bottom color="black" open-delay="400" transition="none" :key="'col' + y + x" :disabled="Object.keys(runner).length === 0">
                <template v-slot:activator="{ on }">
                  <td v-if="runner.name && !runner.scratched" :key="'col' + y + x" v-on="on" v-draggable="{ type: 'speedMapTableItem', index: { x, y } }" @dropped="handleDrop" class="speedTable-runner">
                    <RaceRunners :item="runner" :meeting="meeting" :editMode="editmode" :race="race" :speedMap="true"/>
                    <div class="runnerNumber">{{ runner.tabNo }}</div>
                    <div class="runnerBar">({{ runner.barrier }})</div>
                    <div style="clear:both"></div>
                    <div class="runnerName">{{ runner.name }}</div>
                  </td>
                  <td v-else-if="runner.name && runner.scratched" :key="'col' + y + x" v-on="on" v-droppable="{ type: 'speedMapTable', index: { x, y } }"></td>
                  <td v-else :key="'col' + y + x" v-on="on" v-droppable="{ type: 'speedMapTable', index: { x, y } }"></td>
                </template>
                <span class="caption" style="font-family: 'Roboto', sans-serif;">Jockey: {{ runner.jockey }} | Weight: {{ runner.weight }}kg</span>
              </v-tooltip>
            </tr>
          </template>
        </table>
      </v-container>
      <v-container fluid class="speedMap-labels">
        <div class="speedMap-labels-firstStarters">
          <v-chip color="error mt-1" label><span>First Starters / No Speed Data</span></v-chip>
        </div>
        <div class="speedMap-labels-map">
          <v-row style="margin-right:0px">
            <v-col class="pa-1" v-for="label in getSpeedMapLabels()" :key="label" style="width:190px;text-align:center;margin-left:5px;">
              <v-chip class="caption text-center white--text" color="success" style="width:190px;" label>{{label}}</v-chip>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container class="speedMap-mapNotes">
        <v-textarea
          filled
          label="Map Notes"
          auto-grow
          :value="comments()"
          @input="updateComments"
          background-color="blue lighten-5"
        ></v-textarea>
      </v-container>
      <v-card-title class="grey darken-1 white--text speedMap-footer pt-3 resetSpeedMapButton" style="position: sticky; bottom: 0; z-index: 999;">
        <v-btn small @click="resetToDefault" :color="!resetConfirm ? '' : 'error'"><v-icon class="mr-2">mdi-refresh</v-icon>{{ !resetConfirm ? 'Reset to Default' : 'Are you sure? Click to reset'}}</v-btn>
        <v-btn small @click="printSpeedMap" style="position: absolute; right: 24px;"><v-icon class="mr-2">mdi-printer</v-icon> Print</v-btn>
      </v-card-title>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import SpeedMap from '../components/SpeedMap'
import { SPEEDMAP_LABELS, TRACKDIRECTION } from '../common/constants'
import RaceRunners from '../components/RaceRunners'
import { Printd } from 'printd'

export default {
  data: () => ({
    resetConfirm: false,
    editmode: false,
    loading: false
  }),
  components: {
    RaceRunners
  },
  props: ['raceID'],
  computed: {
    ...mapGetters(['getSpeedMap', 'getSelectedRace']),
    race: function () {
      return this.getSelectedRace()
    },
    meeting: function () {
      return this.getSelectedRace()?.meeting
    },
    title: function () {
      return `Speed Map - ${this.meeting.track.name} R${this.race.number} ${this.getTrackDirection()}`
    },
    speedMap: {
      get () {
        return this.getSpeedMap
      }
    }
  },
  methods: {
    ...mapActions(['selectRace', 'saveSpeedMap', 'setSpeedMap', 'resetSpeedMap', 'saveCoreToken']),
    getExtraRunners: function () {
      if (this.speedMap && this.speedMap.extraRunners && this.speedMap.extraRunners.runners && this.sameRace(this.speedMap.extraRunners, this.race)) {
        return this.speedMap.extraRunners.runners
      }
      if (this.speedMap && this.speedMap.defaultRunners) {
        const rows = []
        rows.push({})
        this.speedMap.defaultRunners.forEach(runner => {
          if (!runner.speedmapPosition || runner.scratched) {
            rows.push(runner)
          } else {
            rows.push({})
          }
        })
        for (let i = rows.length; i < 17; i++) {
          rows.push({})
        }
        return rows
      }
      return []
    },
    getMappedRunners: function () {
      if (this.speedMap && this.speedMap.mappedRunners && this.speedMap.mappedRunners.runners && this.sameRace(this.speedMap.mappedRunners, this.race)) {
        this.speedMap.mappedRunners.runners.forEach(runners => {
          runners.forEach(runner => {
            if (runner.scratched) {
                this.speedMap.extraRunners.runners[runner.tabNo] = runner
            }
          })
        })
        return this.speedMap.mappedRunners.runners
      }
      if (this.speedMap && this.speedMap.defaultRunners) {
        const defaultRunners = [...this.speedMap.defaultRunners]
        const runners = defaultRunners.sort((a, b) => a.barrier - b.barrier)
        // map runners into 12 columns and 8 rows
        const cols = []
        for (let i = 0; i < 12; i++) {
          if (!cols[i]) cols[i] = []
          runners.forEach(runner => {
            if (runner.speedmapPosition && SPEEDMAP_LABELS[runner.speedmapPosition].toString() === (i).toString()) {
              // if row is full start filling the next one
              if (cols[i].length >= 8) {
                if (cols[i + 1]) {
                  cols[i + 1].push(runner)
                } else {
                  cols[i + 1] = []
                  cols[i + 1].push(runner)
                }
              } else {
                cols[i].push(runner)
              }
            }
          })
          while (cols[i].length < 8) {
            cols[i].push({})
          }
        }
        const result = cols[0].map((col, i) => cols.map(row => row[i])).reverse()
        if (this.getTrackDirection() === 'clockwise') {
          return result.map(row => row.reverse())
        } else if (this.getTrackDirection() === 'anti-clockwise') {
          return result
        } else {
          return result
        }
      }
      return []
    },
    getSpeedMapLabels: function () {
      const labels = [
        'Rear',
        'Mid',
        'Handy/Mid',
        'Handy',
        'Lead/Handy',
        'Lead'
      ]
      if (this.getTrackDirection() === 'clockwise') {
        return labels
      } else if (this.getTrackDirection() === 'anti-clockwise') {
        return labels.reverse()
      } else {
        return labels
      }
    },
    getTrackDirection: function () {
      if (this.meeting.track.country === 'AUS') {
        const key = `${this.meeting.track.country}/${this.meeting.track.state}`
        return TRACKDIRECTION[key]
      }
      return ''
    },
    comments: function () {
      if (this.speedMap && this.speedMap.comments) {
        return this.speedMap.comments
      }
      return ''
    },
    updateComments: function (value) {
      const speedMap = { ...this.speedMap }
      speedMap.comments = value
      this.saveSpeedMap({ id: this.race.id, speedMap })
    },
    resetToDefault: function () {
      if (this.resetConfirm) {
        this.resetSpeedMap({ id: this.race.id })
      }
      this.resetConfirm = !this.resetConfirm
      if (this.resetConfirm) {
        setTimeout(() => { this.resetConfirm = false }, 5000)
      }
    },
    handleDrop: function (target, item) {
      const mr = this.getMappedRunners()
      const rl = this.getExtraRunners()

      if (target.type === 'speedMapTable') {
        if (item.type === 'speedMapTableItem') {
          const movedRunner = mr[item.index.y].splice(item.index.x, 1, {})
          mr[target.index.y].splice(target.index.x, 1, movedRunner[0])
        }
        if (item.type === 'speedMapListItem') {
          const movedRunner = rl.splice(item.index.y, 1, {})
          mr[target.index.y].splice(target.index.x, 1, movedRunner[0])
        }
      }
      if (target.type === 'speedMapList') {
        if (item.type === 'speedMapTableItem') {
          const movedRunner = mr[item.index.y].splice(item.index.x, 1, {})
          rl.splice(target.index.y, 1, movedRunner[0])
        }
        if (item.type === 'speedMapListItem') {
          const movedRunner = rl.splice(item.index.y, 1, {})
          rl.splice(target.index.y, 1, movedRunner[0])
        }
      }
      const mappedRunnersObject = { race: `${this.race.id}`, runners: mr }
      const mappedExtraRunners = { race: `${this.race.id}`, runners: rl }
      this.updateSpeedMap(mappedRunnersObject, mappedExtraRunners)
    },
    updateSpeedMap: function (mappedRunners, extraRunners) {
      const comments = this.speedMap && this.speedMap.comments ? this.speedMap.comments : ''
      const speedMap = { mappedRunners, extraRunners, comments }
      this.saveSpeedMap({ id: this.race.id, speedMap })
    },
    sameRace: function (mappedRunners, runners) {
      const key = `${this.race.id}`
      return key === mappedRunners.race
    },
    printSpeedMap () {
      const d = new Printd()
      const cssText = `
        .noSpeedTable-container {
          display: none;
        }
        span {
          display: none;
        }
        .speedMap-container {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 13px;
          padding-bottom: 0;
        }
        .speedMap-labels {
          display: flex;
          justify-content: space-between;
        }
        .speedMap-mapNotes {
          display: flex;
          justify-content: space-between;
        }
        .speedMap-mapNotes h2 {
          width: 100px
        }
        .speedMap-mapNotes textarea {
          height: 70px;
        }
        .speedMap-mapNotes button {
          width: 100px;
          height: 100px;
          margin-left: 10px;
        }
        .speedMap-mapNotes .v-label {
          min-height: 30px;
        }
        table {
          border-collapse: collapse;
        }

        .speedTable td {
          width: 97px;
          height: 69px;
          max-width: 100px;
        }
        .speedTable-runner {
          padding-left: 2px;
          cursor:pointer;
        }
        .speedTable-runner :hover {
          background:#efefef;
        }
        .speedTable td div {
          max-width: 100%;
          max-height: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
        .speedTable-runner span {
          font-size: 13px;
        }
        .speedTable-runner .runnerNumber {
          font-size: 13px;
          margin: 0 15% 0 5px;
          float:left;
        }
        .speedTable-runner .runnerBar {
          font-size: 13px;
          margin: 0 15% 0 5px;
          float:left;
          color: red;
        }
        .noSpeedTable-container {
          max-height: 550px;
          overflow: scroll;
          overflow-x: hidden;
          border-bottom: 1px solid #666;
        }
        .noSpeedTable td {
          width: 200px;
          height: 30px;
          padding: 2px;
        }
        .speedMap-labels button {
          width: 100px;
          height: 70px;
        }
        .speedMap-labels-map button span {
        }
        .speedMap-labels-firstStarters button {
          width: 200px;
          height: 70px;
        }
        .speedMap-labels-firstStarters button span {
          max-width: 100%;
          white-space: normal;
          color: grey;
        }
        .speedMap-footer {
          display: flex;
          justify-content: space-between;
        }
        .iconWidget-HarnessRacing {
          background-size: cover;
          background-image: url('~@/assets/race_types/DO_RaceType_Harness-BLACK.svg');
          display: inline-block;
          height: 15px;
          width: 27px;
          margin-left: 5px;
        }
        .iconWidget-GreyHoundRacing {
          background-size: cover;
          background-image: url('~@/assets/race_types/DO_RaceType_Greyhounds-BLACK.svg');
          display: inline-block;
          height: 15px;
          width: 27px;
          margin-left: 5px;
        }
        .iconWidget-HorseRacing {
          background-size: cover;
          background-image: url('~@/assets/race_types/DO_RaceType_Gallops-BLACK.svg');
          display: inline-block;
          height: 15px;
          width: 27px;
          margin-left: 5px;
        }
        .saveIcon {
          background-size: cover;
          background-image: url('~@/assets/save-24px.svg');
          display: inline-block;
          height: 15px;
          width: 27px;
          margin-left: 5px;
        }
        .savedDialog {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .savedDialog-text {
          font-weight: bold;
        }
        .resetDialog {
          padding: 10px;
        }
      `
      d.print(document.getElementById('myelement'), [ cssText ])
    }
  },
  async created () {
    this.loading = true
    await this.saveCoreToken()
    await this.selectRace({ id: this.$route.params.raceID })
    window.localStorage.removeItem('token_core_api')
    this.loading = false
  }
}
</script>

<style>
.speedMap-container {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 0;
}
.speedMap-labels {
  display: flex;
  justify-content: space-between;
}
.speedMap-mapNotes {
  display: flex;
  justify-content: space-between;
}
.speedMap-mapNotes h2 {
  width: 100px
}
.speedMap-mapNotes textarea {
  height: 70px;
}
.speedMap-mapNotes button {
  width: 100px;
  height: 100px;
  margin-left: 10px;
}
.speedMap-mapNotes .v-label {
  min-height: 30px;
}
table {
  border-collapse: collapse;
}

.speedTable td {
  width: 97px;
  height: 69px;
  max-width: 100px;
}
.speedTable-runner {
  padding-left: 2px;
  cursor:pointer;
}
.speedTable-runner :hover {
  background:#efefef;
}
.speedTable td div {
  max-width: 100%;
  max-height: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.speedTable-runner span {
  font-size: 13px;
}
.speedTable-runner .runnerNumber {
  font-size: 13px;
  margin: 0 15% 0 5px;
  float:left;
}
.speedTable-runner .runnerBar {
  font-size: 13px;
  margin: 0 15% 0 5px;
  float:left;
  color: red;
}
.noSpeedTable-container {
  max-height: 550px;
  overflow: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid #666;
}
.noSpeedTable td {
  width: 200px;
  height: 30px;
  padding: 2px;
}
.speedMap-labels button {
  width: 100px;
  height: 70px;
}
.speedMap-labels-map button span {
}
.speedMap-labels-firstStarters button {
  width: 200px;
  height: 70px;
}
.speedMap-labels-firstStarters button span {
  max-width: 100%;
  white-space: normal;
  color: grey;
}
.speedMap-footer {
  display: flex;
  justify-content: space-between;
}
.iconWidget-HarnessRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Harness-BLACK.svg');
  display: inline-block;
  height: 15px;
  width: 27px;
  margin-left: 5px;
}
.iconWidget-GreyHoundRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Greyhounds-BLACK.svg');
  display: inline-block;
  height: 15px;
  width: 27px;
  margin-left: 5px;
}
.iconWidget-HorseRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Gallops-BLACK.svg');
  display: inline-block;
  height: 15px;
  width: 27px;
  margin-left: 5px;
}
.saveIcon {
  background-size: cover;
  background-image: url('~@/assets/save-24px.svg');
  display: inline-block;
  height: 15px;
  width: 27px;
  margin-left: 5px;
}
.savedDialog {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.savedDialog-text {
  font-weight: bold;
}
.resetDialog {
  padding: 10px;
}

</style>
