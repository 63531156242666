<template>
  <div class="bestOdds-header">
    <h2 style="font-size: 13px;" class="ml-n3" >BEST ODDS</h2>
    <div :class="this.acMode ? 'bestOddsFilters acmode' : 'stdMode bestOddsFilters'" style="width:100%;" v-on:click.stop >
      <v-checkbox dense class="bestPriceCheckBox float-left mr-2 meetingTypesFilter mb-0" :ripple="false" hide-details v-model="selected" value="WIN_FIXED_ODDS"><span slot="label" class="font-weight-bold checkBoxTextBp">Incl. Fixed</span></v-checkbox>
      <v-checkbox dense class="bestPriceCheckBox float-left mr-2 meetingTypesFilter mb-0" :ripple="false" hide-details v-model="selected" value="WIN"><span slot="label" class="font-weight-bold checkBoxTextBp">Incl. Tote</span></v-checkbox>
      <v-checkbox @click="onCheckbox" dense class="bestPriceCheckBox float-left mr-2 meetingTypesFilter" :ripple="false" hide-details v-model="selected" value="ALL_BOOKIES"><span slot="label" class="font-weight-bold checkBoxTextBp">Incl. All</span></v-checkbox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'BestPriceColumnHeader',
  data: () => ({
  }),
  props: {
    column: Object,
    columnNumber: Number,
    acMode: Boolean
  },
  computed: {
    ...mapGetters(['getSelectedColumns', 'getSelectedColumnsName']),
    selected: {
      get () {
        return this.column.filters
      },
      set (value) {
        if (value.includes('ALL_BOOKIES')) {
        } else {
          return this.updateFilters(value)
        }
      }
    }
  },
  methods: {
    ...mapActions(['updateSelectedColumns']),
    updateFilters: function (value) {
      const columns = this.getSelectedColumns
      if (columns[this.columnNumber].filters) {
        columns[this.columnNumber].filters = value
      }
      this.updateSelectedColumns({ columns, name: this.getSelectedColumnsName })
    },
    onCheckbox (event) {
      const isChecked = event.target.getAttribute('aria-checked') === 'true'
      if (isChecked) {
        return this.updateFilters([])
      } else {
        return this.updateFilters(['ALL_BOOKIES', 'WIN_FIXED_ODDS', 'WIN'])
      }
    }
  }
}
</script>

<style scoped>
.bestPriceCheckBox {
    font-size: 10px !important;
    line-height: 5px;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: -5px !important;
  }
  .bestOdds-header {
    display: flex;
    flex-direction: column;
    text-align:center;
    width:95px;
    font-size:10px;
    line-height:14px;
    padding:2px;
    padding-top:4px;
    height:100%;
    /* background-image: linear-gradient(black, grey); */
    background-color: #fff;
  }
  .bestOddsFilters {
    display: flex;
    flex-direction: column;
  }
  .checkBoxTextBp {
    font-size: 10px;
  }

  .bestPriceCheckBox >>> i {
    font-size: 16px !important;
  }
  .bestPriceCheckBox >>> .v-input__slot {
    margin-bottom: -5px !important;
  }
</style>
