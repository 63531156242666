<template>
  <div>
    <template>
      <v-btn :ripple="false" text @click="loadBetHistory" style="height: 100%; padding-right: 25px" class="pa-0 px-1 mr-3">
        <v-icon class="mr-1" color="orange" small>mdi-timetable</v-icon>
        <span style="font-size: 12px;">Bet History</span>
      </v-btn>
    </template>
    <v-dialog v-model="dialog" transition="none" scrollable max-width="1500px" hide-overlay>
      <v-card>
        <v-toolbar color="primary" dark dense>
          <div class="titleBar-content">
            <v-toolbar-title class="raceResults-title"> Bet History </v-toolbar-title>
            <v-btn class="cancelButton" icon small ><v-icon class="white--text" @click="dialog = false">mdi-close</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title style="display: inline-flex;">
          <span style="display: inline-flex;">
            <p class="titleMenu">Show:</p>
            <v-btn outlined x-small v-for="option in showOptions" :key="option.value" :value="option.value" class="ma-1 float-left todayButton" @click="selectShowOption(option.value)" :color="showOptionSelected === option.value ? 'info' : 'normal'">{{ option.text }}</v-btn>
          </span>
          <span style="display: contents;">
            <p class="titleMenu statusTitle">Status:</p>
            <v-checkbox label="Sent" dense class="statusBetHistory" v-model="selected" :value="9" hide-details></v-checkbox>
            <v-checkbox label="Errored" dense class="statusBetHistory" v-model="selected" :value="4" hide-details></v-checkbox>
            <v-checkbox label="Unknown" dense class="statusBetHistory" v-model="selected" :value="6" hide-details></v-checkbox>
          </span>
          <span style="display: contents;">
            <v-text-field
              placeholder="Search"
              outlined
              v-model="search"
              height="25px"
              @input="searchResults()"
              class="float-left"
              hide-details
              style="margin-top: -17px;"
            ></v-text-field>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table dense :loading="betHistory.length === 0" class="tableBetHistory" :items="betHistory" :headers="headers" hide-default-footer :items-per-page=9999>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-icon small color="#cfb53b" v-if="item.win_plc === 'W' && getPosition(item)=== 1 || item.win_plc === 'P' && (getPosition(item)=== 2 || getPosition(item)=== 3)">mdi-trophy</v-icon>
                </td>
                <td>
                  {{ getDate(item) }}
                </td>
                <td>{{ BOOKMAKER_NAMES_LOOKUP[item.bookieCode] }}</td>
                <td><img v-if="item.meeting.type !== ''" :src="raceTypeImg[item.meeting.type]" style="max-width:20px" /></td>
                <td @click="goToRace(item)">
                  {{ `${item.meeting.track.name} R${item.number}` }}
                </td>
                <td>
                  {{ item.tabNo }} - {{ item.runnerName}}
                  <div v-if="getPosition(item)&& getPosition(item)> 0" style="float:right;-webkit-flex: 1;-moz-flex: 1;flex: 1;">
                    <span v-if="getPosition(item)=== 1" class="placingFirst">{{ getPosition(item)}}st</span>
                    <span v-else-if="getPosition(item)=== 2" class="placingSecond">{{ getPosition(item)}}nd</span>
                    <span v-else-if="getPosition(item)=== 3" class="placingThird">{{ getPosition(item)}}rd</span>
                    <span v-else class="placingFourth">{{ getPosition(item)}}th</span>
                  </div>
                </td>
                <td>${{ getStake(item) }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.betType }}</td>
                <td>{{ BET_STATUS_CODE_ENG[item.status] }}</td>
                <td>{{ item.agency_bet_id }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn small @click="exportToCSV">
            <v-icon small style="margin-right: 6px;">mdi-content-save-all</v-icon>
            Export Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loginDialog" max-width="500" hide-overlay>
      <v-card  class="bettingAgencyLogin" v-on:keyup.enter="enableBettingLogin(logedinUsername, password)">
        <v-list-item class="warning">
          <v-list-item-content class="ml-3 white--text">
            <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-account-key-outline</v-icon>Account Security</v-list-item-title>
          </v-list-item-content>
          <v-btn icon small @click="closeDialog"><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-list-item>
        <v-container fluid class="text-center">
          <span class="subtitle-1 ">
            To enable betting we need to confirm it's you<br>
            Please re-enter your <em>Dynamicodds</em> password
            <br>
          </span>
          <div v-if="error" class="subtitle-1 errorMessage">
                {{ error }}
          </div>
          <v-card class="loginDetails mt-5" elevation="0">
            <v-text-field
              class="username"
              label="Username"
              placeholder="Username"
              style="max-width:150px;"
              :value="logedinUsername"
            ></v-text-field>
            <v-text-field
              class="password"
              label="Password"
              placeholder="Password"
              style="max-width:150px;"
              type="password"
              v-model="password"
            ></v-text-field>
          </v-card>
          <div class="loginActions">
            <v-btn type="submit" class="loginButton" color="success" @click="enableBettingLogin(logedinUsername, password)">
              Login
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BET_STATUS_CODE_ENG, BOOKMAKER_NAMES_LOOKUP } from '../common/constants'
import { ExportToCsv } from 'export-to-csv'
import { format, fromUnixTime } from 'date-fns'
// import Vue from 'vue'

export default {
  name: 'BetHistory',
  data: () => ({
    BET_STATUS_CODE_ENG,
    BOOKMAKER_NAMES_LOOKUP,
    dialog: false,
    headers: [
      {
        value: 'resultBet', class: 'betHistoryheader', sortable: false
      },
      {
        text: 'Date/Time', value: 'dateFormatted', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Agency', value: 'agencyName', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Type', value: 'meetingType', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Race', value: 'race', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Runner', value: 'runnerName', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Stake', value: 'stake', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Odds', value: 'price', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Product', value: 'bet_type_description', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Status', value: 'status', class: 'betHistoryheader', align: 'center'
      },
      {
        text: 'Agency Bet ID', value: 'agency_bet_id', class: 'betHistoryheader', align: 'center'
      }
    ],
    betPending: false,
    betSuccess: true,
    betFailed: false,
    betStatus: [9, 4, 6],
    showOptions: [
      {
        text: 'Today',
        value: 'today'
      },
      {
        text: 'Yesterday',
        value: 'yesterday'
      },
      {
        text: '3 Days',
        value: '3'
      },
      {
        text: '7 Days',
        value: '7'
      },
      {
        text: '30 Days',
        value: '30'
      },
      {
        text: '60 Days',
        value: '60'
      },
      {
        text: '90 Days',
        value: '90'
      }
    ],
    showOptionSelected: 'today',
    loginDialog: false,
    username: null,
    password: null,
    error: null,
    search: '',
    raceTypeImg: {
      HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
      GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
      THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
    }
  }),
  computed: {
    ...mapGetters('betting', ['getAllBetHistory', 'getBetHistory', 'getBetHistoryRace', 'getBetHistoryByDate']),
    ...mapGetters('account', ['getUsername', 'getSessionId']),
    betHistory: function () {
      var bets = this.getAllBetHistory(this.showOptionSelected, this.search).filter(bet => {
                  return BOOKMAKER_NAMES_LOOKUP[bet.bookieCode]?.toLowerCase().includes(this.search.toLowerCase()) || bet.meeting?.track?.name?.toLowerCase().includes(this.search.toLowerCase()) || bet.runnerName?.toLowerCase().includes(this.search.toLowerCase()) || bet.meeting?.type?.toLowerCase().includes(this.search.toLowerCase()) || BET_STATUS_CODE_ENG[bet.status]?.toLowerCase().includes(this.search.toLowerCase())
                })
      // console.log(bets)
      if (this.selected.length < 3) {
        bets = bets.filter(bet => {
          if (bet.status === 9 || bet.status === 4 || bet.status === 6) {
            if (this.selected.indexOf(bet.status) !== -1) return bet
          }
        })
      }
      return bets
    },
    selected: {
      get () {
        return this.betStatus
      },
      set (value, newValue) {
        return this.onClick(value)
      }
    },
    logedinUsername: function () {
      return this.getUsername
    }
  },
  methods: {
    ...mapActions('betting', ['fetchBetHistory', 'fetchBetHistoryRace', 'fetchBetHistoryByDate', 'searchBetHistory']),
    ...mapActions(['loginBetting', 'selectRace']),
    ...mapGetters(['getAvailableAgencies']),
    ...mapActions('account', ['confirmSignin']),
    format,
    fromUnixTime,
    goToRace (item) {
      this.selectRace({ id: item.id })
    },
    getPosition (item) {
      const competitor = item.competitors.find(c => c.tabNo === item.tabNo)
      return competitor?.finalPosition || 0
    },
    getDate (item) {
      return item.date_time ? format(fromUnixTime(item.date_time?.seconds), 'dd/MM/yyyy HH:mm:ss') : null
    },
    getStake (bet) {
      const stake = bet?.bet?.['partial_stake'] || bet.stake
      return stake
    },
    async loadBetHistory () {
      const sessionID = this.getSessionId
      // const sessionID = Vue.$cookies.get('sessionId')
      if (sessionID === null) {
        this.loginDialog = true
      } else {
        await this.fetchBetHistoryByDate({ day: this.showOptionSelected })
        await this.fetchBetHistory()
        this.dialog = true
      }
    },
    onClick (value) {
      this.betStatus = value
    },
    async selectShowOption (value) {
      this.showOptionSelected = value
      await this.fetchBetHistoryByDate({ day: value })
      // await this.fetchBetHistory()
    },
    closeDialog () {
      this.loginDialog = false
    },
    async enableBettingLogin (username, password) {
      const result = await this.confirmSignin({ username, password })
      if (result.success) {
        this.loginBetting()
        // await this.fetchBetHistoryByDate({ day: this.showOptionSelected })
        await this.fetchBetHistory()
        this.dialog = true
        this.loginDialog = false
      } else {
        this.error = result.message
      }
    },
    exportToCSV () {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        headers: ['Date/Time', 'Agency', 'Type', 'Race', 'Runner', 'Stake', 'Odds', 'Product', 'Status', 'Agency Bet ID']
      }

      var data = []

      this.betHistory.forEach(bet => {
        data.push({
          date_time: bet.dateFormatted,
          bettingAgency: bet.agencyName,
          meetingType: bet.meeting.type,
          eventDetails: bet.meeting.track.name + ' R' + bet.number,
          selection: bet.selection,
          stake: bet.stake,
          odds: bet.price,
          betType: bet.betType,
          betStatus: bet.betStatus,
          agencyBetID: typeof bet.agency_bet_id === 'undefined' ? '' : bet.agency_bet_id
        })
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    searchResults () {
      this.searchBetHistory({ search: this.search })
    }
  }
}
</script>

<style>
  .statusBetHistory {
    margin-top: -21px !important;
    margin-right: 15px;
  }

  .titleMenu {
    font-size: 15px !important;
    margin-bottom: 0px;
  }

  .statusTitle {
    margin-left: 15px;
  }

  .betHistoryheader .v-data-table-header__icon {
    opacity: 1;
  }

  .tableBetHistory td {
    padding: 0px 12px !important;
  }
</style>
