<template>
  <div >
    <div class="betslip-price-input-section" v-if="isBetfairLay || isBetfairBack">
      <v-text-field class="betslip-input stakeInput" autofocus @focus="handleFocus" outlined label="Stake" :rules="[() => !showMax || setMaxMessage]" @change="recalculate" height="40px" placeholder=0 :value="computedStake" @input="handleStakeInput"></v-text-field>
      <v-text-field v-show="isBetfairLay" class="betslip-input profitInput" @focus="handleProfitFocus" outlined label="Liability" @change="recalculate" height="40px" placeholder=0 :value="computedProfit" @input="handleProfitInput" :disabled="!price && !userLayPrice"></v-text-field>
      <v-text-field v-show="isBetfairBack" class="betslip-input returnInput" outlined label="Return" @change="recalculate" height="40px" placeholder=0 :value="computedReturn" @input="handleReturnInput" :disabled="!price && !userLayPrice"></v-text-field>
      <v-text-field class="layPrice" height="40px" outlined label="Odds" placeholder="1" v-model="layPrice" type="number"></v-text-field>
    </div>
    <div class="betslip-price-input-section" v-else>
      <v-text-field class="betslip-input stakeInput" autofocus @focus="handleFocus" outlined label="Stake" :rules="[() => !showMax || setMaxMessage]" @change="recalculate" height="40px" placeholder=0 :value="computedStake" @input="handleStakeInput"></v-text-field>
      <v-text-field class="betslip-input returnInput" outlined label="Return" @change="recalculate" height="40px" placeholder=0 :value="computedReturn" @input="handleReturnInput" :disabled="!price"></v-text-field>
      <v-text-field class="betslip-input profitInput" @focus="handleProfitFocus" outlined label="Profit" @change="recalculate" height="40px" placeholder=0 :value="computedProfit" @input="handleProfitInput" :disabled="!price"></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetSlipPriceInputs',
  data () {
    return {
      stake: null,
      returnAmount: null,
      profitAmount: null,
      selectedInput: 'stake',
      showMax: false,
      setMaxMessage: '',
      userInput: false,
      userLayPrice: null
    }
  },
  props: {
    setStake: Function,
    price: Number,
    defaultStake: Number,
    maxBet: Number,
    defaultProfit: Number,
    isBetfairLay: Boolean,
    isBetfairBack: Boolean,
    setLayPrice: Function
  },
  computed: {
    computedStake: function () {
      if (!this.price) return this.stake || this.defaultStake || 1
      if (this.selectedInput === 'stake') {
        if (this.userInput) return this.stake
        if (!this.userInput && this.defaultProfit) return Math.floor(this.defaultProfit / (this.price - 1))
        return this.stake || this.defaultStake || 0
      }
      // var setDefStake = Number(this.getBetLimits[0])
      // var setProfit = Number(this.getBetLimits[1])
      // if (!this.price) return this.stake
      // if (this.selectedInput === 'stake') {
      //   if (setDefStake !== 0 && setProfit === 0) {
      //     // this.handleStakeInput(setDefStake)
      //     // this.recalculate()
      //     return setDefStake
      //   }
      //   return this.stake
      // }

      if (this.selectedInput === 'return') {
        if (!this.returnAmount) return null
        const stake = Math.floor(this.returnAmount / this.price)
        return stake >= 1 ? stake : 1
      }
      if (this.selectedInput === 'profit') {
        return this.profitAmount ? Math.floor(this.profitAmount / (this.price - 1)) : null
      }
      return null
    },
    computedReturn: function () {
      if (!this.price && !this.userLayPrice) return null
      const price = this.userLayPrice || this.price
      if (!this.userInput && this.defaultProfit) return (this.computedStake * price).toFixed(2)
      if (this.selectedInput === 'stake') {
        return (this.computedStake) ? (this.computedStake * price).toFixed(2) : null
      }
      if (this.selectedInput === 'return') {
        return this.returnAmount
      }
      if (this.selectedInput === 'profit') {
        return this.computedStake ? (this.computedStake * price).toFixed(2) : null
      }
      return null
    },
    computedProfit: function () {
      var setProfit = this.isBetfairLay ? 0 : Number(this.defaultProfit)
      // if (!this.computedReturn && setProfit ** !this.computedStake) {
      //   // this.handleProfitInput(setProfit)
      //   // this.recalculate()
      //   return this.profitAmount
      // }
      if (!this.userInput && this.defaultProfit) return setProfit
      if (this.selectedInput === 'profit' && this.computedStake) {
        return this.profitAmount || setProfit
      }
      if (!this.computedReturn) return null
      if (this.selectedInput === 'stake') {
        return this.computedStake ? (this.computedReturn - this.computedStake).toFixed(2) : null
      }
      if (this.selectedInput === 'return') {
        return this.returnAmount ? (this.returnAmount - this.computedStake).toFixed(2) : null
      }
      if (this.selectedInput === 'profit') {
        return this.profitAmount || setProfit
      }
      return null
    },
    layPrice: {
      get () {
        return this.price
      },
      set (value) {
        this.userLayPrice = value
      }
    },
    // setAutoFocusStake: function () {
    //   var setDefStake = Number(this.defaultStake)
    //   var setProfit = Number(this.defaultProfit)
    //   if (setProfit && !userInput)
    //   if (setDefStake !== 0 && setProfit === 0) return true
    //   if (setDefStake === 0 && setProfit === 0) return true
    //   return false
    // },
    setAutoFocusProfit: function () {
      if (this.isBetfairLay) return false
      var setProfit = Number(this.defaultReturn)
      if (setProfit !== 0) return true
      return false
    }
  },
  methods: {
    recalculate: function () {
      if (this.selectedInput === 'profit') {
        this.returnAmount = (this.computedStake * this.price).toFixed(2)
        this.profitAmount = (this.computedReturn - this.computedStake).toFixed(2)
      }
      if (this.selectedInput === 'return') {
        this.returnAmount = (this.computedStake * this.price).toFixed(2)
        this.profitAmount = (this.returnAmount - this.computedStake).toFixed(2)
      }
    },
    handleStakeInput: function (value) {
      this.userInput = true
      this.selectedInput = 'stake'
      this.stake = value
    },
    handleReturnInput: function (value) {
      this.userInput = true
      this.selectedInput = 'return'
      this.returnAmount = value
      // this.profitAmount = ((this.computedStake * this.price) - this.computedStake).toFixed(2)
    },
    handleProfitInput: function (value) {
      this.userInput = true
      this.selectedInput = 'profit'
      this.profitAmount = value
      this.returnAmount = (this.computedStake * this.price).toFixed(2)
    },
    handleFocus: function (e) {
      setTimeout(() => {
        e.target.select()
      }, 100)
    },
    handleProfitFocus: function () {
      if (!this.userInput && this.defaultProfit > 0) {
        this.selectedInput = 'profit'
        this.profitAmount = this.defaultProfit
      }
    }
  },
  watch: {
    computedStake: function (value) {
      var setMax = Number(this.maxBet)
      this.setMaxMessage = 'Exceeds Max Default of $' + setMax
      if (setMax > value) {
        this.showMax = false
        this.setStake(value)
      } else if (setMax === 0) {
        this.showMax = false
        this.setStake(value)
      } else if (setMax !== 0 && setMax < value) {
        this.showMax = true
        this.setStake(0)
      } else if (setMax === Number(value)) {
        this.showMax = false
        this.setStake(value)
      } else {
        this.showMax = false
      }
    },
    price: function () {
      this.recalculate()
    },
    userLayPrice: function (value) {
      this.setLayPrice(value)
    }
    // defaultProfit: function (newAmount, oldAmount) {
    //   if (this.defaultProfit > 0) {
    //     this.profitAmount = this.defaultProfit
    //   }
    //   this.recalculate()
    // },
    // defaultStake: function () {
    //   this.recalculate()
    // },
    // maxBet: function () {
    //   this.recalculate()
    // }
  },
  mounted () {
    if (this.defaultStake) {
      this.setStake(this.defaultStake)
    }
  }
}
</script>

<style scoped>
.betslip-input {
  max-width: 90px;
}
.betslip-price-input-section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.layPrice {
  max-width: 90px;
  height: 40px;
}
</style>
