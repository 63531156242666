<template>
  <div>
    <div :class="overflowedList ? 'raceTableColumnContainer-overflowed' : 'raceTableColumnContainer'">
      <div :class="['raceTableColumnMainContainer', 'columnWidth-' + source]">
        <div :class="acMode ? 'raceTableColumn-priceAcMode' : 'raceTableColumn'" :style="getColumnStyle(index)">
          <div>
            <div v-if="editMode" class="raceHeaderCol">
              <div :class="['raceTableColumn-header']" >
                <div :class="['bestPriceColumn save-font']">
                  <span class="text-success">{{ column.source }}</span>
                </div>
                <div class="columnActions editingDecorations">
                  <AddColumnButton :columnLength="columnLength" :position="index"/>
                  <DeleteColumnButton :columnNumber="index"/>
                  <EditColumnButton :columnNumber="index"/>
                </div>
              </div>
            </div>
            <div :class="acMode ? 'raceTableColumn-priceAcMode' : 'raceTableColumn-price'" v-for="(competitor, i) in getRaceCompetitors" :key="`${competitor.tabNo}:${i}`" :style="getColumnStyle(index)">
              <span v-if="!editMode">
                <span v-if="column.source='OCC'" :class="getOCCShift(competitor.tabNo, columnFlucs) > 0 ? 'occ' : 'occ minus'">{{ getOCCShift(competitor.tabNo, columnFlucs) }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getOCCShift } from '../common/methods'
import AddColumnButton from './menus/AddColumnMenu'
import DeleteColumnButton from './menus/DeleteColumnMenu'
import EditColumnButton from './menus/EditColumnMenu'

export default {
  props: {
    id: String,
    editMode: Boolean,
    index: Number,
    columnLength: Number,
    getColumnStyle: Function,
    column: Object,
    overflowedList: Boolean,
    acMode: Boolean,
    source: String
  },
  components: {
    AddColumnButton,
    DeleteColumnButton,
    EditColumnButton
  },
  computed: {
    ...mapGetters(['getRaceCompetitors', 'getColumnFlucsFromKey', 'getColumnFlucs']),
    columnFlucs () {
      return this.getColumnFlucsFromKey(`${this.id}:${this.column.filters.value}:WIN_FIXED_ODDS`)
    }
  },
  methods: {
    getOCCShift
  }
}
</script>

<style scoped>
  .rightAlignPricesDiv {
    text-align: right;
  }
  .occ {
    cursor: pointer;
    min-height:32px;
    max-height:32px;
    line-height:32px;
    text-align:right;
  }
  .minus {
    color: orange;
  }
</style>
