let dragged
let onDrop

export const draggable = {
  inserted: function (el, binding, vnode) {
    onDrop = (newIndex, oldIndex) => vnode.data.on.dropped(newIndex, oldIndex)
    el.draggable = 'true'
    el.addEventListener('dragstart', dragstartHandler)
    el.value = binding.value
  }
}

export const droppable = {
  inserted: function (el, binding) {
    el.ondragover = handleDragOver
    el.ondrop = handleDrop
    el.ondragleave = handleDragLeave
    el.value = binding.value
  }
}

function dragstartHandler (ev) {
  ev.target.style.background = 'rgba(255,255,255, 0.9)'
  dragged = ev
}

function handleDragOver (ev) {
  ev.preventDefault()
  ev.target.style.background = 'rgb(76,175,80, 0.4)'
}

function handleDrop (ev) {
  onDrop(ev.target.value, dragged.target.value)
  ev.target.style.background = ''
}

function handleDragLeave (ev) {
  ev.target.style.background = ''
}
