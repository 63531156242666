<template>
  <!-- template for the modal component -->
  <div>
    <v-dialog v-model="showModal" class="dialogMeetingSummary" persistent scrollable max-width="350px">
      <v-card class="modal-container betSlipContainer" v-moveable>
        <div v-if="raceClosed" class="betslip-popup">
          <v-list-item style="background:#FF5252">
            <v-list-item-content class="ml-3 white--text">
              <v-list-item-title class="caption "><v-icon class="white--text mr-3">mdi-alert-circle-outline</v-icon>Betting on this race has closed</v-list-item-title>
            </v-list-item-content>
            <v-btn icon small @click="$emit('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </v-list-item>
        </div>
        <!-- <div v-else-if="betProcessed" class="betProcessed">
          <v-btn small color="error" @click="$emit('close')">X</v-btn>
          <div>
          <span class="modal-message">{{ betProcessedMessage }}</span>
          </div>
        </div> -->
        <div v-else>
          <v-list-item class="primary">
            <div :class="['spacerHeader-' + bookie]">
              <BookiesLogos :bookieCode="bookie" />
            </div>
            <v-list-item-content class="ml-3 white--text">
              <v-list-item-title class="headline ">Bet slip</v-list-item-title>
              <v-list-item-subtitle class="white--text">{{bookMakerTip(bookie)}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn icon small @click="$emit('close')" class="betslip_close_button"><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </v-list-item>
          <v-card class="pa-4">
            <div class="betStatus">
              <BetStatus v-if="betStatus" :status="betStatus" :additionalMessage="additionalMessage"/>
            </div>
            <v-list-item style="background:#263238" class="pl-6">
              <img :src="getRaceImage()" class="raceImage" />
              <v-list-item-content class="ml-5 white--text">
                <v-list-item-title class="subtitle-2 pa-0">{{ meeting.track.name}} R{{ race.number}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="background:#263238">
              <RaceRunners :meeting="meeting" :race="race" :item="runner" :editMode="false"/>
              <v-list-item-content class="ml-5 white--text">
                <v-list-item-title class="subtitle-2 pa-0">{{ `${runner.tabNo} - ${runner.name}` }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-radio-group v-if="!betSuccess" v-model="computedSelectedType" row class="mb-n3 betType-selectors">
              <v-radio class="betType_win_button" color="info" label="Win" value="W"></v-radio>
              <v-radio class="ml-3 betType_place_button" color="info" label="Place" value="P"></v-radio>
              <v-radio class="ml-3" color="info" label="Each Way" value="EW" disabled></v-radio>
            </v-radio-group>
            <v-card class="bet-type-section">
              <v-list>
                <v-list-item-group v-model="model" active-class="border-selected" mandatory color="blue darken-3">
                  <v-list-item
                    v-for="(betType, i) in filteredBetTypes"
                    :key="betType.bettype_id"
                    class="betType-item"
                    style="cursor:pointer"
                    @click="selectBetType(i)">
                    <v-list-item-content>
                      <v-list-item-title class="subtitle text-left mb-0">{{ getTypeName(betType.win_place_code) + ' - ' + betType.name}}
                        <span v-if="getDisplayedPrice(betType)">${{ getDisplayedPrice(betType) }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="(getDisplayedPrice(betType) && showUpdates) && getPrice(betType) && (getDisplayedPrice(betType) !== getPrice(betType).price)" style="background:#FF6F00;border-radius:4px;" class="mt-2 pa-1 white--text caption">
                        <v-icon class="white--text mr-2">mdi-alert-circle-outline</v-icon>Price changed - ${{ getPrice(betType).price}} {{fractionalPrice(betType)}}<v-btn class="ml-2" @click="selectBetType(i)" x-small color="success">Select</v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <BetSlipPriceInputs v-if="!betSuccess" :price="selectedPrice ? selectedPrice.price : filteredBetTypes[0].price" :setStake="setStake" :defaultStake="getBetLimits[0]" :maxBet="getBetLimits[2]" :defaultProfit="getBetLimits[1]" :isBetfairLay="type.includes('lay')" :isBetfairBack="bookie === 'BF' && type.includes('back')" :setLayPrice="setLayPrice"/>
            <v-switch
              value="processBetsInBG"
              style="margin-left: 20px; margin-right: 20px"
              v-model="betInBG"
              :label="`Process bet in the background`"
            ></v-switch>
            <div v-if="betSuccess" style="{width:100%;text-align:center;}">{{ `Agency Bet Id: ${betId}` }}</div>
            <div>
              <v-list-item v-if="!betSuccess">
                <v-list-item-content class="text-center">
                  <v-list-item-title v-if="!bettingEnabled && !betProcessing" class="subtitle-2 pa-0" style="color:red">Betting disabled. Confirm login to start betting</v-list-item-title>
                  <span v-if="!filteredBetTypes[model]" class="errorMessage">No Products for this Bet Type</span>
                  <v-btn v-if="!bettingEnabled && !betProcessing" small color="success" @click="enableBetting" class="loginBetSlipButton">Login</v-btn>
                  <v-btn x-large v-if="hasFeature('multiBetSlip') && bettingEnabled && !betProcessing && stake && filteredBetTypes[model]" color="warning" class="add_to_betslip_button" @click="addMultiBet">Add to Bet Slip ${{ stake }} </v-btn>
                  <v-btn x-large v-if="bettingEnabled && !betProcessing && stake && filteredBetTypes[model]" color="success" @click="submitBet" class="place_bet_button">Place Bet ${{ stake }} </v-btn>
                  <v-btn x-large v-if="bettingEnabled && !betProcessing && !stake || !filteredBetTypes[model]" color="success" disabled class="place_bet_button">Place Bet</v-btn>
                  <v-btn x-large color="primary" @click="openPriceAlertMenu" class="priceAlertButton">Add to Watchlist and/or <br/>set Price Alert</v-btn>
                </v-list-item-content>
              </v-list-item>
              <v-list class="processing">
                <v-progress-circular
                  v-if="betProcessing"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-list>
            </div>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <PriceAlertMenu v-if="dialogAddToWatchlist"/>
  </div>

  <!-- app -->
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { RACE_TYPE_IMAGE, CONVERT_BET_TYPE, BET_STATUS_SUCCESS, BOOKMAKER_NAMES_LOOKUP, BET_STATUS_CODE } from '../common/constants'
import RaceRunners from './RaceRunners'
import BetStatus from './BetStatus'
import BetSlipPriceInputs from './BetSlipPriceInputs'
import BookiesLogos from './BookiesLogos'
import PriceAlertMenu from './menus/PriceAlertMenu'

export default {
  name: 'BetSlipModal',
  data () {
    return {
      stake: null,
      selectedPrice: {},
      betSuccess: false,
      betProcessedMessage: null,
      additionalMessage: null,
      betProcessing: false,
      model: 0,
      selectedType: null,
      selectedInput: 'stake',
      betId: null,
      layPrice: null
    }
  },
  props: {
    runner: Object,
    race: Object,
    meeting: Object,
    price: Number,
    bookie: String,
    enableBetting: Function,
    type: String,
    betTypes: Array,
    testMode: Boolean,
    gridSettings: Array,
    priceType: String,
    showModal: Boolean
  },
  components: {
    RaceRunners,
    BetStatus,
    BetSlipPriceInputs,
    BookiesLogos,
    PriceAlertMenu
  },
  computed: {
    ...mapGetters(['getSourcePrices', 'hasFeature', 'getBetLimits', 'getBetfairPrice', 'getGridSettings']),
    ...mapGetters('betting', ['getBetHistory']),
    ...mapGetters('account', ['authConfig', 'getSessionId']),
    ...mapState(['dialogAddToWatchlist']),
    showFractionalOdds: function () {
      return this.getGridSettings.includes('fractionalOdds')
    },
    computedSelectedType: {
      get () {
        if (!this.selectedType) {
          if (this.bookie === 'BF') {
            if (this.priceType === 'WIN') {
              return 'W'
            } else if (this.priceType === 'PLACE') {
              return 'P'
            }
          }
          if (this.type.includes('WIN')) {
            return 'W'
          } else if (this.type.includes('PLACE')) {
            return 'P'
          }
        }
        return this.selectedType
      },
      set (value) {
        this.selectedType = value
      }
    },
    betInBG: {
      get () {
        return this.gridSettings
      },
      set (value) {
        return this.onClick('gridSettings', value)
      }
    },
    bettingInBG () {
      if (!this.gridSettings.includes('processBetsInBG')) return false
      return true
    },
    showUpdates: function () {
      return !this.betProcessing && !this.betProcessed
    },
    bettingEnabled: function () {
      return !!this.getSessionId
    },
    raceClosed: function () {
      return this.race.status !== 'OPEN'
    },
    currentPrice: function () {
      const key = `${this.bookie.toLowerCase()}:${this.type}:price`
      const runner = this.race.competitors.find(c => c.tabNo === this.runner.tabNo)
      const price = runner[key]
      return price
    },
    selectedBetType: function () {
      const betType = this.filteredBetTypes[this.model]
      const type = CONVERT_BET_TYPE[`${betType.name}_${betType.win_place_code}`]
      return type
    },
    filteredBetTypes: function () {
      if (!this.selectedType && this.bookie !== 'BF') {
        if (this.type.includes('WIN')) {
          return this.betTypes.filter(t => t.win_place_code === 'W').sort((a, b) => a.name === 'Fixed' ? -1 : 1)
        } else if (this.type.includes('PLACE')) {
          return this.betTypes.filter(t => t.win_place_code === 'P').sort((a, b) => a.name === 'Fixed' ? -1 : 1)
        }
      }
      if (!this.betTypes) return []
      if (this.bookie === 'BF') {
        if (this.type.includes('back') || this.type === 'projected-sp') {
          if (this.computedSelectedType === 'W') {
            return this.betTypes.filter(b => b.win_place_code === 'W' && b.name.includes('Back'))
          }
          if (this.computedSelectedType === 'P') {
            return this.betTypes.filter(b => b.win_place_code === 'P' && b.name.includes('Back'))
          }
          // return this.betTypes.filter(t => t.win_place_code === 'W' && t.name.includes('Back')).sort((a, b) => a.name === 'Fixed' ? -1 : 1)
        }
        if (this.type.includes('lay')) {
          if (this.computedSelectedType === 'W') {
            return this.betTypes.filter(b => b.win_place_code === 'W' && b.name.includes('Lay'))
          }
          if (this.computedSelectedType === 'P') {
            return this.betTypes.filter(b => b.win_place_code === 'P' && b.name.includes('Lay'))
          }
        }
      }
      if (this.selectedType === 'W') {
        return this.betTypes.filter(t => t.win_place_code === 'W').sort((a, b) => a.name === 'Fixed' ? -1 : 1)
      }
      if (this.selectedType === 'P') {
        return this.betTypes.filter(t => t.win_place_code === 'P').sort((a, b) => a.name === 'Fixed' ? -1 : 1)
      }
      return null
    },
    prices: function () {
      const priceSet = this.getSourcePrices(this.bookie, this.runner.tabNo, this.race.id)
      return priceSet
    },
    betStatus: function () {
      const betHistory = this.getBetHistory
      const bet = betHistory?.find(b => b.bet.bet_id === this.betId)
      return bet ? BET_STATUS_CODE[bet?.bet?.['status_code']] : this.betProcessedMessage
      // if (!this.betId || !this.betSuccess) {
      //   return this.betProcessedMessage
      // } else {
      // }
    }
  },
  methods: {
    ...mapActions('account', ['updateAuthToken']),
    ...mapActions('betting', ['selectBet', 'loadBetSlip', 'placeBet']),
    ...mapActions(['updateRaceGridSettings', 'addToWatchListSwitch']),
    onClick (filter, setting) {
      this.updateRaceGridSettings({ filter: filter, value: setting })
    },
    show () {
      this.$modal.show('price-changed-modal')
    },
    showBR () {
      this.$modal.show('bet-rejected-modal')
    },
    showPartialAccept () {
      this.$modal.show('partially-accepted-modal')
    },
    openPriceAlertMenu () {
      this.$emit('close')
      this.addToWatchListSwitch({ meetingId: this.meeting.id, raceNumber: this.race.number, runnerNumber: this.runner.tabNo, meetingDate: this.meeting.date, name: this.runner.name, price: this.price, bookie: this.bookie })
    },
    addMultiBet () {
      const selectedBet = this.filteredBetTypes[this.model]
      this.loadBetSlip({
        runnerName: this.runner.name,
        raceType: this.meeting.type,
        raceId: this.race.id,
        bookieCode: this.bookie,
        raceNumber: this.race.number,
        raceName: this.meeting.track.name,
        betType: this.selectedBetType,
        tabNo: this.runner.tabNo,
        price: this.selectedPrice || this.getPrice(selectedBet),
        gbsMeetingId: this.meeting.externalIDs[0].id,
        betTypeId: JSON.stringify(selectedBet.bettype_id),
        stake: this.stake
      })
      this.$emit('close')
    },
    bookMakerTip: function (bookMakerTipValue) {
      return BOOKMAKER_NAMES_LOOKUP[bookMakerTipValue]
    },
    async submitBet () {
      this.additionalMessage = null
      this.betSuccess = false
      this.betProcessing = true
      if (this.bettingInBG) {
        this.$emit('close')
      }
      const selectedBet = this.filteredBetTypes[this.model]
      let price
      if (this.bookie === 'BF') {
        price = this.layPrice || this.selectedPrice?.price
      } else {
        price = this.selectedPrice ? this.selectedPrice?.price : this.getPrice(selectedBet)?.price
      }
      const result = await this.placeBet({
        raceNumber: this.race.number,
        tabNo: this.runner.tabNo,
        runnerName: this.runner.name,
        bookieCode: this.bookie,
        betTypeId: JSON.stringify(selectedBet.bettype_id),
        betType: this.bookie === 'BF' ? this.filteredBetTypes[this.model].name : this.selectedBetType,
        stake: this.stake,
        price,
        gbsMeetingId: this.meeting.externalIDs[0].id,
        raceId: this.race.id
      })
      if (result) {
        this.betProcessed = true
        this.betProcessedMessage = BET_STATUS_CODE[result.status_code]
        this.betProcessing = false
        this.betId = result.bet_id
        if (BET_STATUS_SUCCESS[BET_STATUS_CODE[result.status_code]] === 'success' || BET_STATUS_SUCCESS[BET_STATUS_CODE[result.status_code]] === 'warning') {
          if (BET_STATUS_CODE[result.status_code] === 'PARTIALLY_ACCEPTED' || BET_STATUS_CODE[result.status_code] === 'PARTIALLY_MATCHED') {
            this.betSuccess = true
            this.showPartialAccept()
          }
          this.betSuccess = true
        } else {
          this.betSuccess = false
          if (BET_STATUS_CODE[result.status_code] === 'BET_REJECTED') {
            this.selectedPrice = this.selectedPrice ? { price: result.new_price } : { price: null }
            if (this.bettingInBG) {
              this.showBR()
              this.selectBet({
                runner: this.runner,
                price: result.new_price,
                bookie: this.bookie,
                type: this.type,
                priceType: this.priceType || 'WIN'
              })
            }
          } else if (BET_STATUS_CODE[result.status_code] === 'PRICE_CHANGED') {
            this.additionalMessage = `to ${result.new_price}`
            this.selectedPrice.price = result.new_price
            if (this.bettingInBG) {
              this.show()
              this.selectBet({
                runner: this.runner,
                price: result.new_price,
                bookie: this.bookie,
                type: this.type,
                priceType: this.priceType || 'WIN'
              })
            }
          }
        }
      }
    },
    getTypeName (type) {
      if (type === 'W') {
        return 'WIN'
      }
      if (type === 'P') {
        return 'PLACE'
      }
      if (type === 'EW') {
        return 'EACH WAY'
      }
      return type
    },
    getPrice (betType) {
      if (!betType) {
        return null
      }
      let type
      if (this.bookie === 'BF') {
        const key = `${this.race.id}:${this.runner.tabNo}:${this.priceType}`
        if (this.type === 'back-1' && betType.name === 'Exchange Back') type = 'back-1'
        if (this.type === 'lay-1' && betType.name === 'Exchange Lay') type = 'lay-1'
        if (!type) return null
        const price = this.getBetfairPrice(key, type)
        return { price }
        // if (betType.name === 'Exchange Back') {
        //   type = CONVERT_BET_TYPE[`Fixed_${betType.win_place_code}`]
        // } else {
        //   type = null
        // }
      } else {
        type = CONVERT_BET_TYPE[`${betType.name}_${betType.win_place_code}`]
      }
      if (this.prices.length <= 0 && type === 'WIN_FIXED_ODDS') return { price: this?.price }
      const price = this.prices.find(p => p.type === type)
      return price || null
    },
    gcd (a, b) {
      return b ? this.gcd(b, a % b) : a
    },
    decimalToFractional (numerator, denominator) {
      const commonDenominator = this.gcd(numerator, denominator)
      return [numerator / commonDenominator, denominator / commonDenominator]
    },
    fractionalOdds (price) {
      const oddsFractional = price - 1
      const numerator = Math.floor(oddsFractional * 100)
      const denominator = 100
      const result = this.decimalToFractional(numerator, denominator)
      const fraction = `${result[0]}/${result[1]}` || ''
      if (fraction === '1/1') {
        return 'E'
      }
      return fraction
    },
    fractionalPrice (betType) {
      const price = this.getPrice(betType)
      if (this.showFractionalOdds) {
        return `- ${this.fractionalOdds(price?.price)}` || null
      }
    },
    getDisplayedPrice (betType) {
      if (this.bookie === 'BF') {
        const price = this.getPrice(betType)
        if (this.showFractionalOdds) {
          return `${price?.price} - ${this.fractionalOdds(price?.price)}` || null
        }
        return price?.price || null
      }
      const type = CONVERT_BET_TYPE[`${betType.name}_${betType.win_place_code}`]
      if (this.selectedPrice && this.selectedPrice.type === type) {
        if (this.showFractionalOdds) {
          return `${this.selectedPrice?.price} - ${this.fractionalOdds(this.selectedPrice?.price)}` || null
        }
        return this.selectedPrice?.price
      }
      const price = this.getPrice(betType)
      if (this.showFractionalOdds && price) {
        return `${price?.price} - ${this.fractionalOdds(price?.price)}` || null
      }
      return price ? price?.price : null
    },
    getRaceImage () {
      return RACE_TYPE_IMAGE[this.meeting.type]
    },
    selectBetType (index) {
      const betType = this.filteredBetTypes[index] ? this.filteredBetTypes[index] : null
      if (betType) this.selectedPrice = this.getPrice(betType)
    },
    hasPrice (betType) {
      const type = CONVERT_BET_TYPE[`${betType.name}_${betType.win_place_code}`]
      return !!this.prices.find(p => p.type === type)
    },
    setStake (stake) {
      this.stake = stake
    },
    setLayPrice (layPrice) {
      this.layPrice = layPrice
    }
  },
  watch: {
    selectedType: function () {
      this.selectBetType(0)
    },
    model: function (value) {
      this.selectBetType(value)
    },
    filteredBetTypes: function () {
      this.selectBetType(0)
    }
  },
  mounted: async function () {
    if (this.bookie === 'BF') {
      let filteredTypes
      if (this.type.includes('lay')) {
        filteredTypes = this.betTypes.filter(type => type.name.includes('Lay'))
      } else if (this.type.includes('projected-sp')) {
        filteredTypes = this.betTypes.filter(type => type.name.includes('SP'))
      } else {
        filteredTypes = this.betTypes.filter(type => type.name.includes('Back'))
      }
      const betType = filteredTypes ? filteredTypes[this.model] : null
      if (betType) {
        this.selectedPrice = this.getPrice(betType)
        this.model = this.filteredBetTypes.findIndex(b => b === betType)
      }
    } else {
      const betType = this.betTypes ? this.betTypes.find(b => b.name === 'Fixed' && b.win_place_code === this.computedSelectedType) : null
      if (betType) this.selectedPrice = this.getPrice(betType)
    }
    // const sessionId = this.getSessionId()
    // this.updateAuthToken()
    // const config = this.authConfig()
    // const betTypes = await getBetTypes(this.race.number, this.bookie, this.meeting.externalIDs[0].id, sessionId, config)
    // this.betTypes = betTypes
  }
}
</script>

<style scoped>
.errorMessage {
  color: red;
  margin: 5px;
}
.add_to_betslip_button {
  margin-bottom: 5px;
}
.betslip-actions {
  width: 100%;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center
}
.betslip-actions button {
  width: 90%;
}
.bet-type-section {
}
.betType-selectors {
  padding-left: 10px;
}
.betslip-popup {
  padding: 10px;
}
.betSlipContainer {
  width: 350px;
  height: 468;
}
.selected {
  border: 1px solid blue;
}
.betType-item {
}
.runnerInfo-name {
  display: flex;
  align-items: center;
  text-align: center;
}
.runnerInfo-name div {
  margin-right: 5px;
}
.runnerInfo {
  margin-left: 25px;
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 10px;
}

.raceImage {
  max-width:40px;
  align-self: center;
  filter: invert(100%);
}
.trackInfo {
  height: 50px;
  padding: 5px;
  display: grid;
  grid-template-columns: 40px auto;
  font-size: 30px;
}
.betslip-header {
  height: 50px;
  padding: 5px;
}
.processing {
  display: flex;
  justify-content: center;
}
.placeBetButton {
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  font-size: 1.8em;
  text-align: center;
  bottom: -20px;
  z-index: 200;
  position: relative;
  font-weight: 600;
  cursor: pointer;
}
.border-selected {
  border: 2px dashed blue;
}
.addMultiButton {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  font-size: 1.1em;
  color: #333;
  width: 100%;
  padding: 10px;
  margin-top: 50px;
  text-align: center;
}
.unitStake {
  border: 1px solid #666;
  width: 100%;
  padding: 5px;
  font-size: 1.2em;
  font-weight: 500;
  background: #e6faff;
  color: #666;
  height: 1.8em;
  margin-top: 50px;
}
.betPriceButton {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  height: 3em;
  width: 17%;
  font-size: 1em;
  padding: 5px;
  text-align: center;
  margin: 7px 0 0 7px;
  line-height: 2.5em;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}
.removeBorder {
  box-shadow: none;
  background-color: #ffffff;
  height: 3em;
  width: 18%;
  padding: 5px;
  text-align: center;
  margin: 5px 0 0 5px;
}
.modal-icon {
  margin-top: -80px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.0s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 350px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.0s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.betPriceButtonClear {
  background-color: #ffffff;
  height: 3em;
  width: 17%;
  font-size: 0.9em;
  padding: 5px;
  text-align: center;
  margin: 7px 0 0 7px;
  line-height: 2.5em;
  font-weight: 600;
  color: #333;
}
@media only screen and (max-width: 600px) {
  .modal-container {
    width: 90%;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.0s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  .betPriceButton {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    height: 3em;
    width: 17%;
    font-size: 0.8em;
    padding: 5px;
    text-align: center;
    margin: 7px 0 0 7px;
    line-height: 2.5em;
    font-weight: 600;
    color: #333;
  }
  .betPriceButtonClear {
    background-color: #ffffff;
    height: 3em;
    width: 17%;
    font-size: 0.7em;
    padding: 5px;
    text-align: center;
    margin: 7px 0 0 7px;
    line-height: 2.5em;
    font-weight: 600;
    color: #333;
  }
  .modal-default-button {
    display: inline-block;
    margin-right: 50px;
  }
  .raceTypeIconSlip {
    height: 80px;
    width: 80px;
    border-radius: 80px;
    background: #fff;
    margin: 0 auto;
    top: -80px;
    z-index: 200;
    position: relative;
    border: 6px solid #000;
    text-align: center;
  }
}
.raceTypeIconSlip {
  height: 80px;
  width: 80px;
  border-radius: 80px;
  background: #fff;
  margin: 0 auto;
  top: -60px;
  z-index: 200;
  position: relative;
  border: 6px solid #000;
  text-align: center;
}
.modal-default-button {
  float: left;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.betProcessed {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.betProcessed button {
  width: 15px;
}
.modal-message {
  margin-left: 30px;
}

.loginBetSlipButton, .place_bet_button {
  margin-bottom: 5px;
}

.v-dialog__content {
  z-index: 20002 !important;
}
</style>
