<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <v-card class="modal-container bettingAgencyLogin">
          <v-card v-if="!agencyAvailable" class="betslip-popup pa-2">
            <v-list-item style="background:#FF5252">
              <v-list-item-content class="ml-3 white--text">
                <v-list-item-title class="caption "><v-icon class="white--text mr-3">mdi-alert-circle-outline</v-icon>Sorry betting to {{bookMakerTip(bookie)}} is currently not available</v-list-item-title>
              </v-list-item-content>
              <v-btn icon small @click="$emit('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
            </v-list-item>
          </v-card>
          <div v-else>
            <v-list-item class="selectedbookie primary">
              <div :class="['spacerHeader-' + bookie]">
                <BookiesLogos :bookieCode="bookie" />
              </div>
              <v-list-item-content class="ml-3 white--text">
                <v-list-item-title class="headline ">Link betting account</v-list-item-title>
                <v-list-item-subtitle class="white--text">{{bookMakerTip(bookie)}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-btn icon small @click="$emit('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
            </v-list-item>
            <v-container v-if="dialogSuccess" fluid>
              <v-btn class="tick" text><v-icon color="success">mdi-check</v-icon></v-btn>
              Login successful
            </v-container>
            <v-container fluid class="text-center">
              <span class="subtitle-1 ">
                Please enter your <em>{{bookMakerTip(bookie)}}</em> account details
                <br><br>
              </span>
              <div v-if="error" class="subtitle-1 error--text">
                {{ error }}
              </div>
              <div class="loginDetails">
                <v-text-field
                  class="username"
                  label="Username"
                  placeholder="Username"
                  style="max-width:150px;"
                  v-model="username"
                  autocomplete="false"
                ></v-text-field>
                <v-text-field
                  class="password"
                  label="Password"
                  placeholder="Password"
                  style="max-width:150px;"
                  type="password"
                  v-model="password"
                  autocomplete="false"
                ></v-text-field>
              </div>
              <div class="loginActions">
                <v-btn type="submit" class="loginButton" color="success" @click="login(username, password, bookie)">
                  Login
                </v-btn>
              </div>
            </v-container>
          </div>
      </v-card>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BookiesLogos from './BookiesLogos.vue'
import { BOOKMAKER_NAMES_LOOKUP } from '../common/constants'
export default {
  data: () => ({
    username: null,
    password: null,
    dialogSuccess: false,
    error: null
  }),
  props: {
    bookie: String,
    close: Function
  },
  components: {
    BookiesLogos
  },
  computed: {
    agencyAvailable: function () {
      const agency = this.getAvailableAgencies().find(a => a.agency_id === this.bookie)
      if (agency) {
        return agency.enabled
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('account', ['agencyLogin', 'fetchBalances']),
    ...mapGetters(['getAvailableAgencies']),
    async login (username, password, bookieCode) {
      const result = await this.agencyLogin({ username, password, bookieCode })
      if (result.success) {
        this.fetchBalances()
        this.dialogSuccess = true
        setTimeout(() => {
          this.$emit('close')
          this.dialogSuccess = false
        }, 1500)
      } else {
        this.error = result.message
      }
    },
    bookMakerTip: function (bookMakerTipValue) {
      return BOOKMAKER_NAMES_LOOKUP[bookMakerTipValue]
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.0s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.bettingAgencyLogin {
  width: 550px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.0s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.subtitle {
  text-align: center;
  margin-bottom: 15px;
}
.selectedBookie {
  width: 50px;
  margin: 10px;
}
.modal-message {
  margin-left: 30px;
}
.error {
  color: red;
  background-color: none;
}
</style>
