<template>
  <v-list-item-content class="pa-0 multibetslip_bet">
    <div>
      <v-list-item-title class="font-weight-bold d-inline "><span class="multibetslip_bet_runnerName">{{ item.runnerName }}</span> | <img :src="getRaceImage(item)" class="raceImagBetSlip" /></v-list-item-title>
        <v-icon class="float-right" color="error" @click="clearThisBet()" dark>mdi-close-box</v-icon>
    </div>
    <div>
      <v-list-item-subtitle class="d-inline">{{ item.race }}</v-list-item-subtitle>
    </div>
    <v-list-item-subtitle class="caption multibetslip_bet_bookieCode" style="color:#DCE775;"><RaceBookmakers :bookieCode="item.agency_id" :betSlipIcon="betSlipIcon" :columnType="item.type" />{{bookMakerTip(item.agency_id)}}</v-list-item-subtitle>
    <v-list-item-subtitle v-if="item.status === 4 || item.status === 0 || item.status === 1 || item.status === 3 || item.status === 5 || item.status === 6 || item.status === 7 || item.status === 8 || item.status === 11 || item.status === 15 || item.status === 16" class="red--text">{{ BET_STATUS_CODE_ENG[item.status] }}</v-list-item-subtitle>
    <v-list-item-subtitle v-else class="white--text">{{ BET_STATUS_CODE_ENG[item.status] }}</v-list-item-subtitle>
    <v-list-item-subtitle class="white--text">
      <span style="color:#00E676;margin-right:10px;">{{ getTypeName(item.bet_type_description)}}</span>
      <span style="color:#DCE775;">Stake:</span>
      $<span class="multibetslip_bet_stake">{{ getStake(item) }}</span>
      <span style="color:#29B6F6;margin-left:10px;">Odds:</span>
      $<span class="multibetslip_bet_price">{{ item.price }}</span>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BOOKMAKER_NAMES_LOOKUP, BET_STATUS_CODE_ENG, RACE_TYPE_IMAGE } from '../common/constants'
import RaceBookmakers from './RaceBookmakers'

export default {
  data () {
    return {
      betProcessed: false,
      betSuccess: false,
      betProcessedMessage: null,
      additionalMessage: null,
      betProcessing: false,
      confirmSendBet: false,
      priceChanged: false,
      betSlipIcon: true,
      sent: false,
      BET_STATUS_CODE_ENG
    }
  },
  props: {
    item: Object,
    i: Number
  },
  components: {
    RaceBookmakers
  },
  methods: {
    ...mapGetters(['hasFeature']),
    ...mapActions('betting', ['setClearedBets']),
    getStake: function (b) {
      return b?.bet?.['partial_stake'] || b.stake
    },
    bookMakerTip: function (bookMakerTipValue) {
      return BOOKMAKER_NAMES_LOOKUP[bookMakerTipValue]
    },
    getTypeName (type) {
      if (type === 'WIN_FIXED_ODDS') {
        return 'WIN'
      }
      if (type === 'P') {
        return 'PLACE'
      }
      if (type === 'EW') {
        return 'EACH WAY'
      }
      return type
    },
    getRaceImage (v) {
      return RACE_TYPE_IMAGE[v]
    },
    clearThisBet () {
      return this.setClearedBets([this.item.id])
    }
  }
}
</script>

<style scoped>
.raceImagBetSlip {
  max-width:40px;
  align-self: center;
  filter: invert(100%);
}
</style>
