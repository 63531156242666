<template>
  <div :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
    <template>
      <v-btn v-if="hasFeature('betLimits') && !getRdcConfig" color="normal" x-small :class="hasFeature('marketMover') ? 'buttonSettings' : 'ma-2 mb-0'" @click.stop="dialog = true">
        <v-icon small class="mr-1">mdi-cash</v-icon>Bet Limits
      </v-btn>
    </template>
    <v-dialog v-model="dialog" max-width="300" :overlay-opacity="opacity" retain-focus>
      <v-card style="max-width:300px;">
        <v-card-title class="primary white--text">
          <div style="width:100%">
            <div class="float-left">
              <span class="headline">Bet Limits</span>
              <v-list-item-subtitle>Set your bet limits</v-list-item-subtitle>
            </div>
            <v-btn class="float-right" icon small @click.stop="dialog = false" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-list>
              <v-list-item>
                <v-text-field label="Maximum stake amount per bet:" :rules="rulesMax" placeholder="1" v-model="maximum" class="float-left d-inline" type="number"></v-text-field>
              </v-list-item>
              <div v-show="maxIsSet && !profitIsSet" class="caption error--text pl-4">Default Stake cannot exceed Max Stake - <br>adjust Max Stake</div>
              <v-list-item>
                <v-text-field :disabled="maxIsSet"  label="Default 'Stake' amount:" :rules="rulesStake" placeholder="1" v-model="stake" class="float-left d-inline" type="number"></v-text-field>
              </v-list-item>
              <span v-show="profitIsSet" class="caption error--text pl-4">Setting Profit will override your Stake</span>
              <v-list-item>
                <v-text-field label="Default 'Profit' amount:" :rules="rulesProfit" placeholder="1" v-model="profit" class="float-left d-inline" type="number"></v-text-field>
              </v-list-item>
            </v-list>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import numeral from 'numeral'

export default {
  data: () => ({
    panel: 0,
    opacity: 0.02,
    dialog: false,
    resetConfirm: false,
    rulesStake: [
      value => (value <= 9999.00) || 'Max 9999.00'
    ],
    rulesProfit: [
      value => (value <= 9999.00) || 'Max 9999.00'
    ],
    rulesMax: [
      value => (value <= 9999.00) || 'Max 9999.00'
    ]
  }),
  props: {
    betLimits: Array
  },
  methods: {
    ...mapActions(['updateRaceGridSettings']),
    onClick (setting) {
      this.updateRaceGridSettings({ filter: 'betLimits', value: setting })
    }
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['hasFeature', 'getBetLimits', 'getRdcConfig']),
    stake: {
      get () {
        return this.betLimits[0]
      },
      set (value) {
        const formatValue = numeral(value).format('(0.00)')
        var setAmt = Number(formatValue)
        return this.onClick([setAmt, this.profit, this.maximum])
      }
    },
    profit: {
      get () {
        return this.betLimits[1]
      },
      set (value) {
        const formatValue = numeral(value).format('(0.00)')
        var setAmt = Number(formatValue)
        return this.onClick([this.stake, setAmt, this.maximum])
      }
    },
    maximum: {
      get () {
        return this.betLimits[2]
      },
      set (value) {
        const formatValue = numeral(value).format('(0.00)')
        var setAmt = Number(formatValue)
        return this.onClick([this.stake, this.profit, setAmt])
      }
    },
    maxIsSet: function () {
      var setMax = Number(this.getBetLimits[2])
      if (!setMax || setMax === 0) return false
      var setProfit = Number(this.getBetLimits[1])
      if (setMax < this.stake) return true
      if (setProfit > 0) return true
      return false
    },
    profitIsSet: function () {
      var setProfit = Number(this.getBetLimits[1])
      if (setProfit > 0) return true
      return false
    }
  }
}
</script>

<style>
.raceFiltersAcMode {
  font-weight:bold !important
}

.buttonSettings {
  margin: 0px 0px 0px 8px !important;
  background-color: #dadada;
}
</style>
