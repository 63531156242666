import axios from 'axios'

export const getDynamicRaceData = async function (gbsMeetingId, raceNo, config) {
  try {
    const result = await axios.get(`public/GetDynamicRaceData?meeting_id=${gbsMeetingId}&race_number=${raceNo}`, config)
    if (result.data) {
      return result.data
    }
  } catch (error) {
  }
}
