<template>
  <v-card>
    <v-list-item class="warning">
      <v-list-item-content class="ml-1 white--text">
        <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-alert</v-icon>Form Analyser Access</v-list-item-title>
      </v-list-item-content>
      <v-btn icon small @click="setAccess('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
    </v-list-item>
    <v-container fluid class="text-center">
      <span class="subtitle-1 ">
      You don't have access to use Form Analyser.
      <br>
    </span>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    setAccess: Function
  }
}
</script>
