<template>
  <v-list>
    <v-list-group
      v-for="item in items"
      :key="item.title"
      v-model="item.active"
      no-action
      :value="false"
      style="background:rgb(25, 118, 210, 0.1)"
      class="priceMenu-group"
    >
      <template v-slot:activator>
        <v-list-item-content class="pa-2 priceMenu_title" fixed>
          <v-list-item-title v-text="item.title" class="body-2"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-divider></v-divider>
      <div
        class="pa-3 overflow-y-auto"
        style="background-color: rgba(0, 0, 0, 0.05); max-height: 300px;"
        >

        <v-list-item
            v-for="subItem in filterBookie(item.items)"
            :key="subItem.source"
            class="priceMenuItem"
          >
          <div :class="'logoWidth' + 10" class="bookieLogo mb-2">
            <div class="opacityHover tooltip"><RaceBookmakers :bookieCode="subItem.source"/></div>
          </div>

          <v-list-item-content>
            <v-list-item-title v-text="getTitle(subItem.source)"></v-list-item-title>
          </v-list-item-content>

          <v-btn class="priceMenuItem-button" x-small @click="handleClick(subItem.source, item.type, 'win')">{{ flucs ? 'WIN FLUCS' : 'WIN' }}</v-btn>
          <v-btn v-if="!flucs" x-small @click="handleClick(subItem.source, item.type, 'place')">PLACE</v-btn>

        </v-list-item>
      </div>
      <v-divider></v-divider>
    </v-list-group>
  </v-list>
</template>

<script>
import { BOOKMAKER_CODE_NAMES, BOOKMAKER_NAMES_LOOKUP, DELETED_BOOKIES, TOTE_BOOKIES } from '../../common/constants'
import RaceBookmakers from '../RaceBookmakers'

export default {
  data: () => ({
    fav: true,
    menu: false,
    items: [
      {
        type: 'tote',
        title: 'Tote',
        items: TOTE_BOOKIES
      },
      {
        type: 'fixed',
        title: 'Fixed',
        items: [
          { source: BOOKMAKER_CODE_NAMES['BaggyBet'] },
          { source: BOOKMAKER_CODE_NAMES['SportChamp Racing'] },
          { source: BOOKMAKER_CODE_NAMES['EskanderBet'] },
          { source: 'BB4' },
          // { source: 'BB2' },
          { source: 'BD' },
          // { source: 'BT' },
          // { source: 'BD2' },
          { source: 'BV' },
          { source: 'BB3' },
          { source: 'BG' },
          { source: 'BS3' },
          { source: BOOKMAKER_CODE_NAMES['BoomBet'] },
          { source: 'BW2' },
          { source: 'CHB' },
          { source: 'CB3' },
          { source: 'DD' },
          { source: 'GSB' },
          { source: 'LB2' },
          { source: 'LB2U' },
          { source: 'ND' },
          { source: 'NZ' },
          { source: 'PP2' },
          { source: 'OP' },
          { source: 'CB2' }, // playup
          { source: 'RB2' },
          { source: 'RB3' },
          { source: BOOKMAKER_CODE_NAMES['Sportsbet'] },
          { source: 'SBU' },
          { source: 'SB6' },
          { source: 'OB' },
          { source: BOOKMAKER_CODE_NAMES['TAB Fixed NSW'] },
          { source: BOOKMAKER_CODE_NAMES['TAB Fixed VIC'] },
          { source: BOOKMAKER_CODE_NAMES['TAB Fixed QLD'] },
          { source: 'TS2' },
          { source: 'Q' },
          { source: 'UB' },
          { source: 'UB_U' },
          { source: '888' },
          { source: 'VPB' },
          { source: 'WH' },
          { source: 'WB3' }
        ]
      }
    ]
  }),
  components: {
    RaceBookmakers
  },
  props: {
    addColumn: Function,
    flucs: Boolean
  },
  methods: {
    getTitle: function (source) {
      return BOOKMAKER_NAMES_LOOKUP[source]
    },
    handleClick: function (source, type, action) {
      let priceType
      if (type === 'tote') {
        priceType = action === 'win' ? 'WIN' : 'PLACE'
      }
      if (type === 'fixed') {
        priceType = action === 'win' ? 'WIN_FIXED_ODDS' : 'PLACE_FIXED_ODDS'
      }
      const display = this.flucs ? 1 : 0
      const column = { source, type: priceType, display }
      this.addColumn(column)
    },
    filterBookie (bookies) {
      const deletedBookies = DELETED_BOOKIES.map(bookie => bookie.source)
      const filteredColumns = bookies.filter(c => !deletedBookies.includes(c.source))
      return filteredColumns
    }
  }
}
</script>

<style>
  .bookieLogo {
    display: flex;
    margin: 0 15px 0 0;
  }
  .priceMenuItem button {
    margin-right: 5px;
  }
  .v-list-item--active {
    background-color: rgb(25, 118, 210, 0.2);
  }
</style>
