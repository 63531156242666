import { render, staticRenderFns } from "./Exotics.vue?vue&type=template&id=342f6e75"
import script from "./Exotics.vue?vue&type=script&lang=js"
export * from "./Exotics.vue?vue&type=script&lang=js"
import style0 from "./Exotics.vue?vue&type=style&index=0&id=342f6e75&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports