<template>
  <v-list-item-content class="pa-0 multibetslip_bet">
    <modal name="BetFailedAlert" :clickToClose=false height=auto>
      <BetFailedAlert
        @clicked="hide"
      />
    </modal>
    <div>
      <v-list-item-title class="font-weight-bold d-inline "><span class="multibetslip_bet_runnerName">{{ item.runnerName }}</span> | <img :src="getRaceImage(item.raceType)" class="raceImagBetSlip" /></v-list-item-title>
      <v-btn x-small class="d-inline float-right success--text clearbet_button" @click="clearThisBet()">
        <v-icon small class="white--text">mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
    <div>
      <v-list-item-subtitle class="d-inline">{{ item.raceName }} R{{item.raceNumber}}</v-list-item-subtitle>
      <v-btn v-if="!confirmSendBet && !betSuccess && !betProcessing && !betProcessed" x-small class="d-inline float-right sendbet_button" @click="confirmThisBet()">
        <v-icon small class="success--text">mdi-fast-forward-outline</v-icon>
      </v-btn>
      <v-btn v-else-if="confirmSendBet" x-small class="d-inline float-right confirm_sendbet_button betProc" @click="placeSingleBet()">
        <span class="white--text">Confirm &amp; Send</span>
      </v-btn>
      <v-btn v-else-if="betProcessing" x-small class="d-inline float-right betProc">
        <span class="warning--text">Processing...</span>
      </v-btn>
      <v-btn v-else-if="betSuccess" x-small class="d-inline float-right betProc">
        <span class="success--text">Bet Sent!</span>
      </v-btn>
      <v-btn v-else-if="priceChanged" x-small class="d-inline float-right betProc" @click="placeSingleBet()">
        <span class="error--text">Price Changed {{this.additionalMessage}} <v-icon small class="success--text">mdi-fast-forward-outline</v-icon></span>
      </v-btn>
      <v-btn v-else x-small class="d-inline float-right betProc" @click="placeSingleBet()">
        <span class="error--text">{{ betProcessedMessage }}</span>
      </v-btn>
    </div>
    <v-list-item-subtitle class="caption multibetslip_bet_bookieCode" style="color:#DCE775;">
      <span>{{bookMakerTip(item.bookieCode)}}</span>
      <div class="betslip-sprite">
        <RaceBookmakers :bookieCode="item.bookieCode" :betSlipIcon="betSlipIcon" :columnType="item.type" />
      </div>
    </v-list-item-subtitle>
    <v-list-item-subtitle class="white--text">
      <span style="color:#00E676;margin-right:10px;">{{ getTypeName(item.betType)}}</span>
      <span style="color:#DCE775;">Stake:</span>
      $<span class="multibetslip_bet_stake">{{ item.stake }}</span>
      <span style="color:#29B6F6;margin-left:10px;">Odds:</span>
      $<span class="multibetslip_bet_price">{{ getItemPrice(item) }}</span>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { RACE_TYPE_IMAGE, BOOKMAKER_NAMES_LOOKUP, BET_STATUS_SUCCESS, BETSTATUS } from '../common/constants'
import RaceBookmakers from './RaceBookmakers'
import BetFailedAlert from './BetFailedAlert'

export default {
  data () {
    return {
      betProcessed: false,
      betSuccess: false,
      betProcessedMessage: null,
      additionalMessage: null,
      betProcessing: false,
      confirmSendBet: false,
      priceChanged: false,
      betSlipIcon: true,
      sent: false,
      layPrice: null
    }
  },
  props: {
    item: Object,
    i: Number
  },
  components: {
    RaceBookmakers,
    BetFailedAlert
  },
  computed: {
    ...mapGetters(['getSourcePrices', 'hasFeature']),
    prices: function () {
      const priceSet = this.getSourcePrices(this.item.bookieCode, this.item.tabNo, this.item.raceId)
      return priceSet
    }
  },
  methods: {
    ...mapActions('betting', ['loadBets', 'placeBet']),
    ...mapMutations('betting', ['removeBetslipBet']),
    bookMakerTip: function (bookMakerTipValue) {
      return BOOKMAKER_NAMES_LOOKUP[bookMakerTipValue]
    },
    show () {
      this.$modal.show('BetFailedAlert')
    },
    hide () {
      this.$modal.hide(
        'BetFailedAlert'
      )
    },
    getRaceImage (v) {
      return RACE_TYPE_IMAGE[v]
    },
    getTypeName (type) {
      if (type === 'WIN_FIXED_ODDS') {
        return 'WIN'
      }
      if (type === 'PLACE_FIXED_ODDS') {
        return 'PLACE'
      }
      if (type === 'EW') {
        return 'EACH WAY'
      }
      return type
    },
    confirmThisBet () {
      this.confirmSendBet = true
      setTimeout(() => { this.confirmSendBet = false }, 5000)
    },
    clearThisBet () {
      // const newBetList = [...this.getBetSlip]
      // newBetList.splice(this.i, 1)
      // this.loadBets(newBetList)
      this.removeBetslipBet(this.item)
      this.confirmSendBet = false
    },
    async placeSingleBet () {
      if (this.sent) return
      const priceChanged = this.priceChangedValidation({ bet: this.item })

      if (priceChanged.changed) {
        this.betProcessing = true
        this.betSuccess = false
        this.sent = false
        this.additionalMessage = `to $${priceChanged.newPrice}`
        this.item.price.price = priceChanged.newPrice
        this.confirmSendBet = false
        this.priceChanged = true
        this.betProcessed = true
        this.betProcessing = false
      } else {
        this.sent = true
        this.additionalMessage = null
        this.confirmSendBet = false
        this.betSuccess = false
        this.betProcessing = true
        this.priceChanged = false
        let price
        if (this.item.bookieCode === 'BF') {
          price = this.layPrice || this.item.price?.price
        } else {
          price = this.item.price?.price
        }
        const result = await this.placeBet({
          raceNumber: this.item.raceNumber,
          tabNo: this.item.tabNo,
          runnerName: this.item.runnerName,
          bookieCode: this.item.bookieCode,
          betTypeId: this.item.betTypeId,
          betType: this.item.betType,
          stake: this.item.stake,
          price,
          gbsMeetingId: this.item.gbsMeetingId,
          raceId: this.item.raceId,
          key: this.item.key
        })
        if (result) {
          this.betProcessed = true
          this.betProcessedMessage = BETSTATUS[result.status_text]
          this.betProcessing = false

          if (BET_STATUS_SUCCESS[result.status_text] === 'success') {
            this.betSuccess = true
            setTimeout(() => { this.clearThisBet() }, 3000)
          } else if (result.status_text === 'BET_FAILED') {
            this.show()
            this.betSuccess = false
            setTimeout(() => { this.clearThisBet() }, 3000)
          } else if (BET_STATUS_SUCCESS[result.status_text] === 'warning') {
            this.betSuccess = false
            setTimeout(() => { this.clearThisBet() }, 3000)
          } else if (BET_STATUS_SUCCESS[result.status_text] === 'error' && result.status_text !== 'PRICE_CHANGED') {
            this.betSuccess = false
            setTimeout(() => { this.clearThisBet() }, 3000)
          } else {
            this.betSuccess = false
            this.sent = false
            if (result.status_text === 'PRICE_CHANGED') {
              this.additionalMessage = `to $${result.new_price}`
              this.item.price.price = result.new_price
              this.confirmSendBet = false
              this.priceChanged = true
            }
          }
        }
      }
    },
    setLayPrice (layPrice) {
      this.layPrice = layPrice
    },
    getItemPrice (item) {
      return item?.price?.price
    },
    priceChangedValidation ({ bet }) {
      const type = bet.price.type
      const price = this.prices.find(p => p.type === type)
      const newPrice = price.price
      if (this.prices.length <= 0 && type === 'WIN_FIXED_ODDS') {
        return {
          changed: false,
          price: bet.price.price
        }
      }

      if (newPrice !== this.item.price.price) {
        return {
          changed: true,
          newPrice: newPrice
        }
      } else {
        return {
          changed: false,
          price: bet.price.price
        }
      }
    }
  }
}
</script>

<style scoped>
.raceImagBetSlip {
  max-width:40px;
  align-self: center;
  filter: invert(100%);
}
.multibetslip_bet {
  text-align: left;
}

.betslip-sprite div {
  margin: unset !important;
  height: unset !important;
  transform: scale(0.8) !important;
}
</style>
