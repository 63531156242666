<template>
  <v-container v-if="meeting" fluid style="height:100%">
    <v-card v-if="!editMode" class="box-shadow--container br--container pa-2">
      <div :class="getRaceFontSize" class="raceGrid mainTable" v-bind:style="setHeight" :key="setHeight.height">
        <div class="raceGridHeader">
          <div class="normalModeContainer" style="background-color:white;">
            <div style="width:320px;">
              <div style="height:100%;width:100%;background:#dadada">
                <RaceGridSettings v-if="!hasFeature('marketMover')" :fontSizeSetting="getRaceFontSize" :gridSettings="getGridSettings" :betFairSettings="getBetFairSettings" :highlightSetting="getHighLightBestOdds" :commissionRate="getBetFairCommission"/>
                <BetLimitSettings v-if="hasFeature('betLimits') && !hasFeature('marketMover')" :betLimits="getBetLimits"/>
                <MarketMover v-if="hasFeature('marketMover')" />
                <!-- <v-btn v-if="selection === 'betButton'" label color="primary" x-small class="ma-2 mb-0" style="height: 18px !important;" @click="showLoginModal = true">Betting Disabled</v-btn> -->
                <span v-if="!hasFeature('betLimits')" >
                  <v-tooltip bottom color="error" open-delay="400">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-btn disabled class="ma-2 mb-0 white--text" color="green" x-small><v-icon small color="white" class="mr-1">mdi-cash</v-icon>Bet Limits</v-btn>
                      </span>
                    </template>
                    <span class="caption">Not yet available in BETA</span>
                  </v-tooltip>
                </span>
              </div>
            </div>
            <scroll-sync vertical horizontal style="overflow-x:hidden;overflow-y:hidden;width:100%;display:flex;">
              <div v-for="(item, index) in selectedColumns" :index="index" :key="index">
                <div :class="getColumnClass(item.source)">
                    <v-menu offset-y style="z-index:202; margin-left: 500px" :absolute="true">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="getColumnType(item.type) === 'price'" :class="['logo-head ' + 'logoColor-' + item.source, 'raceTableColumn-header']" style="min-width:42px" v-bind="attrs" v-on="on">
                          <RaceBookmakers @input="setSelectedTime" :column="item" :bookieCode="item.source" :columnHeader="item.columnHeader" :columnType="item.type" :editMode="editMode" :columnNumber="index"/>
                        </div>
                        <div style="height:100%;" v-else-if="getColumnType(item.type) === 'bestPrice'" v-on="on">
                          <BestPriceColumnHeader :column="item" :columnNumber="index" :acMode="acMode"/>
                        </div>
                        <div style="width: 42px; height: 100%" v-else-if="item.type === 'bookieSelect'">
                          <div v-on="on">
                            <RaceBookmakers :column="item" :bookieCode="item.filters.value" columnHeader="OCC" :columnType="item.type" :editMode="editMode" :columnNumber="index"/>
                          </div>
                          <BookieSelectHeader :column="item" :columnNumber="index" :acMode="acMode" :id="race.id"/>
                        </div>
                        <div v-else :class="['infoColumn', 'columnWidth-' + item.source]" v-on="on">
                          <div v-if="getSortedOrderBookie.columnNumber === index && getSortedOrderBookie.context === 'lowToHigh'"><v-icon small class="success--text">mdi-arrow-down-bold</v-icon></div>
                          <div v-else-if="getSortedOrderBookie.columnNumber === index && getSortedOrderBookie.context === 'highToLow'"><v-icon small class="success--text mb-n2">mdi-arrow-up-bold</v-icon></div>
                          <div style="max-height:80px;width:42px;">{{ item.title }}</div>
                        </div>
                      </template>
                      <div v-if="item.columnHeader === 'Flucs'"></div>
                      <v-list v-else dense>
                        <v-list-item @click="bookMakerWeb(item.source)">
                          <v-list-item-title>Join Bookie</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="onSortLowToHigh(item.source, item.type, index, item.priceType, item.filters)">
                          <v-list-item-title>Sort Low to High</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="onSortHighToLow(item.source, item.type, index, item.priceType, item.filters)">
                          <v-list-item-title>Sort High to Low</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="onSortByRunner()">
                          <v-list-item-title>Sort by Runner</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                </div>
              </div>
            </scroll-sync>
          </div>
        </div>
        <div class="raceGridMidSection">
          <div class="normalModeContainer">
            <scroll-sync vertical class="runnerSection">
              <v-skeleton-loader v-if="!hasCompetitors" width="100%" type="article, actions"></v-skeleton-loader>
              <RaceRunnerSection
                v-else
                :race="race"
                :meeting="meeting"
                :editMode="editMode"
              />
            </scroll-sync>
            <scroll-sync horizontal vertical class="table-list" style="overflow-x:hidden;width:100%;background:#d8d8d8">
              <v-skeleton-loader v-if="!hasCompetitors" width="50%" type="date-picker"></v-skeleton-loader>
              <RaceTableColumns
                v-else
                :columns="selectedColumns"
                :columnFlucs="getColumnFlucs"
                :selectedTime="selectedTime"
                :updateSelectedColumns="updateSelectedColumns"
                :getSelectedColumnsName="getSelectedColumnsName"
                :raceID="race.id"
                :clickPrice="openBetslipModal"
                :getFlucs="getFlucs"
                :fetchFlucs="fetchFlucs"
                :authorized="authorized"
                :testMode="testMode"
                :editMode="editMode"
                :getColumnStyle="getColumnStyle"
              />
            </scroll-sync>
          </div>
        </div>
        <div class="raceGridFooter">
          <div class="normalModeContainer" style="background-color:white;height:100%;">
            <div vertical style="overflow:hidden;width:320px;">
              <RaceTableFooterHeadings
                :race="race"
                :meeting="meeting"
                :acMode="acMode"
              />
            </div>
            <scroll-sync horizontal class="table-list" style="overflow-y:hidden;overflow-x:scroll;width:100%;">
              <div v-for="(item, index) in selectedColumns" :index="index" :key="index" class="table-list-item">
                <RaceTableFooter
                  :column="item"
                  :index="index"
                  :race="race"
                  :acMode="acMode"
                  :getColumnStyle="getColumnStyle"
                />
              </div>
            </scroll-sync>
          </div>
        </div>
      </div>
    </v-card>
    <div v-else-if="editMode" class="editModeContainer" v-bind:style="this.setHeight" style="background-color:#d8d8d8">
      <scroll-sync vertical style="height:100%;overflow-y:hidden;max-width:260px;overflow-x:scroll;z-index:202;background:#fff;">
        <div style="height:91px"/>
        <RaceRunnerSection
          :race="race"
          :meeting="meeting"
          :editMode="editMode"
        />
      </scroll-sync>
      <scroll-sync vertical horizontal style="height:100%;overflow:scroll;z-index:199;background:#d8d8d8;padding-right:80px;">
        <RaceTableColumns
        :columns="selectedColumns"
        :columnFlucs="getColumnFlucs"
        :updateSelectedColumns="updateSelectedColumns"
        :getSelectedColumnsName="getSelectedColumnsName"
        :raceID="race.id"
        :clickPrice="openBetslipModal"
        :getFlucs="getFlucs"
        :fetchFlucs="fetchFlucs"
        :authorized="authorized"
        :testMode="testMode"
        :editMode="editMode"
        :getColumnStyle="getColumnStyle"
        />
      </scroll-sync>
      <div v-if="selectedColumns.length < 30" class="addColumnButtonMain"><AddColumnButton :columnLength="selectedColumns.length" largeIcon :position="-1" :race="race"/></div>
      <div v-else class="addColumnButtonMax editButtons"><v-btn height="30px" color="error"><span class="font-weight-regular">Max Columns Reached - 30</span> <v-icon class="ml-2">mdi-emoticon-confused-outline</v-icon></v-btn></div>
    </div>
    <v-dialog hide-overlay v-model="showBetslipModal">
      <BetslipModal
      v-if="showBetslipModal"
      @close="showBetslipModal = false"
      :runner="betslipModalProps.runner"
      :race="betslipModalProps.race || race"
      :meeting="betslipModalProps.meeting || meeting"
      :price="betslipModalProps.price"
      :type="betslipModalProps.type"
      :bookie="betslipModalProps.bookie"
      :betTypes="betslipModalProps.betTypes"
      :gridSettings="getGridSettings"
      :bettingEnabled="bettingEnabled"
      :enableBetting="() => showLoginModal = true"
      :testMode="testMode"
      :priceType="betslipModalProps.priceType"
      :showModal="showBetslipModal"
      />
    </v-dialog>
    <LoginModal v-if="showLoginModal" @close="showLoginModal = false" :loginBetting="loginBetting"/>
    <AgencyModal v-if="showAgencyModal" @close="showAgencyModal = false" :bookie="betslipModalProps.bookie" />
    <PriceAlertMenu v-if="dialogAddToWatchlist"/>
    <RunnerWatchList v-if="dialogWatchlist"/>
    <PriceAlert v-for="priceAlert in getNewPriceAlerts" :priceAlert="priceAlert" :key="priceAlert.alert_id" :openBetslipModal="openBetslipModal" :audio="getPriceAlertsAudio"/>
    <modal name="price-changed-modal" :clickToClose=false height=auto>
      <PriceChangedAlert
        @clicked="hide" :clickPrice="openBetslipModal"
      />
    </modal>
    <modal name="partially-accepted-modal" :clickToClose=false height=auto>
      <PartiallyAcceptedAlert @clicked="hidePA"/>
    </modal>
    <modal name="bet-rejected-modal" :clickToClose=false height=auto>
      <BetRejectedAlert @clicked="hideBR"/>
    </modal>
  </v-container>
</template>

<script>
import RaceBookmakers from './RaceBookmakers'
import AddColumnButton from './menus/AddColumnMenu'
import PriceChangedAlert from './PriceChangedAlert'
import PartiallyAcceptedAlert from './PartiallyAcceptedAlert'
import BetRejectedAlert from './BetRejectedAlert'
import RaceTableColumns from './RaceTableColumns'
import RaceTableFooter from './RaceTableFooter'
import RaceRunnerSection from './RaceRunnersSection'
import RaceTableFooterHeadings from './RaceTableFooterHeadings'
import { format, parseISO } from 'date-fns'
import { BOOKMAKER_WEB_PAGE, COLUMN_TYPE } from '../common/constants'
import { mapBettingSource } from '../common/methods'
import ScrollSync from 'vue-scroll-sync'
import BetslipModal from './BetSlipModal'
import LoginModal from './LoginModal'
import AgencyModal from './AgencyModal'
import BestPriceColumnHeader from './BestPriceColumnHeader'
import BookieSelectHeader from './BookieSelectHeader'
import RunnerWatchList from './RunnerWatchList'
import PriceAlertMenu from './menus/PriceAlertMenu'
import PriceAlert from './PriceAlert'
import { mapActions, mapGetters, mapState } from 'vuex'
import MarketMover from './MarketMover'
import BetLimitSettings from './menus/BetLimitSettings'
import RaceGridSettings from './menus/RaceGridSettings'

export default {
  name: 'RaceDetail',
  data: () => ({
    showBetslipModal: false,
    showLoginModal: false,
    betslipModalProps: null,
    showAgencyModal: false,
    selectedTime: 'All'
  }),
  props: {
    loginBetting: Function
  },
  components: {
    RaceTableColumns,
    PriceChangedAlert,
    PartiallyAcceptedAlert,
    BetRejectedAlert,
    RaceRunnerSection,
    ScrollSync,
    RaceTableFooter,
    RaceTableFooterHeadings,
    RaceBookmakers,
    AddColumnButton,
    BetslipModal,
    LoginModal,
    AgencyModal,
    BestPriceColumnHeader,
    RunnerWatchList,
    PriceAlertMenu,
    PriceAlert,
    MarketMover,
    BetLimitSettings,
    RaceGridSettings,
    BookieSelectHeader
  },
  methods: {
    ...mapActions(['updateSortPriceOrder', 'fetchFlucs', 'updateSelectedColumns', 'showFeatureAlert', 'setHideBalance', 'updateNewColumns', 'calculateOdds']),
    ...mapActions('betting', ['fetchBetTypes']),
    getColumnClass (source) {
      if (source === 'INF-my_price_dynamicform') {
        return 'raceTableColumn raceTableColumnHead raceTableColumnDFPrices'
      } else if (source === 'INF-rating_dynamicform') {
        return 'raceTableColumn raceTableColumnHead raceTableColumnDFRatings'
      } else {
        return 'raceTableColumn raceTableColumnHead'
      }
    },
    show () {
      this.$modal.show('price-changed-modal')
    },
    hide () {
      this.$modal.hide(
        'price-changed-modal'
      )
    },
    showPA () {
      this.$modal.show('partially-accepted-modal')
    },
    hidePA () {
      this.$modal.hide('partially-accepted-modal')
    },
    showBR () {
      this.$modal.show('bet-rejected-modal')
    },
    hideBR () {
      this.$modal.hide('bet-rejected-modal')
    },
    setSelectedTime (value) {
      this.selectedTime = value
    },
    onSortLowToHigh (bookieCode, priceType, columnNumber, type, filters) {
      this.updateSortPriceOrder({ context: 'lowToHigh', bookieCode, priceType, columnNumber, type, filters })
    },
    bookMakerWeb: function (bookMakerWebValue) {
      return window.open(BOOKMAKER_WEB_PAGE[bookMakerWebValue])
    },
    onSortHighToLow (bookieCode, priceType, columnNumber, type, filters) {
      this.updateSortPriceOrder({ context: 'highToLow', bookieCode, priceType, columnNumber, type, filters })
    },
    onSortByRunner () {
      this.updateSortPriceOrder({})
    },
    async openBetslipModal (props) {
      if (!this.hasFeature('BetSlip')) {
        return
      }
      if (!this.bettingAvailable(props.bookie)) {
        return
      }
      if (!this.sessionId) {
        this.betslipModalProps = props
        const betTypes = await this.fetchBetTypes({ raceNumber: this.race.number, bookieCode: props.bookie, gbsMeetingId: this.meeting.externalIDs[0].id })
        this.betslipModalProps.betTypes = betTypes || []
        this.showBetslipModal = true
      } else if (this.sessionId) {
        const agency = this.balances ? this.balances.find(a => a.agency_id === mapBettingSource(props.bookie)) : null
        if (agency) {
          this.betslipModalProps = props
          const betTypes = await this.fetchBetTypes({ raceNumber: this.race.number, bookieCode: props.bookie, gbsMeetingId: this.meeting.externalIDs[0].id })
          this.betslipModalProps.betTypes = betTypes || []
          this.showBetslipModal = true
        } else {
          this.betslipModalProps = props
          this.showAgencyModal = true
        }
      }
    },
    getFlucs (source, tabNo, type) {
      const flucs = {}
      const flucsType = source === 'BF' ? 'WIN' : type
      const key = `${this.race.id}:${source}:${flucsType}`
      if (this.flucs && this.flucs[key]) {
        const runnerFlucs = this.flucs[key].find(f => f.flucs[0]?.tabNo === tabNo)
        if (runnerFlucs && runnerFlucs.flucs) {
          runnerFlucs.flucs.forEach(runner => {
            const time = format(parseISO(runner.time), 'yyyy-MM-dd')
            if (!flucs[time]) {
              flucs[time] = []
            }
            flucs[time].push(runner)
          })
        }
      }
      return flucs
    },
    getColumnStyle (index) {
      const column = this.selectedColumns[index]
      if (column['settings']) {
        let s = ''
        Object.keys(column['settings']).map(key => {
          if (column['settings'][key]) {
            s = s + `${key}:${column['settings'][key]};`
          }
        })
        return s
      }
    },
    getColumnType (type) {
      return COLUMN_TYPE[type]
    },
    bettingAvailable: function (bookieCode) {
      if (this.getAvailableAgencies) {
        const agency = this.getAvailableAgencies.find(a => a.agency_id === bookieCode)
        return agency && agency.enabled
      } else {
        return []
      }
    }
    // updateNewColumnsHere () {
    //   const items = this.getColumns || []
    //   // const updatedColumns = { ...items }
    //   const allColumns = RUNNER_INFO_COLUMNS
    //   const columns = items.map(c => {
    //     return allColumns.find(i => c.selection === i.selection) || c
    //   })
    //   console.log(columns, items, allColumns, 'raceID', this.getSelectedColumnsName)
    //   this.updateSelectedColumns({ name: this.getSelectedColumnsName, columns })
    // }
  },
  // created () {
  //   this.updateNewColumns(this.getSelectedColumnsName)
  // },
  computed: {
    ...mapGetters(['getRaceFontSize', 'getGridSettings', 'getBetFairSettings', 'getBetLimits', 'getBestPrice', 'getSelectedMeeting', 'getPriceAlertsAudio', 'getSelectedRace', 'getColumns', 'getSelectedColumnsName', 'hasFeature', 'getColumnFlucs', 'getRaceCompetitors', 'getAvailableAgencies', 'getRunnerFlucs', 'getHighLightBestOdds', 'getNewPriceAlerts', 'getBetFairCommission', 'getAgencySettings', 'getRaceSortOrder', 'getSortedOrderBookie']),
    ...mapGetters('account', ['getBalances', 'getSessionId']),
    ...mapState(['dialogWatchlist', 'dialogAddToWatchlist', 'prices', 'selectedRace', 'flucs', 'testMode', 'editMode', 'noFeatureAlert', 'setHeight', 'acMode']),
    ...mapState('account', ['authorized']),
    race: function () {
      return this.getSelectedRace(this.getColumns)
    },
    meeting: function () {
      return this.getSelectedRace()?.meeting
    },
    selectedColumns: function () {
      return this.getColumns || []
    },
    balances: function () {
      return this.getBalances
    },
    hasCompetitors () {
      return this.getRaceCompetitors?.length > 0
    },
    extraSpaces: function () {
      return this.race.competitors
    },
    bettingEnabled: function () {
      return !!this.sessionId
    },
    sessionId: function () {
      return this.getSessionId
    },
    settings: function () {
      const agencySettings = this.getAgencySettings
      return agencySettings['totalBalance'] || {}
    },
    hideBalance: function () {
      return this.settings.hideBalance
    },
    selection: function () {
      if (!this.bettingEnabled) {
        return 'betButton'
      }
      if (!this.hideBalance) {
        return 'totalBalance'
      }
      if (this.hideBalance) {
        return 'tick'
      }
      return null
    }
  }
}
</script>

<style scope>
.raceTableColumnHead {
  min-width:42px;
  height:100%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right:1px solid #d8d8d8;
  border-top: 1px solid #d8d8d8;
}
.raceTableColumnDFRatings {
  min-width: 59px !important;
}
.raceTableColumnDFPrices {
  min-width: 71px !important;
}
.raceFontsizeSmall {
  font-size:10px !important;
}
.raceFontsizeMed {
  font-size:12px !important;
}
.raceFontsizeLrg {
  font-size:14px !important;
}
.editModeContainer {
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  padding:0px;
  background: #fff !important;
}
.normalModeContainer {
  z-index: 1;
  display: flex;
  width: 100%;
  padding:0px;
  background: #fff !important;
  height:100%;
  overflow:hidden;
}
.runnerSection {
  overflow-y:auto;
  width:320px;
  overflow-x:hidden;
  border-right:1px solid #d8d8d8;
}
.v-data-table td.priceColumn,
.v-data-table th {
  padding: 0 0px;
}
.priceRow .v-data-table td {
  padding: 0 0px;
}
thead {
  display: table-header-group;
  vertical-align: bottom;
  border-color: inherit;
}
.table-list {
    display: flex;
  }
.slide-fade-enter-active {
  transition: all 0.8s ease;
  background: greenyellow;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__selections {
  padding-top: 0px;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 0;
}
td.logo-head {
  width: 10px;
  min-width: 10px;
  padding: 0;
}
.v-data-table td.flucs {
  width: 150px;
}
.v-data-table td.market-percentage,
.v-data-table td.flucs {
  padding: 0;
}
.v-data-table table {
  width: 0;
}
.table-list-item {
  max-width: 150px;
  margin: 0;
  z-index: 200;
}
.raceGrid {
  display: flex;
  flex-direction: column;
  height:inherit;
  font-size:0.8rem;
}
.raceGridHeader {
  min-height: 5.3rem;
  overflow:hidden;
}
.raceGridMidSection {
  height: 100%;
  overflow:hidden;
}
.raceGridFooter {
  height: 80px;
}
.raceTableColumnDivBlank {
  box-sizing:border-box;
}

.raceTableColumnMainContainerBlank {
  z-index:200;
  position:relative;
}
.raceTableColumnContainerBlank {
}
.raceTableColumnBlank-price {
  border-bottom:0px;
}
.apolloColumn {
  height:100%;
}
.addColumnButtonMain {
  z-index:202;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}
.addColumnButtonMax {
  top: 81px;
  right: 12px;
  font-weight: 400 !important;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
</style>
