<template>
  <div>
    <Payment v-if="!userSub" :userSubscription="!userSub"/>
    <v-dialog
      v-model="showLoading"
      max-width="310"
      hide-overlay
      :transition="false"
    >
      <v-card>
        <v-card-title class="text-center primary--text">
          Loading race, please wait....
        </v-card-title>
        <v-img src="@/assets/race_types/DO_RaceType_Gallops-BLACK.svg" height="25" contain position="center" style="filter: invert(50%)"/>
        <v-progress-linear
          buffer-value="0"
          stream
          color="green"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showRacesFiltered"
      max-width="350"
      hide-overlay
      :transition="false"
    >
      <v-card>
        <v-card-title class="text-center primary--text">
          No races found (adjust filters) <br>or<br> all races are FINAL
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="didSomethingGoWrong"
      max-width="350"
      hide-overlay
      :transition="false"
    >
      <v-card>
        <v-card-title class="text-center primary--text" style="word-break: break-word">
          Oops, something went wrong - Refresh page or try again later if problem persists
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="onPageRefresh">
            Refresh
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="hasFeature('loginAlways') && changeEnableBetting" v-model="changeEnableBetting" max-width="500" hide-overlay :dialogSuccess="enableBetting">
      <EnableBetting :setLogin="setLogin"/>
    </v-dialog>
    <v-dialog v-if="hasFeature('formAnalyser') && showModalFaAccess" v-model="showModalFaAccess" max-width="500">
      <FormAnalyserAccess :setAccess="setShowModalFaAccess" />
    </v-dialog>
    <div class="raceContainer">
      <div class="headerGrid">
        <RaceHeader/>
      </div>
      <div class="oddsGrid">
        <RaceDetail/>
      </div>
      <NoFeature v-if="noFeatureAlert" @close="closeFeature()" />
    </div>
    <v-snackbar
      :timeout="-1"
      :value="version.stale"
      bottom
      right
      style="margin-bottom: 30px"
    >
      An update of DynamicOdds is available.
      <v-btn
        style="margin-left: 10px !important;"
        color="success"
        dark
        @click="refreshPage"
        small
      >
        Update &amp; Reload
      </v-btn>
      <v-btn
        style="margin-left: 18px !important"
        color="warning"
        dark
        small
        @click="dismissNotif"
      >
        Dismiss
      </v-btn>
    </v-snackbar>
    <v-snackbar
      :timeout="-1"
      :value="announcement.stale"
      bottom
      right
      style="margin-bottom: 30px"
    >
      {{announcement.message}}
      <v-btn
        style="margin-left: 18px !important"
        color="warning"
        dark
        small
        @click="dismissAnnouncement"
      >
        Dismiss
      </v-btn>
    </v-snackbar>
    <modal name="app-out-of-date" :clickToClose=false height=auto>
      <v-toolbar color="warning" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title class="raceResults-title">New DynamicOdds desktop version available!</v-toolbar-title>
          <v-btn class="cancelButton" icon small @click="closeAppAlert" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <br>
      <h2 style="text-align: center">For the best experience please download the latest version below.</h2>
      <br>
      <div class="downloadAppButt">
        <v-btn
          dark
          color="deep-purple accent-4"
          class="ma-2"
          href="https://betmakers.com/wp-content/uploads/DynamicOdds_Setup_1.0.3.exe" download
        >
          Windows 7/8/10 <v-icon class="ml-1">mdi-microsoft-windows</v-icon>
        </v-btn>
        <br>
        <v-btn
          dark
          class="ma-2"
          color="cyan lighten-1 accent-4"
          href="https://betmakers.com/wp-content/uploads/DynamicOdds_Mac_Setup_1.0.3.dmg" download
        >
          MAC OS <v-icon class="ml-1">mdi-apple</v-icon>
        </v-btn>
      </div>
      <div class="downloadAppButt">
        <v-btn
        small
          dark
          class="ma-2"
          color="teal darken-4 accent-4"
          href="https://betmakers.com/wp-content/uploads/Install_Instructions_MacOS.pdf" download
        >
          MAC Install Instructions <v-icon class="ml-1">mdi-card-text-outline</v-icon>
        </v-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import RaceHeader from '../components/RaceHeader'
import RaceDetail from '../components/RaceDetail'
import NoFeature from '../components/NoFeature'
import EnableBetting from '../components/EnableBetting'
import Payment from '../components/Payment'
import { mapState, mapActions, mapGetters } from 'vuex'
import FormAnalyserAccess from '../components/FormAnalyserAccess'

export default {
  components: {
    RaceDetail,
    RaceHeader,
    NoFeature,
    EnableBetting,
    Payment,
    FormAnalyserAccess
  },
  data: () => ({
    enableBetting: true,
    closeEnableBetting: true,
    showFormAnalyserModal: false,
    closeFormAnalyserModal: false
  }),
  // mounted () {
  //   this.checkDesktopVersion()
  // },
  computed: {
    ...mapGetters(['hasFeature', 'getAllRacesFilteredOut', 'getShowNoFAAccess']),
    ...mapGetters('account', ['getSessionId']),
    ...mapState(['noFeatureAlert', 'raceLoading', 'version', 'announcement', 'noRaces', 'didSomethingGoWrong']),
    ...mapState('account', ['authorized', 'userSubscription']),
    userSub () {
      if (this.hasFeature('userSubscriptions')) {
        return this.userSubscription
      }
      return true
    },
    enableBettingHere () {
      if (this.getSessionId) return false
      return true
    },
    changeEnableBetting () {
      if (this.enableBettingHere && !this.closeEnableBetting) {
        this.setLogin('open')
      } if (!this.enableBettingHere && this.closeEnableBetting) {
        this.setLogin('close')
      }
      return this.enableBetting
    },
    showModalFaAccess () {
      if (this.getShowNoFAAccess && !this.closeFormAnalyserModal) {
        this.setShowModalFaAccess('open')
      } else if (!this.getShowNoFAAccess && this.closeFormAnalyserModal) {
        this.setShowModalFaAccess('close')
      }
      return this.showFormAnalyserModal
    },
    showLoading () {
      if (this.getAllRacesFilteredOut) return false
      if (!this.enableBetting) return this.raceLoading && this.authorized
      return false
    },
    showRacesFiltered () {
      if (this.noRaces) {
        return true
      }
      return this.getAllRacesFilteredOut
    },
    checkBrowser () {
      if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return false
      }
      // Main process
      if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return false
      }
      // Detect the user agent when the `nodeIntegration` option is set to true
      if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['showFeatureAlert', 'setNoRaces', 'updateShowNoFAAccess']),
    closeAppAlert () {
      if (this.$modal) {
        this.$modal.hide('app-out-of-date')
      }
    },
    // checkDesktopVersion () {
    //   const currVersion = document.title
    //   console.log(currVersion)
    //   if (currVersion === 'DynamicOdds Desktop v1.0.3') {
    //     // eslint-disable-next-line no-useless-return
    //     return
    //   } else {
    //     if (this.$modal) {
    //       this.$modal.show('app-out-of-date')
    //     }
    //   }
    // },
    async closeFeature () {
      this.showFeatureAlert(false)
    },
    dismissAnnouncement () {
      this.announcement.stale = false
    },
    dismissNotif () {
      this.version.stale = false
    },
    refreshPage () {
      window.location.reload()
    },
    setLogin (v) {
      if (v === 'close') {
        this.enableBetting = false
        this.closeEnableBetting = true
      } if (v === 'open') {
        this.enableBetting = true
        this.closeEnableBetting = false
      }
    },
    setShowModalFaAccess (v) {
      if (v === 'close') {
        this.showFormAnalyserModal = false
        this.closeFormAnalyserModal = true
        this.updateShowNoFAAccess({ value: false })
        this.closeFormAnalyserModal = false
      } if (v === 'open') {
        this.showFormAnalyserModal = true
        this.closeFormAnalyserModal = false
      }
    },
    onPageRefresh () {
      window.location.reload()
      return false
    }
  }
}
</script>

<style scoped>
  .raceContainer {
    display: flex;
    flex-direction: column;
    height:inherit;
  }
  .headerGrid {
    flex-basis: 100px;
  }
  .oddsGrid {
    flex-grow: 4;
    flex-basis: auto;
    overflow:hidden;
  }
  .loading {
    width: 80vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-progress-circular {
    margin: 1rem;
  }
  .downloadAppButt {
    display: flex;
    justify-content: center;
    margin-bottom: 10px
  }
</style>
