<template>
  <v-card  class="bettingAgencyLogin" v-on:keyup.enter="enableBettingLogin(logedinUsername, password)">
    <v-list-item v-if="dialogSuccess" class="primary">
    <v-list-item-content class="ml-3 white--text">
      <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-flash</v-icon>Logged in</v-list-item-title>
    </v-list-item-content>
    <v-btn icon small @click="setLogin('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
    </v-list-item>
    <v-list-item v-if="!dialogSuccess" class="warning">
      <v-list-item-content class="ml-1 white--text">
        <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-account-key-outline</v-icon>Security - Betting Authority</v-list-item-title>
      </v-list-item-content>
      <v-btn icon small @click="setLogin('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
    </v-list-item>
    <v-container v-if="dialogSuccess && !dialogStatus" fluid>
      <v-btn class="tick" text><v-icon color="success">mdi-check</v-icon></v-btn>
      Login successful
    </v-container>
    <v-container v-else fluid class="text-center">
      <span class="subtitle-1 ">
        To enable <strong>betting</strong> we need to confirm it's you<br>
        Please re-enter your <em>Dynamicodds</em> password
        <br>
      </span>
      <div v-if="error" class="subtitle-1 errorMessage">
            {{ error }}
      </div>
      <v-card class="loginDetails mt-5" elevation="0">
        <v-text-field
          class="username"
          label="Username"
          placeholder="Username"
          style="max-width:150px;"
          :value="logedinUsername"
        ></v-text-field>
        <v-text-field
          class="password"
          label="Password"
          placeholder="Password"
          style="max-width:150px;"
          type="password"
          v-model="password"
        ></v-text-field>
      </v-card>
      <div class="loginActions">
        <v-btn type="submit" class="loginButton" color="success" @click="enableBettingLogin(logedinUsername, password)">
          Login
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    username: null,
    password: null,
    error: null,
    dialogStatus: false
  }),
  props: {
    dialogSuccess: Boolean,
    setLogin: Function
  },
  computed: {
    ...mapGetters('account', ['getUsername']),
    logedinUsername: function () {
      return this.getUsername
    }
  },
  methods: {
    ...mapActions('account', ['confirmSignin', 'agencyLogin']),
    ...mapActions(['loginBetting']),
    async login (username, password, bookieCode) {
      const result = await this.agencyLogin({ username, password, bookieCode })
      if (result.success) {
        this.dialogSuccess = true
        setTimeout(() => {
          this.dialog = false
        }, 1500)
      } else {
        this.error = result.message
      }
    },
    async enableBettingLogin (username, password) {
      const result = await this.confirmSignin({ username, password })
      if (result.success) {
        this.loginBetting()
        this.dialogStatus = true
        setTimeout(() => {
          this.setLogin('close')
        }, 1500)
      } else {
        this.error = result.message
      }
    }
  }
}
</script>

<style>

</style>
