<template>
  <v-list>
    <v-list-group
      v-for="item in items"
      :key="item.title"
      v-model="item.active"
      no-action
      :value="false"
      style="background:rgb(25, 118, 210, 0.1)"
      class="runnerInfoMenu-group"
    >
      <template v-slot:activator>
        <v-list-item-content class="pa-2 infoMenu_title" fixed>
          <v-list-item-title v-text="item.title" class="body-2"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-divider></v-divider>
      <div
        class="pa-3 overflow-y-auto"
        style="background-color: rgba(0, 0, 0, 0.05); max-height: 300px;"
        >

        <v-list-item
            v-for="subItem in item.items"
            :key="subItem.selection"
            class="runnerInfoMenuItem"
          >
          <v-chip label color="blue" dark small class="d-inline">{{subItem.title}}</v-chip>
          <v-chip v-if="subItem.linktype === 'Free'" label color="green" dark small class="d-inline ml-2" :href="subItem.url" target="blank">{{subItem.linktype}}</v-chip>
          <v-chip v-else label color="orange" dark small class="d-inline ml-2" :href="subItem.url" target="blank">{{subItem.linktype}}</v-chip>
          <v-list-item-content>
            <v-list-item-title v-text="items.title"></v-list-item-title>
          </v-list-item-content>
          <v-btn v-if="subItem.show" class="runnerInfoMenuItem-button" x-small @click="handleClick(subItem)">Add</v-btn>

        </v-list-item>
      </div>
      <v-divider></v-divider>
    </v-list-group>
  </v-list>
</template>
<script>

import { mapActions } from 'vuex'

export default {
  data: () => ({
    fav: true,
    menu: false,
    items: [
      {
        type: 'runnerInfo',
        title: 'Subscription / Trial',
        items: [
          // stats fields MUST include 'statsDO' in the first part of the selection field eg: statsDist
          // RUNNER_HISTORY = DO   RUNNER_INFO = COREDB
          { source: 'INF-aap_prepost', type: 'RUNNER_HISTORY', selection: 'aap_prepost', title: 'AAP Pre-Post', display: 0, url: 'https://www.aapmegaform.com.au/', linktype: 'Free' },
          { source: 'INF-rating_champion_bets', type: 'RUNNER_HISTORY', selection: 'rating_champion_bets', title: 'Championbets', display: 0, url: 'https://www.championbets.com.au/product-category/horse-racing-tips/', linktype: 'Subscribe' },
          { source: 'INF-rating_dynamicform', type: 'RUNNER_HISTORY', selection: 'rating_dynamicform', title: 'DynamicForm Ratings', display: 0, url: 'https://dynamicform.com.au/', linktype: 'Subscribe' },
          { source: 'INF-my_price_dynamicform', type: 'RUNNER_HISTORY', selection: 'my_price_dynamicform', title: 'My Price DynamicForm', display: 0, url: 'https://dynamicform.com.au/', linktype: 'Subscribe' },
          { source: 'INF-odds_dynamicform', type: 'RUNNER_HISTORY', selection: 'odds_dynamicform', title: 'Odds DynamicForm', display: 0, url: 'https://dynamicform.com.au/', linktype: 'Subscribe' },
          { source: 'INF-rating_winform', type: 'RUNNER_HISTORY', selection: 'rating_winform', title: 'Winform', display: 0, url: 'https://horseracingaustralia.info/shop/', linktype: 'Subscribe' },
          { source: 'INF-rating_winning_edge', type: 'RUNNER_HISTORY', selection: 'rating_winning_edge', title: 'Winning Edge', display: 0, url: 'https://winningedgeinvestments.com/products', linktype: 'Subscribe' }
        ]
      }
    ]
  }),
  props: {
    addColumn: Function,
    flucs: Boolean
  },
  async created () {
    const subLevel = await this.getDFSubscriptionLevel()
    this.items[0].items.forEach(i => {
      if (subLevel) {
        i.show = true
      } else if (!subLevel && (i.selection !== 'rating_dynamicform' && i.selection !== 'my_price_dynamicform' && i.selection !== 'odds_dynamicform')) {
        i.show = true
      } else {
        i.show = false
      }
    })
  },
  methods: {
    ...mapActions('account', ['getDFSubscriptionLevel']),
    handleClick: function (column) {
      this.addColumn(column)
    }
  }
}
</script>

<style>
  .bookieLogo {
    display: flex;
    margin: 0 15px 0 0;
  }
  .runnerInfoMenuItem button {
    margin-right: 5px;
  }
  .v-list-item--active {
    background-color: rgb(25, 118, 210, 0.2);
  }
</style>
