<template>
  <v-list>

      <v-divider></v-divider>
      <div
        class="pa-3 overflow-y-auto"
        style="background-color: rgba(0, 0, 0, 0.05); max-height: 300px;"
        >

          <v-list-item
            class="spacerMenuItem"
          >

          <v-btn class="spacerMenuItem-button" x-small @click="handleClick">Add Spacer</v-btn>

        </v-list-item>
      </div>
      <v-divider></v-divider>
  </v-list>
</template>

<script>

export default {
  props: {
    addColumn: Function,
    flucs: Boolean
  },
  methods: {
    handleClick: function () {
      const column = { source: 'SPC', type: 'spacer', display: 2 }
      this.addColumn(column)
    }
  }
}
</script>

<style>
  .bookieLogo {
    display: flex;
    margin: 0 15px 0 0;
  }
  .spacerMenuItem button {
    margin-left: auto;
    margin-right: 5px;
  }
  .v-list-item--active {
    background-color: rgb(25, 118, 210, 0.2);
  }
</style>
