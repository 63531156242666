<template>
  <ApolloQuery
    :query="exoticsQuery"
    fetchPolicy="network-only"
  >
   <template v-slot="{ result: { loading, error, data } }">
    <v-card class="resultsModal">
      <v-toolbar color="primary" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title class="raceResults-title"> Race Results </v-toolbar-title>
          <v-btn class="cancelButton" icon small @click="closeResultsSummary()" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card elevation="0" class="pa-2 mb-n2 pt-2">
        <div class="headings">
          <v-chip class="headings_label ma-1 pa-3" tile color="success" label><span>Status: {{ status(data) }}</span></v-chip>
          <v-chip class="headings_label ma-1 mr-4 pa-3" tile label><span>Win Dividends</span></v-chip>
          <v-chip class="headings_label ma-1 pa-3" tile label><span>Place Dividends</span></v-chip>
        </div>
        <v-spacer class="mt-2"></v-spacer>
        <data-table class="fontfix resultsTable" :items="competitors(data)" item-key="tabNo" :headers="headers" hide-default-footer hide-default-header disable-pagination dense style="background-color:white;">
          <template v-slot:header="{ props: { headers } }">
            <tr>
              <td class="headerSilkRunner"></td>
              <td class="headerNameRunnerMobile"></td>
              <!-- below empty TD is so footer %'s background colours line up in the current colspan arrangement -->
              <td></td>
              <template v-for="(h, i) in headers">
                <td :key="`bm-${i}`" :class="['logo-head ' + 'logoColor-' + h.source, selectedColumn === i ? 'selectedColumn' : '' ]">
                  <RaceBookmakers :bookieCode="h.source" :columnHeader="h.label" :editMode="true" :columnType="h.type"/>
                </td>
              </template>
            </tr>
          </template>
          <template v-slot:item="{ item, headers }">
            <tr>
              <td class="RunnerNumber text-start" style="padding-left:5px;padding-right:5px;height:34px;">
                <div v-if="meeting.type === 'THOROUGHBRED' || meeting.type ==='HARNESS'" class="horseRacingNumberDiv">
                  <div style="line-height:25px;">{{ item.competitor.tabNo }}</div>
                </div>
                <div v-if="meeting.type === 'GREYHOUND'" class="greysRacingNumberDiv">
                  <div style="line-height:25px;">{{ item.competitor.tabNo }}</div>
                </div>
                <div style="float:right">
                  <RaceRunners :meeting="meeting" :race="race" :item="item.competitor" :editMode="false"/>
                </div>
              </td>
              <RaceNames :raceId="race.id" :resultsMode="'width:220px;'" :item="item.competitor" :meeting="meeting" :mobile="false" :results="data.race.results" hideDetails :acMode="acMode" :showJockeys="true"/>
              <!-- below empty TD is so footer %'s background colours line up in the current colspan arrangement -->
              <td></td>
              <template>
                <template v-for="(h, i) in headers">
                  <td :key="`pc-${race.meeting.id}-${race.number}-${i}`">
                    <div class="bookiePriceRes"><span>{{ price(h, item, data) }}</span></div>
                  </td>
                </template>
              </template>
            </tr>
          </template>
        </data-table>
      </v-card>
      <v-card class="pa-2">
       <data-table class="fontfix resultsTable" :items="exotics" item-key="tabNo" :headers="headers.filter(h => h.type === 'WIN')" hide-default-footer hide-default-header disable-pagination dense style="background-color:white;">
          <template v-slot:header="{ props: {} }">
            <tr>
              <td class="headerSilkRunner"></td>
              <td class="headerNameRunnerMobile"></td>
              <!-- below empty TD is so footer %'s background colours line up in the current colspan arrangement -->
              <td></td>
            </tr>
          </template>
          <template v-slot:item="{ item, headers }">
            <tr v-if="showDividends(item.type, data)">
              <td class="exotics_title">{{ item.title }} ({{ dividendNumbers(item.type, data) }})</td>
              <template>
                <template v-for="(h, i) in headers">
                  <td class="exotics_price " :key="`pc-${race.meeting.id}-${race.number}-${i}`">
                    <div class="bookiePriceRes"><span>{{ dividend(item.type, h.source, data) }}</span></div>
                  </td>
                </template>
              </template>
            </tr>
          </template>
        </data-table>
        <v-checkbox
        v-model="selected"
        value="raceResults"
        class="font-weight-bold"
        label="Show results automatically on Interim / Final"
      ></v-checkbox>
      </v-card>
    </v-card>
   </template>
  </ApolloQuery>
</template>

<script>
import { exoticsPriceQuery } from '../../common/queries'
import { RESULTS_COLUMNS } from '../../common/constants'
import RaceBookmakers from '../RaceBookmakers'
import RaceNames from '../RaceNames'
import RaceRunners from '../RaceRunners'
import DataTable from '../DataTable'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    widget: false,
    selectedColumn: null,
    exotics: [
      { type: 'QUINELLA', title: 'Quinella' },
      { type: 'EXACTA', title: 'Exacta' },
      { type: 'TRIFECTA', title: 'Trifecta' },
      { type: 'FIRST_FOUR', title: 'First Four' },
      { type: 'DAILY_DOUBLE', title: 'Daily Double' },
      { type: 'EXTRA_DOUBLE', title: 'Extra Double' },
      { type: 'TREBLE', title: 'Treble ' },
      { type: 'QUADRELLA', title: 'Quadrella' }
    ]
  }),
  props: {
    resultsSummaryDiag: Boolean,
    closeResultsSummary: Function,
    race: Object,
    meeting: Object,
    runners: Array,
    showResults: String
  },
  components: {
    RaceBookmakers,
    RaceNames,
    RaceRunners,
    DataTable
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['getPrice']),
    selected: {
      get () {
        return this.showResults
      },
      set (value) {
        return this.onClick(value)
      }
    },
    headers () {
      return RESULTS_COLUMNS
    },
    exoticsQuery () {
      return exoticsPriceQuery(this.race.id)
    },
    newHeaders: function () {
      const cols = [
        {
          text: '',
          value: 'tabNo'
        },
        {
          text: 'Name',
          value: 'Name'
        }
      ]
      RESULTS_COLUMNS.forEach((x) => {
        if (x.source) {
          cols.push({
            text: x.source,
            value: x.source.toLowerCase() + ':' + x.type + ':price',
            link: x.link,
            width: 10,
            class: 'priceColumn',
            align: 'end',
            priceColumn: true,
            display: x.display || 0,
            type: x.type
          })
        } else {
          cols.push({ priceColumn: false })
        }
      })
      return cols
    }
  },
  methods: {
    ...mapActions(['updateRaceGridSettings']),
    onClick (setting) {
      this.updateRaceGridSettings({ filter: 'raceResults', value: setting })
    },
    status: function (data) {
      return data?.race?.status || null
    },
    competitors: function (data) {
      var res = Math.max.apply(Math, data?.race?.results?.map(r => r.positions.length))
      var result = data?.race?.results?.find(r => r.positions.length === res)
      const positions = result ? Array.from(new Set(result.positions)) : []
      return positions?.filter(c => c.position <= 4).sort((a, b) => a.position - b.position) || null
    },
    price: function (column, item, data) {
      if (column.source === 'BF' && item.position === 1 && column.type === 'WIN') return this.getSpPrice(item.competitor.tabNo, data)
      // if (column.source === 'BF' && item.position === 1 && column.type === 'PLACE') return this.getSpPrice(item.competitor.tabNo, data)
      if (column.source === 'OP' && item.position === 1 && column.type === 'WIN') return this.getPrice(item.competitor.tabNo, `${this.race.id}:OP:WIN_FIXED_ODDS`)?.price
      const dividends = data?.race?.results.find(r => r.source === column.source)
      const dividend = dividends?.dividends.find(d => d.type === column.type && d.dividendNumbers[0] === item.competitor.tabNo) || 0
      return dividend?.dividend || null
    },
    dividend: function (type, source, data) {
      const bookie = data?.race?.results.find(r => r.source === source)
      return bookie?.dividends?.find(d => d.type === type)?.dividend || null
    },
    showDividends (type, data) {
      const bookie = data?.race?.results.find(result => {
        return result.dividends.find(d => d.type === type)
      })
      if (typeof bookie !== 'undefined') {
        return true
      }
      return false
    },
    dividendNumbers: function (type, data) {
      const bookie = data?.race?.results.find(result => {
        return result.dividends.find(d => d.type === type)
      })
      return bookie?.dividends?.find(d => d.type === type)?.dividendNumbers?.join('-') || null
    },
    getSpPrice: function (tabNo, data) {
      const price = data.race.betFairMarkets?.selections.find(s => s.number === tabNo)
      return price?.sellingPrice
    }
  }
}
</script>

<style scoped>
  .headings {
    display: flex;
  }
  .headings_label {
    width: 280px;
  }
  .exotics_title {
    width: 287px;
    text-align: right;
    font-size: 12px;
    font-weight: bold;
  }
  .exotics_price {
    width: 42px;
  }
  .titleBar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cancelButton {
  }
  .bookiePriceRes {
    padding: 0px 2px;
    min-width: 25px;
    cursor: pointer;
    min-height:32px;
    max-height:32px;
    line-height:32px;
    text-align:right;
    font-size:0.70rem;
    padding-right:4px;
  }
  .resultsModal{
    z-index:202;
    max-width: 750px;
  }
  .greysRacingNumberDiv {
  float:left;
  margin-right:5px;
  width: 27px;
  height: 25px;
  border: 1px solid rgb(216, 216, 216);
  background-color: rgb(255, 255, 255);
  font-weight:900;
  text-align: center;
  margin-bottom:3px;
  margin-top:3px;
  }
  .horseRacingNumberDiv {
    float:left;
    width: 27px;
    height: 25px;
    border: 1px solid rgb(216, 216, 216);
    background-color: rgb(255, 255, 255);
    font-weight:900;
    text-align: center;
    margin-bottom:3px;
    margin-top:3px;
  }
  </style>
