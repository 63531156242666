<template>
  <v-row :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
    <v-col cols="12" sm="4" md="4" class="mt-n5 pt-0 countryFilterCol">
      <v-checkbox class="scheduleLabels countryFilter" label="Australia" color="primary" v-model="selected" value="AUS" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="New Zealand" color="primary" v-model="selected" value="NZL" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="USA/CA" color="primary" v-model="selected" value="USA/CAN" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="Singapore" color="primary" v-model="selected" value="SGP" hide-details></v-checkbox>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="mt-n5 pt-0 countryFilterCol">
      <v-checkbox class="scheduleLabels countryFilter" label="United Kingdom" color="primary" v-model="selected" value="GBR" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="Ireland" color="primary" v-model="selected" value="IRL" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="France" color="primary" v-model="selected" value="FRA" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="Japan" color="primary" v-model="selected" value="JPN" hide-details></v-checkbox>
    </v-col>
    <v-col cols="12" sm="4" md="4" class="mt-n5 pt-0 countryFilterCol">
      <v-checkbox class="scheduleLabels countryFilter" label="South Africa" color="primary" v-model="selected" value="ZAF" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="Hong Kong" color="primary" v-model="selected" value="HKG" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels countryFilter" label="Other (ie: Sweden, Mexico)" color="primary" v-model="selected" value="OTHER" hide-details></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    countries: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    ...mapGetters(['getIsCountrySelectedFilter']),
    selected: {
      get () {
        return this.countries
      },
      set (value) {
        return this.onClick(value)
      }
    }
  }
}
</script>
