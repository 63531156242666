<template>
  <div>
    <template>
      <v-btn v-if="hasFeature('meetingSummary')" outlined :color="dialogMeetingSummary ? 'info' : 'normal'" x-small class="ma-1 float-left meetingSummaryButton" @click="openMeetingSummary" style="overflow: auto; margin: 2px !important;">
        Summary
      </v-btn>
    </template>
    <v-dialog v-if="hasFeature('meetingSummary')" v-model="dialogMeetingSummary" class="dialogMeetingSummary" persistent hide-overlay scrollable max-width="59%" transition="none">
      <v-card class="cardMeetingSummary">
        <v-toolbar color="primary" dark dense>
          <div class="titleBar-content">
            <v-toolbar-title class="raceResults-title"> Meeting Summary </v-toolbar-title>
            <v-btn class="cancelButton" icon small @click="closeMeetingSummary" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title style="padding: 5px 24px 5px !important;">
          <v-list class="pa-0" style="margin-right: 20px;">
            <v-list-item class="pa-0">
              <v-menu ref="menuMeetingSummary" v-model="menuMeetingSummary" transition="scale-transition" offset-y min-width="0%" :nudge-right="40">
                <template v-slot:activator="{ on }">
                  <v-text-field class="formattedDate" v-model="formattedDateMeetingSummary" small prepend-icon="mdi-calendar" readonly v-on="on" style="font-size:14px; padding-top: 0px; margin-top: 0px;" hide-details=""></v-text-field>
                </template>
                <v-date-picker v-model="dateMeetingSummary" no-title scrollable actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuMeetingSummary = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuMeetingSummary.save(dateMeetingSummary)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-list-item>
          </v-list>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="normal" x-small class="ma-1" v-bind="attrs" v-on="on" style="overflow: auto; margin: 2px !important;">
                Countries
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="countriesMeetingSummary">
                <Countries :countries="getIsCountrySelectedFilter" :onClick="onCountryClick" />
              </v-card-text>
            </v-card>
          </v-menu>
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="normal" x-small class="ma-1" v-bind="attrs" v-on="on" style="overflow: auto; margin: 2px !important;">
                Codes
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <Codes :codes="getIsCodesSelectedFilter" :onClick="onCodesClick" />
              </v-card-text>
            </v-card>
          </v-menu>
          <v-btn v-if="hasFeature('showHideMeetingRaces')" outlined :color="showHideMeetingsRaces ? 'info' : 'normal'" x-small class="ma-1" @click="showHideMeetingsRacesClick" style="overflow: auto; margin: 2px !important;">
            Show/Hide Meetings &amp; Races
          </v-btn>
          <v-btn v-if="hasFeature('showHideMeetingRaces') && showHideMeetingsRaces" color="red" dark x-small class="ma-1" @click="resetShowHideMeetingsRacesClick" style="overflow: auto; margin: 2px 30px !important;">
            <v-icon style="padding-right: 5px" small>mdi-refresh</v-icon>Reset To Show ALL Races
          </v-btn>
          <v-btn v-if="hasFeature('showHideMeetingRaces') && showHideMeetingsRaces && isEditing" color="green" dark x-small class="ma-2" @click="saveRacesSelected(); updateRaceFilter(); isEditing = false;" style="overflow: auto; margin: 2px !important;">
            <v-icon style="padding-right: 5px" small>mdi-content-save</v-icon>Save Changes
          </v-btn>
          <v-btn v-if="hasFeature('showHideMeetingRaces') && showHideMeetingsRaces && !isEditing" disabled depressed x-small class="ma-2" style="overflow: auto; margin: 2px !important;">
            <v-icon style="padding-right: 5px" small>mdi-content-save</v-icon>Save Changes
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="padding: 0px 0px 0px 0px;">
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="blue"
          ></v-progress-linear>
          <table class="meetingSummaryTable">
            <thead>
              <tr>
                <th class="headcol"></th>
                <th v-for="n in 22" :key="n" style="text-align: center;">R{{ n }}</th>
              </tr>
            </thead>
            <tbody v-for="(racesByType, raceType) in sortedMeetingSummary" :key="raceType">
              <tr style="height: 35px !important;">
                <td class="headcol columnRaceType">
                  <img :src="raceTypeImg[raceType]" style="max-width:100%; margin-right: 10px;" />
                  <strong class="raceType">{{ raceType }}</strong>
                  <input class="meetingCheckbox" type="checkbox" v-show="showHideMeetingsRaces" @change="showHideMeetingType($event, raceType)" @click="isEditing = true" :checked="!meetingsTypeSelected.includes(raceType)">
                </td>
                <td v-for="n in 22" :key="n"></td>
              </tr>
              <tr v-for="(races, raceName) in racesByType" :key="raceName">
                <td :class="meetingsSelected.includes(races[0].meeting.id) ? 'headcol meetingRaceHid' : 'headcol'" style="vertical-align: bottom;">
                  <v-row class="meetingInSummary">
                    <country-flag :country="races[0].countryGBS" size="small" style="margin-right: -20px; margin: -21px;" /> {{ raceName }}
                    <!--<v-checkbox v-show="showHideMeetingsRaces" @change="showHideMeeting" v-model="meetingsSelected" :value="races[0].meeting.id" dense hide-details class="float-right" style="margin-top: 0px !important; padding-top: 0px!important;"></v-checkbox>-->
                    <input class="meetingCheckbox" type="checkbox" v-show="showHideMeetingsRaces" @click="isEditing = true" @change="showHideMeeting($event, races[0].meeting.id)" :checked="!meetingsSelected.includes(races[0].meeting.id)">
                  </v-row>
                </td>
                <td v-for="(race, key) in races" :key="key" :class="showHideMeetingsRaces ? 'checkBoxesShowed' : '' " style="text-align: center; vertical-align: bottom;">
                  <v-tooltip bottom color="black" open-delay="400">
                    <template v-slot:activator="{ on }">
                      <span v-if="race.status === 'ABANDONED'" v-show="!showHideMeetingsRaces" style="text-align: center; cursor:pointer; color: red;" @click="clickRace(race)" v-on="on" :class="racesSelected.includes(race.id) || meetingsSelected.includes(race.meeting.id) ? 'meetingRaceHid' : ''">ABND</span>
                      <span v-else v-show="!showHideMeetingsRaces" style="text-align: center; cursor:pointer;" @click="clickRace(race)" v-on="on" :class="racesSelected.includes(race.id) || meetingsSelected.includes(race.meeting.id) ? 'meetingRaceHid' : ''">{{ race.startTime }}</span>
                    </template>
                    <span class="tooltiptextRaceNavigation caption"><pre class="tooltipTextRaceNavigationPreformatted">Race {{ race.raceNumber }} - {{ race.raceName }}</pre></span>
                  </v-tooltip>
                  <!--<td v-show="showHideMeetingsRaces" v-on="on" :class="showHideMeetingsRaces ? 'checkBoxesShowed' : '' " >
                    <v-checkbox @change="showHideRace" v-model="racesSelected" :value="race.id" dense hide-details style="margin-top: 0px !important; padding-top: 0px!important;"></v-checkbox>-->
                  <span v-show="showHideMeetingsRaces">
                    <!--<input v-if="meetingsSelected.includes(race.meeting.id)" type="checkbox" @change="showHideRace($event, race.id)" :value="race.id">-->
                    <input type="checkbox" @change="showHideRace($event, race.id)" @click="isEditing = true" :checked="!racesSelected.includes(race.id)">
                  </span>
                  <!--</td>-->
                </td>
                <td v-for="n in (races.length < 22 ? 22 - races.length : races.length)" :key="'race'+n"></td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import Countries from './filters/Countries'
import Codes from './filters/Codes'
import { debounce } from 'debounce'

export default {
  name: 'MeetingSummary',
  components: {
    Countries,
    Codes
  },
  props: {
    meetingSummaryToggle: Boolean,
    closeSummary: Function,
    closeMeetingSummaryModal: Boolean
  },
  data: () => ({
    menuMeetingSummary: false,
    raceTypeImg: {
      HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
      GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
      THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
    },
    showHideMeetingsRaces: true,
    meetingsSelected: [],
    racesSelected: [],
    meetingsTypeSelected: [],
    dialogMeetingSummary: false,
    isEditing: false,
    isLoading: true
  }),
  computed: {
    ...mapState(['races', 'selectedDateMeetingSummary']),
    ...mapGetters(['getRaces', 'getIsCountrySelectedFilter', 'getIsCodesSelectedFilter', 'hasFeature', 'getMeetingSummaryRaces', 'getCustomShowHideMeetingsData', 'getCustomShowHideRacesData', 'getAllMeetingSummaryRaces', 'getAllMeetingSummaryMeetings', 'getCustomShowHideMeetingTypesData']),
    dateMeetingSummary: {
      get () {
        const d = format(this.selectedDateMeetingSummary, 'yyyy-MM-dd')
        return d
      },
      async set (n) {
        await this.setDateMeetingSummary(utcToZonedTime(new Date(n)))
        this.clearData()
      }
    },
    meetingSumOpen () {
      return this.meetingSummaryToggle
    },
    closeMeetingSummaryFromModal () {
      return this.closeMeetingSummaryModal
    },
    formattedDateMeetingSummary () {
      return format(this.selectedDateMeetingSummary, 'MMM d, yyyy')
    },
    sortedMeetingSummary: function () {
      const races = this.getMeetingSummaryRaces
      const racesByMeetingType = {}
      var racesByName = {}
      for (const key in races) {
        if (!racesByMeetingType[races[key].meeting.type]) {
          racesByMeetingType[races[key].meeting.type] = []
        }

        racesByMeetingType[races[key].meeting.type].push({
          displayName: races[key].meeting.track.name,
          id: races[key].id,
          meeting: {
            ExternalMeetingID: races[key].meeting.externalIDs[0],
            id: races[key].meeting.id
          },
          raceNumber: races[key].number,
          countryGBS: races[key].meeting.track.country,
          meetingType: races[key].meeting.type,
          startTime: format(parseISO(races[key].startTime), 'HH:mm'),
          status: races[key].status,
          raceName: races[key].name
        })
      }

      for (const type in racesByMeetingType) {
        racesByName = {}
        for (const race in racesByMeetingType[type]) {
          const name = racesByMeetingType[type][race].displayName
          if (!racesByName[name]) {
            racesByName[name] = []
          }
          racesByName[name].push(racesByMeetingType[type][race])
        }

        var racesByNameSorted = {}
        Object.keys(racesByName).sort().forEach(function (v) {
          racesByNameSorted[v] = racesByName[v]
        })

        racesByMeetingType[type] = racesByNameSorted
      }

      var racesByMeetingTypeSorted = {}
      Object.keys(racesByMeetingType).sort().reverse().forEach(function (v) {
        racesByMeetingTypeSorted[v] = racesByMeetingType[v]
      })
      if (Object.keys(racesByMeetingTypeSorted).length > 1) {
        this.setLoading()
      }
      return racesByMeetingTypeSorted
    }
  },
  watch: {
    meetingSumOpen () {
      if (this.meetingSummaryToggle) {
        this.openMeetingSummary()
        this.dialogMeetingSummary = true
        this.showHideMeetingsRaces = true
      }
    },
    closeMeetingSummaryFromModal () {
      if (this.closeMeetingSummaryModal) {
        this.$modal.hide('ms-unsaved-modal')
        this.dialogMeetingSummary = false
        this.closeSummary()
      }
    }
  },
  methods: {
    ...mapActions(['setDateMeetingSummary', 'updateRaceNavFilter', 'setDateMeetingSummary', 'selectRace', 'setCustomData', 'selectRaceNav', 'fetchMeetingSummaryRaces']),
    setLoading () {
      this.isLoading = false
    },
    onCountryClick (countries) {
      this.updateRaceNavFilter({ filter: 'countries', value: countries })
    },
    onCodesClick (codes) {
      this.updateRaceNavFilter({ filter: 'codes', value: codes })
    },
    updateRaceFilter () {
      this.$store.dispatch('updateRaceNavFilter', { filter: 'races', value: this.racesSelected })
    },
    saveRacesSelected () {
      const date = this.dateMeetingSummary

      this.setCustomData({ id: date, columnName: 'meetings-selected', value: this.meetingsSelected })
      this.setCustomData({ id: date, columnName: 'races-selected', value: this.racesSelected })
      this.setCustomData({ id: date, columnName: 'meeting-types-selected', value: this.meetingsTypeSelected })
    },
    resetShowHideMeetingsRacesClick () {
      this.meetingsSelected = []
      // this.updateRaceNavFilter({ filter: 'meetings', value: this.meetingsSelected })
      const date = this.dateMeetingSummary
      debounce(this.setCustomData({ id: date, columnName: 'meetings-selected', value: this.meetingsSelected }), 200)

      this.racesSelected = []
      // this.updateRaceNavFilter({ filter: 'races', value: this.racesSelected })
      debounce(this.setCustomData({ id: date, columnName: 'races-selected', value: this.racesSelected }), 200)
    },
    async showHideMeetingsRacesClick () {
      this.showHideMeetingsRaces = !this.showHideMeetingsRaces
    },
    showHideMeetingType (e, meetingType) {
      // const meetings = this.$store.state.meetings
      const meetings = this.getAllMeetingSummaryMeetings()
      var findMeetingsByType = []
      for (const meetingID in meetings) {
        if (meetings[meetingID].type === meetingType) {
          findMeetingsByType.push(meetingID)
        }
      }

      if (e.target.checked) {
        const index = this.meetingsTypeSelected.indexOf(meetingType)
        if (index > -1) {
          this.meetingsTypeSelected.splice(index, 1)
          findMeetingsByType.forEach(mbt => {
            this.showHideMeeting(e, mbt)
          })
        }
      } else {
        this.meetingsTypeSelected.push(meetingType)
        findMeetingsByType.forEach(mbt => {
          this.showHideMeeting(e, mbt)
        })
      }
    },
    showHideMeeting (e, meetingID) {
      // const races = this.$store.state.meetings[meetingID].races
      const races = this.getAllMeetingSummaryRaces(meetingID)
      if (e.target.checked) {
        const index = this.meetingsSelected.indexOf(meetingID)
        if (index > -1) {
          this.meetingsSelected.splice(index, 1)
          races.forEach(race => {
            const raceIndex = this.racesSelected.indexOf(race.id)
            if (raceIndex > -1) { this.racesSelected.splice(raceIndex, 1) }
            // console.log('show meeting', this.meetingsSelected)
          })
        }
      } else {
        this.meetingsSelected.push(meetingID)
        races.forEach(race => {
          this.racesSelected.push(race.id)
        })
        // console.log('hide meeting', this.meetingsSelected)
      }
    },
    showHideRace (e, raceID) {
      if (e.target.checked) {
        const index = this.racesSelected.indexOf(raceID)
        if (index > -1) { this.racesSelected.splice(index, 1) }
      } else {
        this.racesSelected.push(raceID)
      }
    },
    openMeetingSummary () {
      this.fetchMeetingSummaryRaces()
      const date = this.dateMeetingSummary
      this.getCustomDataShowHideMeetings(date)
      this.getCustomDataShowHideRaces(date)
      this.getCustomDataShoHideMettingTypes(date)
      this.showHideMeetingsRaces = false
      this.dialogMeetingSummary = true
    },
    closeMeetingSummary () {
      if (this.isEditing) {
        this.$modal.show('ms-unsaved-modal')
        return
      }
      this.dialogMeetingSummary = false
      this.closeSummary()
    },
    clickRace (v) {
      this.dialogMeetingSummary = false
      this.selectRaceNav(1)
      this.selectRace({
        id: v.id
      })
    },
    getCustomDataShowHideMeetings (id) {
      const data = this.getCustomShowHideMeetingsData(id)
      if (data && data['meetings-selected']) {
        this.meetingsSelected = data['meetings-selected']
        return true
      } else {
        this.meetingsSelected = []
      }
    },
    getCustomDataShowHideRaces (id) {
      const data = this.getCustomShowHideRacesData(id)
      if (data && data['races-selected']) {
        this.racesSelected = data['races-selected']
        return true
      } else {
        this.racesSelected = []
      }
    },
    getCustomDataShoHideMettingTypes (id) {
      const data = this.getCustomShowHideMeetingTypesData(id)
      if (data && data['meeting-types-selected']) {
        this.meetingsTypeSelected = data['meeting-types-selected']
        return true
      } else {
        this.meetingsTypeSelected = []
      }
    },
    clearData () {
      this.meetingsSelected = []
      this.racesSelected = []
      this.meetingsTypeSelected = []
      this.openMeetingSummary()
    }
  }
}

</script>

<style>
  .meetingSummaryTable {
      border:none;
      border-collapse: collapse;
      width: 1700px;
      overflow: scroll;
  }

  .meetingSummaryTable th {
      background: white;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
      border-top: none;
      border-left: none;
      border-bottom: 1px solid #e0e0e0;
  }

  .meetingSummaryTable .headcol {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0;
      background: #f2f2f2;
      border-left: none;
      padding-left: 20px;
      width: 200px;
  }

  .meetingSummaryTable td, .meetingSummaryTable th {
      border: 0.5px solid #d8d8d8;
      height: 25px !important;
  }

  .titleBar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  thead th:first-child {
    left: 0;
    z-index: 1;
  }

  .meetingSummaryTable tbody td:nth-of-type(even) {
    background: white !important;
  }

  .countriesMeetingSummary {
    padding: 40px 16px 0px 16px !important;
  }

  .cardMeetingSummary {
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important;   /* Safari */
    -khtml-user-select: none !important;    /* Konqueror HTML */
    -moz-user-select: none !important;      /* Firefox */
    -ms-user-select: none !important;       /* Internet Explorer/Edge */
    user-select: none !important;           /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  .meetingCheckbox {
    margin-top: revert;
    margin-left: auto;
    float: right;
  }

  .meetingRaceHid {
    text-decoration: line-through;
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }

  .columnRaceType {
    padding-left: 5px !important;
  }

  .row {
    margin-right: 0px;
  }

  .checkBoxesShowed {
    width: 55px;
  }

  .meetingInSummary {
    margin: 0px;
  }

</style>
