<template>
  <div class="runnerSectionSubContainer">
    <div class="runnerSectionFooterContainer">
      <data-table class="runnersSection" v-if="race" :items="race.competitors" :headers="[]" item-key="tabNo">
        <template v-slot:body.append>
          <template>
              <tr class="footerContent">
                <td colspan="2"  :class="acMode ? 'market-percentage pl-2 font-weight-black tableFooterTitleAcMode' : 'market-percentage pl-2 font-weight-black tableFooterTitle'" >Market %</td>
              </tr>
              <tr class="footerContent">
                <td colspan="2"  :class="acMode ? 'market-percentage pl-2 font-weight-black tableFooterTitleAcMode' : 'market-percentage pl-2 font-weight-black tableFooterTitle'" >Pools/Matched (K)
                </td>
              </tr>
              <tr class="footerContent">
                <td colspan="2"  :class="acMode ? 'market-percentage pl-2 font-weight-black tableFooterTitleAcMode' : 'market-percentage pl-2 font-weight-black tableFooterTitle'" >Last Price Change</td>
              </tr>
          </template>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from './DataTable'

export default {
  props: {
    race: Object,
    meeting: Object,
    acMode: Boolean
  },
  components: {
    DataTable
  }
}
</script>

<style>
  .runnersSection {
    width: 100%;
  }
  .tableFooterTitle {
    font-weight: bold;
    font-size: 0.6em;
    color: #333;
    padding: 3px;
    text-align: end;
    border-top:1px solid #ddd;
    font-size:10px
  }
  .tableFooterTitleAcMode {
    font-weight: bold;
    font-size: 0.6em;
    color: #333;
    padding: 3px;
    text-align: end;
    font-size:10px;
    border-top:1px solid #333;
    border-bottom:1px solid #333;
  }
  .runnerSectionFooterContainer {
    width:100%;
    box-sizing:border-box;
  }
  .runnerSectionSubContainer {
    width:100%;
    height:100%;
    z-index:1;
    border-right:1px solid #d8d8d8;
  }
  .footerContent {
    height:22px;
  }
</style>
