<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="425"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color=""
      >
        Support
    <v-icon small class="ml-2" color="red">mdi-lifebuoy</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Support <v-icon class="ml-2" color="red">mdi-lifebuoy</v-icon>
      </v-card-title>
      <v-card-text>Here you can find useful FAQ's, Tutorials, and our Service Status if you can't find what you're looking for please submit a request</v-card-text>
      <v-divider></v-divider>
      <iframe frameBorder="0" id="statushub-widget" style="width:425px;" src="https://cdn.statushub.io/apps/widget/3.4.1/app/iframe.html?token=8ec5a356e1d5d2619e40f131e047d010333a8fbe&daysBefore=30&daysAfter=30&limit=10&incidentsFirst=false" width="400px" height="600px"></iframe>
      <v-card-text>Need help? Submit a Support Request below (via registered support account) or email <a href="mailto:support@dynamicodds.com"></a></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text href="https://betmakers.service-now.com/b2c-portal?id=kb_search&kb_knowledge_base=2b292926c3302200e7c7d44d81d3ae10" target="_blank">
          FAQ's &amp; Tutorials
        </v-btn>
        <v-btn text href="https://betmakers.service-now.com/b2c-portal" target="_blank">
          Support Request
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  }
}
</script>
