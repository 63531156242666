import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import account from './store/account'
import userConfig from './store/userConfig'
import store from './store/index'
import socket from './store/socket'
import betting from './store/betting'
import router from './router'
import '@/plugins/apexcharts'
import vuetify from './plugins/vuetify'
import { Amplify } from '@aws-amplify/core'
import * as AmplifyModules from '@aws-amplify/core'
import CountryFlag from 'vue-country-flag'
import { draggable, droppable } from '../src/directives/sortable'
import { moveable } from './directives/moveable'
import { focus } from './directives/focus'
import Vuex from 'vuex'
import Trend from 'vuetrend'
import { apolloClient } from './vue-apollo'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from './aws-exports'
import VueApollo from 'vue-apollo'
import VueCookies from 'vue-cookies'
import VueNativeSock from 'vue-native-websocket'
import VueObserveVisibility from 'vue-observe-visibility'
import VModal from 'vue-js-modal'
import IdleVue from 'idle-vue'
import TwitterFeed from 'vuejs-twitter-feed'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'

const eventsHub = new Vue()

Amplify.configure(awsconfig)
Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(Vuex)
Vue.use(Trend)
Vue.use(VueApollo)
Vue.use(VueCookies)
Vue.use(VModal, { dialog: true })
Vue.use(VueObserveVisibility)
Vue.use(TwitterFeed)

Vue.component('country-flag', CountryFlag)

Vue.directive('draggable', draggable)
Vue.directive('droppable', droppable)
Vue.directive('moveable', moveable)
Vue.directive('focus', focus)

const awsExports = {
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
}

async function bootStrap () {
  let conf = {}
  if (process.env.VUE_APP_CONFIGJS) {
    const c = await axios.get('/config.json')
    conf = c.data
  } else {
    conf.baseUrl = process.env.VUE_APP_ENDPOINT_API
  }

  axios.defaults.baseURL = conf.baseUrl
  axios.defaults.withCredentials = true

  // Add a request interceptor to put auth header
  axios.interceptors.request.use(async config => {
  // get token from local storage, if expired, updateAuthToken will refresh the token
  await s.dispatch('account/updateAuthToken')
   const token = window.localStorage.getItem('auth_token')
   config.headers.Authorization = `Bearer ${token}`

    return config
  }, err => Promise.reject(err))

  Amplify.configure(awsExports)

  store.modules = { ...store.modules, account, userConfig, socket, betting }
  const s = new Vuex.Store(store)

  Vue.use(VueNativeSock, process.env.VUE_APP_ENDPOINT_SOCKET, {
    store: s,
    format: 'json',
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
    connectManually: true
  })
  Vue.use(IdleVue, { eventEmitter: eventsHub, store: s, idleTime: 30000 })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  })
  const doApp = new Vue({
    store: s,
    router,
    vuetify,
    apolloProvider,
    render: h => h(App)
  }).$mount('#app')

  window.doApp = doApp
}

bootStrap()
