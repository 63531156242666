<template>
<!-- Start Harness and Greyhounds  Form-->
  <v-card class="lastStartsCard" v-if="this.meeting.type === 'HARNESS' || this.meeting.type === 'GREYHOUND'">
    <v-toolbar color="primary" dark dense>
      <div style="width:100%;">
        <v-toolbar-title class="float-left"> Last 10 Starts - {{item.tabNo}}. {{item.name}} ({{item.barrier}}) &nbsp; <span style="font-size: 15px" v-if="item.jockey"> &nbsp; - {{item.jockey}}</span></v-toolbar-title>
      </div>
    </v-toolbar>
    <v-container v-if="runner">
      <v-simple-table dense v-if="runnerHasPastStarts(item.tabNo)">
        <tr class="tableHeadersR">
          <th style="text-align: center">Plc</th>
          <th style="text-align: center">Date</th>
          <th style="text-align: center">Race</th>
          <th style="text-align: center">Class</th>
          <th style="text-align: center">Age</th>
          <th style="text-align: center">Prize</th>
          <th v-if="meeting.type != 'GREYHOUND' && meeting.type != 'HARNESS'" style="text-align: center">Hcp</th>
          <th style="text-align: center">Bar</th>
          <th v-if="meeting.type != 'GREYHOUND'" style="text-align: center">Margin</th>
          <th v-if="meeting.type != 'GREYHOUND'" style="text-align: center">Jockey</th>
          <th style="text-align: center">Dist</th>
          <th style="text-align: center">Cond</th>
          <!-- <th style="text-align: center">Time</th> -->
        </tr>
        <tr v-for="(d, index) in runner.competitorsConnection.competitors" :key="index">
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{runnerPlacingZeros(d.finalPosition)}} / {{totalRunners(d)}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{format(new Date(d.race.startTime), 'dd MMM yy')}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{d.race.meeting.track.name}} R{{d.race.number}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{checkClass(d.race.class)}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{d.age}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)" style="text-align: right">{{numeral(d.race.prizeMoney).format('0.0a')}}</td>
          <td v-if="d.race.status !='ABANDONED' && meeting.type != 'GREYHOUND' && meeting.type != 'HARNESS' && !d.scratched && !runningToday(d)">{{d.weightAllocated}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{d.barrier}}</td>
          <td v-if="d.race.status !='ABANDONED' && meeting.type != 'GREYHOUND' && !d.scratched && !runningToday(d)">{{d.margin || 0}}</td>
          <td v-if="d.race.status !='ABANDONED' && meeting.type != 'GREYHOUND' && !d.scratched && !runningToday(d)">{{d.jockey}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{d.race.distance}}</td>
          <td v-if="d.race.status !='ABANDONED' && !d.scratched && !runningToday(d)">{{d.race.trackCondition}}</td>
          <!-- <td>{{format(new Date(d.race.startTime), 'HH:mm:ss')}}</td> -->
        </tr>
      </v-simple-table>
      <div v-else>
        No Past Starts Recorded
      </div>
      <div v-if="runnerPlacingZero" style="margin-top: 7px">
        *Placings may not yet available for Greyhound or International Races
      </div>
    </v-container>
    <v-skeleton-loader v-if="!runner" width="100%" type="article, actions"></v-skeleton-loader>
  </v-card>
  <!-- End Harness and Greyhounds  Form-->
  <!-- Start Thoroughbred Form-->
  <v-card class="lastStartsCard" v-else-if="this.meeting.type === 'THOROUGHBRED'">
    <v-toolbar color="primary" dark dense>
      <div style="width:100%;">
        <v-toolbar-title class="float-left"> Last 10 Starts - {{item.tabNo}}. {{item.name}} ({{item.barrier}}) &nbsp; <span style="font-size: 15px" v-if="item.jockey"> &nbsp; - {{item.jockey}}</span></v-toolbar-title>
      </div>
    </v-toolbar>
    <v-container v-if="runner && checkForForm(runner)">
      <v-simple-table dense>
        <tr class="tableHeadersR">
          <th class="pl-4">Placing</th>
          <th>Margin</th>
          <th>Dist</th>
          <th>Venue</th>
          <th>SP</th>
          <th>Class</th>
          <th>Condition</th>
          <th>Prize</th>
          <th>Date</th>
          <th>Days</th>
          <th>Bar</th>
          <th>Jockey</th>
          <th>Winner</th>
          <th>Time</th>
        </tr>
        <template v-for="a in runner.competitorsConnection.competitors">
          <tr v-for="(d, index) in mutateForm(a.formHistory)" :key="index">
            <td style="font-size: 12px; white-space: nowrap">{{d.finishingPosition}} / {{d.starters}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.margin}}L</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.distance}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.track}} R{{d.raceNumber}}</td>
            <td style="font-size: 12px; white-space: nowrap"
                v-if="d.class !== 'BTrial'">
              ${{d.startingPrice}}
            </td>
            <td v-else>
              -
            </td>
            <td style="font-size: 12px; white-space: nowrap">{{d.class}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.trackCondition}}</td>
            <td style="text-align: right;font-size: 12px; white-space: nowrap">{{numeral(d.prizeTotal).format('0.0a')}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{format(new Date(d.date), 'dd MMM yy')}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.dsls || '-'}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.barrier}}</td>
            <td style="font-size: 12px; white-space: nowrap">{{d.jockey}} ({{d.weight}}kg)</td>
            <td style="font-size: 12px; white-space: nowrap">
              {{findWinnerForm(d) || `${item.name} (${item.weightTotal}kg)`}}
            </td>
            <td style="font-size: 12px; white-space: nowrap">
              {{formatToMinutes(d.elapsedTime)}}
            </td>
            <!-- <td>{{format(new Date(d.race.startTime), 'HH:mm:ss')}}</td> -->
          </tr>
        </template>

      </v-simple-table>
      <div v-if="runnerPlacingZero" style="margin-top: 7px">
        *Placings may not yet available for Greyhound or International Races
      </div>
    </v-container>
    <div v-else>
      *Form may not yet be available for some International Races
    </div>
    <v-skeleton-loader v-if="!runner" width="100%" type="article, actions"></v-skeleton-loader>
  </v-card>
  <!-- End Thoroughbred Form-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'
import numeral from 'numeral'
export default {
  data: () => ({
    format,
    numeral,
    runnerPlacingZero: false
  }),
  props: {
    item: Object
  },
  name: 'RunnerLastStarts',
  methods: {
    ...mapActions(['fetchRunner']),
    mutateForm (data) {
      const formattedData = data.filter(b => b.class !== 'BTrial')
      formattedData.map((b, index) => {
        if (index !== formattedData.length - 1) {
          const nowTime = formattedData[index].date
          const pastTimer = formattedData[index + 1].date
          const nowTimeMili = new Date(nowTime).getTime()
          const pastTimeMili = new Date(pastTimer).getTime()
          const timeBetween = nowTimeMili - pastTimeMili
          const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
          const daysFormatted = Math.floor(daysBetween)
          b.dsls = daysFormatted
        }
      })
      return data.slice(0, 10)
    },
    findWinnerForm (data) {
      let winnerName = ''
      data.placeGetters.forEach((b) => {
        if (b.finishingPosition === 1) {
          winnerName = `${b.name} (${b.weight}kg)`
        }
      })
      return winnerName
    },
    formatToMinutes (time) {
      var minutes = Math.floor(time / 60000)
      var seconds = ((time % 60000) / 1000).toFixed(2)
      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
    },
    checkForForm (data) {
      let formAvailable
      let competitors = data?.competitorsConnection?.competitors
      if (!competitors) return null
      competitors.forEach(b => {
        formAvailable = b.formHistory.length > 0
      })
      // data.race.competitors.every((b) => {
      //   if (b.formHistory.length > 0) {
      //     formAvailable = true
      //     return false
      //   }
      //   else {
      //     formAvailable = false
      //   }
      // })
      return formAvailable
    },
    checkClass: function (x) {
      if (x.includes('HANDICAP=TRUE')) {
        // eslint-disable-next-line no-unused-vars
        const [first, second] = x.split(',')
        return `${first}, HCP`
      }
      if (x.includes('HANDICAP=FALSE')) {
        // eslint-disable-next-line no-unused-vars
        const [first, second] = x.split(',')
        return `${first}, HCP`
      }
      return x
    },
    totalRunners: function (d) {
      const runnerNo = d.race.competitors.length
      const scratched = d.race.competitors.filter(b => b.scratched === true)
      const scratchNo = scratched.length
      return runnerNo - scratchNo
    },
    runningToday: function (d) {
      if (this.race.name === d.race.name && this.race.startTime === d.race.startTime) {
        return true
      }
      return false
    },
    runnerHasPastStarts: function (tabNo) {
      const pastStarts = this.runner.competitorsConnection.competitors
      const competitorList = []
      pastStarts.forEach((d) => competitorList?.push(d.scratched, d.race.status))
      if (pastStarts.length > 1 && competitorList.status !== 'ABANDONED' && !competitorList.scratched) {
        return true
      }
      return false
    },
    runnerPlacingZeros: function (pos) {
      const pastStarts = this.runner.competitorsConnection.competitors
      const competitorList = []
      pastStarts.forEach((d) => {
        if (!d.scratched && d.race.status !== 'ABANDONED' && !this.runningToday(d)) {
          // eslint-disable-next-line no-unused-expressions
          competitorList?.push(d.finalPosition)
        }
      })
      if (competitorList.includes(0)) {
        this.runnerPlacingZero = true
      }
      return this.checkFinalPos(pos)
    },
    checkFinalPos: function (pos) {
      if (pos === 0) {
        return '*'
      }
      return pos
    }
  },
  computed: {
    ...mapGetters(['getRunners', 'getSelectedMeeting', 'getSelectedRace', 'getColumns']),
    runner: function () {
      return this.getRunners(this.item?.runner?.id)
    },
    meeting: function () {
      return this.getSelectedRace()?.meeting
    },
    race: function () {
      return this.getSelectedRace(this.getColumns)
    }
  }
}
</script>

<style>
.lastStartsCard {
  z-index:999;
  box-shadow: 5px 5px 20px grey;
}
.tableHeadersR {
  text-align: center;
}
</style>
