<template>
  <span v-observe-visibility="visibilityChanged" :style="getStartTimeColor()">{{ prepend }} {{ toStart }} {{ append }} </span>
</template>

<script>
import { countdown, countdownObject } from '../common/methods'
import { parseISO, getUnixTime } from 'date-fns'

export default {
  name: 'Countdown',
  props: {
    startTime: String,
    styles: String,
    prepend: String,
    append: String
  },
  methods: {
    countdown,
    getStartTimeColor () {
      const start = countdown(getUnixTime(parseISO(this.startTime)))
      if (start.includes('h')) return 'color:grey'
      if (start.includes('-')) return 'color:red'
      if (start.includes('d')) return 'color:grey'
      return 'color:green'
    },
    createInterval (length) {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.toStart = countdown(getUnixTime(parseISO(this.startTime)))
      }, length)
    },
    visibilityChanged (isVisible) {
      if (isVisible) {
        this.start()
        return
      }
      this.stop()
    },
    start () {
      const time = getUnixTime(parseISO(this.startTime))
      const timeObj = countdownObject(time)
      if (timeObj.minutes >= 5) {
        this.interval = setInterval(() => {
          const currentTime = countdownObject(getUnixTime(parseISO(this.startTime)))
          if (currentTime.hours === 0 && currentTime.minutes < 5) {
            this.createInterval(500)
          }
          this.toStart = countdown(time)
        }, 30000)
      } else {
        this.createInterval(1000)
      }
    },
    stop () {
      clearInterval(this.interval)
    }
  },
  data () {
    return {
      toStart: countdown(getUnixTime(parseISO(this.startTime))),
      interval: null
    }
  },
  created () {

  },
  beforeDestroy () {
    this.stop()
  }
}
</script>
