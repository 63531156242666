<template>
  <div :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
    <template>
      <v-btn color="normal" x-small :class="hasFeature('marketMover') ? 'buttonSettings' : 'ma-2 mb-0'" @click.stop="dialog = true">
        <v-icon small class="mr-1">mdi-cog</v-icon>Grid Settings
      </v-btn>
    </template>
    <v-dialog v-model="dialog" max-width="600" :overlay-opacity="opacity" retain-focus>
      <v-card style="max-width:600px;">
        <v-card-title class="primary white--text">
          <div style="width:100%">
            <div class="float-left">
              <span class="headline">Race Grid Settings</span>
              <v-list-item-subtitle>Customize your race grid settings</v-list-item-subtitle>
            </div>
            <v-btn class="float-right" icon small @click.stop="dialog = false" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title  class="body-2"><v-icon class="mr-1">mdi-format-size</v-icon>Grid Font Size (ALL)</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list color="blue lighten-5">
              <v-list-item>
                <v-checkbox class="mt-0 mb-0" v-model="selectedFont" value="raceFontsizeSmall"><span slot="label" class="caption smallFont">Small</span></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox  class="mt-0 mb-0" v-model="selectedFont" value="raceFontsizeMed"><span slot="label" class="subtitle-2 mediumFont">Medium</span></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox  class="mt-0 mb-0" v-model="selectedFont" value="raceFontsizeLrg"><span slot="label" class="title largeFont">Large</span></v-checkbox>
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title  class="body-2"><v-icon class="mr-1">mdi-home-currency-usd</v-icon>Betfair Settings</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list color="blue lighten-5">
              <v-list-item>
                <v-checkbox v-model="selectedSettings" value="bFLayOdds" class="mt-0 mb-0"></v-checkbox>
                <v-text-field label="Underline Lay Odds when price is less than:" :rules="rulesOdds" placeholder="1" v-model="layOdds" class="float-left d-inline" type="number"></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-checkbox v-model="selectedSettings" value="bFBackOdds" class="mt-0 mb-0"></v-checkbox>
                <v-text-field label="Underline Back odds when amount is greater than:" :rules="rulesOdds" placeholder="1" v-model="backOdds" class="float-left d-inline" type="number"></v-text-field>
              </v-list-item>
              <v-list-item>
                <v-checkbox v-model="selectedSettings" value="commission" class="mt-0 mb-0"></v-checkbox>
                <v-text-field label="Re-calculate odds based on commission rate (%) of" :rules="rulesOdds" placeholder="1" v-model="commission" class="float-left d-inline" type="number"></v-text-field>
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title  class="body-2"><v-icon class="mr-1">mdi-format-color-highlight</v-icon>Highlight Best Odds</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list color="blue lighten-5">
              <v-list-item>
                <v-checkbox class="mt-0 mb-0" v-model="selectedHighlight" value="WIN_FIXED_ODDS"><span slot="label" class="caption">Win</span></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox  class="mt-0 mb-0" v-model="selectedHighlight" value="PLACE_FIXED_ODDS"><span slot="label" class="caption">Place</span></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox  class="mt-0 mb-0" v-model="selectedHighlight" value="ignoreToteOdds"><span slot="label" class="caption">Ignore Tote Odds</span></v-checkbox>
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-checkbox class="mb-0" v-model="selectedSettings" value="highLightOdds" dense label="Highlight Fixed Odds for 2 seconds when price change occurs"></v-checkbox>
          <v-checkbox v-if="hasFeature('sortByResults')" v-model="selectedSettings" dense value="sortByResults" class="mt-n3 mb-0" label="Sort Runners by results"></v-checkbox>
          <v-checkbox v-model="selectedSettings" value="excludeScratched" dense class="mt-n3 mb-0" label="Exclude Scratched Runners when sorting field"></v-checkbox>
          <v-checkbox v-model="selectedSettings" value="displayScratchedPrice" dense class="mt-n3 mb-0" disabled label="Display Prices of Scratched Runners"></v-checkbox>
          <div class="error--text caption mt-n5 mb-2">Currently Unavailable</div>
          <v-checkbox v-model="selectedSettings" dense value="showDecimalPlaces"  class="mt-n3 mb-0" label="Show two decimal places "></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="rightAlignPrices" class="mt-n3 mb-0" label="Right align prices"></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="showSilks" class="mt-n3 mb-0" label="Show Jockey Silks (colour for Greys)"></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="showJockey"  class="mt-n3 mb-0" label="Show Jockey Name under runner"></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="showPriceFlucs" class="mt-n3 mb-0" label="Show Price Flucs on hover of prices"></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="showForm" class="mt-n3 mb-0" label="Show Form Pop-Up (Last 10 Starts) when hovering over runner name"></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="fractionalOdds" class="mt-n3 mb-0" label="Display fractional odds"></v-checkbox>
          <v-checkbox v-model="selectedSettings" dense value="displayImperial" class="mt-n3 mb-0" label="Display weight and distance in lbs/furlongs"></v-checkbox>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import numeral from 'numeral'

export default {
  data: () => ({
    panel: 0,
    opacity: 0.02,
    dialog: false,
    resetConfirm: false,
    rulesOdds: [
      value => !!value || 'Required.',
      value => (value && value.length <= 7) || 'Max 6 characters',
      value => (value <= 999.00) || 'Max 999'
    ]
  }),
  props: {
    fontSizeSetting: String,
    highlightSetting: Array,
    gridSettings: Array,
    betFairSettings: Array,
    commissionRate: Number
  },
  methods: {
    ...mapActions(['updateRaceGridSettings']),
    onClick (filter, setting) {
      this.updateRaceGridSettings({ filter: filter, value: setting })
    },
    onClickBetfair (setting) {
      this.updateRaceGridSettings({ filter: 'betFair', value: setting })
    },
    updateBetFairComission (value) {
      this.updateRaceGridSettings({ filter: 'betFairCommission', value })
    }
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['hasFeature']),
    selectedFont: {
      get () {
        return this.fontSizeSetting
      },
      set (value) {
        return this.onClick('raceFontSize', value)
      }
    },
    selectedHighlight: {
      get () {
        return this.highlightSetting
      },
      set (value) {
        return this.onClick('highlightBestOdds', value)
      }
    },
    selectedSettings: {
      get () {
        return this.gridSettings
      },
      set (value) {
        return this.onClick('gridSettings', value)
      }
    },
    layOdds: {
      get () {
        return this.betFairSettings[0]
      },
      set (value) {
        const formatValue = numeral(value).format('(0.00)')
        return this.onClickBetfair([formatValue, this.backOdds])
      }
    },
    backOdds: {
      get () {
        return this.betFairSettings[1]
      },
      set (value) {
        const formatValue = numeral(value).format('(0.00)')
        return this.onClickBetfair([this.layOdds, formatValue])
      }
    },
    commission: {
      get () {
        return this.commissionRate
      },
      set (value) {
        return this.updateBetFairComission(value)
      }
    }
  }
}
</script>

<style>
.raceFiltersAcMode {
  font-weight:bold !important
}
</style>
