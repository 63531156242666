<template>
  <div v-if="meetingType === 'GREYHOUND' && meetingCountry === 'GBR' && tabNo" style="width:28px;margin-top:2px;overflow:hidden;" :class="['greyhoundSpriteUk-' + tabNo]" />
  <div v-else-if="meetingType === 'GREYHOUND' && meetingCountry === 'AUS' || meetingType === 'GREYHOUND' && meetingCountry === 'NZL' && tabNo" style="width:28px;margin-top:2px;overflow:hidden;" :class="['greyhoundSpriteAUS-' + tabNo]" />
  <div v-else-if="meetingType === 'GREYHOUND' && meetingCountry === 'USA' || (meetingType === 'GREYHOUND' && meetingCountry === 'MEX' && tabNo)" style="width:28px;margin-top:2px;overflow:hidden;" :class="['greyhoundSpriteUs-' + tabNo]" />
</template>

<script>

export default {
  name: 'GreyHoundSprite',
  props: {
    meetingType: String,
    meetingCountry: String,
    tabNo: Number
  }
}
</script>

<style scoped>
/* UK Greys */
.greyhoundSpriteUk-1 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 0px;
}
.greyhoundSpriteUk-2 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -28px;
}
.greyhoundSpriteUk-3 {
  width: 28px;
  height: 28px;
  border: 1px solid #333;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -56px;
}
.greyhoundSpriteUk-4 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -84px;
}
.greyhoundSpriteUk-5 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -112px;
}
.greyhoundSpriteUk-6 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -140px;
}
.greyhoundSpriteUk-7 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -168px;
}
.greyhoundSpriteUk-8 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -196px;
}

/* AUS Greys */
.greyhoundSpriteAUS-1 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 0px;
}
.greyhoundSpriteAUS-2 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -28px;
}
.greyhoundSpriteAUS-3 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -56px;
}
.greyhoundSpriteAUS-4 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -84px;
}
.greyhoundSpriteAUS-5 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -112px;
}
.greyhoundSpriteAUS-6 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -140px;
}
.greyhoundSpriteAUS-7 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -168px;
}
.greyhoundSpriteAUS-8 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -196px;
}
.greyhoundSpriteAUS-9 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -224px;
}
.greyhoundSpriteAUS-10 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -252px;
}

/* US Greys */
.greyhoundSpriteUs-1 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 0px;
}
.greyhoundSpriteUs-2 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -28px;
}
.greyhoundSpriteUs-3 {
  width: 28px;
  height: 28px;
  border: 1px solid #333;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -56px;
}
.greyhoundSpriteUs-4 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -84px;
}
.greyhoundSpriteUs-5 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -112px;
}
.greyhoundSpriteUs-6 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -140px;
}
.greyhoundSpriteUs-7 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -168px;
}
.greyhoundSpriteUs-8 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -196px;
}
.greyhoundSpriteUs-9 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -224px;
}
.greyhoundSpriteUs-10 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -252px;
}
</style>
