<template>
  <div v-if="columnHeader === 'Flucs'" :class="'logoWidth' + bookieCode" class="tooltip bookieHead" style="min-width:110px;vertical-align: bottom;">
    <div v-if="getSortedOrderBookie.columnNumber === columnNumber && getSortedOrderBookie.context === 'lowToHigh'" style="position:fixed;"><v-icon class="success--text">mdi-arrow-down-bold</v-icon></div>
    <div v-else-if="getSortedOrderBookie.columnNumber === columnNumber && getSortedOrderBookie.context === 'highToLow'" style="position:fixed;"><v-icon class="success--text">mdi-arrow-up-bold</v-icon></div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <!-- <img :src="logoFromPriceCode(bookieCode)" class="opacityHover center" style="height:38px; width:38px" v-bind="attrs" v-on="on"> -->
        <div class="opacityHover center" :style="`transform: scale(0.82); height:38px; width:38px; position: relative; top: -2px; margin-bottom: ${editMode && '10px'}`" v-bind="attrs" v-on="on">
          <BookiesLogos :bookieCode="bookieCode" />
        </div>
      </template>
      <span v-if="!editMode" >{{bookMakerTip(bookieCode)}} </span>
    </v-tooltip>
    <span v-if="!editMode" class="tooltiptext">{{bookMakerTip(bookieCode)}}</span>
    <div v-if="bookieCode !== 'SPC'" class="priceTypeHead"><span class="save-font">{{columnHeader}}</span></div>
    <v-select v-if="meeting.track.country === 'AUS' && !editMode" class="selectTimes mt-n2" hide-details dense :items="timeItems" v-model="selectedTime">
    </v-select>
    <v-select v-else-if="!editMode" class="selectTimes mt-n2" hide-details dense :items="timeItemsAlt" v-model="selectedTime">
    </v-select>
  </div>
  <!-- <img v-else-if="betSlipIcon" :src="logoFromPriceCode(bookieCode)" class="opacityHover tooltip center" style="height:22px; width:22px"> -->
  <div v-else-if="betSlipIcon" class="opacityHover tooltip center" style="height:22px; width:22px">
    <BookiesLogos :bookieCode="bookieCode" />
  </div>

  <!-- <img v-else-if="!column && !editMode" :src="logoFromPriceCode(bookieCode)" class="opacityHover tooltip center" style="height:32px; width:32px"> -->
  <div v-else-if="!column && !editMode" class="opacityHover tooltip">
    <BookiesLogos :bookieCode="bookieCode" />
  </div>

  <div v-else :class="'logoWidth' + bookieCode" class="tooltip bookieHead" style="min-width:42px;width: 42px;vertical-align: bottom;">
    <div class="highLow" v-if="getSortedOrderBookie.columnNumber === columnNumber && getSortedOrderBookie.context === 'lowToHigh'"><v-icon x-small class="success--text">mdi-arrow-down-bold</v-icon></div>
    <div class="highLow" v-else-if="getSortedOrderBookie.columnNumber === columnNumber && getSortedOrderBookie.context === 'highToLow'"><v-icon x-small class="success--text">mdi-arrow-up-bold</v-icon></div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="bookieCode !== 'SPC' && editMode" style="transform: scale(0.85); position: relative; left: -2px;" class="opacityHover tooltip tiledBackground bookiesPositioning" v-bind="attrs" v-on="on">
          <BookiesLogos :bookieCode="bookieCode" />
        </div>
        <div v-else-if="bookieCode !== 'SPC' && !editMode" :style="getSortedOrderBookie.columnNumber === columnNumber && !editMode ? 'margin-top:-9px;height:28px; width:28px;' : 'height:38px; width:38px'" class="opacityHover tooltip center bookiesPositioning" v-bind="attrs" v-on="on">
          <BookiesLogos :bookieCode="bookieCode" />
        </div>
        <div v-else style="height:56px;width:42px;"></div>
        <!-- <img v-if="bookieCode !== 'SPC'" :style="getSortedOrderBookie.columnNumber === columnNumber && !editMode ? 'margin-top:-9px;height:28px; width:28px;' : 'height:38px; width:38px'" :src="logoFromPriceCode(bookieCode)" class="opacityHover tooltip center" v-bind="attrs" v-on="on">
        <div v-else style="height:56px;width:42px;"></div> -->
      </template>
      <!-- <span v-if="!editMode" >{{bookMakerTip(bookieCode)}} </span> -->
    </v-tooltip>
    <div v-if="bookieCode !== 'SPC'" class="priceTypeHead"><span class="save-font">{{columnHeader}}</span></div>
    <div v-if="hasFeature('betting') && bookieCode !== 'SPC' && editMode === false && column.type !== 'bookieSelect'">
      <BetBalance :userAccount="findBalance()" :bookieCode="bookieCode" :settings="settings" :canBet="canBet()"/>
    </div>
    <div v-else-if="!hasFeature('betting') && bookieCode !== 'SPC' && editMode === false" style="text-align:center"><v-btn @click.stop="handleClick" x-small disabled class="betButton grey--text">Bet</v-btn></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BOOKMAKER_NAMES_LOOKUP } from '../common/constants'
import { canBetOnColumn, mapBettingSource } from '../common/methods'
import BetBalance from './BetBalance'
import BookiesLogos from './BookiesLogos.vue'

export default {
  name: 'RaceBookmakers',
  data: () => ({
    // selectedTime: 'All',
    timeItems: [ // 2015-03-25T12:00:00Z"
      { text: '>9am', value: '>9am' },
      { text: '1 min', value: '00:01:00' },
      { text: '3 min', value: '00:03:00' },
      { text: '5 min', value: '00:05:00' },
      { text: '10 min', value: '00:10:00' },
      { text: '15 min', value: '00:15:00' },
      { text: '30 min', value: '00:30:00' },
      { text: '1 hour', value: '01:00:00' },
      { text: '3 hours', value: '03:00:00' },
      { text: '6 hours', value: '06:00:00' },
      { text: '12 hours', value: '12:00:00' },
      { text: 'All', value: 'All' }
    ],
    timeItemsAlt: [ // 2015-03-25T12:00:00Z"
      { text: '1 min', value: '00:01:00' },
      { text: '3 min', value: '00:03:00' },
      { text: '5 min', value: '00:05:00' },
      { text: '10 min', value: '00:10:00' },
      { text: '15 min', value: '00:15:00' },
      { text: '30 min', value: '00:30:00' },
      { text: '1 hour', value: '01:00:00' },
      { text: '3 hours', value: '03:00:00' },
      { text: '6 hours', value: '06:00:00' },
      { text: '12 hours', value: '12:00:00' },
      { text: 'All', value: 'All' }
    ]
  }),
  bookmakerNames: BOOKMAKER_NAMES_LOOKUP,
  props: {
    bookieCode: String,
    columnHeader: String,
    columnType: String,
    editMode: Boolean,
    betSlipIcon: Boolean,
    columnNumber: Number,
    column: Object
  },
  components: {
    BetBalance,
    BookiesLogos
  },
  computed: {
    ...mapGetters(['hasFeature', 'getAgencySettings', 'getSortedOrderBookie', 'getSelectedMeeting', 'getSelectedColumns', 'getSelectedColumnsName', 'getSelectedRace']),
    account: function () {
      return this.findBalance()
    },
    settings: function () {
      const agencySettings = this.getAgencySettings
      return agencySettings?.[this.bookieCode] || {}
    },
    meeting: function () {
      return this.getSelectedRace()?.meeting
    },
    selectedTime: {
      get () {
        return this.column?.time
      },
      set (selectedTime) {
        return this.updateFilters(selectedTime)
      }
    }
  },
  methods: {
    ...mapGetters('account', ['getBalances']),
    ...mapActions(['updateSelectedColumns']),
    // logoFromPriceCode (code) {
    //   return 'https://betmakers.com/img/circle_logos/' + code + '.png'
    // },
    updateFilters: function (selectedTime) {
      const columns = this.getSelectedColumns
      columns[this.columnNumber].time = selectedTime
      this.updateSelectedColumns({ columns, name: this.getSelectedColumnsName })
    },
    changeTime: function () {
      this.$emit('input', this.selectedTime)
    },
    canBet: function () {
      return canBetOnColumn(this.columnType)
    },
    findBalance () {
      const balances = this.getBalances()
      const code = mapBettingSource(this.bookieCode)
      return balances ? balances.find(b => b.agency_id === code) : null
    },
    bookMakerTip: function (bookMakerTipValue) {
      return BOOKMAKER_NAMES_LOOKUP[bookMakerTipValue]
    }
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}
.selectTimes {
  z-index: 200;
  width: 90px;
  margin-left: 10px;
  margin-right: 10px;
  height: 20px !important;
  min-height: 20px !important;
  font-size: 13px;
}
.logoWidth {
  width: 55px;
  position: relative;
  text-align: center;
}

.bookiesPositioning {
  transform: scale(0.8);
  position: relative;
  top: -2px;
  left: -3px;
}

.priceTypeHead {
  width: 90%;
  height: 15px;
  background: #000;
  color: #fff;
  font-size: 10px;
  text-align: center;
  margin: 0 auto;
  margin-top: 2.5px;
}
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #666;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;
  z-index:202;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.bookieHead {
  height: 62.5px;
  background:#fff !important;
}

.highLow {
  left: 0;
  top: 0;
  position: absolute;
  background-color: white;
  border: 1px solid green;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  z-index: 5;
}

.highLow i {
  position: relative;
  bottom: 4.5px;
}

.highLow + span + div {
  margin-top: 1px !important;
  margin-bottom: 12px;
  margin-right: 10px;
}
</style>
