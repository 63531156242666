<template>
  <div v-if="columns.length > 0" class="raceTableColumns" ref="priceList">
    <div v-if="editMode" class="editMode">
      <div class="editButtons duplicateColumnButtonMain"><DuplicateColumnMenu largeIcon :editMode="editMode"/></div>
      <div class="editButtons layoutName"><v-chip class="ma-2" label dark color="black"><v-icon class="mr-2">mdi-playlist-edit</v-icon> Editing Layout: {{getSelectedColumnsName}}</v-chip></div>
      <div class="editButtons removeButton" @click="clearAllColumns"><v-chip class="ma-2" style="cursor: pointer;" label dark color="red"><v-icon class="mr-2">mdi-delete</v-icon>Clear Columns</v-chip></div>
      <div class="editColumnsSection" style="height:100%;">
        <SlickList lockAxis="x" axis="x" v-model="items" class="table-list">
          <SlickItem v-for="(item, index) in items" :index="index" :key="index" class="table-list-item-normal">
            <BestPriceColumn
              v-if="item.type === 'BEST_PRICE' || item.type === 'BEST_TOTE'"
              :id="raceID"
              :source="item.source"
              :display="item.display"
              :editMode="editMode"
              :title="item.title"
              :index="index"
              :columnLength="columns.length"
              :getColumnStyle="getColumnStyle"
              :overflowedList="isOverflowing()"
              :column="item"
              :clickPrice="clickPrice"
              />
            <RunnerInfoColumn
              v-else-if="item.type === 'RUNNER_INFO'"
              :id="raceID"
              :selection="item.selection"
              :source="item.source"
              :queryType="item.queryType"
              :title="item.title"
              :index="index"
              :editMode="editMode"
              :columnLength="columns.length"
              :getColumnStyle="getColumnStyle"
              :overflowedList="isOverflowing()"
              />
            <RunnerHistory
              v-else-if="item.type === 'RUNNER_HISTORY'"
              :type="item.type"
              :sub="item.sub"
              :id="raceID"
              :selection="item.selection"
              :source="item.source"
              :editMode="editMode"
              :title="item.title"
              :index="index"
              :columnLength="columns.length"
              :getColumnStyle="getColumnStyle"
              :overflowedList="isOverflowing()"
              />
            <CustomDataColumn
              v-else-if="item.type === 'CUSTOM_DATA'"
              :id="raceID"
              :selection="item.selection"
              :source="item.source"
              :editMode="editMode"
              :title="item.title"
              :index="index"
              :columnLength="columns.length"
              :getColumnStyle="getColumnStyle"
              :overflowedList="isOverflowing()"
            />
            <PriceDataColumn
              v-else-if="item.type === 'bookieSelect'"
              :id="raceID"
              :column="item"
              :getFlucs="getFlucs"
              :fetchFlucs="fetchFlucs"
              :getColumnStyle="getColumnStyle"
              :overflowedList="isOverflowing()"
              :columnLength="columns.length"
              :index="index"
              :selection="item.selection"
              :source="item.source"
              :editMode="editMode"
            />
            <RaceTableColumn
              v-else
              :raceID="raceID"
              :columnLength="columns.length"
              :getColumnStyle="getColumnStyle"
              :column="item"
              :columnFlucs="columnFlucs"
              :clickPrice="clickPrice"
              :getFlucs="getFlucs"
              :fetchFlucs="fetchFlucs"
              :widget="widget"
              :authorized="authorized"
              :testMode="testMode"
              :editMode="editMode"
              :index="index"
              />
          </SlickItem>
        </SlickList>
      </div>
    </div>
    <div v-else class="raceTableColumns_container">
      <div class="table-list">
        <div v-for="(item, index) in items" :index="index" :key="index" :class="item.selection === 'my_price_dynamicform' || item.selection === 'rating_dynamicform' ? 'table-list-item-normal table-list-item-df' : 'table-list-item-normal'" style="background:#fff;margin-right:1px;">
          <BestPriceColumn
            v-if="item.type === 'BEST_PRICE'"
            :id="raceID"
            :source="item.source"
            :display="item.display"
            :editMode="editMode"
            :title="item.title"
            :index="index"
            :columnLength="columns.length"
            :getColumnStyle="getColumnStyle"
            :overflowedList="isOverflowing()"
            :column="item"
            :clickPrice="clickPrice"
          />
          <RunnerInfoColumn
            v-else-if="item.type === 'RUNNER_INFO'"
            :id="raceID"
            :selection="item.selection"
            :source="item.source"
            :queryType="item.queryType"
            :title="item.title"
            :index="index"
            :editMode="editMode"
            :columnLength="columns.length"
            :getColumnStyle="getColumnStyle"
            :overflowedList="isOverflowing()"
            />
          <RunnerHistory
            v-else-if="item.type === 'RUNNER_HISTORY'"
            :type="item.type"
            :sub="item.sub"
            :id="raceID"
            :editMode="editMode"
            :selection="item.selection"
            :source="item.source"
            :title="item.title"
            :index="index"
            :columnLength="columns.length"
            :getColumnStyle="getColumnStyle"
            :overflowedList="isOverflowing()"
          />
          <CustomDataColumn v-else-if="item.type === 'CUSTOM_DATA'"
            :id="raceID"
            :selection="item.selection"
            :source="item.source"
            :editMode="editMode"
            :title="item.title"
            :index="index"
            :columnLength="columns.length"
            :getColumnStyle="getColumnStyle"
            :overflowedList="isOverflowing()"
          />
          <PriceDataColumn
            v-else-if="item.type === 'bookieSelect'"
            :id="raceID"
            :column="item"
            :getFlucs="getFlucs"
            :fetchFlucs="fetchFlucs"
            :getColumnStyle="getColumnStyle"
            :index="index"
            :editMode="editMode"
            :selection="item.selection"
            :overflowedList="isOverflowing()"
            :columnLength="columns.length"
          />
          <RaceTableColumn v-else :overflowedList="isOverflowing()" :raceID="raceID" :columnLength="columns.length" :getColumnStyle="getColumnStyle" :selectedTime="selectedTime" :column="item" :columnFlucs="columnFlucs" :clickPrice="clickPrice" :getFlucs="getFlucs" :fetchFlucs="fetchFlucs" :widget="widget" :authorized="authorized" :testMode="testMode" :editMode="editMode" :index="index" />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="columns.length < 0 || !editMode">
    <v-card
      class="ml-10"
      max-width="344"
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">Add Columns!</v-list-item-title>
          <v-list-item-subtitle>Edit layout to add columns to this table</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar
          tile
          size="80"
        ><v-icon large class="mr-1">mdi-pencil-plus-outline</v-icon></v-list-item-avatar>
      </v-list-item>
      <v-card-actions>
        <v-btn @click="setEditMode()" color="orange" x-small class="ml-1 d-inline white--text mr-2 editLayoutsButton"><v-icon small class="mr-1">mdi-pencil-plus-outline</v-icon>Edit Layout</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { SlickList, SlickItem } from 'vue-slicksort'
import { mapActions } from 'vuex'
import RaceTableColumn from './RaceTableColumn'
import DuplicateColumnMenu from './menus/DuplicateColumnMenu'
import RunnerInfoColumn from './RunnerInfoColumn'
import BestPriceColumn from './BestPriceColumn'
import CustomDataColumn from './CustomDataColumn'
import RunnerHistory from './RunnerHistory'
import PriceDataColumn from './PriceDataColumn'
// import RaceRunnersColumn from './RaceRunnersColumn'
// import RaceNamesColumn from './RaceNamesColumn'

export default {
  components: {
    SlickList,
    SlickItem,
    RaceTableColumn,
    DuplicateColumnMenu,
    RunnerInfoColumn,
    BestPriceColumn,
    CustomDataColumn,
    RunnerHistory,
    PriceDataColumn
    // RaceRunnersColumn,
    // RaceNamesColumn
  },
  props: {
    columns: Array,
    columnFlucs: Object,
    selectedTime: String,
    updateSelectedColumns: Function,
    getSelectedColumnsName: String,
    clickPrice: Function,
    getFlucs: Function,
    fetchFlucs: Function,
    widget: Boolean,
    authorized: Boolean,
    testMode: Boolean,
    editMode: Boolean,
    getColumnStyle: Function,
    raceID: String
  },
  methods: {
    ...mapActions(['setEditMode']),
    isOverflowing () {
      var element = this.$refs.priceList
      return (element?.offsetHeight < element?.scrollHeight || element?.offsetWidth < element?.scrollWidth)
    },
    clearAllColumns () {
      this.updateSelectedColumns({ name: this.getSelectedColumnsName, columns: [] })
    }
  },
  computed: {
    items: {
      get: function () {
        return [ ...this.columns ]
      },
      set: function (items) {
        const columns = items.map(i => {
          return {
            source: i.source,
            type: i.type,
            display: i.displayType,
            settings: i.settings,
            selection: i.selection,
            title: i.title,
            sub: i.sub,
            queryType: i.queryType,
            filters: i.filters,
            priceType: i.priceType
          }
        })
        this.updateSelectedColumns({ columns, name: this.getSelectedColumnsName })
      }
    }
  }
}
</script>

<style>
  .raceTableColumns {
    height: 100%;
  }
  .raceTableColumns_container {
    height: 100%;
  }
  .table-list {
    height: 100%;
  }
  .duplicateColumnButtonMain {
    top: 78px;
    height: 32px !important;
    transform: scale(0.85);
    left: 591px;
  }
  .layoutName {
    text-transform: uppercase;
    top: 71px;
    left: 239px;
    z-index: 300;
    margin: 0 !important;
  }
  .removeButton {
    text-transform: uppercase;
    cursor: pointer !important;
    top: 71px;
    left: 426px;
  }
  .removeButton i {
    font-size: 20px !important;
  }
  .editColumnsSection {
    display: flex;
    position: relative;
    z-index: 202;
    width:100%;
    font-size:0.8rem;
  }
  .table-list {
    display: flex;
  }
  .table-list-item {
    max-width: 150px;
    margin: 0;
    z-index: 200;
    height: 100%
  }
  .table-list-item-normal {
    max-width: 150px;
    margin: 0;
    z-index: 200;
    margin-right:1px;
  }

  .table-list-item-df {
    max-width: 70px !important;
  }

  .editMode .table-list-item :hover {
    cursor:grab;
  }

  /* COLUMN ACTIONS */
  .column-actions__add {
    margin: 0 !important;
    color: #4bb04f !important;
  }

  .column-actions__delete {
    margin: 0 !important;
    color: red !important;
 }

  .column-actions__gear {
    margin: 0 !important;
    color: #1d2253 !important;
 }

 .save-font {
  font-family: Helvetica, Arial, sans-serif !important;
  line-height: 16px !important;
  margin: 0 !important;
 }

 .text-success {
  color: #4bb04f !important;
 }
</style>
