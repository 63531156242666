import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_DYNAMIC_FORM_API
})

export const batchFetch = async function (keys, config) {
  try {
    const result = await axios.post('public/getUserKeys', { keys }, config)
    const results = result.data.records
    return results
  } catch (error) {
  }
}

export const batchFetchDynamicFormData = async function (keys, config) {
  try {
    const result = await axiosInstance.post('/api.public.Public/GetUserKeys', { keys }, config)
    const results = result.data.records
    return results
  } catch (error) {
    //
  }
}

export const fetchUserFilter = async function (name, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: name }, config)
    const filter = result.data.records ? JSON.parse(result.data.records[name]) : null
    if (filter) {
      return filter
    }
  } catch {
  }
}

export const setUserFilter = async function (name, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: name, value: JSON.stringify(value) }, config)
  } catch {
  }
}

export const dynamicFormSetUserData = function (name, value, config) {
  try {
   axiosInstance.post('/api.public.Public/UpsertUserKey', { key: name, value: JSON.stringify(value) }, config)
  } catch {
    //
  }
}

export const deleteBlackbookEntry = function (runnerId, key, config) {
  try {
    axiosInstance.post('/api.public.Public/DeleteBlackbookEntry', { runnerId: runnerId, key: key }, config)
  } catch {
    //
  }
}

export const insertBlackbookEntry = function (tabType, runnerId, selectedTabTypeName, raceNumber, meetingId, meetingType, key, config) {
  try {
    axiosInstance.post(`/api.public.Public/InsertBlackbookEntry`, { tabType: tabType, runnerId: runnerId, selectedTabTypeName: selectedTabTypeName, raceNumber: raceNumber, meetingId: meetingId, meetingType: meetingType, key: key }, config)
  } catch {
    //
  }
}

export const resetUserFilters = async function (config) {
  try {
    const result = await axios.post('public/getUserKeys', {}, config)
    Object.keys(result.data.records).forEach(async (key) => {
      await axios.post('public/deleteUserKey', { key }, config)
    })
  } catch {
  }
}

export const fetchColumnLayout = async function (name, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `columnLayout:${name}` }, config)
    const layout = result.data.records ? JSON.parse(result.data.records[`columnLayout:${name}`]) : null
    if (layout) {
      return layout
    }
  } catch (error) {
    return []
  }
  return null
}

export const setColumnLayout = async function (name, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `columnLayout:${name}`, value: JSON.stringify(value) }, config)
  } catch (error) {
    window.localStorage.setItem(`columnLayout:${name}`, JSON.stringify(value))
  }
}

export const fetchSpeedMap = async function (id, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `speedMap:${id}` }, config)
    const speedMap = result.data.records ? JSON.parse(result.data.records[`speedMap:${id}`]) : null
    if (speedMap) {
      return speedMap
    }
  } catch (error) {
  }
  return null
}

export const setSpeedMap = async function (id, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `speedMap:${id}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const resetSpeedMap = async function (id, config) {
  try {
    await axios.post('public/deleteUserKey', { key: `speedMap:${id}` }, config)
  } catch (error) {
  }
}

export const setCustomRaceData = async function (id, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `customRaceData:${id}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const fetchCustomRaceData = async function (id, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `customRaceData:${id}` }, config)
    const data = result.data.records ? JSON.parse(result.data.records[`customRaceData:${id}`]) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const getLastRunnerComment = async function (id, config) {
  try {
    const result = await axios.post('public/getLastRunnerComment', { runner_id: id }, config)
    const data = result.data.comment
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const setCustomShowHideMeetingsData = async function (date, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `filteredMeetings:${date}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const fetchCustomShowHideMeetingsData = async function (date, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `filteredMeetings:${date}` }, config)
    const data = result.data.records ? JSON.parse(result.data.records[`filteredMeetings:${date}`]) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const setCustomShowHideRacesData = async function (date, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `filteredRaces:${date}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const fetchCustomShowHideRacesData = async function (date, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `filteredRaces:${date}` }, config)
    const data = result.data.records ? JSON.parse(result.data.records[`filteredRaces:${date}`]) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const setMyRaces = async function (date, races, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `myRaces:${date}`, value: JSON.stringify(races) }, config)
  } catch (error) {
  }
}

export const getMyRaces = async function (date, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `myRaces:${date}` }, config)
    const data = result.data.records ? JSON.parse(result.data.records[`myRaces:${date}`]) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const setShowRacedayUpdates = async function (key, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: key, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const fetchShowRacedayUpdates = async function (key, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: key }, config)
    const data = result.data.records ? JSON.parse(result.data.records[key]) : null
    if (data !== null) {
      return data
    }
  } catch (error) {
  }
}

export const setMarketMoverBookie = async function (value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: 'marketMoverBookie', value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const getMarketMoverBookie = async function (config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: 'marketMoverBookie' }, config)
    const data = result.data.records ? JSON.parse(result.data.records.marketMoverBookie) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const setRacesGroupByMeeting = async function (value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: 'racesGroupByMeeting', value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const getRacesGroupByMeeting = async function (config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: 'racesGroupByMeeting' }, config)
    const data = result.data.records ? JSON.parse(result.data.records.racesGroupByMeeting) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}

export const setCustomShowHideMeetingTypesData = async function (date, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `filteredMeetingTypes:${date}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const fetchCustomShowHideMeetingTypesData = async function (date, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `filteredMeetingTypes:${date}` }, config)
    const data = result.data.records ? JSON.parse(result.data.records[`filteredMeetingTypes:${date}`]) : null
    if (data) {
      return data
    }
  } catch (error) {
  }
}
