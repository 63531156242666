<template>
  <span :style="getStartTimeColor()">{{ prepend }} {{ formattedStartTime }} {{ append }} </span>
</template>

<script>

export default {
  name: 'Countdown',
  props: {
    styles: String,
    prepend: String,
    append: String,
    formattedStartTime: String
  },
  methods: {
    getStartTimeColor () {
      const start = this.formattedStartTime
      if (!start) {
        return ''
      }
      if (start.includes('h')) return 'color:grey'
      if (start.includes('-')) return 'color:red'
      if (start.includes('d')) return 'color:grey'
      return 'color:green'
    }
  }
}
</script>
