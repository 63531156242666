<template>
    <div class="acceptances overflow-y-hidden">
      <div class="acceptances__wrapper">
        <v-toolbar elevation="1" color="primary" class="acceptances__toolbar white--text pa-0 text-lg-h6 font-weight-regular" dark dense>
          <v-toolbar-title>Dual Acceptances</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon small @click="toggleDualAcceptances({ boolean: false })"><v-icon size="20">mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-container>
          <header class="acceptances__header d-flex space-between">
            <v-text-field class="ml-2" placeholder="Search by runner name, jockey or trainer" outlined v-model="search" style="width: 20px !important; height: 20px !important;" @keydown.enter="onFilter" dense hide-details>
              <template v-slot:append>
                <v-icon v-if="isEmpty(search)" color="primary">mdi-magnify</v-icon>
                <v-icon v-else color="primary" class="pointer" @click="onClickFilter" >mdi-close</v-icon>
              </template>
            </v-text-field>
          </header>

          <div style="height: 460px" class="overflow-y-auto">
            <v-card v-for="(acceptances, index) in filteredAcceptances" :key="`${index}`" class="pa-2" elevation="0">
              <v-card class="box-shadow--container">
                <v-simple-table height="auto" class="acceptances__table" dense>
                  <thead>
                    <tr>
                      <th style="width: 6.2% !important;" class="text-left">Silk</th>
                      <th class="text-left">Name</th>
                      <th class="text-left" style="width: 35% !important;">Race</th>
                      <th class="text-left">Jockey</th>
                      <th class="text-left">Trainer</th>
                      <th class="text-left">Weight</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(acceptance, index) in acceptances" :key="`${index}-${acceptance?.externalMeetingID}-${acceptance?.startTime}`" :class="`acceptances__tr ${acceptance.scratched ? 'acceptances__scratched' : ''}`" @click="onGoToRace({ acceptance })">
                      <td style="width: 6.2% !important;" class="text-left acceptances__silks">
                        <img :src="getRunnerSlikUrl({ runner: acceptances[0] })" alt="Runner silk" @error="setAltImage" class="ml-n1 mb-n1 pt-1" />
                      </td>
                      <td class="text-left acceptances__runner">
                        <Tooltip :text="acceptance.runner" :maxCharacters="10" />
                      </td>
                      <td style="width: 35% !important;">
                        <span>
                          R{{ acceptance.raceNumber}} @ {{ returnRaceData({ acceptance })?.startTime }} (<span :class="`acceptances__${dateToWord({ acceptance })}`"> {{ dateToWord({ acceptance }) }}</span>) - {{ acceptance.raceName}} - {{ acceptance?.distance }}m
                        </span>
                      </td>
                      <td class="text-left">
                        <Tooltip :text="acceptance.jockeyDriver" :maxCharacters="10" />
                      </td>
                      <td class="text-left">
                        <Tooltip :text="acceptance.trainer" :maxCharacters="10" />
                      </td>
                      <td class="text-left">{{ acceptance.weight }}kg</td>
                      <td class="text-left">{{ `${acceptance.scratched ? 'Scratched' : 'Racing'}` }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-card>

            <v-card  elevation="0" v-if="isEmpty(filteredAcceptances)" class="pa-2 d-flex justify-center align-center" height="32px">
              <span class="text-caption ma-0 grey--text text-sm-body-2">No data avaiable...</span>
            </v-card>
          </div>
        </v-container>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { format, getUnixTime, parseISO } from 'date-fns'

import { countdown, isEmpty, formattDateToWords } from '../common/methods'
import Tooltip from './Tooltip.vue'

export default {
  data: () => ({
    search: '',
    filteredAcceptances: []
  }),
  components: {
    Tooltip
  },
  computed: {
    ...mapState(['isDualAcceptances', 'dualAcceptances']),
    isDual: {
      get () {
        return this.isDualAcceptances
      },
      set (value) {
        this.toggleDualAcceptances({ boolean: value })
        return value
      }
    }
  },
  mounted () {
    this.filteredAcceptances = this.onSortAcceptancesAlphabetically({ acceptances: this.dualAcceptances })
  },
  methods: {
    ...mapActions(['toggleDualAcceptances', 'selectRace']),
    formattDateToWords,
    isEmpty,
    setAltImage (event) {
      event.target.src = '/assets/default_silk.png'
    },
    getRunnerSlikUrl ({ runner }) {
      if (!isEmpty(runner?.externalMeetingID)) {
        const url = `https://1ae2v478chfucobmr4slnqs1d6.silks.betmakers.com/${runner?.externalMeetingID}_${runner.raceNumber}_${runner.tabNo}_64x64.png`
        return url
      } else {
        return '@/assets/default_silk.png'
      }
    },
    formattedStartTime ({ runner }) {
      const time = getUnixTime(parseISO(runner.startTime))
      const newStartTime = countdown(time)
      return newStartTime
    },
    dateToWord ({ acceptance }) {
      const toYyyyMmDd = acceptance?.date?.split('/').reverse().join('-')
      return formattDateToWords(toYyyyMmDd)
    },
    returnRaceData ({ acceptance }) {
      const startTime = format(new Date(acceptance?.startTime), 'HH:mmaaa')
      const raceDetails = `R${acceptance?.raceNumber} - ${startTime}`

      return {
        startTime,
        raceDetails
      }
    },
    onGoToRace ({ acceptance }) {
      this.selectRace({ id: acceptance?.raceID })
      // this.toggleDualAcceptances({ boolean: false })
    },
    onFilter () {
      if (isEmpty(this.search)) {
        this.filteredAcceptances = this.onSortAcceptancesAlphabetically({ acceptances: this.dualAcceptances })
      } else {
        const filtered = this.dualAcceptances.filter(acceptance => {
          const search = this.search?.toLowerCase()
          const runner = acceptance[0].runner?.toLowerCase()
          const jockey = acceptance[0].jockeyDriver?.toLowerCase()
          const trainer = acceptance[0].trainer?.toLowerCase()
          return runner?.includes(search) || jockey?.includes(search) || trainer?.includes(search)
        })
        this.filteredAcceptances = filtered
      }
    },
    onClickFilter (event) {
      this.search = ''
      this.filteredAcceptances = this.dualAcceptances
    },
    onSortAcceptancesAlphabetically ({ acceptances }) {
      const alphabeticalOrder = acceptances.sort(function (a, b) {
        const textA = a[0]?.runner
        const textB = b[0]?.runner
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
      return alphabeticalOrder
    }
  }
}
</script>

<style lang="scss">
  .acceptances {
    overflow-y: hidden;

    &__wrapper {
      background-color: white;
      overflow-y: auto;
    }

    &__toolbar {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &__header {
      margin-bottom: 50px;

      input {

        &::active {
          border: none;
        }
      }
    }

    &__table {
      background: white !important;

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 10px !important;
        font-size: 12.5px !important;
        height: 25px !important;
        text-align: left !important;
      }
    }

    &__tr {
      cursor: pointer;
    }

    &__silks {
      img {
        width: 28px;
        height: 28px;
      }
    }

    &__scratched {
      background: repeating-linear-gradient(
        180deg,
        red 0%,
        red 100%
      );
      background-size: 100% 1px !important;
      background-position: center;
      background-repeat: no-repeat;
      color: #777 !important;
      opacity: 0.7 !important;

      img {
        opacity: 0.4 !important;
      }
    }

    &__Today {
      color: #4caf50;
      font-weight: bold;
    }

    &__Tomorrow {
      font-weight: bold;
      color: orange;
    }

    &__runner {
      span {
        font-weight: bold !important;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }
</style>
