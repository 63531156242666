<template>
 <v-card class="pa-2 mb-2" elevation="0">
    <div class="d-flex align-center">
        <v-icon v-if="isJockey" class="mr-2 grey--text jt-icon">mdi-account</v-icon>
        <v-icon v-else class="mr-2 grey--text jt-icon">mdi-binoculars</v-icon>

      <div class="d-flex width100">
        <div class="mr-auto text-sm-body-2">
          <p class="font-weight-medium inline-block cursor mb-0" :title="`${upcoming.appendedSelectedTabTypeName}`">
            <Tooltip :text="`${upcoming.appendedSelectedTabTypeName} (${upcoming.barrier})`" :maxCharacters="10" />
          </p>
          <p class="cursor font-weight-light mb-0" @click="onOpenNewRace({upcoming})">{{upcoming.raceName}} R{{upcoming.raceNumber}} {{ callFormattDateToWords(upcoming.date) }}</p>
        </div>
        <div class="text-sm-body-2 font-weight-light ml-auto">
          <p class="mb-0 align-right text-right">
              <span>{{ raceTime }}</span> (<Countdown
              :key="upcoming.startTime"
              :startTime="upcoming.startTime"
              :formattedStartTime="formattedStartTime({ upcoming })"
            />)
          </p>
          <p class="ml-auto mb-0">
            <Tooltip :text="`${upcoming.runner} (${upcoming.barrier})`" :maxCharacters="runnerMaxChar" />
          </p>
        </div>
        <div v-if="isCheckboxMode" class="d-flex align-center ml-2">
          <v-simple-checkbox
            v-if="isCheckboxMode"
            color="black"
            class="checkbox ma-0 px-0 mr-1"
            :ripple="false"
            @click="onToggleDynamicCheckbox({ blackbook: currentBlackbook })"
            :value="!isCheckboxMode2 ? onReturnCheckboxState({ blackbook: currentBlackbook }) : true"
            hide-details
          ></v-simple-checkbox>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>

import { mapState } from 'vuex'
import { formattDateToWords } from '../common/methods'
import Countdown from './Countdown.vue'
import Tooltip from './Tooltip'

export default {
  name: 'BlackbookUpcomingJockeyAndtrainer',
  components: { Countdown, Tooltip },
  props: {
    blackbooks: {
      type: Array,
      required: true
    },
    upcoming: {
      type: Object,
      required: true
    },
    isJockey: {
      type: Boolean,
      required: true
    },
    isCheckboxMode: {
      type: Boolean,
      required: true
    },
    isCheckboxMode2: {
      type: Boolean,
      required: true
    },
    onReturnCheckboxState: {
      type: Function,
      required: true
    },
    onToggleDynamicCheckbox: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState(['races']),
    raceTime: function () {
      return this.upcoming.race ? this.upcoming.race.split(' ')[0] : null
    },
    currentBlackbook () {
      const found = this.blackbooks?.find(blackbook => blackbook?.runner?.appendedId === this.upcoming?.appendedId)
      return found || {}
    },
    runnerMaxChar () {
      return this.isCheckboxMode ? 15 : 30
    }
  },
  methods: {
    callFormattDateToWords () {
      if (!formattDateToWords(new Date(this.upcoming.startTime)) === 'Today') return `(${this.upcoming.date})`
      return ''
    },
    truncate (text) {
      if (text.length > 10) {
        return text.substring(0, 10) + '...'
      }
      return text
    },
    onOpenNewRace ({ upcoming }) {
      this.$emit('open-new-race', { upcoming })
    },
    formattedStartTime ({ upcoming }) {
      return this.races[upcoming.raceID]?.formattedStartTime
    }
  }
}
</script>

<style scoped>
.width100 {
  width: 100%;
}

.indicators__indicator:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: pink;
}

.indicators__indicator--nomination::before {
  background: #c70066;
}

.indicators__indicator--weight::before {
  background: #0032c7;
}

.indicators__indicator--acceptance::before {
  background: #eb8402;
}

.cursor {
  cursor: pointer !important;
}
.checkbox i {
  font-size: 20px;
}
</style>
