<template>
  <div :class="iconClass(mobile,meeting)"></div>
</template>

<script>
export default {
  props: {
    meeting: Object,
    mobile: Boolean
  },
  methods: {
    iconClass (mobile, meeting) {
      return mobile ? `iconWidget-Inverse-${meeting.type}` : `iconWidget-${meeting.type}`
    }
  }
}
</script>

<style>
.iconWidget-HarnessRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Harness-BLACK.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-GreyHoundRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Greyhounds-BLACK.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-HorseRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Gallops-BLACK.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-Inverse-HarnessRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Harness.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-Inverse-GreyHoundRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Greyhounds.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-Inverse-HorseRacing {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Gallops.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
/* new graphql endpoint */
.iconWidget-HARNESS {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Harness-BLACK.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-GREYHOUND{
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Greyhounds-BLACK.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-THOROUGHBRED {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Gallops-BLACK.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-Inverse-HARNESS {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Harness.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-Inverse-GREYHOUND {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Greyhounds.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
.iconWidget-Inverse-THOROUGHBRED {
  background-size: cover;
  background-image: url('~@/assets/race_types/DO_RaceType_Gallops.svg');
  display: inline-block;
  height: 22px;
  width: 43px;
  margin-left: 5px;
}
</style>
