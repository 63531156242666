<template>
      <modal @resize="watchSize" @opened="() => dialog = true" @closed="() => dialog = false" name="exotics-modal" draggable="" resizable :clickToClose=false :minWidth=600 :minHeight=300 width=1000px height=600px>
        <v-card class="allExotics" elevation="0">
          <v-toolbar color="primary" dark dense style="position: sticky; top: 0; z-index: 999;">
            <div style="width: 100%;">
              <v-toolbar-title class="float-left"> Exotics </v-toolbar-title>
              <v-btn class="float-right" icon small @click="hide"><v-icon class="white--text">mdi-close</v-icon></v-btn>
            </div>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="5">
              <v-simple-table height="auto" class="exotic-items" dense>
                <thead>
                  <tr>
                    <th class="text-center">Exotic Pools</th>
                    <th class="text-center">
                      <div class="logo-V">&nbsp;</div>
                      <div class="exoticTypeHead">Tote</div>
                    </th>
                    <th class="text-center">
                      <div class="logo-N">&nbsp;</div>
                      <div class="exoticTypeHead">Tote</div>
                    </th>
                    <!-- <th class="text-center">
                      <div class="logo-Q">&nbsp;</div>
                      <div class="exoticTypeHead">Tote</div>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pool, index) in getPools()" :key="`${pool.type}-${index}`">
                    <td class="text-center">{{ pool.type}}</td>
                    <td class="text-center">{{ pool.poolSizeV }}</td>
                    <td class="text-center">{{ pool.poolSizeN }}</td>
                    <!-- <td class="text-center">{{ pool.poolSizeQ }}</td> -->
                  </tr>
                </tbody>
              </v-simple-table>
              </v-col>
              <v-col cols="7">
                <v-card elevation="0">
                  <v-card-title class="py-0">Settings</v-card-title>
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="3">
                        <v-select dense outlined label="Betting Agency" :items="bettingAgencies" v-model="bettingAgencySelected"></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-select dense outlined label="Exotic Type" :items="exoticTypes" v-model="exoticTypeSelected"></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field dense outlined label="Min Odds" v-model="minOdds" type="number" disabled></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field dense outlined label="Max Odds" v-model="maxOdds" type="number" disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-simple-table height="auto" dense>
              <thead>
                <tr>
                  <th class="exoticsHeader text-center">No</th>
                  <th v-for="header in headers" :key="header" class="exoticsHeader text-center">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="verticalHeader in verticalHeaders" :key="`${verticalHeader}-vertical`">
                  <th class="exoticsHeader text-center px-2">{{ verticalHeader }}</th>
                  <td v-for="header in headers" :key="`${header}-dividens`" class="text-center">{{ getPriceFromDividens(verticalHeader, header) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      dialog: false,
      bettingAgencies: [
        {
          text: 'TAB VIC ',
          value: 'V'
        },
        {
          text: 'TAB NSW',
          value: 'N'
        }
        // {
        //   text: 'TAB QLD',
        //   value: 'Q'
        // }
      ],
      exoticTypes: [
        {
          text: 'Quinella',
          value: 'QUINELLA'
        },
        {
          text: 'Exacta',
          value: 'EXACTA'
        }
      ],
      bettingAgencySelected: 'V',
      exoticTypeSelected: 'QUINELLA',
      minOdds: 1,
      maxOdds: 999
    }
  },
  computed: {
    ...mapGetters(['getSelectedRace', 'getColumns', 'getExoticsRaceSelected']),
    headers () {
      const competitors = this.race.competitors
      const numbersCompetitors = []
      competitors.forEach((competitor) => {
        numbersCompetitors.push(competitor.tabNo)
      })
      const numberCompetitorsSorted = numbersCompetitors.sort(function (a, b) {
        return a - b
      })
      return numberCompetitorsSorted
    },
    verticalHeaders () {
      const competitors = this.race.competitors
      const numbersCompetitors = []
      competitors.forEach((competitor) => {
        numbersCompetitors.push(competitor.tabNo)
      })
      const numberCompetitorsSorted = numbersCompetitors.sort(function (a, b) {
        return a - b
      })
      return numberCompetitorsSorted
    },
    race () {
      return this.getSelectedRace(this.getColumns)
    },
    getDividens () {
      const exotics = this.getExoticsRaceSelected
      const filteredExotics = exotics.filter(exotic => exotic.source === this.bettingAgencySelected && exotic.type === this.exoticTypeSelected)
      const dividens = filteredExotics[0]?.dividends
      return dividens
    }
  },
  methods: {
    ...mapActions(['fetchExotics']),
    watchSize () {
      const modal = document.querySelector('.vm--modal').clientHeight
      document.querySelector('.exotic-items').style.maxHeight = `${modal - 72}px`
      document.querySelector('.exotic-items').style.overflow = scroll
    },
    watchSizeDelay () {
      window.setTimeout(() => {
        const modal = document.querySelector('.vm--modal').clientHeight
        document.querySelector('.exotic-items').style.maxHeight = `${modal - 72}px`
        document.querySelector('.exotic-items').style.overflow = scroll
      }, 100)
      return ''
    },
    show () {
      this.$modal.show(
        'exotics-modal'
      )
    },
    hide () {
      this.$modal.hide(
        'exotics-modal'
      )
    },
    fetchRaceExotics () {
      this.fetchExotics()
    },
    getPriceFromDividens (firstCombination, secondCombination) {
      const dividend = this.getDividens?.filter(dividend => dividend.combination[0] === firstCombination && dividend.combination[1] === secondCombination)
      if (dividend && dividend.length > 0) {
        return dividend[0]?.price
      }
      return ''
    },
    getPools () {
      const exotics = this.getExoticsRaceSelected
      let poolsGroupedByExoticType = exotics.reduce((acc, obj) => {
        const property = obj.type
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})

      const pools = []

      for (const exoticType in poolsGroupedByExoticType) {
        const pool = {
          type: exoticType
        }
        poolsGroupedByExoticType[exoticType].forEach(x => {
          if (x.source === 'V') {
            pool.poolSizeV = x.poolSize
          } else if (x.source === 'N') {
            pool.poolSizeN = x.poolSize
          } else if (x.source === 'Q') {
            pool.poolSizeQ = x.poolSize
          }
        })
        pools.push(pool)
      }

      return pools
    }
  }
}
</script>

<style>
  /* TAB (TAB QLD)*/
  .logo-Q {
    width: 45px;
    height: 45px;
    background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -1125px;
    background-size: 90px;
    margin: 0 auto;
  }

  /* TAB (TAB VIC)*/
  .logo-V {
    width: 45px;
    height: 45px;
    background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -1035px;
    background-size: 90px;
    margin: 0 auto;
  }

  /* TAB NSW*/
  .logo-N {
    width: 45px;
    height: 45px;
    background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -1080px;
    background-size: 90px;
    margin: 0 auto;
  }

  .exoticTypeHead {
    width: 100%;
    background: #000;
    color: #fff;
    font-size: 10px;
    text-align: center;
    margin: 0 auto;
    margin-top: 2.5px;
    margin-bottom:2px;
  }

  .allExotics {
    height: 100%;
    overflow-y: auto;
  }

  .exoticsHeader {
    background-color: #BBDEFB;
  }
</style>
