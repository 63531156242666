<template>
  <div :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'" style="width:100%;" class="mt-n5 pt-0">
    <v-checkbox class="scheduleLabels float-left mr-2 meetingTypesFilter" label="TAB" color="primary" hide-details v-model="selected" value="T"></v-checkbox>
    <v-checkbox class="scheduleLabels float-left mr-2 meetingTypesFilter" label="Non-TAB" color="primary" hide-details v-model="selected" value="N"></v-checkbox>
    <v-checkbox class="scheduleLabels float-left mr-2 meetingTypesFilter" label="Picnic" color="primary" hide-details v-model="selected" value="P"></v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    meetingTypes: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    selected: {
      get () {
        return this.meetingTypes
      },
      set (value) {
        return this.onClick(value)
      }
    }
  }
}
</script>

<style>
</style>
