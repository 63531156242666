<template>
  <v-card>
    <v-footer padless class="white--text text-center pa-1" color="grey darken-3" style="z-index:200;border-radius:0;font-size:12px;position:fixed;bottom:0;width:100%;">
      <v-row no-gutters>
        <v-col>
          <div style="float:left;">
            &copy; Copyright BetMakers ASX:BET
          </div>
        </v-col>
        <v-col v-if="hasFeature('versionAlert')">
          <span>Build Number: </span>
          <span id="buildVersionNumber">FRONTENDVERSION</span>
        </v-col>
        <v-col>
          <div>
            <div class="d-inline float-right">
              <div v-if="connected" class="d-inline text-center float-right">
                <v-btn x-small tile class="white--text" outlined color="success" style="cursor:none;pointer-events: none;">Server<v-icon small dark>mdi-server-plus</v-icon>
                </v-btn>
              </div>
              <div v-if="!connected" class="d-inline text-center float-right">
                <v-btn x-small class="white--text" outlined color="error" style="cursor:none;pointer-events: none;">Server<v-icon small dark>mdi-server-minus</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="d-inline float-right">
            <v-btn x-small tile class="white--text" text @click="showTermsFunc(true)" color="black">Terms &amp; Conditions</v-btn>
            <Terms v-if="showTerms" :showTermsFunc="showTermsFunc" :acMode="this.acMode"/>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Terms from './Terms'
export default {
  data: () => ({
    showTerms: false
  }),
  components: {
    Terms
  },
  computed: {
    ...mapGetters(['getSocketStatus', 'hasFeature']),
    ...mapState(['acMode']),
    connected: function () {
      if (this.getSocketStatus) {
        this.updateTokenExpired({ callback: () => {} })
      }
      return this.getSocketStatus
    }
  },
  methods: {
    ...mapActions('account', ['updateTokenExpired']),
    showTermsFunc (v) {
      if (v === true) {
        this.showTerms = true
      } else {
        this.showTerms = false
      }
    }
  }
}
</script>

<style>
</style>
