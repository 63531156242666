import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import SignUp from './views/SignUp.vue'
import SpeedMap from './views/SpeedMap.vue'
import Flucs from './views/Flucs.vue'
import account from './store/account'
import { isEmpty } from './common/methods'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
      props: { authMode: 'signup' },
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: SignUp,
      props: { authMode: 'login' },
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/about',
      name: 'about',
      meta: { hideToolbar: true, requiresAuth: false },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/speedMap/:raceID',
      name: 'speedMap',
      component: SpeedMap,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/flucs/:raceID',
      name: 'flucs',
      component: Flucs,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ((account.state.authorized === true && !isEmpty(account.state.user)) || account.state.isFreetrial) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
