<template>
  <v-dialog v-if="hasFeature('priceAlerts')" hide-overlay persistent v-model="dialogAddToWatchlist" scrollable max-width="400px">
    <v-card v-moveable>
      <v-toolbar color="primary" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title>Add to Watchlist</v-toolbar-title>
          <v-btn class="cancelButton" icon small @click="addToWatchListSwitch" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="cardSection">
        <div>
          <strong><span style="margin-right:20px;">Runner</span> <span>{{ runnerName }}</span></strong>
        </div>
        <v-divider></v-divider>
        <div class="agencySelectSection">
          <strong>Agency</strong>
          <v-select :items="bookies" v-model="selectedBookie" class="agencySelect" return-object>
            <template v-slot:item="{ item }">
              <div v-if="item.source !== 'All'" style="margin-left: 5px; margin-right: 10px"><RaceBookmakers :bookieCode="item.source"/></div>
              <span>{{ item.text }}</span>
            </template>
          </v-select>
          <strong class="agencyPrice">${{ bestPrice }}</strong>
        </div>
        <v-divider></v-divider>
        <div>
          <v-checkbox dense v-model="isPriceAlert" label="Set a price based alert for this runner"></v-checkbox>
        </div>
        <v-card :disabled="!isPriceAlert">
          <v-card-text>
            <div class="triggerSelect">
              <strong>Trigger</strong>
              <v-select class="triggerType" solo :items="triggers" v-model="trigger">
              </v-select>
              <v-text-field class="triggerPrice" placeholder="1" v-model="triggerPrice" type="number"></v-text-field>
            </div>
            <v-divider></v-divider>
            <div>
              <strong>Notification</strong>
              <v-checkbox v-model="alertTypes" value="UI" hide-details="true" class="notificationOptions" dense label="Display on Screen" style="display: -webkit-inline-box; margin-left: 22px;"></v-checkbox>
              <v-checkbox v-model="audioAlert" value="BELL" hide-details="true" class="notificationOptions" dense label="Audible Alert"></v-checkbox>
              <v-checkbox v-if="hasFeature('priceAlertsEmailNotification')" v-model="alertTypes" value="EMAIL" hide-details="true" class="notificationOptions" dense label="Email (user email)"></v-checkbox>
              <v-checkbox v-else v-model="alertTypes" value="EMAIL" disabled hide-details="true" class="notificationOptions" dense label="Email (user email)"></v-checkbox>
              <!-- <v-checkbox v-model="alertTypes" value="SMS" disabled hide-details="true" class="notificationOptions" dense label="SMS"></v-checkbox> -->
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="!loadingAlertIn" color="blue darken-1" text @click="addAlert">Save</v-btn>
        <v-progress-circular
        v-if="loadingAlertIn"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn color="blue darken-1" text @click="watchListSwitch">View Watchlist</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PRICE_ALERT_BOOKIES, BOOKMAKER_NAMES_LOOKUP, BETTYPE, DELETED_BOOKIES } from '../../common/constants'
import RaceBookmakers from '../RaceBookmakers'
import numeral from 'numeral'

export default {
  data: () => ({
    selectedBookie: null,
    alertTypes: ['UI'],
    trigger: { value: 'LESS_THAN', text: 'Less Than' },
    triggers: [
      { value: 'LESS_THAN', text: 'Less Than' },
      { value: 'GREATER_THAN', text: 'Greater Than' },
      { value: 'NOT_EQUAL', text: 'Greater or Less Than' }
    ],
    triggerPrice: null,
    isPriceAlert: true,
    loadingAlertIn: false
  }),
  components: {
    RaceBookmakers
  },
  computed: {
    ...mapState(['dialogAddToWatchlist', 'priceAlertRunner', 'selectedRace']),
    ...mapGetters(['hasFeature', 'getPrice', 'getBetfairPrice', 'getPriceAlertsAudio']),
    audioAlert: {
      get () {
        return this.getPriceAlertsAudio
      },
      set (value) {
        return this.onClick('priceAlertsAudio', value)
      }
    },
    bookies: function () {
      const deletedBookies = DELETED_BOOKIES.map(bookie => bookie.source)
      const list = PRICE_ALERT_BOOKIES.filter(c => !deletedBookies.includes(c.source))
      list.unshift({ type: 'all', source: 'All', text: 'all' })
      const bookiesList = list.map(x => {
        return {
          source: x.source,
          text: this.getTitle(x),
          type: x.type
        }
      })
      return bookiesList.sort((a, b) => {
        if (a.text.toUpperCase() < b.text.toUpperCase()) {
          return -1
        }
        return 1
      })
    },
    runnerName: function () {
      return `${this.priceAlertRunner.runnerNumber}.${this.priceAlertRunner.name}`
    },
    bestPrice: function () {
      const bookie = this.selectedBookie
      if (bookie?.source === 'BF') {
        const key = `${this.selectedRace.id}:${this.priceAlertRunner.runnerNumber}:WIN`
        const price = numeral(this.getBetfairPrice(key, bookie.type)).format('(0.0a)')
        return price
      }
      if (bookie) {
        const key = `${this.selectedRace.id}:${bookie.source}:${bookie.type}`
        const price = this.getPrice(this.priceAlertRunner.runnerNumber, key)
        return price?.price || this.priceAlertRunner.price
      }
      return this.priceAlertRunner.price
    }
  },
  methods: {
    ...mapActions(['addToWatchListSwitch', 'watchListSwitch', 'addPriceAlert', 'updateRaceGridSettings']),
    getTitle: function (bookie) {
      if (!BOOKMAKER_NAMES_LOOKUP[bookie.source]) return '* Any Agency *'
      return `${BOOKMAKER_NAMES_LOOKUP[bookie.source]} - ${BETTYPE[bookie.type]}`
    },
    findSelectedPrice: function (item) {
      return this.bookies.find(b => b.text === item.text)
    },
    onClick (filter, setting) {
      this.updateRaceGridSettings({ filter: filter, value: setting })
    },
    addAlert: function () {
      const agency = this.selectedBookie.source === 'All' ? null : this.selectedBookie?.source === 'BF' ? this.selectedBookie?.text.replace('Betfair', 'BF') : this.selectedBookie?.source
      if (this.isPriceAlert) {
        this.addPriceAlert({
          meetingId: this.priceAlertRunner.meetingId,
          raceNumber: this.priceAlertRunner.raceNumber,
          runnerNumber: this.priceAlertRunner.runnerNumber,
          types: this.alertTypes,
          agency: agency,
          priceAlertType: this.trigger.value || this.trigger,
          triggerPrice: this.triggerPrice,
          meetingDate: this.priceAlertRunner.meetingDate
        })
      } else {
        this.addPriceAlert({
          meetingId: this.priceAlertRunner.meetingId,
          raceNumber: this.priceAlertRunner.raceNumber,
          runnerNumber: this.priceAlertRunner.runnerNumber,
          agency: agency,
          meetingDate: this.priceAlertRunner.meetingDate
        })
      }
      this.loadingAlertIn = true
      setTimeout(() => {
        this.loadingAlertIn = false
        this.addToWatchListSwitch()
        // this.watchListSwitch()
      }, 3000)
    },
    setTriggerPrice: function (price) {
      this.triggerPrice = price
    }
  },
  created () {
    this.triggerPrice = this.bestPrice
  },
  watch: {
    runnerName: function (newName, oldName) {
      if (newName === oldName) {
        this.triggerPrice = this.bestPrice
      }
    },
    bestPrice: function () {
      this.setTriggerPrice(this.bestPrice)
    }
  },
  mounted () {
    const bookie = this.priceAlertRunner.bookie
    if (typeof bookie !== 'undefined') {
      this.selectedBookie = this.bookies.find(b => b.source === bookie)
    } else {
      this.selectedBookie = this.bookies[0]
    }
  }
}
</script>

<style scoped>
.cardSection {
  margin-top: 30px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.agencySelect {
  max-width: 200px;
}
.runnerName {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.agencySelectSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.agencyPrice {
  font-size: 1.2em;
}
.triggerSelect {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.triggerPrice {
  max-width: 60px;
}
.triggerType {
  max-width: 150px;
}
</style>
