<template>
  <div style="background-color: white; width: 99.6%; height: 87px;">
    <div class="marketMoverSection pt-1" >
      <v-row justify="space-between" class="pt-0">
        <v-col style="padding: 5px 0px 30px 20px !important;" class="mt-2" sm="1">
          <v-select :items="bookies" v-model="marketMoverBookie" class="agencySelect" :hide-selected="true" dense hide-details append-icon="">
            <template v-slot:selection="{ item }">
              <div style="transform: scale(0.9);"><RaceBookmakers :bookieCode="item.value"/></div>
            </template>
            <template v-slot:item="{ item }" nudge>
              <div class="d-flex justify-center align-center">
                <div style="margin-left: 5px; margin-right: 10px; transform: scale(0.8);"><RaceBookmakers :bookieCode="item.value"/></div>
                <span>{{ item.text }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
      <!-- <v-col md="2"><div style="zoom:80%" :class="'logo-' + marketMoverBookie"></div></v-col> -->
        <v-col style="padding: 6.5px 0px 30px 0px !important;" sm="9">
          <div class="ml-1">
            <h3 class="mb-n2 mt-2">MARKET MOVER</h3>
            <div class="marketMoverInformation" style="margin-top: 3px;">{{ this.marketMover.competitor }}</div>
            <div v-if="Math.abs(this.marketMover.percentage) !== 0" class="marketMoverInformation font-weight-light mt-n1">${{ this.marketMover.firstPrice }} to ${{ this.marketMover.lastPrice }} = {{ Math.abs(this.marketMover.percentage) }}%</div>
            <div v-else class="marketMoverInformation font-weight-light mt-n1">No Movers</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BOOKMAKER_NAMES_LOOKUP, DELETED_BOOKIES, PRICE_CHANGE_BOOKIES } from '../common/constants'
import RaceBookmakers from './RaceBookmakers'

export default {
  name: 'MarketMover',
  components: {
    RaceBookmakers
  },
  computed: {
    ...mapState(['flucs', 'poolHistory']),
    ...mapGetters(['getSelectedRace', 'getColumns', 'getMarketMover', 'getMarketMoverBookie']),
    bookies: function () {
      return this.items.map(x => {
        return {
          value: x.value,
          text: BOOKMAKER_NAMES_LOOKUP[x.value]
        }
      })
    },
    marketMover: function () {
      return this.getMarketMover
    },
    marketMoverBookie: {
      get () {
        return this.getMarketMoverBookie
      },
      set (bookie) {
        this.setMarketMoverBookie({ source: bookie })
      }
    },
    items () {
      const deletedBookies = DELETED_BOOKIES.map(bookie => bookie.source)
      const filteredColumns = PRICE_CHANGE_BOOKIES.filter(c => !deletedBookies.includes(c.value))
      return filteredColumns
    }
  },
  methods: {
    ...mapActions(['fetchFlucs', 'getNewFlucs', 'setMarketMoverBookie']),
    getTitle: function (bookie) {
      return `${BOOKMAKER_NAMES_LOOKUP[bookie.source]}`
    }
  }
}
</script>

<style scoped>
.marketMoverSection {
  /* background-image: linear-gradient(black, grey); */
  background-color: #fff;
  /* color: #fff; */
  height: 87px;
  border-top-left-radius: 5px;
}
.agencySelect {
  width: 80px;
  font-size:14px;
  margin-bottom: 5px;
}

.agencySelect >>> .v-input__slot::before {
  border-style: none !important;
}

.marketMoverInformation {
  font-weight: 500;
}
</style>
