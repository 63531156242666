<template>
  <div>
    <template v-if="widget || !authorized || editMode">
      <div v-if="showPrice" class="bookiePrice" v-bind:class="classObject"  @click="!editMode ? handlePriceClick() : null">{{ price }}</div>
    </template>
    <v-menu v-if="showPrice && !widget && authorized && !editMode" :transition="false" open-on-hover :open-delay="openDelay" offset-x :close-delay="openDelay/3">
      <template v-slot:activator="{ on }">
        <div v-if="showPrice && !showDecimalPlaces && !showFractionalOdds || type === 'total-matched' && !showFractionalOdds" class="bookiePrice setBoxWidth" v-bind:class="classObject" v-on="on" @mouseover="getFlucs()"  @mouseleave="cancelFlucsCall()"  @click="!editMode ? handlePriceClick() : null"><span>{{ formatPrice(price)}}</span></div>
        <div v-else-if="showPrice && showDecimalPlaces && !showFractionalOdds || type !== 'total-matched' && !showFractionalOdds" class="bookiePrice setBoxWidth" v-bind:class="classObject" v-on="on" @mouseover="getFlucs()"  @mouseleave="cancelFlucsCall()"  @click="!editMode ? handlePriceClick() : null"><span>{{ priceFixed }}</span></div>
        <div v-else-if="showPrice && (showDecimalPlaces || !showDecimalPlaces) && showFractionalOdds || type === 'total-matched' && showFractionalOdds" class="bookiePrice setBoxWidth" v-bind:class="classObject" v-on="on" @mouseover="getFlucs()"  @mouseleave="cancelFlucsCall()"  @click="!editMode ? handlePriceClick() : null"><span>{{ priceFractional }}</span></div>
      </template>
      <v-list v-if="hasFlucs()" dense v-show="showFlucsMenu" class="priceFlucsMenu">
        <template v-for="(d, k) in priceFlucs">
          <v-list-item :key="k" class="pa-2 mt-n4 mb-n4">
            <v-list-item-content class="priceFlucsMenu-content">
              <v-list-item-title class="bold" style="fontSize:12px">{{ formatDate(k) }}</v-list-item-title>
              <template v-for="(p, i) in d">
                <div :key="Math.random(p.time.seconds)" v-if="p.price" >
                  <div style="float:left; marginRight:2px; fontSize:10px">{{formatTime(getUnixTime(parseISO(p.time)))}}</div>
                  <div style="float:right; marginRight:2px; fontSize:10px" :class="[getPriceFlucsStyled(k, i) ? 'drop' : 'normal']">{{p.price.toFixed(2)}}</div>
                </div>
              </template>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div style="height:80x;width:80px;background:#fff;z-index:999;text-align:center;" v-else-if="!hasFlucs() && !hideFlucs && price && price !== '-' && showFlucsChart">
        Loading...
      </div>
    </v-menu>
    <div v-if="showFlucs && columnFlucs" class="bookiePrice raceFlucsCol raceFlucs" @mouseover="getFlucs()" @mouseleave="cancelFlucsCall()">
      <RaceFlucs v-if="!competitor.scratched" :columnTime="column.time" :priceObject="competitor" :selectedTime="selectedTime" :flucs="columnFlucs[key]" :acMode="acMode"/>
    </div>
  </div>
</template>

<script>
import RaceFlucs from './RaceFlucs'
import {
  COLUMN_TYPE_FLUCS,
  COLUMN_TYPE_PRICE,
  COLUMN_TYPE_INFO,
  SHOW_PRICE_UPDATE
} from '../common/constants'
import { formatTime, formattDateToWords, matchPriceToType } from '../common/methods'
import { getUnixTime, parseISO } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import numeral from 'numeral'

export default {
  name: 'RacePriceColumn',
  components: {
    RaceFlucs
  },
  props: {
    acMode: Boolean,
    widget: Boolean,
    bookie: String,
    priceFlucs: Object,
    columnFlucs: Object,
    selectedTime: String,
    fetchFlucs: Function,
    authorized: Boolean,
    testMode: Boolean,
    priceKey: String,
    type: String,
    clickPrice: Function,
    hideFlucs: Boolean,
    competitor: Object,
    column: Object,
    raceID: String
  },
  beforeDestroy: function () {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  },
  computed: {
    ...mapGetters(['getGridSettings', 'getBetFairSettings', 'getBestPrice', 'getPrice', 'getBetFairPrices', 'getHighLightBestOdds', 'getBestPricePlace', 'getBetfairPrice', 'getBestTotePrice', 'getExchangeRate']),
    classObject: function () {
      return {
        bookiePriceButtonFirm: this.priceChangedFirm,
        bookiePriceButtonDrift: this.priceChangedDrift,
        bestprice: this.isBestPriceTote(),
        bestpriceWinFixed: this.isBestPriceWinFixed(),
        bestpricePlaceFixed: this.isBestPricePlaceFixed(),
        rightAlignPricesDiv: this.getGridSettings.includes('rightAlignPrices'),
        underlineDivLay: this.underlineLayOddsValue(),
        underlineDivBack: this.underlineBackOddsValue()
      }
    },
    showDecimalPlaces: function () {
      return this.getGridSettings.includes('showDecimalPlaces')
    },
    showFractionalOdds: function () {
      return this.getGridSettings.includes('fractionalOdds')
    },
    showScratched: function () {
      return this.getGridSettings.includes('displayScratchedPrice')
    },
    showFlucsChart: function () {
      return this.getGridSettings.includes('showPriceFlucs')
    },
    price: function () {
      const currentPrice = this.bookie === 'BF' ? this.betfairPrice() : this.bookie === 'BTC' ? this.getBestTotePrice(this.competitor.tabNo) : this.getPrice(this.competitor?.tabNo, this.key)?.price
      if (this.showScratched) {
        if (currentPrice) {
          return currentPrice
        }
      } else {
        if (currentPrice && !this.competitor?.scratched) {
          return currentPrice
        }
      }
      return null
    },
    priceFixed: function () {
      if (this.showScratched) {
        if (this.price) {
          return numeral(this.price).format('(0.00)') || '-'
        }
      } else {
        if (this.price && !this.competitor?.scratched) {
          return numeral(this.price).format('(0.00)') || null
        }
      }
      return null
    },
    priceFractional: function () {
      if (this.showScratched) {
        if (this.price) {
          const oddsFractional = this.price - 1
          const numerator = Math.floor(oddsFractional * 100)
          const denominator = 100
          const result = this.decimalToFractional(numerator, denominator)
          const fraction = `${result[0]}/${result[1]}` || ''
          if (fraction === '1/1') {
            return 'E'
          }
          return fraction
        }
      } else {
        if (this.price && !this.competitor?.scratched) {
          const oddsFractional = this.price - 1
          const numerator = Math.floor(oddsFractional * 100)
          const denominator = 100
          const result = this.decimalToFractional(numerator, denominator)
          const fraction = `${result[0]}/${result[1]}` || ''
          if (fraction === '1/1') {
            return 'E'
          }
          return fraction
        }
      }
      return null
    },
    key: function () {
      return this.priceKey
    },
    openDelay: function () {
      return !this.testMode ? 300 : 0
    },
    showFlucsMenu: function () {
      if (this.type === 'last-matched-price') return true
      if (this.bookie === 'BF') return false
      if (!this.showFlucsChart) return false
      if (this.hideFlucs) return false
      if (this.editMode || !this.price) return false
      return true
    },
    showFlucs: function () {
      return this.column?.display === COLUMN_TYPE_FLUCS
    },
    showPrice: function () {
      return this.column?.display === COLUMN_TYPE_PRICE || this.column?.displayType === COLUMN_TYPE_PRICE
    },
    showInfo: function () {
      return this.column?.display === COLUMN_TYPE_INFO
    }
  },
  watch: {
    price: function (newPrice, oldPrice) {
      if (!oldPrice || !newPrice || oldPrice === 0.0) return
      if (!this.highLightOdds()) return null
      if (newPrice > oldPrice) {
        this.priceChangedFirm = true
      } else if (newPrice < oldPrice) {
        this.priceChangedDrift = true
      }
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.priceChangedFirm = false
        this.priceChangedDrift = false
        this.timeout = null
      }, 2000)
    },
    raceID () {
      this.priceChangedFirm = false
      this.priceChangedDrift = false
    }
  },
  methods: {
    ...mapActions('betting', ['selectBet']),
    formatTime,
    parseISO,
    formattDateToWords,
    getUnixTime,
    formatPrice: function (price) {
      if (!Number(price)) return price
      return price > 0 ? Math.round(price * 100) / 100 : null
    },
    gcd (a, b) {
      return b ? this.gcd(b, a % b) : a
    },
    decimalToFractional (numerator, denominator) {
      const commonDenominator = this.gcd(numerator, denominator)
      return [numerator / commonDenominator, denominator / commonDenominator]
    },
    betfairPrice: function () {
      const priceType = this.column?.priceType || 'WIN'
      const key = `${this.raceID}:${this.competitor?.tabNo}:${priceType}`
      const price = (this.column?.type === 'back-1' || this.column?.type === 'lay-1') ? this.getBetfairPrice(key, this.column.type) : matchPriceToType(this.column?.type, this.betFairPrices()[key], this.getExchangeRate)
      if (!this.showDecimalPlaces) {
        return numeral(price).format('(0.0a)')
      } else if (this.showDecimalPlaces && this.column?.type === 'total-matched') {
        return numeral(price).format('(0.0a)')
      } else if (this.showDecimalPlaces && this.column?.type !== 'total-matched') {
        return numeral(price).format('(0.00a)')
      }
      return price
    },
    betFairPrices: function () {
      return this.getBetFairPrices
    },
    highLightOdds: function () {
      if (!SHOW_PRICE_UPDATE[this.type]) return false
      if (!this.getGridSettings.includes('highLightOdds')) return false
      return true
    },
    isBestPriceWinFixed: function () {
      const priceType = 'WIN_FIXED_ODDS'
      const key = `${this.raceID}:${this.competitor?.tabNo}:${priceType}`
      const compNum = this.competitor?.tabNo
      const priceNum = Number(this.price)
      if (this.getHighLightBestOdds.includes('WIN_FIXED_ODDS') && (this.type === 'WIN_FIXED_ODDS' || this.type === 'back-1') && priceNum !== 0) return priceNum === this.getBestPrice(compNum, key)
      return false
    },
    isBestPricePlaceFixed: function () {
      const priceNum = Number(this.price)
      if (this.getHighLightBestOdds.includes(this.type) && this.type === 'PLACE_FIXED_ODDS' && priceNum !== 0) return priceNum === this.getBestPricePlace(this.competitor?.tabNo, 'PLACE_FIXED_ODDS')
      return false
    },
    isBestPriceTote: function () {
      const priceNum = Number(this.price)
      if (!this.getHighLightBestOdds.includes('ignoreToteOdds') && (this.type === 'WIN' || this.type === 'PLACE') && priceNum !== 0) return priceNum === this.getBestPrice(this.competitor?.tabNo)
      return false
    },
    underlineBackOddsValue: function () {
      const underlineBackOdds = this.getGridSettings.includes('bFBackOdds')
      const x = Number(this.getBetFairSettings[1])
      const y = Number(this.price)
      if (underlineBackOdds && this.type === 'back-1' && x < y) return true
      return false
    },
    underlineLayOddsValue: function () {
      const underlineLayOdds = this.getGridSettings.includes('bFLayOdds')
      const x = Number(this.getBetFairSettings[0])
      const y = Number(this.price)
      if (underlineLayOdds && this.type === 'lay-1' && x > y) return true
      return false
    },
    handlePriceClick: function () {
      if (!this.price || !this.clickPrice) return null
      this.clickPrice({
        runner: this.competitor,
        price: Number(this.price),
        bookie: this.bookie,
        type: this.type,
        priceType: this.column?.priceType || 'WIN'
      })
    },
    getFlucs: function () {
      if (this.testMode) {
        this.fetchFlucs({
          source: this.bookie,
          type: this.type
        })
      } else {
        const flucsTimeout = setTimeout(() => {
          if (this.fetchFlucs && !this.hideFlucs) {
            this.fetchFlucs({
              source: this.bookie,
              type: this.type
            })
          }
        }, 300)
        clearTimeout(this.flucsTimeout)
        this.flucsTimeout = flucsTimeout
      }
    },
    cancelFlucsCall: function () {
      clearTimeout(this.flucsTimeout)
    },
    formatDate: function (date) {
      return formattDateToWords(date)
    },
    getPriceFlucsStyled: function (k, i) {
      const flucs = this.priceFlucs[k]
      const currentPrice = flucs[i]
      const previousPrice = flucs[i - 1]
      if (currentPrice && previousPrice) {
        return previousPrice.price > currentPrice.price
      } else {
        return false
      }
    },
    hasFlucs: function () {
      if (!this.priceFlucs) return null
      return Object.keys(this.priceFlucs).length > 0
    }
  },
  data: () => ({
    showModal: false,
    priceChangedFirm: false,
    priceChangedDrift: false,
    timeout: null,
    editMode: false,
    flucsTimeout: null,
    initialised: false
  })
}
</script>

<style scoped>
.rightAlignPricesDiv {
  text-align:right !important;
}
.bookiePriceButtonFirm {
  background-color: #57c957;
}

.bookiePriceButtonDrift {
  background-color: #eb5b5b;
}
.runnerInfo{
  width:200px;
}
.bestprice {
  background-color: rgba(5, 127, 175, 0.4);
}
.bestpriceWinFixed {
  background-color: rgba(5, 127, 175, 0.4);
}
.bestpricePlaceFixed {
  background-color: rgba(5, 127, 175, 0.4);
}
.raceFlucsCol {
  width:110px !important;
}
.bookiePrice {
  padding: 0px 4px;
  cursor: pointer;
  min-height:32px;
  max-height:32px;
  line-height:32px;
  text-align:center;
}
.setBoxWidth {
  width:42px;
  white-space: nowrap;
  overflow:hidden;
}
.priceFlucsMenu{
  min-width:98px;
  max-width:98px;
  max-height:900px;
  overflow-y:scroll;
  font-size:14px;
}
.priceFlucsMenu-content{
  font-size:12px;
}
.underlineDivLay {
  text-decoration: underline;
}
.underlineDivBack {
  text-decoration: underline;
}
.priceFlucsMenu-content-data{
  font-size:10px;
  display: flex;
  justify-content: space-between;
}
.drop{
  color: orange;
  font-weight: bold;
}
.normal{
  color:#1a237e;
}
</style>
