<template>
  <table class="widgetTable">
    <colgroup>
      <col v-for="(h, i) in headers" :key="`colg-${i}`">
    </colgroup>
    <slot name="header" :props="{ headers }"></slot>
    <tbody>
      <template name="item" v-for="item in items">
        <slot name="item" :item="item" :headers="headers"></slot>
      </template>
      <slot name="body.append" :headers="headers">
      </slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    items: Array,
    itemKey: String,
    headers: Array
  }
}
</script>

<style>
.widgetTable {
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  word-wrap: break-word;
  border-collapse: collapse;
  border: 0;
}
td {
  border-bottom: 0.5px solid #d8d8d8;
}
</style>
