<template>
  <div class="bestBookiePrice" :class="isInWatchList(competitor.tabNo) ? 'highlight' : ''">
    <div v-if="source" class="singlePrice" @click="handlePriceClick(bookies.prices[0])">
      <div class="bestPriceSingle">{{price}}</div>
      <div class="bestPriceLogoDiv">
        <div class="bestPriceLogo">
          <BookiesLogos :bookieCode="source" />
        </div>
    </div>
    </div>
    <div v-else>
      <v-menu :offset-x="true" :close-on-content-click="true">
        <template v-slot:activator="{ on }">
          <div class="multiplePriceSection"><div class="bestPriceSingle">{{ price }}</div><div class="multipleBestPrices" v-on="on"><span class="bestPrices-number">{{ bookies.prices.length }}</span><span style="font-size:11px;">best</span></div></div>
        </template>
        <v-card class="bookiesMenu pa-2">
          <v-btn v-for="bookie in bookies.prices" :key="bookie.source" @click="handlePriceClick(bookie)" class="bookieButton" fab small :disabled="!bettingAvailable(bookie.source)"><v-img :src="logoFromPriceCode(bookie.source)" height="50" width="50" class='bestPriceLogoMenu'></v-img></v-btn>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BookiesLogos from './BookiesLogos'

export default {
  props: {
    bookies: Object,
    competitor: Object,
    handleClick: Function
  },
  components: {
    BookiesLogos
  },
  computed: {
    ...mapGetters(['getAvailableAgencies', 'getWatchListRunners']),
    price () {
      return this.bookies?.price.toFixed(2) || null
    },
    source () {
      if (!this.bookies?.prices?.length || this.bookies?.prices.length > 1) {
        return null
      }
      return this.bookies?.prices[0]?.source || null
    }
  },
  methods: {
    handlePriceClick: function (source) {
      this.handleClick({
        runner: this.competitor,
        price: source.price,
        bookie: source.source,
        type: source.type
      })
    },
    logoFromPriceCode (code) {
      return 'https://betmakers.com/img/circle_logos/' + code + '.png'
    },
    getSource: function () {
      if (!this.bookies?.prices?.length || this.bookies?.prices.length > 1) {
        return null
      }
      return this.bookies?.prices[0]?.source || null
    },
    bettingAvailable: function (bookieCode) {
      if (this.getAvailableAgencies) {
        const agency = this.getAvailableAgencies.find(a => a.agency_id === bookieCode)
        return agency && agency.enabled
      } else {
        return []
      }
    },
    isInWatchList (tabNo) {
      return this.getWatchListRunners.includes(tabNo)
    }
  }
}
</script>

<style>
.bestBookiePrice {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight:600;
}
.bestPriceLogo {
  transform-origin: top left;
  transform: scale(0.5);
  width:45px;
  margin-inline-end: 10px;
  margin-top:3px;
}
.bestPriceLogoMenu {
  transform: scale(0.7);
}
.singlePrice {
  display: flex;
  justify-content: space-between;
  width:75px;
  text-align:right;
}
.bestPriceLogoDiv {
  width:22px;
  margin-right:4px;
}
.bestPriceSingle {
  width:42px;
  text-align:right
}
.bookiesMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bookieButton{
  margin:0 auto;
  margin-bottom: 5px;
}
.multiplePriceSection {
  display: flex;
  justify-content: space-between;
  width:100%;
}
.multipleBestPrices {
  height:27px;
  border:1px solid #ddd;
  line-height:24px;
  margin-top:2px;
  width:45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float:right;
  margin-left:4px;
}
.bestPrices-number {
  color: green;
  font-weight: bold;
  margin-right:4px;
  font-size:1.1rem;
}
</style>
