import { ApolloClient } from 'apollo-client'
import { split, ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'node-fetch'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { RetryLink } from '@apollo/client/link/retry'

const withToken = setContext(async () => {
  await window.doApp.$store.dispatch('account/updateCoreAPIToken', null, { root: true })
  const token = window.localStorage.getItem('coreapi_auth_token')
  return {
    // Make sure to actually set the headers here
    headers: {
      authorization: `Bearer ${token}` || null
    }
  }
})

const httpLink = ApolloLink.from([withToken, new HttpLink({
  uri: `${process.env.VUE_APP_GRAPHQL_HTTP}query`,
  options: { mode: 'no-cors' }
})])

const subscriptionLink = process.browser ? new GraphQLWsLink(createClient({
  url: `${process.env.VUE_APP_GRAPHQL_WS}`,
  lazy: true,
  connectionParams: async () => {
    await window.doApp.$store.dispatch('account/updateCoreAPIToken', null, { root: true })
    const token = window.localStorage.getItem('coreapi_auth_token')
    return {
      Authorization: `Bearer ${token}`
    }
  }
})) : null

const subLink = process.browser ? subscriptionLink : null

const splitLink = process.browser ? split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  subLink,
  httpLink
) : httpLink

const retryLink = new RetryLink({
  delay: {
    initial: 2 * 1000,
    max: 64 * 1000,
    jitter: true
  }
})

const link = ApolloLink.from([
  retryLink,
  splitLink
])

const cache = new InMemoryCache({ resultCaching: false })

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export const subscriptionClient = subscriptionLink?.subscriptionClient || {}

export const apolloClient = new ApolloClient({
  link,
  fetch: fetch,
  cache: cache,
  defaultOptions
})
