<template>
  <v-dialog v-if="hasFeature('priceAlerts')" hide-overlay v-model="watchListOpen" scrollable max-width="600px">
    <v-card v-moveable>
      <v-toolbar color="primary" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title>Runner Watchlist</v-toolbar-title>
          <v-btn class="cancelButton" icon small @click="watchListSwitch" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-data-table :headers="headersWatchList" :items="alerts">
          <template v-slot:item.runner_number="{ item }">
            <div class="runnerInfo">
               <span>
                {{ item.runner_number }}.
                {{ getRunnerInfo(item) }}
              </span>
              <span class="runnerWatchList-meetingInfo" @click="handleMeetingClick(item)">
                {{ getMeetingInfo(item) }}
              </span>
            </div>
          </template>
          <template  v-slot:item.agency="{ item }">
            <div v-if="item.agency" style="{margin: 0;}"><RaceBookmakers :bookieCode="item.agency"/></div>
          </template>
          <template v-slot:item.odds="{ item }">
            ${{ getOdds(item) }}
          </template>
          <template v-slot:item.price_alert_type="{ item }">
            <div v-if="getAlertType(item)">
              <div class="alertType">
                <span>{{ getAlertType(item) }}</span>
                <span>${{ item.trigger_price }}</span>
              </div>
              <span class="alertStatus">{{ getAlertStatus(item) }}</span>
            </div>
            <div v-else>None</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="deleteAlert(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { fromUnixTime, format } from 'date-fns'
import RaceBookmakers from './RaceBookmakers'

export default {
  data: () => ({
    headersWatchList: [
      {
        text: 'Runner',
        value: 'runner_number'
      },
      {
        text: 'Agency',
        value: 'agency'
      },
      {
        text: 'Odds',
        value: 'odds'
      },
      {
        text: 'Alert Type',
        value: 'price_alert_type'
      },
      {
        value: 'actions'
      }
    ]
  }),
  computed: {
    ...mapState(['dialogWatchlist']),
    ...mapGetters(['hasFeature', 'getGridSettings', 'getRunner', 'getRace', 'getWatchList']),
    alerts: function () {
      return Object.values(this.getWatchList)
    },
    watchListOpen: {
      get () {
        return this.dialogWatchlist
      },
      set () {
        this.watchListSwitch()
      }
    }
  },
  components: {
    RaceBookmakers
  },
  methods: {
    ...mapActions(['watchListSwitch', 'deletePriceAlert', 'selectRace']),
    deleteAlert: function (item) {
      this.deletePriceAlert({ alertId: item.alert_id })
    },
    getAlertType: function (alert) {
      if (alert.trigger_price && !alert.price_alert_type) {
        return 'Less than'
      }
      if (alert.price_alert_type === 1) {
        return 'Greater than'
      }
      if (alert.price_alert_type === 2) {
        return 'Greater or Less than'
      }
      return null
    },
    getRunnerInfo: function (item) {
      return item.name
    },
    getMeetingInfo: function (item) {
      return `${item.race_number} ${item.track}`
    },
    getOdds: function (item) {
      return item.prices ? item.prices.find(p => p.type === 'WIN_FIXED_ODDS')?.price : '-'
    },
    handleMeetingClick: function (item) {
      this.selectRace({ id: item.race.id })
    },
    getAlertStatus: function (item) {
      if (item.triggered_date?.seconds) {
        const date = fromUnixTime(item.triggered_date.seconds)
        const formattedDate = format(date, 'MM/dd/yyyy hh:mm:ss')
        return `sent ${formattedDate}`
      }
      if (item.race.status !== 'OPEN') {
        return 'expired'
      } else {
        return 'pending'
      }
    },
    getLogoClass: function (agency) {
      return `logo-${agency}`
    }
  }
}
</script>

<style scoped>
.alertType {
  display: flex;
}
.alertType span {
  margin-right: 10px;
}
.runnerInfo {
  display: flex;
  flex-direction: column;
}
.runnerWatchList-meetingInfo {
  font-size: .8em;
  text-decoration: underline;
  cursor: pointer;
}
.alertStatus {
  font-size: .8em;
}
</style>
