<template>
  <AmpAuth :authMode="authMode"/>
</template>

<script>
import AmpAuth from '../components/AmpAuth'

export default {
  components: {
    AmpAuth
  },
  props: {
    authMode: String
  }
}
</script>
