<template>
    <div v-if="item">
      <div v-if="meeting.type === 'THOROUGHBRED' || meeting.type === 'HARNESS'" style="vertical-align:bottom" :class="!editMode ? 'tooltipRunner silkSprite' : 'silkSprite'">
        <div style="overflow:hidden;width:30px;height: 30px;">
          <img v-if="item.tabNo" draggable="false" alt="silk" :class="['silkSprite-' + item.tabNo]" :src="image"
          @error="setAltImage"
          />
        </div>
      </div>
      <GreyHoundSprite
        v-if="meeting.type === 'GREYHOUND'"
        :meetingType="meeting.type"
        :meetingCountry="meeting.track.country"
        :tabNo="item.tabNo" />
    </div>
</template>

<script>
import GreyHoundSprite from './GreyHoundSprite.vue'

export default {
  props: {
    meeting: Object,
    item: Object,
    race: Object,
    editMode: Boolean,
    speedMap: Boolean,
    highlight: Boolean
  },
  components: {
    GreyHoundSprite
  },
  computed: {
    image () {
      if (this.race?.meeting?.externalIDs) {
        const url = 'https://1ae2v478chfucobmr4slnqs1d6.silks.betmakers.com/' +
                this.race?.meeting?.externalIDs[0]?.id +
                '_' +
                this.race.number +
                '_SPRITE_32x32.png'
        return url
      } return '@/assets/default_silk.png'
    }
  },
  methods: {
    setAltImage (event) {
      event.target.src = '/assets/default_silk.png'
    }
  }
}
</script>

<style scoped>
.defaultImage {
  background-repeat: no-repeat;
  background-image: url('~@/assets/default_silk.png')
}
.silkSprite {
  width: 30px;
  height: 30px;
}
.silkSprite-1 {
  margin: 0px;
}
.silkSprite-2 {
  margin: 0 0 0 -32px;
}
.silkSprite-3 {
  margin: 0 0 0 -64px;
}
.silkSprite-4 {
  margin: 0 0 0 -96px;
}
.silkSprite-5 {
  margin: 0 0 0 -128px;
}
.silkSprite-6 {
  margin: 0 0 0 -160px;
}
.silkSprite-7 {
  margin: 0 0 0 -192px;
}
.silkSprite-8 {
  margin: 0 0 0 -224px;
}
.silkSprite-9 {
  margin: 0 0 0 -256px;
}
.silkSprite-10 {
  margin: 0 0 0 -288px;
}
.silkSprite-11 {
  margin: 0 0 0 -320px;
}
.silkSprite-12 {
  margin: 0 0 0 -352px;
}
.silkSprite-13 {
  margin: 0 0 0 -384px;
}
.silkSprite-14 {
  margin: 0 0 0 -416px;
}
.silkSprite-15 {
  margin: 0 0 0 -448px;
}
.silkSprite-16 {
  margin: 0 0 0 -480px;
}
.silkSprite-17 {
  margin: 0 0 0 -512px;
}
.silkSprite-18 {
  margin: 0 0 0 -544px;
}
.silkSprite-19 {
  margin: 0 0 0 -576px;
}
.silkSprite-20 {
  margin: 0 0 0 -608px;
}
.silkSprite-21 {
  margin: 0 0 0 -640px;
}
.silkSprite-22 {
  margin: 0 0 0 -672px;
}
.silkSprite-23 {
  margin: 0 0 0 -704px;
}
.silkSprite-24 {
  margin: 0 0 0 -736px;
}

.tooltipRunner {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipRunner .tooltiptextRunner {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 105%;
  white-space: nowrap;
}

.tooltipRunner:hover .tooltiptextRunner {
  visibility: visible;
}

</style>
