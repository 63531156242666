<template>
  <div :class="this.acMode ? 'raceFiltersAcMode startersFilters' : 'stdMode startersFilters'" style="width:100%">
    <v-text-field label="From:" :rules="rulesStarter" placeholder="1" v-model="min" class="float-left d-inline" type="number" style="max-width:100px;"></v-text-field>
    <v-text-field label="To:" :rules="rulesStarter" placeholder="30" v-model="max" class="float-right d-inline" type="number" style="max-width:100px;"></v-text-field>
  </div>
</template>

<script>
export default {
  data: () => ({
    rulesStarter: [
      value => !!value || 'Required.',
      value => (value && value.length <= 2) || 'Max 2 characters',
      value => (value <= 30) || 'Max 30 Starters'
    ]
  }),
  props: {
    starters: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    min: {
      get () {
        return this.starters[0]
      },
      set (value) {
        return this.onClick([value, this.max])
      }
    },
    max: {
      get () {
        return this.starters[1]
      },
      set (value) {
        return this.onClick([this.min, value])
      }
    }
  }

}
</script>

<style>
</style>
