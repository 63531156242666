<template>
  <div>
    <v-navigation-drawer disable-resize-watcher hide-overlay disable-route-watcher permanent @mouseleave.native="mouseLeaveExpandThisPanel(checked)" width="auto" v-model="drawer" app v-show="autoHide" color="white" style="max-width:320px;overflow-x:hidden;" class="box-shadow--container raceDayNavigation" :class="this.acMode ? 'acMode' : 'stdMode'">
      <div style="width:400px;"></div>
      <template v-slot:prepend>
        <div class="px-2 pt-3">
          <v-text-field
            placeholder="Raceday search"
            outlined
            v-model="search"
            height="28px"
            @keydown.enter="raceDaySearch()"
            class="float-left pb-0 mb-n6"
            style="width: 253px;"
          ></v-text-field>
          <!--<v-btn @click="raceDaySearch()" small outlined class="ml-2 float-left"><v-icon>mdi-magnify</v-icon></v-btn>-->
          <v-checkbox @change="expandThisPanel(checked)" dense class="scheduleLabels autoHideCheckbox mb-n2" label="Auto hide" height="20px" :value="checked" v-model="checked" hide-details style="margin-right: 0px !important;"></v-checkbox>
        </div>
        <div class="px-2" style="max-width:100%">
          <RaceNavNextToGo :race="getNextToGo" :onClick="clickNextToGoRace" :widthNextToGo="'width: 100%;'"/>
          <v-list class="pa-0" style="height: 38px; margin-top: -7px;">
            <v-list-item class="pa-0">
              <v-menu ref="menu" v-model="menu" transition="scale-transition" offset-y min-width="0%" :nudge-right="40">
                <template v-slot:activator="{ on }">
                  <v-text-field class="formattedDate" v-model="formattedDate" small prepend-icon="mdi-calendar" readonly v-on="on" style="font-size:14px;" hide-details=""></v-text-field>
                </template>
                <v-date-picker v-if="authorized" v-model="date" no-title scrollable actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
              <!--Day Filter Buttons-->
              <v-btn outlined :color="dateColor('today')" x-small class="ma-1 float-left todayButton" @click="selectToday" style="overflow: auto; margin: 2px !important;">
                Today
              </v-btn>
              <v-btn outlined :color="dateColor('tomorrow')" x-small class="ma-1 float-left tomorrowButton" @click="selectTomorrow" style="overflow: auto; margin: 2px !important;">
                Tomorrow
              </v-btn>
              <v-btn outlined :color="dateColor('saturday')" x-small class="ma-1 float-left saturdayButton" @click="selectSaturday" style="overflow: auto; margin: 2px !important;">
                Saturday
              </v-btn>
            </v-list-item>
          </v-list>
          <div>
            <!--Schedule Filter Buttons-->
            <MeetingSummary :meetingSummaryToggle="meetingSummaryToggle" :closeSummary="closeMeetingSummary" :closeMeetingSummaryModal="closeMeetingSummaryModal"/>
            <RaceNavFilter :setFiltersOpen="setFiltersOpen" :openMeetingSummary="openMeetingSummary"/>

            <!-- <v-tooltip bottom color="black" open-delay="400" transition="none">
              <template v-slot:activator="{ on }">
                <v-btn outlined :color="showRaceAlerts ? 'info' : 'normal'" v-on="on" @click="showRaceAlertsCheckboxes(true)" x-small class="ma-1 float-left" style="overflow: auto; margin: 2px 2px 6px 2px !important;">
                  <v-icon x-small>mdi-bell-alert-outline</v-icon>
                </v-btn>
              </template>
              <span class="caption" style="font-family: 'Roboto', sans-serif;">Race Alerts</span>
            </v-tooltip> -->
            <v-menu elevation="20" :close-on-content-click="true" :nudge-left="68" :nudge-bottom="25">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined :color="showRaceAlerts || showOpenMarketAlerts ? 'info' : 'normal'" v-bind="attrs" v-on="on"  x-small class="ma-1 float-left" style="overflow: auto; margin: 2px 2px 6px 2px !important;">
                  <v-icon x-small v-if="showOpenMarketAlerts">
                    mdi-currency-usd
                  </v-icon>
                  <v-icon x-small v-else>
                    mdi-bell-alert-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense dark>
                <v-list-item @click="showRaceAlertsCheckboxes(true)"><v-icon small class="mr-2">mdi-flag-checkered</v-icon> Race Alert</v-list-item>
                <v-list-item v-if="hasFeature('openMarketAlert')" @click="onShowOpenMarketAlert()"><v-icon small class="mr-2">mdi-currency-usd</v-icon> Open Market Alert</v-list-item>
              </v-list>
            </v-menu>

            <RaceNavigationSettings :getConfigurationRaceAlert="getConfigurationRaceAlert" :saveConfigurationRaceAlerts="saveConfigurationRaceAlerts" :alerts="alerts" :soundsAlerts="soundsAlerts" :timesAlerts="timesAlerts" />
            <v-checkbox v-model="groupByMeeting" class="ma-1 float-left scheduleLabels" label="Sch/Meeting" style="margin: 2px !important; padding: 0px 0px !important;" height="20px" dense hide-details></v-checkbox>
          </div>
        </div>
      </template>
      <v-card flat>
        <v-tabs
          v-model="tab"
          background-color="white"
          grow
          :color="`${tab === 1 ? 'black' : 'primary'}`"
          class="tab-header"
        >
          <v-tabs-slider v-if="!hasFeature('blackbook')" color="white"></v-tabs-slider>
          <v-tabs-slider v-else :color="`${tab === 1 ? 'black' : 'primary'}`"></v-tabs-slider>
          <v-tab>Schedule</v-tab>
          <v-tab v-if="hasFeature('blackbook')" color="pink" class="blackbook-tab">Blackbook <span class="blackbook-notification">({{ numberOfUpcomingCompetitors }})</span></v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tab-header__body">
          <v-tab-item>
            <v-card color="basil" flat>
              <div class="raceNavigation" style="font-weight:300;margin:0 auto;">
                <v-data-table v-if="!this.getRacesGroupByMeeting" dense loading="Object.keys(races).length === 0" overflow-y="hidden" class="raceDayTable" loading-text="Loading Races..." :items="sorted" :headers="headersRaces" hide-default-footer hide-default-header :items-per-page=9999 :header-props="{ sortIcon: null }">
                  <template v-slot:header="{ props }">
                    <th v-for="(head, index) in props.headers" :key="index" style="border-bottom: 1px solid rgba(0,0,0,0.1);">
                      <span style="font-size: 12px; color: #666666;">
                        {{ head.text }}
                      </span>
                    </th>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-tooltip right nudge-bottom="40" nudge-left="70" z-index="999" color="black" open-delay="400">
                      <template v-slot:activator="{ on }">
                        <tr v-if="Object.keys(races).length !== 0" style="cursor:pointer" @click="clickRace(item)" :class="{selected:(highlightedRace == item.id)}" class="raceNavigationRow" v-on="on">
                          <td class="pa-1" style="font-size:0.8em">
                            {{formattedStartTime(item)}}
                          </td>
                          <td class="pa-1">
                            <country-flag style="vertical-align: middle; transform: scale(0.3);" :country="item.meeting.track.country" size="small" />
                          </td>
                          <td class="pa-1">
                            <img :src="raceTypeImg[item.meeting.type]" style="max-width:20px" />
                          </td>
                          <td class="pa-1" :style="item.bets ? 'color: orange;font-weight: bold;' : null">
                            {{`R${item.number}`}}
                          </td>
                          <td  :style="item.bets ? 'color: orange;font-weight: bold;' : null" style="white-space: nowrap;text-overflow: ellipsis;max-width:100px;padding-right:0px;overflow:hidden;margin-left:15px;">
                            <div v-if="item.fixedAvailable" style="background:#00A500;height:8px;width:8px;display:inline-block;margin-right:5px;border-radius:5px;" title="Fixed odds available"></div>{{item.meeting.track.name}}
                          </td>
                          <td class="pa-1 raceDayTime" align="right" v-if="item.status == 'FINAL'" >
                            Final
                          </td>
                          <td class="pa-1 raceDayTime" align="right" v-if="item.status === 'CLOSED'"  style="color:red">
                            Closed
                          </td>
                          <td class="pa-1 raceDayTime" align="right" v-if="item.status === 'ABANDONED'"  style="color:red">
                            Abandoned
                          </td>
                          <td class="pa-1 raceDayTime" align="right" v-if="item.status === 'INTERIM'"  style="color:orange">
                            Interim
                          </td>
                          <td class="pa-1 raceDayTime"  align="right" v-else-if="item.status === 'OPEN'">
                            <Countdown :key="item.startTime" :startTime="item.startTime" :formattedStartTime="races[item.id].formattedStartTime"/>
                          </td>
                          <td v-if="showRaceAlerts" class="raceAlertTD" :key="`${item.id}-racedayalert`">
                            <v-checkbox @click.native.stop :ripple="false" :input-value="item.activeAlert" :false-value="0" :true-value="1" :disabled="item.status !== 'OPEN'" hide-details="true" dense class="raceAlertCheckbox" @change="saveRaceAlert($event, item.id, item.meeting.date)"></v-checkbox>
                          </td>
                          <td v-if="showOpenMarketAlerts && !onReturnOpenMarketAvailability({ meeting: item })" class="raceAlertTD" :key="`${item.id}-available`">
                            <v-menu open-on-hover :close-on-content-click="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="red" style="position: relative; left: 1px;" title size="22px" v-bind="attrs" v-on="on">mdi-close-box</v-icon>
                              </template>
                              <div class="open-market-modal pa-2">
                                <span>Market Already Available</span>
                              </div>
                            </v-menu>
                          </td>
                          <td v-if="showOpenMarketAlerts && onReturnOpenMarketAvailability({ meeting: item })" class="raceAlertTD" :key="`${item.id}-openmarkeralert`">
                            <v-checkbox @click.native.stop :ripple="false" :input-value="onReturnOpenMarketValue({ meeting: item, ogPriceAlerts })" :false-value="false" :true-value="true"  hide-details="true" dense class="raceAlertCheckbox" @change="onToggleOpenMarketAlert({ value: $event, meeting: item })"></v-checkbox>
                          </td>
                        </tr>
                      </template>
                      <span class="tooltiptextRaceNavigation caption"><pre class="tooltipTextRaceNavigationPreformatted">{{getHoverText(item)}}</pre></span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-data-table v-else-if="hasFeature('racesGroupByMeeting')" dense overflow-y="hidden" :items="sorted[0]" :headers="headersRacesGroupByMeeting" hide-default-footer :items-per-page=9999 show-expand :expanded.sync="expanded" item-key="meetingName" :sort-by.sync="sortBy" :sort-desc="true">
                  <template v-slot:header.data-table-expand>
                    <button @click="closeAllExpanded">Close</button>
                  </template>
                  <template v-slot:item="{ item, expand, isExpanded }">
                    <tr @click="expand(!isExpanded)">
                      <td class="tdRacesGroupByMeeting" style="padding-left: 10px !important;">
                        <country-flag style="vertical-align: middle; transform: scale(0.3);" :country="item.meetingCountry" size="small" />
                      </td>
                      <td class="tdRacesGroupByMeeting">
                        <img :src="raceTypeImg[item.meetingType]" style="max-width:20px" />
                      </td>
                      <td class="tdRacesGroupByMeeting" style="white-space: nowrap; text-overflow: ellipsis; max-width: 200px; padding-right:0px; overflow:hidden; margin-left:15px; font-weight: bold;">
                        {{ item.meetingName }} ({{sorted[1][item.meetingName+'-'+item.meetingType].length}})
                      </td>
                      <td class="pa-1">
                        <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-data-table dense overflow-y="hidden" class="raceDayTable" :items="sorted[1][item.meetingName+'-'+item.meetingType]" hide-default-footer :items-per-page=9999 :header-props="{ sortIcon: null }">
                        <template v-slot:item="{ item }">
                          <v-tooltip right nudge-bottom="40" nudge-left="70" z-index="999" color="black" open-delay="400">
                            <template v-slot:activator="{ on }">
                              <tr v-if="Object.keys(races).length !== 0" style="cursor:pointer"  :class="{selected:(highlightedRace == item.id)}" class="raceNavigationRow" v-on="on" @click="clickRace(item)">
                                <td class="pa-1" style="font-size:0.8em">
                                  {{formattedStartTime(item)}}
                                </td>
                                <td class="pa-1" :style="item.bets ? 'color: orange; font-weight: bold;' : null">
                                  {{`R${item.number}`}}
                                </td>
                                <td :style="item.bets ? 'color: orange;font-weight: bold;' : null" style="white-space: nowrap; text-overflow: ellipsis; max-width:100px; padding-right:0px; overflow:hidden; margin-left:15px; padding: 0 5px;">
                                  <div v-if="item.fixedAvailable" style="background:#00A500;height:8px;width:8px;display:inline-block;margin-right:5px;border-radius:5px;" title="Fixed odds available"></div>{{item.name}}
                                </td>
                                <td class="pa-1 raceDayTime" align="right" v-if="item.status == 'FINAL'" >
                                  Final
                                </td>
                                <td class="pa-1 raceDayTime" align="right" v-if="item.status === 'CLOSED'"  style="color:red">
                                  Closed
                                </td>
                                <td class="pa-1 raceDayTime" align="right" v-if="item.status === 'ABANDONED'"  style="color:red">
                                  Abandoned
                                </td>
                                <td class="pa-1 raceDayTime" align="right" v-if="item.status === 'INTERIM'"  style="color:orange">
                                  Interim
                                </td>
                                <td class="pa-1 raceDayTime"  align="right" v-else-if="item.status === 'OPEN'">
                                  <Countdown :key="item.startTime" :startTime="item.startTime" :formattedStartTime="races[item.id].formattedStartTime"/>
                                </td>
                                <td v-if="showRaceAlerts" class="raceAlertTD" :key="`${item.id}-racedayalert`">
                                  <v-checkbox @click.native.stop :ripple="false" :input-value="item.activeAlert" :false-value="0" :true-value="1" :disabled="item.status !== 'OPEN'" hide-details="true" dense class="raceAlertCheckbox" @change="saveRaceAlert($event, item.id, item.meeting.date)"></v-checkbox>
                                </td>
                                <td v-if="showOpenMarketAlerts && !onReturnOpenMarketAvailability({ meeting: item })" class="raceAlertTD" :key="`${item.id}-available`">
                                  <v-menu open-on-hover :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon color="red" style="position: relative; left: 1px;" title size="22px" v-bind="attrs" v-on="on">mdi-close-box</v-icon>
                                    </template>
                                    <div class="open-market-modal pa-2">
                                      <span>Market Already Available</span>
                                    </div>
                                  </v-menu>
                                </td>
                                <td v-if="showOpenMarketAlerts && onReturnOpenMarketAvailability({ meeting: item })" class="raceAlertTD" :key="`${item.id}-openmarkeralert`">
                                  <v-checkbox @click.native.stop :ripple="false" :input-value="onReturnOpenMarketValue({ meeting: item, ogPriceAlerts })" :false-value="false" :true-value="true"  hide-details="true" dense class="raceAlertCheckbox" @change="onToggleOpenMarketAlert({ value: $event, meeting: item })"></v-checkbox>
                                </td>
                              </tr>
                            </template>
                            <span class="tooltiptextRaceNavigation caption"><pre class="tooltipTextRaceNavigationPreformatted">{{getHoverText(item)}}</pre></span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <Blackbook v-if="hasFeature('blackbook')" />
            <v-container v-else>
              <p class="text-center font-weight-medium text-caption" style="color: rgba(0, 0, 0, 0.45);">Sorry, not available.</p>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <RaceAlert />
    </v-navigation-drawer>
    <div v-show="!autoHide" style="position: absolute; margin-left: -15px; margin-top: 15vh; width: 54px;">
      <RaceNavNextToGo :race="getNextToGo" :onClick="clickNextToGoRace" :widthNextToGo="''" style="transform: rotate(-90deg); width: 300px; margin-top: 174px; margin-left: -113px; padding: 0 12.4444444444px !important;"/>
      <AutoHide :mouseOver="mouseOverExpandThisPanel"/>
    </div>
    <modal name="search-modal" width=80% height="auto" draggable="">
      <AzList :closeAzList="closeAzList" :searchOnOpen="search" :raceDaySearchOpen="raceDaySearchOpen" @clicked="hideAzList"/>
    </modal>
    <modal
      name="ms-unsaved-modal"
      :clickToClose=false
      height=auto
    >
      <div>
        <v-toolbar color="warning" dark dense>
          <div class="titleBar-content">
            <v-toolbar-title class="raceResults-title">You haven't saved your changes!</v-toolbar-title>
            <v-btn class="cancelButton" icon small @click="closeAlert" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <br>
        <h2 style="text-align: center">Make sure you save your changes with the green</h2>
        <h2 style="text-align: center">"Save Changes" button!</h2>
        <br>
        <div class="msErrorButton">
          <v-btn color="success" @click="closeAlert">Go Back To Meeting Summary</v-btn>
          <v-btn color="error" @click="closeMeetingSummaryModal = true">
            Close Anyway
          </v-btn>
        </div>
        <h4 style="text-align: center">Unsaved changes can be returned to when reopening the meeting summary</h4>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { startOfDay, format, isBefore, startOfTomorrow, add, eachWeekendOfInterval, getUnixTime, isSameDay, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import goTo from 'vuetify/es5/services/goto'
import RaceNavNextToGo from './RaceNavNextToGo'
import RaceNavFilter from './RaceNavFilter'
import AutoHide from './AutoHide'
import Countdown from './Countdown'
import RaceAlert from './RaceAlert'
import Blackbook from './Blackbook'
import AzList from './AzList'
import MeetingSummary from './MeetingSummary'
import RaceNavigationSettings from './menus/RaceNavigationSettings'
import { BET_STATUS_SUCCESS, BET_STATUS_CODE, FIRST_TO_OPEN_PRICE_MARKETS } from '../common/constants'
import { isEmpty } from '../common/methods'

export default {
  name: 'RaceDayNavigation',
  data: () => ({
    search: '',
    drawer: true,
    checked: false,
    setRaceOnLoad: 0,
    meetingSummaryToggle: false,
    closeMeetingSummaryModal: false,
    clickedRace: null,
    raceTypeImg: {
      HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
      GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
      THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
    },
    isAZList: false,
    raceDaySearchOpen: false,
    headersRaces: [
      {
        value: 'time'
      },
      {
        value: 'country'
      },
      {
        value: 'raceType'
      },
      {
        value: 'raceNum'
      },
      {
        text: 'Race',
        value: 'displayName'
      },
      {
        text: 'Status',
        value: 'timeToGo'
      }
    ],
    headersRacesGroupByMeeting: [
      {
        value: 'meetingCountry',
        class: 'racesGroupByMeeting'
      },
      {
        value: 'meetingType',
        class: 'racesGroupByMeeting'
      },
      {
        value: 'meetingName',
        class: 'racesGroupByMeeting'
      },
      {
        text: 'Close',
        value: 'data-table-expand',
        class: 'racesGroupByMeeting'
      }
    ],
    menu: false,
    filtersOpen: false,
    showRaceAlerts: false,
    showOpenMarketAlerts: false,
    alerts: [
      {
        key: 1,
        value: 0
      },
      {
        key: 2,
        value: 0
      },
      {
        key: 3,
        value: 0
      }
    ],
    soundsAlerts: [
      {
        key: 1,
        value: 0
      },
      {
        key: 2,
        value: 0
      },
      {
        key: 3,
        value: 0
      }
    ],
    timesAlerts: [
      {
        key: 1,
        value: '30 Sec'
      },
      {
        key: 2,
        value: '30 Sec'
      },
      {
        key: 3,
        value: '30 Sec'
      }
    ],
    autoHide: true,
    expanded: [],
    sortBy: 'meetingType',
    tab: null
  }),
  components: {
    RaceNavNextToGo,
    RaceNavFilter,
    AutoHide,
    Countdown,
    RaceAlert,
    Blackbook,
    MeetingSummary,
    RaceNavigationSettings,
    AzList
  },
  props: {
    clickFeature: Function,
    expandOnHoverFunc: Function
  },
  computed: {
    ...mapState(['selectedDate', 'selectedRace', 'races', 'selectedNavRace', 'acMode', 'selectRaceNavSet', 'ogPriceAlerts', 'azList', 'runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook']),
    ...mapState('account', ['authorized']),
    ...mapGetters(['getNextToGo', 'hasFeature', 'getSelectedColumns', 'getRaces', 'getRaceAlerts', 'getRacesGroupByMeeting', 'getRacesGroupByMeeting']),
    ...mapGetters('betting', ['getBetHistory']),
    highlightedRace: function () {
      return this.clickedRace || this.selectedRace.id
    },
    groupByMeeting: {
      get () {
        return this.getRacesGroupByMeeting
      },
      set (value) {
        this.setRacesGroupByMeeting({ value: value })
      }
    },
    sorted: function () {
      // const rtype = this.raceTypeImg
      let races = this.addBetHistory(this.getRaces, this.getBetHistory)
      let meetingsGrouped = []
      if (!this.getRaceAlerts && this.getRacesGroupByMeeting) {
        let racesGroupByMeeting = races.reduce((acc, obj) => {
          const property = obj.meeting.track.name + '-' + obj.meeting.type
          if (!acc[property]) {
            meetingsGrouped.push({ meetingCountry: obj.meeting.track.country, meetingType: obj.meeting.type, meetingName: obj.meeting.track.name })
          }
          acc[property] = acc[property] || []
          acc[property].push(obj)
          return acc
        }, {})
        for (const property in racesGroupByMeeting) {
          racesGroupByMeeting[property] = racesGroupByMeeting[property].filter(r => isSameDay(startOfDay(this.selectedDate), parseISO(r.meeting.date)))
        }
        meetingsGrouped.sort((a, b) => a.meetingName < b.meetingName ? -1 : 1)
        meetingsGrouped.forEach(m => {
          if (racesGroupByMeeting[m.meetingName]?.length === 0) {
            const index = meetingsGrouped.indexOf(m)
            if (index > -1) {
              meetingsGrouped.splice(index, 1)
            }
          }
        })
        return [meetingsGrouped, racesGroupByMeeting]
      } else if (this.getRaceAlerts && this.getRacesGroupByMeeting) {
        let racesGroupByMeeting = races.reduce((acc, obj) => {
          const property = obj.meeting.track.name + '-' + obj.meeting.type
          if (!acc[property]) {
            meetingsGrouped.push({ meetingCountry: obj.meeting.track.country, meetingType: obj.meeting.type, meetingName: obj.meeting.track.name })
          }
          acc[property] = acc[property] || []
          acc[property].push(obj)
          return acc
        }, {})
        meetingsGrouped.sort((a, b) => a.meetingName < b.meetingName ? -1 : 1)
        for (const property in racesGroupByMeeting) {
          racesGroupByMeeting[property] = racesGroupByMeeting[property].filter(r => isSameDay(startOfDay(this.selectedDate), parseISO(r.meeting.date)))
          racesGroupByMeeting[property] = this.addAlertIndicator(racesGroupByMeeting[property], this.getRaceAlerts.map(alert => alert.raceID))
        }
        return [meetingsGrouped, racesGroupByMeeting]
      } else if (this.getRaceAlerts && !this.getRacesGroupByMeeting) {
        const racesWithAlerts = this.addAlertIndicator(races, this.getRaceAlerts.map(alert => alert.raceID))
        // raceType: rtype[races[x].meeting.type],
        return racesWithAlerts.sort((a, b) => isBefore(a.isoStartTime, b.isoStartTime) ? -1 : 1).filter(race => (isSameDay(startOfDay(this.selectedDate), parseISO(race.meeting.date))))
      } else {
        return races.sort((a, b) => isBefore(a.isoStartTime, b.isoStartTime) ? -1 : 1).filter(race => (isSameDay(startOfDay(this.selectedDate), parseISO(race.meeting.date))))
      }
    },
    selectedColumns: function () {
      return this.getSelectedColumns
    },
    date: {
      get () {
        const d = format(this.selectedDate, 'yyyy-MM-dd')
        return d
      },
      async set (n) {
        // this.showRaceAlerts = false
        await this.setDate(utcToZonedTime(new Date(n)))
        await this.reFetchRaces()
        // if (this.sorted[0]) {
        //   this.selectRace({ id: this.sorted[0].id })
        // }
        // this.showRaceAlertsCheckboxes(false)
      }
    },
    formattedDate () {
      return format(this.selectedDate, 'MMM d, yyyy')
    },
    selectedNextRaceScroll () {
      return (`${this.setRaceOnLoad}`)
    },
    combinedBlackbook () {
      const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
      return combined
    },
    numberOfUpcomingCompetitors () {
      const open = this.azList.filter(el => el.status === 'OPEN')

      const current = this.combinedBlackbook.map(co => {
        const upcoming = open.filter(item => item.runnerId === co.runnerId)
        return upcoming
      })

      const cleaned = current.filter(element => {
        return !isEmpty(element)
      })

      return cleaned.length
    }
  },
  async created () {
    // Select the next race to go on mount
    await this.setDate(startOfDay(Date.now()))
    // await this.fetchRaces()
    const r = this.getNextToGo
    if (r) {
      this.selectRace({ id: r.id })
    }
    this.setThisRaceOnLoad(1)
    setInterval(() => {
      this.updateClocks()
    }, 1000)
  },
  watch: {
    selectedNextRaceScroll: function () {
      if (this.setRaceOnLoad !== 0) {
        try {
          goTo('.raceNavigation .selected', { container: this.$el.querySelector('.v-navigation-drawer__content'), offset: 50 })
        } catch {}
      }
    },
    selectRaceNavSet (v) {
      if (v !== 0) {
        this.setThisRaceOnLoad(1)
        this.selectRaceNav(0)
      }
      return null
    },
    filtersOpen: function (open) {
      if (!open && this.checked) {
        this.expandOnHoverFunc(true)
      }
    },
    selectedRace: function (newRace, oldRace) {
      if (newRace.id !== oldRace.id) {
        this.clickedRace = null
      }
    },
    checked (newState) {
      this.toggleIsAutoHide(newState)
    },
    races () {
      // Refech user race alerts when `races` changes
      this.getRaceAlertsByUsername()
    }
  },
  methods: {
    ...mapActions(['selectRace', 'updateClocks', 'selectRaceNav', 'setDate', 'fetchRaces', 'selectNavRace', 'saveConfigRaceAlerts', 'createRaceAlert', 'removeRaceAlert', 'getRaceAlertsConfiguration', 'getRaceAlertsByUsername', 'fetchAzList', 'reFetchRaces', 'setRaceLoading', 'setRacesGroupByMeeting', 'toggleIsAutoHide', 'deletePriceAlert', 'addPriceAlert']),
    closeAllExpanded () {
      this.expanded = []
    },
    closeAlert () {
      this.$modal.hide('ms-unsaved-modal')
    },
    showAzList () {
      this.$modal.show(
        'search-modal'
      )
    },
    hideAzList () {
      this.$modal.hide(
        'search-modal'
      )
      this.raceDaySearchOpen = false
      this.search = ''
    },
    raceDaySearch () {
      this.raceDaySearchOpen = true
      this.showAzList()
    },
    closeAzList () {
      this.isAZList = false
      this.raceDaySearchOpen = false
      this.search = ''
    },
    openMeetingSummary () {
      this.meetingSummaryToggle = true
    },
    closeMeetingSummary () {
      this.meetingSummaryToggle = false
      this.closeMeetingSummaryModal = false
    },
    setThisRaceOnLoad (value) {
      const races = this.addBetHistory(this.getRaces, this.getBetHistory)
      const racesLoad = Object.keys(races).length
      if (racesLoad !== 0) {
        setTimeout(() => {
          this.setRaceOnLoad = value
        }, 1000)
        this.setRaceOnLoad = 0
      } else {
        setTimeout(() => {
          this.setThisRaceOnLoad()
        }, 3000)
      }
    },
    async getConfigurationRaceAlert () {
      const raceAlertsConfiguration = await this.getRaceAlertsConfiguration()
      if (typeof raceAlertsConfiguration !== 'undefined') {
        const alerts = raceAlertsConfiguration.map(config => {
          return { key: config.numberAlert, value: config.alertEnabled }
        })

        const sounds = raceAlertsConfiguration.map(config => {
          return { key: config.numberAlert, value: config.soundEnabled }
        })
        const times = raceAlertsConfiguration.map(config => {
          return { key: config.numberAlert, value: config.timeBeforeJump }
        })
        this.alerts = alerts
        this.soundsAlerts = sounds
        this.timesAlerts = times
        return true
      } else {
        return false
      }
    },
    addBetHistory: function (races, betHistory) {
      const racesWithBets = races.map(race => {
        const bets = Object.keys(betHistory).filter(k => betHistory[k].raceId === race.id).map(b => {
          return betHistory[b]
        })
          .filter(b => BET_STATUS_SUCCESS[BET_STATUS_CODE[b.bet.status_code]] === 'success' || BET_STATUS_SUCCESS[BET_STATUS_CODE[b.bet.status_code]] === 'warning')
        if (bets.length > 0) {
          return { ...race, bets: bets }
        }
        return race
      })
      return racesWithBets
    },
    addAlertIndicator: function (races, alerts) {
      if (races && alerts) {
        const racesWithAlerts = races.map(race => {
          if (alerts.find(a => a === race.id)) {
            const newRace = { ...race, activeAlert: 1 }
            return newRace
          }
          return race
        })
        return racesWithAlerts
      }
    },
    formattedStartTime: function (x) {
      return format(x.isoStartTime, 'HH:mm')
    },
    dateColor: function (v) {
      const weekend = eachWeekendOfInterval({
        start: startOfDay(Date.now()),
        end: add(startOfDay(Date.now()), { days: 7 })
      })
      if (isSameDay(startOfDay(Date.now()), this.selectedDate)) {
        if (v === 'today') {
          return 'info'
        } else {
          return 'normal'
        }
      }
      if (isSameDay(startOfTomorrow(), this.selectedDate)) {
        if (v === 'tomorrow') {
          return 'info'
        } else {
          return 'normal'
        }
      }
      if (isSameDay(weekend[0], this.selectedDate)) {
        if (v === 'saturday') {
          return 'info'
        } else {
          return 'normal'
        }
      }
      return 'normal'
    },
    async clickRace (v) {
      if (!this.autoHide) return
      if (!this.authorized) {
        this.clickFeature('inBeta')
      } else {
        this.clickedRace = v.id
        this.selectRace({
          id: v.id
        })
      }
    },
    selectNextToGo () {
      this.setDate(startOfDay(Date.now()))
      const r = this.getNextToGo
      if (r) {
        this.selectRace({ id: r.id })
      }
    },
    expandThisPanel (valueChecked) {
      if (valueChecked === true) {
        this.autoHide = false
        this.expandOnHoverFunc(valueChecked)
      } else {
        this.expandOnHoverFunc(valueChecked)
        this.autoHide = true
      }
    },
    mouseOverExpandThisPanel () {
      this.autoHide = true
      this.toggleIsAutoHide(false)
      this.expandOnHoverFunc(null)
    },
    mouseLeaveExpandThisPanel (checked) {
      if (checked) {
        this.autoHide = false
        this.expandOnHoverFunc(true)
        this.toggleIsAutoHide(true)
      }
    },
    getUnixTime,
    async clickNextToGoRace (v) {
      await this.setDate(startOfDay(Date.now()))
      this.setRaceLoading({ loading: true })
      await this.reFetchRaces()
      this.selectRace({
        id: v.id
      }).catch((e) => {
        this.setRaceLoading({ loading: false })
      })
      this.selectNavRace({
        id: v.id
      })
      this.setThisRaceOnLoad(2)
    },
    async selectToday () {
      if (!this.authorized) {
        this.clickFeature('inBeta')
      } else {
        // this.showRaceAlerts = false
        await this.setDate(startOfDay(Date.now()))
        await this.reFetchRaces()
        // if (this.sorted[0]) {
        //   this.selectRace({ id: this.sorted[0].id })
        // }
        // this.selectNextToGo()
        // this.showRaceAlertsCheckboxes(false)
      }
    },
    async selectTomorrow () {
      if (!this.authorized) {
        this.clickFeature('inBeta')
      } else {
        // this.showRaceAlerts = false
        await this.setDate(startOfTomorrow())
        await this.reFetchRaces()
        // if (this.sorted[0]) {
        //   this.selectRace({ id: this.sorted[0].id })
        // }
        // this.showRaceAlertsCheckboxes(false)
      }
    },
    async selectSaturday () {
      if (!this.authorized) {
        this.clickFeature('inBeta')
      } else {
        // this.showRaceAlerts = false
        const weekend = eachWeekendOfInterval({
          start: startOfDay(Date.now()),
          end: add(startOfDay(Date.now()), { days: 7 })
        })
        await this.setDate(weekend[0])
        await this.reFetchRaces()
        // if (this.sorted[0]) {
        //   this.selectRace({ id: this.sorted[0].id })
        // }
        // this.showRaceAlertsCheckboxes(false)
      }
    },
    getHoverText (item) {
      return `R${item.number} ${item.meeting.track.name} - ${item.name} \nDist: ${item.distance} | Cond: ${item.trackCondition}`
    },
    setFiltersOpen (state) {
      this.filtersOpen = state
    },
    async showRaceAlertsCheckboxes (openCheckboxes) {
      // this.raceAlertsByUsername = []
      await this.getRaceAlertsByUsername()
      // if (typeof raceAlertsByUsername !== 'undefined') {
      //   raceAlertsByUsername.forEach(alert => {
      //     this.raceAlertsByUsername.push(alert.raceID)
      //   })
      // }
      if (openCheckboxes) {
        this.showRaceAlerts = !this.showRaceAlerts
      }

      this.showOpenMarketAlerts = false

      var userHasConfiguration = await this.getConfigurationRaceAlert()
      if (!userHasConfiguration) {
        this.alerts.forEach(alert => {
          if (alert.key === 1 || alert.key === 2) {
            alert.value = 1
          }
        })

        this.soundsAlerts.forEach(sound => {
          if (sound.key === 1 || sound.key === 2) {
            sound.value = 1
          }
        })

        this.timesAlerts.forEach(time => {
          if (time.key === 1) {
            time.value = '2 Mins'
          } else if (time.key === 2) {
            time.value = '1 Min'
          }
        })

        this.saveConfigurationRaceAlerts()
      }
    },
    saveRaceAlert (value, raceId, raceDate) {
      if (value === 1) {
        this.createRaceAlert({ raceId: raceId, raceDate: raceDate })
      } else {
        this.removeRaceAlert({ raceId: raceId })
      }
    },
    saveConfigurationRaceAlerts () {
      this.saveConfigRaceAlerts({
        alerts: this.alerts,
        soundsAlerts: this.soundsAlerts,
        timesAlerts: this.timesAlerts
      })
    },
    onShowOpenMarketAlert () {
      this.showOpenMarketAlerts = !this.showOpenMarketAlerts
      this.showRaceAlerts = false
    },
    onToggleOpenMarketAlert ({ value, meeting }) {
      const firstAppearanceOfARunnerThatIsNotScrached = meeting?.competitors?.find(runner => runner.scratchingType === 'STARTER')

      if (value) {
        const openMarketData = {
          meetingId: meeting?.meeting?.id,
          raceNumber: meeting?.number,
          runnerNumber: firstAppearanceOfARunnerThatIsNotScrached?.tabNo ?? 1,
          agency: null,
          meetingDate: meeting?.meeting?.date,
          priceAlertType: 'GREATER_THAN', // Default price alert type
          triggerPrice: 1, // Default trigger price
          types: ['UI', 'EMAIL']
        }

        this.addPriceAlert(openMarketData)
      } else {
        const alert = this.ogPriceAlerts?.find(alert => {
          return alert?.meeting_id === meeting?.meeting?.id && alert?.race_number === meeting?.number && alert?.meeting_date === meeting?.meeting?.date
        })

        if (!isEmpty(alert)) {
          this.deletePriceAlert({ alertId: alert?.alert_id })
        }
      }
    },
    onReturnOpenMarketAvailability ({ meeting }) {
      const prices = meeting?.priceSets

      const firstToMarket = FIRST_TO_OPEN_PRICE_MARKETS.map(source => source.source)

      const onlySourcesThatsFirstToMarket = prices.filter(price => firstToMarket.includes(price.source))

      return isEmpty(onlySourcesThatsFirstToMarket)
    },
    onReturnOpenMarketValue ({ meeting, ogPriceAlerts }) {
      const found = ogPriceAlerts?.find(alert => {
        return alert?.meeting_id === meeting?.meeting?.id && alert?.race_number === meeting?.number && alert?.meeting_date === meeting?.meeting?.date
      })

      return !isEmpty(found)
    }
  }
}
</script>
<style scoped>
.raceDayNavigation {
  z-index: 5;
  padding-bottom:30px;
}
</style>
<style>
.msErrorButton {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px
}
.raceNavigation, .fixesOddsAvailable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
}
.acMode .theme--light.v-data-table {
  color:#000;
  font-weight:500;
}
.acMode .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row)  {
  border-bottom: 1px solid #333;
}
.stdMode .theme--light.v-data-table {
  color:#000;
  font-weight:300;
}
.betsPlaced{
  color: orange;
  font-weight: bold;
}
.raceDayName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:130px;
}
.raceDayTime {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:55px;
}
.selected {
  background: #4caf50;
}
.scheduleLabels .v-input__slot .v-label {
  font-size: 12px;
  line-height: 13px !important;
}
.scheduleLabels {
  margin-top: 4px;
  padding-top: 0px;
  margin-bottom: 10px;
}
.v-data-table__wrapper .raceDayTable {
  overflow-x: hidden;
}
.v-navigation-drawer__border {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 0px;
}
.v-data-table table {
    min-width: 100%;
    border-spacing: 0;
}
.tooltipTextRaceNavigationPreformatted {
  font-family: 'Roboto', sans-serif;
}
.raceDayTable tbody td:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0);
}
.v-input__icon--prepend .v-icon {
  font-size: 16px;
}
.v-input__prepend-outer{
    margin-right: 0px !important;
}
.ma-1 {
  padding: 0 2.8888888889px !important;
}
.formattedDate {
  padding-top: 0px;
  margin-top: 0px;
}
label {
  height: 10px;
}

.raceAlertCheckbox {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.raceAlertTD {
  padding: 0 5px !important;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
}

.titleBar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.racesGroupByMeeting .v-data-table-header__icon {
  opacity: 1;
}

.tdRacesGroupByMeeting {
  padding: 0px 3px !important;
}

.v-text-field input {
  padding: 0 !important;
}

.v-input__append-outer, .v-input__prepend-outer {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.raceNavigation .v-data-table-header .racesGroupByMeeting {
  padding: 0;
}

.tab-header {
  border-top: 1px solid rgba(0,0,0,0.1) !important;
  border-bottom: 1px solid rgba(0,0,0,0.1) !important;
  background-color: white;
  position: fixed;
  top: 132px;
  z-index: 5;
  width: calc(100% - 8px) !important;
}

.tab-header__body {
  position: relative;
  bottom: -50px;
  z-index: 0;
}

.blackbook-notification {
}

.open-market-modal {
  background: rgba(0,0,0,0.8);
  color: white;
}
</style>
