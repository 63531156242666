<template>
  <div :style="`background-image: url('https://1ae2v478chfucobmr4slnqs1d6.silks.betmakers.com/${externalMeetingId}_${raceNumber}_SPRITE_32x32.png')`" :class="`silkSprite-${tabNo} harness`" />
</template>

<script>

export default {
  name: 'HarnessSprite',
  props: {
    externalMeetingId: String,
    raceNumber: Number,
    tabNo: Number
  }
}
</script>

<style scoped>
.harness {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: 342px 32px;
}
.silkSprite-1 {
  background-position-x: 0;
}
.silkSprite-2 {
  margin: 0 0 0 -32px;
}
.silkSprite-3 {
  margin: 0 0 0 -64px;
}
.silkSprite-4 {
  margin: 0 0 0 -96px;
}
.silkSprite-5 {
  margin: 0 0 0 -128px;
}
.silkSprite-6 {
  margin: 0 0 0 -160px;
}
.silkSprite-7 {
  margin: 0 0 0 -192px;
}
.silkSprite-8 {
  margin: 0 0 0 -224px;
}
.silkSprite-9 {
  margin: 0 0 0 -256px;
}
.silkSprite-10 {
  margin: 0 0 0 -288px;
}
.silkSprite-11 {
  margin: 0 0 0 -320px;
}
.silkSprite-12 {
  margin: 0 0 0 -352px;
}
.silkSprite-13 {
  margin: 0 0 0 -384px;
}
.silkSprite-14 {
  margin: 0 0 0 -416px;
}
.silkSprite-15 {
  margin: 0 0 0 -448px;
}
.silkSprite-16 {
  margin: 0 0 0 -480px;
}
.silkSprite-17 {
  margin: 0 0 0 -512px;
}
.silkSprite-18 {
  margin: 0 0 0 -544px;
}
.silkSprite-19 {
  margin: 0 0 0 -576px;
}
.silkSprite-20 {
  margin: 0 0 0 -608px;
}
.silkSprite-21 {
  margin: 0 0 0 -640px;
}
.silkSprite-22 {
  margin: 0 0 0 -672px;
}
.silkSprite-23 {
  margin: 0 0 0 -704px;
}
.silkSprite-24 {
  margin: 0 0 0 -736px;
}

</style>
