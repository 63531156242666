<template>
  <div>
    <v-toolbar color="red" dark dense>
      <div class="titleBar-content">
        <v-toolbar-title class="raceResults-title">Bet Error -- BET FAILED</v-toolbar-title>
        <v-btn class="cancelButton" icon small @click="closePriceChange" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <br>
    <h2 style="text-align: center" class="black--text">There has been a bet fail in your betslip</h2>
    <br>
    <h4 style="text-align: center" class="black--text">Please refer to the bet history button at the top of the screen for more details or open the bet slip to re-place the bet.</h4>
    <br>
  </div>
</template>

<script>
import { BOOKMAKER_NAMES_LOOKUP, BETTYPE } from '../common/constants'
export default {
  data () {
    return {
      BOOKMAKER_NAMES_LOOKUP,
      BETTYPE
    }
  },
  name: 'BetFailedAlert',
  methods: {
    closePriceChange () {
      this.$emit('clicked')
    }
  }
}
</script>

<style>
.priceChangedButt {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px
}
</style>
