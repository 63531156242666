<template>
  <div class="apolloColumn runnerInfoColumnDiv" style="background:#fff;" :style="getColumnStyle(index)">
    <ApolloQuery
      :query="infoQuery"
      v-if="id && selection"
      fetchPolicy="no-cache"
    >
      <template v-slot="{ result: { loading, error, data } }">
        <div :class="overflowedList ? 'raceTableColumnContainer-overflowed' : 'raceTableColumnContainer'">
          <div class="raceTableColumnMainContainer">
            <div :class="acMode ? 'raceTableColumnDiv-priceAcMode' : 'raceTableColumnDiv'" :style="getColumnStyle(index)">
              <div class=" raceTableColumn">
                <!-- <div v-if="loading" class="runnerInfo-loading">Loading...</div> -->
                <div :class="['runnerInfo-content']">
                  <div v-if="editMode" class="raceHeaderCol">
                    <div :class="['raceTableColumn-header save-font']" >
                      <div :class="['infoColumnEditMode']">
                        {{ title }}
                      </div>
                      <div v-if="editMode" class="columnActions editingDecorations">
                        <AddColumnButton :columnLength="columnLength" :position="index"/>
                        <DeleteColumnButton :columnNumber="index"/>
                        <EditColumnButton :columnNumber="index"/>
                      </div>
                    </div>
                  </div>
                  <div v-if="!editMode" :class="editMode ? 'editColumn' : ''">
                    <div v-for="(competitor, i) in getRaceCompetitors" :key="`${competitor.tabNo}:${i}`" :class="isInWatchList(competitor.tabNo) ? ['highlight columnWidth-' + source] : ['columnWidth-' + source]" style="background:#fff;" :style="getColumnStyle(index)">
                      <div v-if="!selectionStats" :class="acMode ? 'runnerInfo-columnAcMode' : 'runnerInfo-column'" :style="getColumnStyle(index)">
                        <v-tooltip bottom v-if="!competitor.scratched" color="black">
                          <template v-slot:activator="{ on: tooltip }">
                            <div v-on="tooltip" :class="['statsClass', classObject]">{{ infoSet(competitor[selection]) }}</div>
                          </template>
                          <span>{{ infoSet(competitor[selection]) }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else :class="acMode ? 'runnerInfo-columnAcMode' : 'runnerInfo-column'" :style="getColumnStyle(index)">
                        <v-tooltip bottom v-if="!competitor.scratched" color="black">
                          <template v-slot:activator="{ on: tooltip }">
                            <div v-on="tooltip" :class="['statsClass', classObject]">{{ statsCore(data, competitor.tabNo) }}</div>
                          </template>
                          <span :class="classObject">{{ statsCore(data, competitor.tabNo)  }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import { runnerInfoQuery, apiQuery, firstUpQuery, careerStatsAllQuery, careerStatsByConditionQuery, silksColourQuery, lastTenStartsQuery, careerStatsByTrackQuery, careerStatsJockeyQuery, careerStatsByDistQuery, careerStatsByTrackDistQuery, runnerCommentsQuery } from '../common/queries'
import AddColumnButton from './menus/AddColumnMenu'
import DeleteColumnButton from './menus/DeleteColumnMenu'
import EditColumnButton from './menus/EditColumnMenu'
import { mapState, mapGetters } from 'vuex'
import gql from 'graphql-tag'
import numeral from 'numeral'

export default {
  props: {
    id: String,
    overflowedList: Boolean,
    selection: String,
    source: String,
    editMode: Boolean,
    title: String,
    index: Number,
    columnLength: Number,
    getColumnStyle: Function,
    queryType: String
  },
  components: {
    AddColumnButton,
    DeleteColumnButton,
    EditColumnButton
  },
  methods: {
    numeral,
    gql,
    isInWatchList (tabNo) {
      return this.getWatchListRunners.includes(tabNo)
    },
    statsCore (data, i) {
      const comp = data?.race?.competitors.find(c => c.tabNo === i)
      if (this.selected === 'api') {
        const item = comp?.runnerStatistics?.career?.all?.averagePrizeMoneyWon
        return numeral(item).format('(0.00a)') || '-'
      }
      if (this.selected === 'statsDO1stup') {
        const d = comp?.runnerStatistics?.career
        if (!d) return '-'
        const firsts = d?.spell?.firstUp?.firsts || 0
        const seconds = d?.spell?.firstUp?.seconds || 0
        const thirds = d?.spell?.firstUp?.thirds || 0
        const numberOfRuns = d?.spell?.firstUp?.numberOfRuns || 0
        if (numberOfRuns) return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
        else return '-'
      }
      if (this.selected === 'statsDOAll') {
        const d = comp?.runnerStatistics
        if (!d) return '-'
        const firsts = d?.career?.all?.firsts || 0
        const seconds = d?.career?.all?.seconds || 0
        const thirds = d?.career?.all?.thirds || 0
        const numberOfRuns = d?.career?.all?.numberOfRuns || 0
        return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
      }
      if (this.selected === 'statsDODist') {
        const d = comp?.runnerStatistics?.career?.detail?.byDistance
        const dist = data?.race?.distance
        let distStats = {
          firsts: 0,
          key: '',
          numberOfRuns: 0,
          seconds: 0,
          thirds: 0
        }
        if (!d) return '-'
        d.forEach(v => {
          if (v.key.includes(dist)) {
            distStats = {
              firsts: v.firsts,
              numberOfRuns: v.numberOfRuns,
              seconds: v.seconds,
              thirds: v.thirds
            }
          }
        })
        return `${distStats.numberOfRuns}: ${distStats.firsts}-${distStats.seconds}-${distStats.thirds}`
      }
      if (this.selected === 'statsDOJockey') {
        const d = comp?.runnerStatistics?.career?.detail?.byJockey
        const jockey = comp?.jockey
        let jockeyStats = {
          firsts: 0,
          key: '',
          numberOfRuns: 0,
          seconds: 0,
          thirds: 0
        }
        if (!d) return '-'
        d.forEach(v => {
          if (v.key.includes(jockey)) {
            jockeyStats = {
              firsts: v.firsts,
              numberOfRuns: v.numberOfRuns,
              seconds: v.seconds,
              thirds: v.thirds
            }
          }
        })
        return `${jockeyStats.numberOfRuns}: ${jockeyStats.firsts}-${jockeyStats.seconds}-${jockeyStats.thirds}`
      }
      if (this.selected === 'statsDOTrack') {
        const d = comp?.runnerStatistics?.career?.detail?.byTrackAndDistance
        const dist = data?.race?.distance
        const track = data?.race?.meeting?.track?.name
        let distStats = {
          firsts: 0,
          key: '',
          numberOfRuns: 0,
          seconds: 0,
          thirds: 0
        }
        if (!d) return '-'
        d.forEach(v => {
          if (v.key === `(${track}, ${dist})`) {
            distStats = {
              firsts: v.firsts,
              numberOfRuns: v.numberOfRuns,
              seconds: v.seconds,
              thirds: v.thirds
            }
          }
        })
        return `${distStats.numberOfRuns}: ${distStats.firsts}-${distStats.seconds}-${distStats.thirds}`
      }
      if (this.selected === 'statsDOTrackStats') {
        const d = comp?.runnerStatistics?.career?.detail?.byTrack
        const track = data?.race?.meeting?.track?.name
        let distStats = {
          firsts: 0,
          key: '',
          numberOfRuns: 0,
          seconds: 0,
          thirds: 0
        }
        if (!d) return '-'
        d.forEach(v => {
          if (v.key === track) {
            distStats = {
              firsts: v.firsts,
              numberOfRuns: v.numberOfRuns,
              seconds: v.seconds,
              thirds: v.thirds
            }
          }
        })
        return `${distStats.numberOfRuns}: ${distStats.firsts}-${distStats.seconds}-${distStats.thirds}`
      }
      if (this.selected === 'statsDOFast') {
        const d = comp?.runnerStatistics?.career?.detail?.byCondition
        let firsts = 0
        let numberOfRuns = 0
        let seconds = 0
        let thirds = 0
        if (!d) return '-'
        d.forEach(v => {
          if (v.key === 'Firm1' || v.key === 'Firm2' || v.key === 'Firm' || v.key === 'Fast') {
            firsts += v.firsts
            numberOfRuns += v.numberOfRuns
            seconds += v.seconds
            thirds += v.thirds
          }
        })
        return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
      }
      if (this.selected === 'statsDOGood') {
        const d = comp?.runnerStatistics?.career?.detail?.byCondition
        let firsts = 0
        let numberOfRuns = 0
        let seconds = 0
        let thirds = 0
        if (!d) return '-'
        d.forEach(v => {
          if (v.key === 'Good3' || v.key === 'Good4' || v.key === 'Good' || v.key === 'Dead') {
            firsts += v.firsts
            numberOfRuns += v.numberOfRuns
            seconds += v.seconds
            thirds += v.thirds
          }
        })
        return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
      }
      if (this.selected === 'statsDOHeavy') {
        const d = comp?.runnerStatistics?.career?.detail?.byCondition
        let firsts = 0
        let numberOfRuns = 0
        let seconds = 0
        let thirds = 0
        if (!d) return '-'
        d.forEach(v => {
          if (v.key === 'Heavy8' || v.key === 'Heavy9' || v.key === 'Heavy10' || v.key === 'Heavy') {
            firsts += v.firsts
            numberOfRuns += v.numberOfRuns
            seconds += v.seconds
            thirds += v.thirds
          }
        })
        return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
      }
      if (this.selected === 'statsDOSlow') {
        const d = comp?.runnerStatistics?.career?.detail?.byCondition
        let firsts = 0
        let numberOfRuns = 0
        let seconds = 0
        let thirds = 0
        if (!d) return '-'
        d.forEach(v => {
          if (v.key === 'Soft5' || v.key === 'Soft6' || v.key === 'Soft7' || v.key === 'Slow') {
            firsts += v.firsts
            numberOfRuns += v.numberOfRuns
            seconds += v.seconds
            thirds += v.thirds
          }
        })
        return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
      }
      if (this.selected === 'jockey_colours') {
        const d = comp?.silksColour
        return d || '-'
      }
      if (this.selected === 'last_10') {
        const d = comp?.runnerStatistics?.recent?.last10Starts
        return d || '-'
      }
      if (this.selected === 'comment') {
        const d = comp?.comment
        return d || '-'
      }
    },
    infoSet (v) {
      if (this.selected === 'weightAllocated') {
        const weightInLbs = v * 2.205
        if (this.displayImperial) {
          return `${numeral(weightInLbs).format('0,00')}`
        } else {
          return `${v}`
        }
      }
      if (this.selected === 'weightTotal') {
        const weightInLbs = v * 2.205
        if (this.displayImperial) {
          return `${numeral(weightInLbs).format('0,00')}`
        } else {
          return `${v}`
        }
      }
      return v
    }
  },
  computed: {
    ...mapGetters(['getRaceCompetitors', 'getWatchListRunners', 'getGridSettings']),
    ...mapState(['acMode']),
    classObject: function () {
      return {
        rightAlignPricesDiv: this.getGridSettings.includes('rightAlignPrices'),
        hovertheComment: true
      }
    },
    displayImperial: function () {
      return this.getGridSettings.includes('displayImperial')
    },
    selected () {
      return this.selection
    },
    selectionStats: function () {
      if (this.queryType === 'core') return true
      return false
    },
    selectionStatsField: function () {
      const v = this.selection
      if (v.includes('statsCoreRunner')) return 'runnerStatistics'
      return this.selection
    },
    infoQuery () {
      if (this.selectionStats && this.selected === 'api') return apiQuery(this.id)
      if (this.selectionStats && this.selected === 'statsDO1stup') return firstUpQuery(this.id)
      if (this.selectionStats && this.selected === 'statsDOAll') return careerStatsAllQuery(this.id)
      if (this.selectionStats && this.selected === 'statsDOJockey') return careerStatsJockeyQuery(this.id)
      if (this.selectionStats && this.selected === 'statsDODist') return careerStatsByDistQuery(this.id)
      if (this.selectionStats && this.selected === 'statsDOTrack') return careerStatsByTrackDistQuery(this.id)
      if (this.selectionStats && this.selected === 'statsDOTrackStats') return careerStatsByTrackQuery(this.id)
      if (this.selectionStats && (this.selected === 'statsDOFast' || this.selected === 'statsDOGood' || this.selected === 'statsDOHeavy' || this.selected === 'statsDOSlow')) return careerStatsByConditionQuery(this.id)
      if (this.selectionStats && this.selected === 'jockey_colours') return silksColourQuery(this.id)
      if (this.selectionStats && this.selected === 'last_10') return lastTenStartsQuery(this.id)
      if (this.selected === 'comment') return runnerCommentsQuery(this.id)
      return runnerInfoQuery(this.id)
    }
  }
}
</script>
<style>
.statsClass {
  overflow:hidden;
  max-height:30px !important;
  text-align:left;
  padding-left:4px;
}
.runnerInfoColumnDiv {
}
.infoColumn {
  width: 42px;
  writing-mode: vertical-rl;
  text-align: left;
  font-weight:600;
  font-size:0.8rem;
  white-space: wrap;
  transform: rotate(-180deg);
  height:80px;
    /* Safari */
  -webkit-transform: rotate(-180deg);

  /* Firefox */
  -moz-transform: rotate(-180deg);

  /* IE */
  -ms-transform: rotate(-180deg);
}
.editColumn {
  background:#fff;
}
.infoColumnEditMode {
  width: 60px;
  writing-mode: vertical-rl;
  text-align: left;
  font-weight:600;
  font-size:0.8rem;
  white-space: wrap;
  height: 62.5px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: rotate(-180deg);
    /* Safari */
  -webkit-transform: rotate(-180deg);

  /* Firefox */
  -moz-transform: rotate(-180deg);

  /* IE */
  -ms-transform: rotate(-180deg);
}
.columnWidth-INF-formComments {
  width:130px;
  text-align:left !important;
}
.columnWidthFooter-INF-formComments {
  width:130px !important;
}
.columnWidth-INF-jockey {
  width:130px;
}
.columnWidthFooter-INF-jockey {
  width:130px !important;
}
.columnWidth-INF-trainer {
  width:130px;
}
.columnWidthFooter-INF-trainer {
  width:130px !important;
}
.columnWidth-INF-sire {
  width:100px;
}
.columnWidthFooter-INF-sire {
  width:100px !important;
}
.columnWidth-INF-owners {
  width:130px;
}
.columnWidthFooter-INF-owners {
  width:130px !important;
}
.columnWidth-INF-dam {
  width:90px;
}
.columnWidthFooter-INF-dam {
  width:90px !important;
}
.columnWidth-INF-sire {
  width:90px;
}
.columnWidthFooter-INF-sire {
  width:90px !important;
}
.columnWidth-INF-prizeMoney {
  width:50px;
}
.columnWidthFooter-INF-prizeMoney {
  width:50px !important;
}
.columnWidthFooter-INF-api {
  width:80px !important;
}
.columnWidth-INF-api {
  width:80px !important;
}
.runnerInfo-loading{
  width:42px;
  border-top:0.5px solid #ddd;
  border-bottom:0.5px solid #ddd;
  border-right:1px solid #e4e4e4;
}
.runnerInfo-content{
  height: 100%;
  min-width: 42px;
}
.runnerInfo-column {
  padding: 0px 0px;
  min-width: 42px;
  cursor: pointer;
  min-height:32px;
  max-height:32px;
  line-height:32px;
  text-align:center;
  border-bottom:0.5px solid #ddd;
  overflow: hidden;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runnerInfo-columnAcMode {
  padding: 0px 0px;
  min-width: 42px;
  cursor: pointer;
  min-height:32px;
  max-height:32px;
  line-height:32px;
  text-align:center;
  border-bottom:0.5px solid #333;
  overflow: hidden;
  padding: 2px;
  background:#fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
