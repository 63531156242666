<template>
  <div class="editColumnMenuButton text-center">
    <v-menu
      nudge-left="320"
      nudge-top="5"
      :close-on-content-click="false"
      min-width="300"
      max-width="300"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon
              dark
              size="13"
              color="primary"
              class="editColumnButton column-actions__gear"
              v-on="{ ...tooltip, ...menu }"
            >mdi-cog</v-icon>
          </template>
          <span>Edit Column</span>
        </v-tooltip>
      </template>
      <div class="editColumnMenu-content">
        <v-list>
          <v-list-group
            no-action
            :value="false"
            class="editColFont-group"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title  class="editColFontSize body-2"><v-icon class="mr-1">mdi-format-size</v-icon>Font Size</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item @click="editColumn('font-size', '10px')" class="caption smallFont">Small</v-list-item>
              <v-list-item @click="editColumn('font-size', '12px')" class="subtitle-2 mediumFont">Medium</v-list-item>
              <v-list-item @click="editColumn('font-size', '15px')" class="title largeFont">Large</v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title  class="editColFontWeight body-2"><v-icon class="mr-1">mdi-format-bold</v-icon> Font Weight</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item @click="editColumn('font-weight', '300')" class="font-weight-thin">Thin</v-list-item>
              <v-list-item @click="editColumn('font-weight', '500')" class="font-weight-regular">Normal</v-list-item>
              <v-list-item @click="editColumn('font-weight', '700')" class="font-weight-black">Bold</v-list-item>
            </v-list>
          </v-list-group>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="editColColor body-2"><v-icon class="mr-1">mdi-format-color-fill</v-icon>Column Colour</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-color-picker class="ma-2" :swatches="swatches" show-swatches v-model="bgColor"></v-color-picker>
          </v-list-group>
          <v-list-group
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-content >
                <v-list-item-title class="editColFontColor body-2"><v-icon class="mr-1">mdi-format-color-highlight</v-icon>Font Colour</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-color-picker class="ma-2" :swatches="swatches" show-swatches v-model="fontColor"></v-color-picker>
          </v-list-group>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    swatches: [
      ['#FF0000', '#AA0000', '#550000'],
      ['#FFFF00', '#AAAA00', '#555500'],
      ['#00FF00', '#00AA00', '#005500'],
      ['#00FFFF', '#00AAAA', '#005555'],
      ['#efefef', '#dadada', '#000000']
    ],
    type: 'hex'
  }),
  props: {
    columnNumber: Number
  },
  computed: {
    ...mapGetters(['hasFeature', 'getSelectedColumns', 'getSelectedColumnsName']),
    bgColor: {
      get () {
        return this[this.type]
      },
      set (v) {
        this.editColumn('background', v.hex)
        this[this.type] = v
      }
    },
    fontColor: {
      get () {
        return this[this.type]
      },
      set (v) {
        this.editColumn('color', v.hex)
        this[this.type] = v
      }
    }
  },
  methods: {
    ...mapActions(['updateSelectedColumns']),
    editColumn: function (type, value) {
      const columns = this.getSelectedColumns
      if (columns[this.columnNumber].settings) {
        columns[this.columnNumber].settings[type] = value
      } else {
        columns[this.columnNumber].settings = {}
        columns[this.columnNumber].settings[type] = value
      }
      this.updateSelectedColumns({ columns, name: this.getSelectedColumnsName })
    }
  }
}
</script>

<style>
 .editColumnMenuButton {
   display: inline-block;
 }

</style>
