<template>
  <v-app class="doApp">
    <MessageHandler />
    <v-container :class="isNoParams ? 'appContainer appgrid px-0 pt-0' : this.$route.path.includes('/speedMap') ? 'appContainer appgridSpeedMap px-0 pt-0' : 'appContainer appgridFlucs px-0 pt-0'" fluid>
      <div class="appHeaderGrid" v-if="isNoParams && authorized">
        <AppNavigation :clickFeature="clickFeature" :changeAcMode="changeAcMode" :acMode="acMode"/>
      </div>
      <div :class="isNoParams || this.$route.path.includes('/speedMap') ? 'appOddsGrid' : 'appOddsFlucsGrid'" ref="raceBox" >
        <RaceDayNavigation v-if="authorized && isNoParams" :clickFeature="clickFeature" :expandOnHoverFunc="expandOnHoverFunc" />
        <v-main v-if="showRouter" :style="vAppWidth" :class="`mainContent ${isAutoHide ? 'mt-2' : ''}`" style="padding-top: 10px;">
          <router-view></router-view>
        </v-main>
      </div>
      <div class="appFooter">
      </div>
    </v-container>
    <AppFooter v-if="isNoParams && authorized"/>
    <v-overlay :value="editMode" z-index="189"></v-overlay>
    <NoFeature v-if="noFeatureAlert" @close="closeFeature()" :userStatus="authorized" :featureInBeta="featureInBeta"/>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// import startOfDay from 'date-fns/startOfDay'
import AppNavigation from './components/AppNavigation'
import AppFooter from './components/AppFooter'
import RaceDayNavigation from './components/RaceDayNavigation'
import NoFeature from './components/NoFeature'
import MessageHandler from './components/MessageHandler'
import { subscriptionClient } from './vue-apollo'
import { datadogRum } from '@datadog/browser-rum'
import { isCoreApiTokenExpired, isEmpty } from './common/methods'

export default {
  name: 'App',
  components: {
    AppNavigation,
    RaceDayNavigation,
    AppFooter,
    NoFeature,
    MessageHandler
  },
  data () {
    return {
      featureInBeta: null,
      vAppWidth: null,
      // expandOnHover: null,
      setRaceHeight: { },
      disconnected: false,
      showRouter: true,
      runEvent: true,
      subscriptionInitialised: false,
      userMemberId: null
    }
  },
  mounted () {
    this.matchHeight()
    this.fetchUrl()
    window.addEventListener('resize', this.matchNewHeightWait)
    // document.addEventListener('DOMContentLoaded', function () {
    //   if (!Notification) {
    //     alert('Desktop notifications not available in your browser. Try Chromium.')
    //     return
    //   }
    //   if (Notification.permission !== 'granted') {
    //     Notification.requestPermission()
    //   }
    // })
  },
  computed: {
    ...mapState('account', ['authorized', 'user', 'coreApiToken', 'memberId']),
    ...mapState(['noFeatureAlert', 'acMode', 'isAutoHide']),
    ...mapState(['editMode'], 'user'),
    ...mapGetters(['hasFeature']),
    ...mapGetters({
      getAuthToken: 'account/authToken'
    }),
    isNoParams () {
      return isEmpty(this.$route.params)
    },
    latestMemberId () {
      return this.userMemberId // Return the most updated memberId
    }
  },
  watch: {
    memberId (newValue) {
      this.userMemberId = newValue
      this.handleMemberIdChange()
    },
    authorized: async function (n) {
      if (subscriptionClient && subscriptionClient.on && !this.subscriptionInitialised) {
        subscriptionClient.on('disconnected', () => {
          this.disconnected = true
          this.socketClosed()
        })
        subscriptionClient.on('reconnected', (context) => {
          if (this.disconnected) {
            this.disconnected = false
            this.reFetchRaces()
          }
        })
        this.subscriptionInitialised = true
      }
      // setTimeout(() => {
      //   console.log('set expired token')
      //   const token = 'eyJraWQiOiI3bzNUN3NoemhqM0pJaGpNZjFwbE1OcUN1ZTB0Vjk4SDVnMStlSk5ubDZnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlMzljMWUwNS01Mzc4LTQwZWYtOThkZi1jNjRjNGU1N2YwMzgiLCJldmVudF9pZCI6IjczNTkyNjA5LWQzMGUtNDFkNy1hZmM5LTE4ZDRmZGVlZDgxNCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTE4MjgzMTksImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aGVhc3QtMi5hbWF6b25hd3MuY29tXC9hcC1zb3V0aGVhc3QtMl9oOTRsTmhwRWwiLCJleHAiOjE2MTE4ODM2NjAsImlhdCI6MTYxMTg4MDA2MCwianRpIjoiNmVmYThlODUtM2ZhYS00ZWZkLWI1NWQtYjFkNWE0NDc3MGY1IiwiY2xpZW50X2lkIjoiNXF2NmJ0bHZzZmx2YXRuMmptOWdnNzF1ZXIiLCJ1c2VybmFtZSI6ImpheWtlbGx5In0.F_iBIOdKbLhEMdZUzigmQ-8t_t2bzSTNwInpdsFzN2Nj1f61wQnKLjJ09LKs5m_Cf8CWMt9u6ZRY5j0HiFWByS41C09uVLwUdtqes2m924YsiS2ZBpWvJidxiNYN-WhSd_sR9BUKNZ1qbXExysLGN_rTuuKc5cxer_yeEh05Voi6z7wtoZjcrTWJIjgFPnB4Tk1i1eL6jJI05GOWScV292CYm8_uCNGRTfH3YOm3txKlN6jc63Mi54-s934RSg-hzL2pdeQCdAETIy4hvrNmy-sQIx-rrFw_Vi7XYOI5DPEIAembGbTFo9iNZqo-jLE7fWgfnhsDTfxHOGW3lKlhMw'
      //   window.localStorage.setItem('auth_token', token)
      // }, 30000)
      if (n) {
        // signed in
        // await this.updateAuthToken()
        this.fetchUserDetails()
        await this.fetchUserFilters()

        await this.handleMemberIdChange()
        if (this.hasFeature('blackbook')) await this.fetchDynamicFormData()

        // await this.fetchUserColumns()
        await this.fetchRaces()
        this.fetchAzList()
        this.fetchBettingAgencies()
        this.$connect()
        this.fetchRacedayUpdatesEnabled()
        this.fetchMarketMoverBookie()
        this.fetchBetHistory()
        this.updateNewColumns()
        if (this.$route && this.$route.path !== '/' && this.isNoParams) {
          this.$router.push('/')
        }
        if (this.hasFeature('datadog')) {
          datadogRum.addRumGlobalContext('usr', {
            username: this.user.username,
            email: this.user.attributes.email
          })
        }
      } else {
        // logged out
        // await this.fetchRaces()
        // this.setDate(startOfDay(Date.now()))
        this.$disconnect()
      }
    },
    isAppIdle () {
      if (isCoreApiTokenExpired({ token: this.coreApiToken })) {
        this.updateTokenExpired({ callback: () => {} })
      }
    }
  },
  methods: {
    ...mapActions(['fetchRaces', 'updateNewColumns', 'fetchUrl', 'reFetchRaces', 'acModeSwitch', 'setDate', 'fetchUserFilters', 'fetchDynamicFormData', 'fetchUserColumns', 'showFeatureAlert', 'fetchBettingAgencies', 'setMatchHeight', 'fetchMyRaces', 'fetchRacedayUpdatesEnabled', 'socketOpen', 'socketClosed', 'fetchMarketMoverBookie', 'fetchUserDetails', 'fetchAzList']),
    ...mapActions('betting', ['fetchBetHistory']),
    ...mapActions('account', ['fetchUser', 'updateAuthToken', 'fetchUserAccountStatus', 'updateTokenExpired', 'getSubscriptionLevel', 'deleteUser']),
    async handleMemberIdChange () {
      if (this.userMemberId !== null) {
        const level = await this.getSubscriptionLevel()
        if (level.subStatus === 1) {
          if (level.attributes.email.includes('@miningpets.com')) {
            this.deleteUser()
          }
        }
        await this.fetchUserAccountStatus()
      }
    },
    matchHeight () {
      var heightString = this.$refs.raceBox.clientHeight - 115 + 'px'
      Vue.set(this.setRaceHeight, 'height', heightString)
      this.setMatchHeight(this.setRaceHeight)
    },
    async matchNewHeightWait () {
      this.showRouter = false
      if (this.runEvent) {
        setTimeout(() => {
          this.matchNewHeight()
        }, 1000)
      }
    },
    async matchNewHeight () {
      this.runEvent = false
      await this.resetHeight()
      var heightString = this.$refs.raceBox.clientHeight - 115 + 'px'
      Vue.set(this.setRaceHeight, 'height', heightString)
      this.setMatchHeight(this.setRaceHeight)
      this.showRouter = true
      this.runEvent = true
    },
    resetHeight () {
      Vue.set(this.setRaceHeight, 'height', '0px')
    },
    clickFeature (v) {
      if (v === 'inBeta') {
        this.featureInBeta = true
      } else {
        this.featureInBeta = false
      }
      this.showFeatureAlert(true)
    },
    changeAcMode () {
      if (this.acMode === true) {
        this.$vuetify.theme.themes.light.primary = '#1A237E'
        this.$vuetify.theme.themes.light.success = '#4caf50'
        this.$vuetify.theme.themes.light.error = '#ff5252'
        this.$vuetify.theme.themes.light.info = '#2196f3'
        this.acModeSwitch(false)
      } else if (this.acMode === false) {
        this.$vuetify.theme.themes.light.success = '#388E3C'
        this.$vuetify.theme.themes.light.error = '#C62828'
        this.$vuetify.theme.themes.light.primary = '#263238'
        this.$vuetify.theme.themes.light.info = '#FF5722'
        this.acModeSwitch(true)
      }
    },
    closeFeature () {
      this.showFeatureAlert(false)
    },
    expandOnHoverFunc (v) {
      if (v === true) {
        this.vAppWidth = 'padding:0px 0px 0px 32px'
      } else {
        this.vAppWidth = null
      }
    }
  },
  async created () {
    this.$vuetify.theme.themes.light.primary = '#1A237E'
    await this.fetchUser()
    // this.removeSessionCookies()
    this.$store.commit('updateLoadAZListData', true)
  },
  BeforeDestroy () {
    // this.removeSessionCookies()
    window.removeEventListener('resize', this.matchNewHeight)
  }
}
</script>

<style>
  .mainContent {
    height:100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Safari */
    -khtml-user-select: none;    /* Konqueror HTML */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  .appContainer {
    height:100%;
    width:100%;
  }
  .appgrid {
    grid-template-rows: 30px auto 2.5rem;
    grid-template-columns: [first] 100% [col-second] 0% [end];
    display:grid;
    min-height:100vh;
    grid-template-areas:
    "header"
    "main"
    "footer";
  }
  .appgridSpeedMap {
    grid-template-rows: 30px auto 2.5rem;
    display:grid;
    min-height:100vh;
    grid-template-areas:
    "header"
    "main"
    "footer";
  }
  .appgridFlucs {
    grid-template-rows: 30px auto 2.5rem;
    display:grid;
    min-height:100vh;
    grid-template-areas:
    "header"
    "main"
    "footer";
  }
  .appHeaderGrid {
    grid-area: header;
    align-self: start;
    grid-column-start: first;
    grid-column-end: 3;
  }
  .appOddsGrid {
    grid-area: main;
    align-self: stretch;
    grid-column-start: first;
    grid-column-end: 2;
  }
  .appOddsFlucsGrid {
    grid-area: main;
    align-self: stretch;
    grid-column-start: first;
    grid-column-end: 2;
  }
  .appFooter {
    grid-area: footer;
    align-self: start;
    grid-column-start: first;
    grid-column-end: 2;
  }
  .appdoApp {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
  }
  .doApp {
    height:100vh;
    background-color: #f3f5f8 !important;
  }
  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.0s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 350px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.0s;
    font-family: Helvetica, Arial, sans-serif;
  }

  .highlight {
    background-color: #cfd3ce !important
  }
</style>
