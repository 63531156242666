<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
      <v-card class="modal-container" min-width="500" v-on:keyup.enter="login(username, password)">
        <v-list-item v-if="dialogSuccess" class="primary">
          <v-list-item-content class="ml-3 white--text">
            <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-flash</v-icon>Logged in</v-list-item-title>
          </v-list-item-content>
          <v-btn icon small @click="$emit('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-list-item>
        <v-list-item v-if="!dialogSuccess" class="warning">
          <v-list-item-content class="ml-3 white--text">
            <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-account-key-outline</v-icon>Account Security</v-list-item-title>
          </v-list-item-content>
          <v-btn icon small @click="$emit('close')"><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-list-item>
        <v-container v-if="dialogSuccess" fluid>
          <v-btn class="tick" text><v-icon color="success">mdi-check</v-icon></v-btn>
          Login successful
        </v-container>
        <v-container v-else-if="loginTools" fluid class="text-center">
          <span class="subtitle-1 ">
            To access this tool we need to confirm it's you<br>
            Please re-enter your <em>Dynamicodds</em> password
            <br>
          </span>
          <div v-if="error" class="subtitle-1 errorMessage">
            {{ error }}
          </div>
          <v-card class="loginDetails mt-5" elevation="0">
            <v-text-field
              class="username"
              label="Username"
              placeholder="Username"
              style="max-width:150px;"
              :value="username"
            ></v-text-field>
            <v-text-field
              class="password"
              label="Password"
              placeholder="Password"
              style="max-width:150px;"
              type="password"
              v-model="password"
            ></v-text-field>
          </v-card>
          <div class="loginActions">
            <v-btn type="submit" class="loginButton" color="success" @click="login(username, password)">
              Login
            </v-btn>
          </div>
        </v-container>
        <v-container v-else fluid class="text-center">
          <span class="subtitle-1 ">
            To enable betting we need to confirm it's you<br>
            Please re-enter your <em>Dynamicodds</em> password
            <br>
          </span>
          <div v-if="error" class="subtitle-1 errorMessage">
                {{ error }}
          </div>
          <v-card class="loginDetails mt-5" elevation="0">
            <v-text-field
              class="username"
              label="Username"
              placeholder="Username"
              style="max-width:150px;"
              :value="username"
            ></v-text-field>
            <v-text-field
              class="password"
              label="Password"
              placeholder="Password"
              style="max-width:150px;"
              type="password"
              v-model="password"
            ></v-text-field>
          </v-card>
          <div class="loginActions">
            <v-btn type="submit" class="loginButton" color="success" @click="login(username, password)">
              Login
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    password: null,
    dialogSuccess: false,
    error: null
  }),
  props: {
    close: Function,
    loginTools: Boolean
  },
  computed: {
    ...mapGetters('account', ['getUsername']),
    username: function () {
      return this.getUsername
    }
  },
  methods: {
    ...mapActions('account', ['confirmSignin']),
    ...mapActions(['loginBetting']),
    async login (username, password) {
      const result = await this.confirmSignin({ username, password })
      if (result.success) {
        this.loginBetting()
        this.dialogSuccess = true
        setTimeout(() => {
          this.$emit('close')
          this.dialogSuccess = false
        }, 1500)
      } else {
        this.error = result.message
      }
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.0s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.loginModal {
  width: 550px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.0s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.subtitle {
  text-align: center;
  margin-bottom: 15px;
}
.errorMessage {
  color: red;
  background-color: none;
}
</style>
