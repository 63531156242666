<template>
  <div class="runnerSectionContainer">
    <div class="runnerSectionMainContainer">
      <data-table class="runnersSection" v-if="race" :items="race.competitors" :headers="[]" item-key="tabNo">
        <template>
          <tr>
            <td class="headerSilkRunner"></td>
            <td class="headerNameRunner"></td>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <v-menu
            v-if="hasFeature('priceAlerts')"
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <tr class="runnerHeight" v-bind="attrs" v-on="on" :class="isInWatchList(item.tabNo) ? 'highlight' : ''">
                <td v-bind:class="{acModeRunner: acMode, stdMode: !acMode, showSilksWidth: showSilks, showSilksWidthNew: !showSilks}" class="text-start" style="">
                  <div style="float:left;width: 27px; height: 25px; border: 1px solid rgb(216, 216, 216); background-color: rgb(255, 255, 255); font-weight:900; text-align: center;margin-bottom:3px;margin-top:3px;">
                        <div style="line-height:25px;">{{ item.tabNo }}</div>
                  </div>
                  <div v-if="showSilks" class="float-left pl-1">
                    <RaceRunners :meeting="meeting" :race="race" :item="item" :editMode="editMode"/>
                  </div>
                </td>
                <RaceNames :raceId="race.id" :item="item" :meeting="meeting" :mobile="false" :results="race.results" :acMode="acMode" :showJockeys="showJockeys" :betHistory="getFilteredBetHistory"/>
                <!-- below empty TD is so footer %'s background colours line up in the current colspan arrangement -->
              </tr>
            </template>
          <v-list dense>
            <v-list-item
              v-for="(menuOption, i) in menuOptionsPriceAlerts"
              :key="menuOption?.title"
              @click="watchList(menuOption.action, item)"
            >
              <v-list-item-title>{{ menuOption.title }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="!hasBlackbook({ runner: item }) && !isEmpty(hasBlackbook({ runner: item }))" @click="onAddRunnerToBlackbook({ runner: item })">
              <v-list-item-title>Add to Blackbook</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="hasBlackbook({ runner: item }) && !isEmpty(hasBlackbook({ runner: item }))" @click="onRemoveRunnerToBlackbook({ runner: item })">
              <v-list-item-title>Remove from Blackbook</v-list-item-title>
            </v-list-item>
            <!-- <v-divider></v-divider>
            <v-list-item
              v-for="(menuOption, i) in menuRdcList"
              :key="menuOption?.title"
              @click="watchList(menuOption.action, item)"
            >
              <v-list-item-title v-if="getRdcConfig"><span class="overline mr-2 error--text">RDC Function:</span>{{ menuOption.title }}</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <tr class="runnerHeight" v-else :class="isInWatchList(item.tabNo) ? 'highlight' : ''">
          <td v-bind:class="{acModeRunner: acMode, stdMode: !acMode, showSilksWidth: showSilks, showSilksWidthNew: !showSilks}" class="text-start" style="">
            <div style="float:left;width: 27px; height: 25px; border: 1px solid rgb(216, 216, 216); background-color: rgb(255, 255, 255); font-weight:900; text-align: center;margin-bottom:3px;margin-top:3px;">
                  <div style="line-height:25px;">{{ item.tabNo }}</div>
            </div>
            <div v-if="showSilks" class="float-left pl-1">
              <RaceRunners :meeting="meeting" :race="race" :item="item" :editMode="editMode"/>
            </div>
          </td>
          <RaceNames :raceId="race.id" :item="item" :meeting="meeting" :mobile="false" :results="race.results" :acMode="acMode" :showJockeys="showJockeys" :betHistory="getFilteredBetHistory"/>
          <!-- below empty TD is so footer %'s background colours line up in the current colspan arrangement -->
        </tr>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>

import DataTable from './DataTable'
import RaceRunners from './RaceRunners'
import RaceNames from './RaceNames'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isEmpty } from '../common/methods'

export default {
  data: () => ({
    menuOptionsPriceAlerts: [
      {
        title: 'Add to Watchlist & Highlight Runners',
        action: 'highlight'
      },
      {
        title: 'Add to Watchlist & Monitor Price',
        action: 'monitor_price'
      },
      {
        title: 'Add to Watchlist & Set Price Alert',
        action: 'price_alert'
      }
    ],
    menuRdcList: [
      {
        title: 'Scratch Runner',
        action: 'scratchRunner'
      },
      {
        title: 'Change Jockey',
        action: 'jockeyChange'
      }
    ]
  }),
  props: {
    race: Object,
    meeting: Object,
    editMode: Boolean
  },
  components: {
    DataTable,
    RaceRunners,
    RaceNames
  },
  computed: {
    ...mapState(['acMode', 'dialogWatchlist', 'dialogAddToWatchlist', 'runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook']),
    ...mapGetters(['hasFeature', 'getGridSettings', 'getBestPrice', 'getWatchListRunners', 'getRdcConfig']),
    ...mapGetters('betting', ['getBetHistory']),
    showSilks () {
      if (!this.getGridSettings.includes('showSilks')) return false
      return true
    },
    showJockeys () {
      if (!this.getGridSettings.includes('showJockey')) return false
      return true
    },
    getFilteredBetHistory () {
      const betHistory = this.getBetHistory
      return betHistory?.filter(b => b.raceId === this.race.id)
    }
  },
  methods: {
    ...mapActions(['watchListSwitch', 'addToWatchListSwitch', 'addToWatchList', 'addToBlackBook', 'deleteRunnerFromBlackbook']),
    isEmpty,
    watchList (action, item) {
      if (action === 'highlight') {
        this.addToWatchList({ meetingId: this.meeting.id, raceNumber: this.race.number, runnerNumber: item.tabNo, meetingDate: this.meeting.date })
        // this.watchListSwitch()
      } else {
        this.addToWatchListSwitch({ meetingId: this.meeting.id, raceNumber: this.race.number, runnerNumber: item.tabNo, meetingDate: this.meeting.date, name: item.name, price: this.getBestPrice(item.tabNo) })
      }
    },
    isInWatchList (tabNo) {
      return this.getWatchListRunners.includes(tabNo)
    },
    onAddRunnerToBlackbook ({ runner }) {
      const saveCurrentRunner = {
        tabType: 'runnerTab',
        runnerId: runner?.runner?.id,
        selectedTabTypeName: runner?.name,
        raceNumber: this.race?.number,
        meetingId: this?.meeting?.id,
        meetingType: this?.meeting?.type
      }

      this.addToBlackBook({ blackbook: saveCurrentRunner })
    },
    onRemoveRunnerToBlackbook ({ runner }) {
      const removeCurrentRunner = {
        tabType: 'runnerTab',
        runnerId: runner?.runner?.id
      }

      this.deleteRunnerFromBlackbook({ blackbook: removeCurrentRunner })
    },
    hasBlackbook ({ runner }) {
      if (!this.hasFeature('blackbook') || this.meeting.type === 'HARNESS') return null
      if ((this.meeting.type === 'THOROUGHBRED' || this.meeting?.type === 'GREYHOUND') && this.hasFeature('blackbook')) {
        const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
        const found = combined.find(co => co.runnerId === runner?.runner?.id && co.tabType === 'runnerTab')
        return !isEmpty(found)
      } else {
        return false
      }
    }
  }
}
</script>

<style>
  .showSilksWidth {
    height:30px;width:65px;padding:0px;
  }
  .showSilksWidthNew {
    height:30px;width:30px;padding:0px;
  }
  .runnerSectionMainContainer {
    padding-bottom:30px;
    z-index:202;
    height:100%;
  }
  .runnerSectionContainer {
    width:100%;
    height:100%;
    background:#fff;
    z-index:202;
    min-width: 245px !important;
  }
  .runnersSection {
    width: 100%;
    border-top:1px solid #efefef;
  }
  td.headerNameRunner {
  font-size: 0.9em;
  font-weight: 300;
  color: #555;
  vertical-align: bottom;
  width: 300px;
  height:34px;
  }
  .acModeRunner {
    border-bottom: 1px solid #333;
  }
  .tableFooterTitle {
  font-weight: 500 !important;
  font-size: 0.8em;
  color: #000 !important;
  padding: 5px;
  text-align: end;
  }
  .runnerHeight {
    height:32px !important
  }

  .runnerHeight:hover {
    background-color: #EFEFEF;
  }

  .notificationOptions {
    margin-top: 0px !important;
    padding-top: 0px !important;
    margin-left: 100px;
  }
</style>
