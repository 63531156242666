<template>
  <v-row :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
      <v-checkbox class="scheduleLabels codesFilter mr-2" label="Gallops" color="primary" v-model="selected" value="THOROUGHBRED" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels codesFilter mr-2" label="Harness" color="primary" v-model="selected" value="HARNESS"  hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels codesFilter mr-2" label="Greys" color="primary" v-model="selected"  value="GREYHOUND" hide-details></v-checkbox>
  </v-row>
</template>

<script>
export default {
  props: {
    codes: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    selected: {
      get () {
        return this.codes
      },
      set (value, newValue) {
        return this.onClick(value)
      }
    }
  }
}
</script>

<style>
</style>
