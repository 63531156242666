import Vue from 'vue'
import axios from 'axios'
import { AU_COLUMNS } from '../common/constants'

const COUNTRIES = ['AUS', 'NZL', 'GBR', 'IRL', 'ZAF', 'FRA', 'HKG', 'SGP', 'JPN']
const USA_CA = ['USA', 'CAN']
const OTHER = ['SWE', 'MEX', 'NOR', 'FIN', 'DEU', 'CHL', 'ARG', 'UAE', 'URY', 'KOR', 'PER', 'MUS', 'BRA', 'ITA', 'DNK', 'IND', 'ESP', 'MYS', 'ARE', 'BLE', 'SAU']
const CODES = ['THOROUGHBRED', 'HARNESS', 'GREYHOUND']
const MEETING_TYPES = ['T', 'N', 'P']
const DEFAULT_GRID = ['highLightOdds', 'rightAlignPrices', 'showSilks', 'showJockey', 'showPriceFlucs']
const STATUSES = ['Open', 'Closed', 'Interim/Paying', 'Abandoned']
const CONDITIONS = ['Fast', 'Good', 'Dead', 'Slow', 'Heavy']
const HIGHLIGHTBEST = ['WIN_FIXED_ODDS', 'ignoreToteOdds']

const store = {
  state: {
    raceNavFilters: {
      countries: [...COUNTRIES, 'USA/CAN', 'OTHER'],
      codes: CODES,
      meetingTypes: MEETING_TYPES,
      statuses: STATUSES,
      starters: [1, 30],
      distances: [1, 10000],
      conditions: CONDITIONS,
      meetings: [],
      races: []
    },
    raceGridSettings: {
      resultsShowHide: '',
      raceFontSize: '',
      audioPriceAlert: '',
      highlightBestOdds: HIGHLIGHTBEST,
      gridSettings: DEFAULT_GRID,
      betFairSettings: [1.00, 1.00],
      betFairCommission: 0,
      betLimits: [0.00, 0.00, 0.00]
    },
    priceSorting: {},
    selectedColumnsName: 'AU',
    selectedColumns: AU_COLUMNS,
    speedMap: null
  },
  mutations: {
    updateMeetings (state, value) {
      state.raceNavFilters.meetings = value
      // Vue.set(state.raceNavFilters.meetings)
    },
    updateRaces (state, value) {
      // state.raceNavFilters.races = value
      Vue.set(state.raceNavFilters, 'races', value)
    },
    updateSortPrices (state, value) {
      state.priceSorting = value
    },
    updateCountries (state, value) {
      state.raceNavFilters.countries = value
    },
    updateCodes (state, value) {
      state.raceNavFilters.codes = value
    },
    updateMeetingTypes (state, value) {
      state.raceNavFilters.meetingTypes = value
    },
    updateStatuses (state, value) {
      state.raceNavFilters.statuses = value
    },
    updateStarters (state, value) {
      state.raceNavFilters.starters = value
    },
    updateDistances (state, value) {
      state.raceNavFilters.distances = value
    },
    updateResultsShowHide (state, value) {
      state.raceGridSettings.resultsShowHide = value
    },
    updateAudioPriceAlert (state, value) {
      state.raceGridSettings.audioPriceAlert = value
    },
    updateRaceFontSize (state, value) {
      state.raceGridSettings.raceFontSize = value
    },
    updateHighLightBestOdds (state, value) {
      state.raceGridSettings.highlightBestOdds = value
    },
    updateGridSettings (state, value) {
      state.raceGridSettings.gridSettings = value
    },
    updateBetFairSettings (state, value) {
      state.raceGridSettings.betFairSettings = value
    },
    updateBetFairComission (state, value) {
      state.raceGridSettings.betFairCommission = value
    },
    updatebetLimits (state, value) {
      state.raceGridSettings.betLimits = value
    },
    updateConditions (state, value) {
      state.raceNavFilters.conditions = value
    },
    setSelectedColumns (state, { name, columns }) {
      state.selectedColumns = columns
      state.selectedColumnsName = name
      columns.map((c, i) => {
        Vue.set(state.selectedColumns, i, c)
      })
    },
    setSpeedMap (state, speedMap) {
      state.speedMap = speedMap
    }
  },
  actions: {
    async fetchSelectedColumns (context) {
      const res = await axios.post('public/getSelectedColumns', {})
      const mappedData = res.data.columns.map(x => {
        const codeparts = x.code.split(':')
        return {
          source: codeparts[0],
          type: codeparts[1],
          display: x.column_type,
          link: x.header_link
        }
      })
      context.commit('setSelectedColumns', { name: 'A', columns: mappedData })
    }
  },
  getters: {
    getRaceNavFilters (state) {
      return state.raceNavFilters
    },
    getCountriesFilter (state) {
      let result = [...state.raceNavFilters.countries]
      if (state.raceNavFilters.countries.includes('OTHER')) {
        result = [...result, ...OTHER]
      } if (state.raceNavFilters.countries.includes('USA/CAN')) {
        result = [...result, ...USA_CA]
      }
      return result
    },
    getIsCountrySelectedFilter (state) {
      return state.raceNavFilters.countries
    },
    getIsCodesSelectedFilter (state) {
      return state.raceNavFilters.codes
    },
    getMeetingTypesSelectedFilter (state) {
      return state.raceNavFilters.meetingTypes
    },
    getStatusesFilter (state) {
      return state.raceNavFilters.statuses
    },
    getStartersFilter (state) {
      return state.raceNavFilters.starters
    },
    getDistanceFilter (state) {
      return state.raceNavFilters.distances
    },
    getConditionsFilter (state) {
      return state.raceNavFilters.conditions
    },
    getResultsShowHide (state) {
      return state.raceGridSettings.resultsShowHide
    },
    getPriceAlertsAudio (state) {
      return state.raceGridSettings.audioPriceAlert
    },
    getRaceFontSize (state) {
      return state.raceGridSettings.raceFontSize
    },
    getHighLightBestOdds (state) {
      return state.raceGridSettings.highlightBestOdds
    },
    getGridSettings (state) {
      return state.raceGridSettings.gridSettings
    },
    getBetFairSettings (state) {
      return state.raceGridSettings.betFairSettings
    },
    getBetFairCommission (state) {
      return state.raceGridSettings.betFairCommission
    },
    getBetLimits (state) {
      return state.raceGridSettings.betLimits || []
    },
    getSelectedColumns (state, getters) {
      return state.selectedColumns
    },
    getSelectedColumnsName (state) {
      return state.selectedColumnsName
    },
    getSpeedMap (state) {
      return state.speedMap
    },
    getShowHideMeetingsFilter (state) {
      return state.raceNavFilters.meetings
    },
    getShowHideRacesFilter (state) {
      return state.raceNavFilters.races
    }
  }
}

export default store

export const defaultCountries = [...COUNTRIES, 'USA/CAN', 'OTHER']
export const defaultCodes = ['THOROUGHBRED', 'HARNESS', 'GREYHOUND']
export const defaultMeetingTypes = ['T', 'N', 'P']
export const defaultStatuses = ['Open', 'Closed', 'Interim/Paying', 'Abandoned']
export const defaultStarters = [1, 30]
export const defaultDistances = [1, 10000]
export const defaultConditions = ['Fast', 'Good', 'Dead', 'Slow', 'Heavy']
