<template>
  <div>
    <p class="font-weight-light">To enable alerts for a race simply click the <strong><v-icon small>mdi-bell-alert-outline</v-icon> button</strong> at the top of the 'Race Schedule' and then tick the checkbox to the right of each race you wish to receive alerts for.</p>
    <v-data-table :headers="headersRaceAlerts" :items="raceAlertsItems" hide-default-footer>
      <template v-slot:item="{ item }">
        <tr v-if="item.title === 'Enable Alert:'">
          <th style="text-align:center;">{{ item.title }}</th>
          <td v-for="alert in alerts" :key="alert.key">
            <v-checkbox hide-details="true" :false-value="0" :true-value="1" dense v-model="alert.value" @change="saveConfigurationRaceAlerts" class="raceAlertCheckbox"></v-checkbox>
          </td>
        </tr>
        <tr v-else-if="item.title === 'Play Sound:'">
          <th style="text-align:center;">{{ item.title }}</th>
          <td v-for="sound in soundsAlerts" :key="sound.key">
            <v-checkbox hide-details="true" :false-value="0" :true-value="1" dense v-model="sound.value" @change="saveConfigurationRaceAlerts" class="raceAlertCheckbox"></v-checkbox>
          </td>
        </tr>
        <tr v-else>
          <th style="text-align:center; width: 120px;">{{ item.title }}</th>
          <td v-for="time in timesAlerts" :key="time.key">
            <v-select :items="raceAlertsTimes" dense v-model="time.value" @change="saveConfigurationRaceAlerts"></v-select>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'RaceAlertConfiguration',
  props: {
    saveConfigurationRaceAlerts: Function,
    alerts: Array,
    soundsAlerts: Array,
    timesAlerts: Array
  },
  data: () => ({
    headersRaceAlerts: [
      {
        value: 'title'
      },
      {
        text: '1st Alert',
        value: 'firstAlert',
        align: 'center'
      },
      {
        text: '2nd Alert',
        value: 'secondAlert',
        align: 'center'
      },
      {
        text: 'Final Alert',
        value: 'finalAlert',
        align: 'center'
      }
    ],
    raceAlertsItems: [
      {
        title: 'Enable Alert:',
        firstAlert: 0,
        secondAlert: 0,
        finalAlert: 0
      },
      {
        title: 'Play Sound:',
        firstAlert: 0,
        secondAlert: 0,
        finalAlert: 0
      },
      {
        title: 'Time Before Jump:',
        firstAlert: 0,
        secondAlert: 0,
        finalAlert: 0
      }
    ],
    raceAlertsTimes: ['30 Sec', '1 Min', '2 Mins', '3 Mins', '4 Mins', '5 Mins', '10 Mins', '15 Mins', '20 Mins', '25 Mins', '30 Mins']
  })
}
</script>
