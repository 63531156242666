<template>
  <v-menu :close-on-content-click="false" offset-y z-index="2002" min-width="210px">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-btn label color="primary" v-show="setting === 'totalBalance'" outlined key="totalBalance" class="totalBalance mr-2" x-small style="height: 18px !important;">Total Balance: {{numeral(getBalance()).format('($0,0.00)')}}</v-btn>
        <div v-show="setting === 'balance'" key="balance" class="balance" x-small>{{numeral(getBalance()).format('(0.00a)')}}</div>
        <v-btn v-show="setting === 'tick' && bookieCode !== 'totalBalance'" key="tick" class="tick" text x-small><v-icon x-small color="success">mdi-check</v-icon></v-btn>
        <v-btn label color="primary" v-show="setting === 'tick' && bookieCode === 'totalBalance'" x-small class="totalBalance mr-2" style="height: 18px !important;"><v-icon x-small color="success">mdi-check</v-icon></v-btn>
      </div>
      <v-tooltip bottom color="black" open-delay="300" transition="none" style="z-index:202; position:relative">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="setting === 'unavailable'" key="unavailable" class="unavailable" text x-small  v-bind="attrs" v-on="on">
              <v-icon small color="error">mdi-window-minimize</v-icon>
            </v-btn>
          </template>
          <span>Betting not available to this agency</span>
      </v-tooltip>
    </template>
    <v-card class="betBalanceMenu">
      <v-switch color="success" class="hideBalance-switch" label="Hide Balance" v-model="hideBalance"></v-switch>
      <v-btn v-show="bookieCode !== 'totalBalance'" @click="agencyLogout({ bookieCode })" color="error">Logout</v-btn>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import numeral from 'numeral'
export default {
  props: {
    bookieCode: String,
    balance: Number,
    setting: String,
    setHideBalance: Function
  },
  methods: {
    ...mapActions('account', ['agencyLogout']),
    getBalance: function () {
      return this.balance
    },
    numeral
  },
  computed: {
    hideBalance: {
      get () {
        return this.setting === 'tick'
      },
      set () {
        return this.setHideBalance(this.bookieCode)
      }
    }
  }
}
</script>

<style>
.betBalanceMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.balance {
  color:#2972ba;
  font-size: 12px;
  height: 100%;
}
.tick {
  max-width: 10px;
  max-height: 20px;
  padding: 0;
  margin: 0;
}
.unavailable {
  max-width: 10px;
  max-height: 20px;
  padding: 0;
  margin: 0;
}
</style>
