<template>
  <div class="betBalance">
    <div v-if="selection === 'nonBetting'"></div>
    <BetButton v-else-if="selection === 'betButton'" :setDialog="setDialog" />
    <BetBalanceMenu v-else-if="selection !== 'betButton'" :setting="selection" :bookieCode="bookieCode" :balance="balance" :setHideBalance="handleHideBalance"/>
    <v-dialog v-model="dialog" max-width="500" overlay-opacity="0.03">
      <v-card  class="bettingAgencyLogin">
        <v-list-item class="primary">
          <div :class="['spacerHeader-' + bookieCode]">
            <BookiesLogos :bookieCode="bookieCode" />
          </div>
          <v-list-item-content class="ml-3 white--text">
            <v-list-item-title class="headline ">Link betting account</v-list-item-title>
            <v-list-item-subtitle class="white--text">{{bookMakerTip(bookieCode)}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-btn icon small @click="cancel"><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-list-item>
        <v-container fluid class="text-center">
          <span class="subtitle-1 ">
            Please enter your <em>{{bookMakerTip(bookieCode)}}</em> account details
            <br>
          </span>
          <div v-if="error" class="subtitle-1 errorMessage">
                {{ error }}
          </div>
          <v-card class="loginDetails mt-5" elevation="0">
            <v-text-field
              class="username"
              label="Username"
              placeholder="Username"
              style="max-width:150px;"
              v-model="username"
              autocomplete="false"
            ></v-text-field>
            <v-text-field
              class="password"
              label="Password"
              placeholder="Password"
              style="max-width:150px;"
              type="password"
              v-model="password"
              autocomplete="false"
            ></v-text-field>
          </v-card>
          <div class="loginActions">
            <v-btn type="submit" class="loginButton" color="success" @click="login(username, password, bookieCode)">
              Login
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loginDialog" max-width="500" overlay-opacity="0.03">
      <v-card  class="bettingAgencyLogin" v-on:keyup.enter="enableBettingLogin(logedinUsername, password)">
        <v-list-item v-if="dialogSuccess" class="primary">
          <v-list-item-content class="ml-3 white--text">
            <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-flash</v-icon>Logged in</v-list-item-title>
          </v-list-item-content>
          <v-btn icon small @click="closeDialog"><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-list-item>
        <v-list-item v-if="!dialogSuccess" class="warning">
          <v-list-item-content class="ml-3 white--text">
            <v-list-item-title class="headline "><v-icon large class="white--text mr-3">mdi-account-key-outline</v-icon>Account Security</v-list-item-title>
          </v-list-item-content>
          <v-btn icon small @click="closeDialog"><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </v-list-item>
        <v-container v-if="dialogSuccess" fluid>
          <v-btn class="tick" text><v-icon color="success">mdi-check</v-icon></v-btn>
          Login successful
        </v-container>
        <v-container v-else fluid class="text-center">
          <span class="subtitle-1 ">
            To enable betting we need to confirm it's you<br>
            Please re-enter your <em>Dynamicodds</em> password
            <br>
          </span>
          <div v-if="error" class="subtitle-1 errorMessage">
                {{ error }}
          </div>
          <v-card class="loginDetails mt-5" elevation="0">
            <v-text-field
              class="username"
              label="Username"
              placeholder="Username"
              style="max-width:150px;"
              :value="logedinUsername"
            ></v-text-field>
            <v-text-field
              class="password"
              label="Password"
              placeholder="Password"
              style="max-width:150px;"
              type="password"
              v-model="password"
            ></v-text-field>
          </v-card>
          <div class="loginActions">
            <v-btn type="submit" class="loginButton" color="success" @click="enableBettingLogin(logedinUsername, password)">
              Login
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BetButton from './BetButton'
import BookiesLogos from './BookiesLogos'
import BetBalanceMenu from './menus/BetBalanceMenu'
import { BOOKMAKER_NAMES_LOOKUP } from '../common/constants'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    dialog: false,
    loginDialog: false,
    dialogSuccess: false,
    username: null,
    password: null,
    error: null
  }),
  props: {
    userAccount: Object,
    bookieCode: String,
    settings: Object,
    canBet: Boolean
  },
  components: {
    BetButton,
    BetBalanceMenu,
    BookiesLogos
  },
  computed: {
    ...mapGetters(['getAvailableAgencies', 'getRdcConfig']),
    ...mapGetters('account', ['getSessionId', 'getUsername']),
    hideBalance: function () {
      return this.settings.hideBalance
    },
    bettingEnabled: function () {
      return !!this.getSessionId
    },
    selection: function () {
      if (!this.canBet) {
        return 'nonBetting'
      }
      if (!this.bettingAvailable) {
        return 'unavailable'
      }
      if ((!this.bettingAvailable && this.getRdcConfig) || (this.bettingAvailable && this.getRdcConfig)) {
        return 'unavailable'
      }
      if (!this.bettingEnabled || (this.bettingAvailable && !this.userAccount)) {
        return 'betButton'
      }
      if (this.userAccount && !this.hideBalance) {
        return 'balance'
      }
      if (this.userAccount && this.hideBalance) {
        return 'tick'
      }
      return null
    },
    bookieName: function () {
      return BOOKMAKER_NAMES_LOOKUP[this.bookieCode]
    },
    bettingAvailable: function () {
      if (this.getAvailableAgencies) {
        const agency = this.getAvailableAgencies.find(a => a.agency_id === this.bookieCode)
        return agency && agency.enabled
      } else {
        return []
      }
    },
    logedinUsername: function () {
      return this.getUsername
    },
    balance: function () {
      return this.userAccount && this.userAccount.balance ? this.userAccount.balance : 0
    }
  },
  methods: {
    ...mapActions(['setHideBalance']),
    ...mapActions('account', ['confirmSignin', 'agencyLogin', 'agencyLogout']),
    ...mapActions(['loginBetting']),
    handleHideBalance: function () {
      this.setHideBalance(this.bookieCode)
    },
    setDialog () {
      const sessionId = this.getSessionId
      if (sessionId) {
        if (this.bookieCode === 'BF') {
          // const sessionId = this.getSessionId
          const url = `https://old.dynamicodds.com/xml/betfair/bf_login_popup.php?session_id=${sessionId}`
          window.open(url, '_blank') || window.location.replace(url)
        } else {
          this.dialogSuccess = false
          this.dialog = true
        }
      } else {
        this.dialogSuccess = false
        this.loginDialog = true
      }
    },
    bookMakerTip: function (bookMakerTipValue) {
      return BOOKMAKER_NAMES_LOOKUP[bookMakerTipValue]
    },
    cancel () {
      this.dialog = false
      this.username = null
      this.password = null
    },
    closeDialog () {
      this.loginDialog = false
    },
    async login (username, password, bookieCode) {
      const result = await this.agencyLogin({ username, password, bookieCode })
      if (result.success) {
        this.dialogSuccess = true
        setTimeout(() => {
          this.dialog = false
        }, 1500)
      } else {
        this.error = result.message
      }
    },
    async enableBettingLogin (username, password) {
      const result = await this.confirmSignin({ username, password })
      if (result.success) {
        this.loginBetting()
        this.dialogSuccess = true
        setTimeout(() => {
          this.loginDialog = false
        }, 1500)
      } else {
        this.error = result.message
      }
    }
  }
}
</script>

<style>
.betBalance {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedBookie {
  width: 50px;
  margin: 10px;
}
.loginDetails {
  height: 100px;
  display: flex;
  justify-content: space-around;
}
.loginActions {
  display: flex;
  justify-content: space-around;
}
.subtitle {
  text-align: center;
  margin-bottom: 15px;
}
.errorMessage {
  color: red;
  background-color: none;
}
</style>
