import Vue from 'vue'
import { format, startOfDay, isSameDay, isBefore, parseISO, formatISO, getUnixTime, eachDayOfInterval, addDays } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { filterRacesByShowHideRace, filterRacesByShowHideMeeting, filterRacesByCountry, filterRacesByCodes, filterRacesByMeetingType, filterRacesByStatuses, filterRacesByStarters, filterRacesByDistance, filterRacesByCondition, matchPriceToType, getColumnHeader, calculateBfBackCommission, calculateBfLayCommission, matchPoolToType, countdown, getOCCShift, isEmpty, returnUniqueElementsFromArray } from '../common/methods'
import { fetchColumnLayout, setColumnLayout, setUserFilter, fetchSpeedMap, setSpeedMap, resetSpeedMap, setCustomRaceData, fetchCustomRaceData, getLastRunnerComment, setCustomShowHideMeetingsData, setCustomShowHideRacesData, fetchCustomShowHideMeetingsData, fetchCustomShowHideRacesData, setMyRaces, setShowRacedayUpdates, fetchShowRacedayUpdates, batchFetch, getMyRaces, fetchUserFilter, setMarketMoverBookie, getMarketMoverBookie, setRacesGroupByMeeting, getRacesGroupByMeeting, fetchCustomShowHideMeetingTypesData, setCustomShowHideMeetingTypesData, batchFetchDynamicFormData, dynamicFormSetUserData, insertBlackbookEntry, deleteBlackbookEntry } from '../services/userConfig'
import gql from 'graphql-tag'
import getUserCountry from 'js-user-country'
import { defaultCountries, defaultCodes, defaultMeetingTypes, defaultStatuses, defaultStarters, defaultDistances, defaultConditions } from './userConfig'
import { getBettingAgencies, getAgencySettings, setAgencySettings } from '../services/betting'
import { addToWatchList, getPriceAlertsBtwDates, deletePriceAlert, addPriceAlert } from '../services/alerts'
import { meetingsDated, queryRace, queryRunner, queryRunnerDO, subscribeRaceUpdates, queryFlucs, queryFlucsBetFair, queryPoolHistory, queryPrices, subscribePrices, subscribeResultsQuery, speedMapQuery, queryBetFairPrices, subscribeBetFairPrices, priceAlertInfoQuery, meetingsDatedAz, subscribeDetailedRace, basicRaceQuery, queryExotics, subscribeScratching } from '../common/queries'
import { getDynamicRaceData } from '../services/infoFields'
import { apolloClient } from '../vue-apollo'
import socket from './socket'
import { BET_STATUS_CODE, SHOW_MARKET_PERCENTAGE, AU_COLUMNS, UK_COLUMNS, COLUMN_TYPE_FLUCS, PRICE_ALERT_BOOKIES, ALL_BOOKIES_CODES, BOOKMAKER_FLUC_TYPE, RUNNER_INFO_COLUMNS, BOOKMAKER_CODE_NAMES, DELETED_BOOKIES } from '../common/constants'
import { columnMigration } from '../utils/migrations'
import axios from 'axios'

const store = {
  state: {
    apiKey: null,
    acMode: false,
    selectedRace: {
      id: null,
      MeetingID: null,
      RaceNumber: null
    },
    selectedNavRace: {
      id: null,
      MeetingID: null,
      RaceNumber: null
    },
    selectRaceNavSet: 0,
    setHeight: {},
    meetings: {},
    races: {},
    version: {
      stale: false
    },
    announcement: {
      message: '',
      stale: false
    },
    todaysRaces: {},
    detailedRaces: {},
    detailedRunner: {},
    exoticsResults: {},
    prices: {},
    flucs: {},
    poolHistory: {},
    columnFlucs: {},
    betFairPrices: {},
    selectedDate: startOfDay(Date.now()),
    todaysDate: startOfDay(Date.now()),
    selectedDateMeetingSummary: startOfDay(Date.now()),
    mobile: false,
    testMode: false,
    editMode: false,
    noFeatureAlert: false,
    enableBettingAlert: false,
    openMultiBetSlip: false,
    priceSubscription: null,
    betFairPriceSubscription: null,
    resultsSubscription: null,
    detailedRaceSubscription: null,
    raceSubscription: null,
    scratchingSubscription: null,
    availableAgencies: [],
    agencieBalances: [],
    dynamicRaceData: [],
    sessionId: null,
    raceAlertHistory: {},
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false
    },
    urlLocation: '',
    agencySettings: {},
    dialogWatchlist: false,
    dialogAddToWatchlist: false,
    customRaceData: {},
    meetingSummaryMeetings: {},
    meetingSummaryRaces: {},
    prevComments: {},
    customShowHideMeetingsData: {},
    customShowHideRacesData: {},
    customShowHideMeetingTypesData: {},
    myRaces: [],
    raceAlertsDate: null,
    priceAlerts: {},
    priceAlertRunner: null,
    raceDayUpdates: [],
    raceDayUpdatesEnable: true,
    watchListPriceSubscriptions: {},
    priceAlertPrices: {},
    newPriceAlerts: {},
    azListRunners: { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] },
    azListJockeysDrivers: { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] },
    azListTrainers: { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] },
    azSearchResults: [],
    azList: [],
    raceLoading: false,
    noRaces: false,
    betFairTotalTraded: {},
    loadAZListData: false,
    raceAlerts: [],
    marketMoverBookie: BOOKMAKER_CODE_NAMES['Sportsbet'],
    marketMover: {},
    selectedBookie: BOOKMAKER_CODE_NAMES['Sportsbet'],
    sessionIdInterval: null,
    racesGroupByMeeting: false,
    betHistorySearchResults: [],
    raceIDAlerts: [],
    selectedBookieColumns: [],
    myRacesFull: [],
    exoticsRaceSelected: [],
    showNoFAAccess: false,
    isAutoHide: false,
    ogPriceAlerts: [],
    runnerBlackbook: [],
    jockeyBlackbook: [],
    trainerBlackbook: [],
    runnerBlackbookComments: {},
    jockeyBlackbookComments: {},
    trainerBlackbookComments: {},
    didSomethingGoWrong: false,
    isDualAcceptances: false,
    dualAcceptances: [],
    dualAcceptancesHasLoaded: false,
    myCompetitorPricesFromDB: {},
    myCompetitorRatingsFromDB: {},
    marketRange: 5.0,
    marketPercentage: 100,
    oddsToUse: 'calculated',
    oddsFormat: 'decimal',
    ratingToUse: 'dry'
  },
  mutations: {
    SOCKET_ONOPEN (state, event) {
      Vue.prototype.$socket = event.currentTarget
      socket.authenticate(
        state.account.user
          .getSignInUserSession()
          .getAccessToken()
          .getJwtToken()
      )

      socket.subscribeRaceAlerts(state.account.user.username)
      socket.subscribeAppVersion()
      socket.subscribeBroadcastMessage()
    },
    SOCKET_RECONNECT (state, count) {
      if (state.apiKey) {
        socket.authenticate({ apiKey: state.apiKey })
      } else {
        socket.authenticate({
          token: state.account.user
            .getSignInUserSession()
            .getAccessToken()
            .getJwtToken()
        })
        socket.subscribeRaceAlerts(state.account.user.username)
        socket.subscribeAppVersion()
      }
        axios.get(`${process.env.VUE_APP_ENDPOINT_API}/public/getVersion`).then(response => {
          const version = response.data.version
          const domVersion = document.getElementById('buildVersionNumber')?.innerText
          if (domVersion !== version && version.length >= 1) {
            Vue.set(state.version, 'stale', true)
          }
        })
    },
    SOCKET_RECONNECT_ERROR (state) {
    },
    SOCKET_ONMESSAGE (state, msg) {
      const version = msg?.payload?.version
      const announcement = msg?.payload?.announcement
      if (typeof version !== 'undefined' && version.length >= 1) {
        const domVersion = document.getElementById('buildVersionNumber')?.innerText
        if (domVersion !== version) {
          Vue.set(state.version, 'stale', true)
        }
      } if (typeof announcement !== 'undefined' && announcement.length >= 1) {
          Vue.set(state.announcement, 'message', announcement)
          Vue.set(state.announcement, 'stale', true)
      } else if (msg.payload.bet_id) {
        const bet = state.betting.betHistory.find(b => b.bet.bet_id === msg.payload.bet_id)
        const index = state.betting.betHistory.findIndex(b => b.bet.bet_id === msg.payload.bet_id)
        const updatedBet = bet ? { ...bet } : {}
        updatedBet.bet = msg.payload
        updatedBet.bet.status_text = BET_STATUS_CODE[updatedBet.bet.status_code]
        const updatedHistory = [...state.betting.betHistory]
        // updatedHistory[index] = updatedBet
        updatedHistory.splice(index, 1, updatedBet)
        // Vue.set(state, 'betHistory', updatedHistory)
        state.betting.betHistory = updatedHistory
        const bet2 = state.betting.allBets.find(b => b.bet && b.bet.bet_id === msg.payload.bet_id)
        const index2 = state.betting.allBets.findIndex(b => b.bet && b.bet.bet_id === msg.payload.bet_id)
        const updatedBet2 = bet2 ? { ...bet2 } : {}
        updatedBet2.bet = msg.payload
        updatedBet2.status = msg.payload.status_code
        const updatedBetHistory2 = [...state.betting.allBets]
        updatedBetHistory2.splice(index2, 1, updatedBet2)
        state.betting.allBets = updatedBetHistory2
      } else if (msg.payload && msg.payload.triggered_date && msg.payload.triggered_date.seconds) {
        const alert = state.priceAlerts[msg.payload.alert_id]
        // console.log(msg)
        // console.log(alert)
        const updatedAlert = Object.assign({}, alert, msg.payload)
        // console.log(updatedAlert)
        Vue.set(state.newPriceAlerts, msg.payload.alert_id, updatedAlert)
        Vue.set(state.priceAlerts, msg.payload.alert_id, updatedAlert)
      } else {
        const key = msg.payload.alert_id + '-' + msg.payload.time_before_jump
        Vue.set(state.raceAlertHistory, key, msg.payload)
      }
    },
    SOCKET_ONERROR (state) {
      if (state.apiKey) {
        socket.authenticate({ apiKey: state.apiKey })
      } else {
        socket.authenticate({
          token: state.account.user
            .getSignInUserSession()
            .getAccessToken()
            .getJwtToken()
        })
        socket.subscribeRaceAlerts(state.account.user.username)
      }
    },
    SOCKET_ONCLOSE (state) {
    },
    clearRaces (state) {
      if (state.selectedRace.id !== null) {
        const detRace = state.detailedRaces[state.selectedRace.id]
        const keepMeetings = Object.values(state.meetings).filter(meeting => meeting.id === detRace.meeting.id)
        const keepRaces = Object.values(state.races).filter(race => race.meeting.id === detRace.meeting.id)
        const racesObject = {}
        keepRaces.forEach((race) => {
          racesObject[race.id] = race
        })
        const meetingObject = {}
        keepMeetings.forEach((meeting) => {
          meetingObject[meeting.id] = meeting
        })

        state.raceIDAlerts.forEach(raceID => {
          const race = state.races[raceID]
          if (race?.status === 'OPEN') {
            racesObject[raceID] = race
          }
        })

        state.meetings = meetingObject
        state.races = racesObject
      } else {
        state.meetings = {}
        state.races = {}
      }
    },
    saveRaceIDAlerts (state, { raceID }) {
      state.raceIDAlerts.push(raceID)
    },
    clearRaceIDAlert (state, { raceID }) {
      const index = state.raceIDAlerts.indexOf(raceID)
      if (index > -1) {
        state.raceIDAlerts.splice(index, 1)
      }
    },
    clearAllRaceIDAlerts (state) {
      state.raceIDAlerts = []
    },
    clearTodaysRaces (state) {
      state.todaysRaces = {}
    },
    clearRunners (state) {
      if (state.selectedRace.id && state.detailedRaces[state.selectedRace.id]) {
        Vue.set(state.detailedRaces, state.selectedRace.id, {})
      }
      state.detailedRunner = {}
    },
    setUrlLocation (state, loc) {
      state.urlLocation = loc
    },
    clearPrices (state) {
      state.prices = {}
    },
    clearColumnFlucs (state) {
      state.columnFlucs = {}
    },
    clearRaceAlertHistory (state, value) {
      Vue.delete(state.raceAlertHistory, value)
    },
    clearMeetingSummaryRaces (state) {
      state.meetingSummaryRaces = {}
    },
    clearAzLists (state) {
      state.azListRunners = { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] }
      state.azListJockeysDrivers = { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] }
      state.azListTrainers = { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] }
      state.azList = []
    },
    clearAzSearchResults (state) {
      state.azSearchResults = []
    },
    setSelectedBookie (state, { bookie }) {
      state.selectedBookie = bookie
    },
    setMarketMover (state, marketMover) {
      state.marketMover = marketMover
    },
    setMarketMoverBookie (state, { bookie }) {
      state.marketMoverBookie = bookie
    },
    updateLoadAZListData (state, value) {
      state.loadAZListData = value
    },
    updatePrice (state, { price }) {
      const key = `${price.id}:${price.source}:${price.type}`
      const race = state.detailedRaces[price.id]
      const scratched = []
      if (race && race.competitors) {
        race.competitors.forEach(x => {
          if (x.scratched) {
            scratched.push(x.tabNo)
          }
        })
      }
      const prices = [...price.prices]
      const filteredPrices = [...price.prices.filter(price => !scratched.includes(price.tabNo))]
      let marketPercentage = 0
      filteredPrices.forEach(x => {
        if (x.price) {
          marketPercentage += 1 / x.price
        }
      })
      prices.MarketPercentage = marketPercentage * 100.0
      prices.poolSize = price.poolSize
      if (price.updated) {
        prices.updated = parseISO(price.updated)
      }
      prices.type = price.type
      prices.source = price.source
      Object.freeze(prices)
      Vue.set(state.prices, key, prices)

      // update columnFlucs
      const columnKey = `${price.id}:${price.source}:${price.type}`
      if (state.columnFlucs[columnKey]) {
        const columnFlucs = { ...state.columnFlucs }
        prices.forEach(runner => {
          const runnerArray = columnFlucs[columnKey][runner.tabNo - 1] ? columnFlucs[columnKey][runner.tabNo - 1].flucs : null
          if (runnerArray && runnerArray[runnerArray.length - 1] && runnerArray[runnerArray.length - 1].price !== runner.price) {
            columnFlucs[columnKey][runner.tabNo - 1].flucs.push({ tabNo: runner.tabNo, price: runner.price, time: formatISO(Date.now()) })
            Object.freeze(columnFlucs[columnKey][runner.tabNo - 1])
          }
        })
        state.columnFlucs = columnFlucs
      }
      // update priceFlucs
      const priceKey = `${price.id}:${price.source}:${price.type}`
      if (state.flucs[priceKey]) {
        const flucs = { ...state.flucs }
        prices.forEach(runner => {
          const runnerArray = flucs[priceKey][runner.tabNo - 1] ? flucs[priceKey][runner.tabNo - 1].flucs : null
          if (runnerArray && runnerArray[runnerArray.length - 1] && runnerArray[runnerArray.length - 1].price !== runner.price) {
            flucs[priceKey][runner.tabNo - 1].flucs.push({ tabNo: runner.tabNo, price: runner.price, time: formatISO(Date.now()) })
            Object.freeze(flucs[priceKey][runner.tabNo - 1])
          }
        })
        state.flucs = flucs
      }
    },
    updatePriceSubscription (state, { subscription }) {
      if (state.priceSubscription) {
        state.priceSubscription.unsubscribe()
      }
      state.priceSubscription = subscription
    },
    updateBetFairPriceSubscription (state, { subscription }) {
      if (state.betFairPriceSubscription) {
        state.betFairPriceSubscription.unsubscribe()
      }
      state.betFairPriceSubscription = subscription
    },
    updateResultsSubscription (state, { subscription }) {
      if (state.resultsSubscription) {
        state.resultsSubscription.unsubscribe()
      }
      state.resultsSubscription = subscription
    },
    updateRaceSubscription (state, { subscription }) {
      if (state.raceSubscription) {
        state.raceSubscription.unsubscribe()
      }
      state.raceSubscription = subscription
    },
    updateScratchingSubscription (state, { subscription }) {
      if (state.scratchingSubscription) {
        state.scratchingSubscription.unsubscribe()
      }
      state.scratchingSubscription = subscription
    },
    updateDetailedRaceSubscription (state, { subscription }) {
      if (state.detailedRaceSubscription) {
        state.detailedRaceSubscription.unsubscribe()
      }
      state.detailedRaceSubscription = subscription
    },
    updateDetailedRace (state, { race }) {
      const key = `${race.id}`
      if (race.results && race.results.length > 0) {
        race.competitors.forEach(c => {
          const result = race.results[0].positions.find(p => p.tabNo === c.tabNo)
          if (result) {
            c.result = result.position
          }
        })
      }
      // Object.freeze(race)
      Vue.set(state.detailedRaces, key, race)
    },
    updateDetailedRunner (state, { runner }) {
      if (!runner) {
        return
      }
      const key = `${runner.id}`
      Vue.set(state.detailedRunner, key, runner)
    },
    updateExoticsResults (state, { exResults }) {
      state.exoticsResults = exResults
    },
    updateFlucs (state, { flucs, source, id, type }) {
      if (!flucs) {
        return
      }
      const sortedFlucs = flucs.map(f => {
        const sorted = f.flucs.sort(
          (b, a) => b.time - a.time
        ).filter((e, i, arr) => {
          if (!arr[i - 1]) {
            return e
          }
          return (e.price !== arr[i - 1].price)
        })
        return { flucs: sorted }
      })
      const key = `${id}:${source}:${type}`
      Vue.set(state.flucs, key, sortedFlucs)
    },
    updatePoolHistory (state, { poolHistory, source, id, type }) {
      if (!poolHistory) {
        return
      }
      const sortedPool = poolHistory.map(f => {
        let maxCompetitor = 0
        f.prices.forEach(x => { if (x.tabNo > maxCompetitor) { maxCompetitor = x.tabNo } })
        let sorted = []
        for (let i = 1; i <= maxCompetitor; i++) {
          sorted[i - 1] = f.prices.find(x => x.tabNo === i)
          if (!sorted[i - 1]) {
            sorted[i - 1] = { tabNo: i, price: '-' }
          }
        }
        return {
          prices: sorted,
          poolSize: f.poolSize,
          updated: f.updated
        }
      }).reverse()
      const key = `${id}:${source}:${type}`
      Vue.set(state.poolHistory, key, sortedPool)
    },
    setColumnFlucs (state, { flucs, source, id, priceType }) {
      if (!flucs) {
        return
      }
      const sortedFlucs = flucs.map(f => {
        const sorted = !Array.isArray(f.flucs) ? [f.flucs] : f.flucs.sort(
          (b, a) => b.time - a.time
        ).filter(p => p.price !== 0).filter((e, i, arr) => {
          if (!arr[i - 1]) {
            return e
          }
          return (e.price !== arr[i - 1].price)
        })
        return { flucs: sorted }
      })
      const key = `${id}:${source}:${priceType}`
      Vue.set(state.columnFlucs, key, sortedFlucs)
    },
    updateRaceDetail (state, { detailedRace }) {
      const key = `${detailedRace.id}`
      Vue.set(state.detailedRaces, key, detailedRace)
    },
    updateRace (state, { raceUpdate }) {
      const key = `${raceUpdate.id}`
      const race = state.races[key]
      const meeting = raceUpdate.meeting
      meeting.externalIDs = race.meeting.externalIDs
      const newRace = { ...raceUpdate, meeting }
      if (race) {
        // Object.freeze(raceUpdate)
        Vue.set(state.races, key, newRace)
      }
      const todayRace = state.todaysRaces[key]
      if (todayRace) {
        // Object.freeze(todayRace)
        Vue.set(state.todaysRaces, key, newRace)
      }
      // const detailedRace = state.detailedRaces[key]
      // if (detailedRace) {
      //   if (raceUpdate.competitors && raceUpdate.competitors.length > 0) {
      //     const competitors = [...detailedRace.competitors]
      //     raceUpdate.competitors.forEach(competitor => {
      //       const index = competitors.findIndex(c => c.tabNo === competitor.tabNo)
      //       if (index) {
      //         competitors[index] = competitor
      //       }
      //     })
      //     const newDetailedRace = { ...raceUpdate, competitors }
      //     Vue.set(state.detailedRaces, key, newDetailedRace)
      //   }
      //   const newDetailedRace = { ...raceUpdate, competitors: [...detailedRace.competitors] }
      //   Vue.set(state.detailedRaces, key, newDetailedRace)
      // }
    },
    updateResults (state, { results }) {
      const key = `${results.raceId}`
      const race = state.races[key]

      const resultsArray = []
      resultsArray.push(results)

      if (race) {
        const newRace = { ...race, status: results.status, results: resultsArray }
        Vue.set(state.races, key, newRace)
        if (isSameDay(parseISO(newRace.meeting.date), startOfDay(Date.now()))) {
          Vue.set(state.todaysRaces, key, newRace)
        }
      }
      const detailedRace = state.detailedRaces[key]
      if (detailedRace) {
        const newDetailedRace = { ...detailedRace, status: results.status, results: resultsArray }
        Vue.set(state.detailedRaces, key, newDetailedRace)
      }
    },
    updateRaceSummary (state, { race, meeting }) {
      const newMeeting = meeting || state.meetings[race.meeting.id]
      state.raceAlertsDate = meeting?.date
      const key = `${race.id}`
      Vue.set(state.meetings, newMeeting.id, newMeeting)
      const newRace = {
        ...race,
        isoStartTime: parseISO(race.startTime),
        meeting: newMeeting
      }
      // Object.freeze(newRace)
      if (isSameDay(parseISO(newMeeting.date), startOfDay(Date.now()))) {
        Vue.set(state.todaysRaces, key, newRace)
      }
      if (isSameDay(parseISO(newMeeting.date), state.selectedDate)) {
        Vue.set(state.races, key, newRace)
      }
    },
    updateRaceCountdown (state) {
      const races = state.races
      Object.keys(races).forEach((b) => {
        const race = races[b]
        const time = getUnixTime(parseISO(race.startTime))
        let formattedStartTime = countdown(time)
        Vue.set(race, 'formattedStartTime', formattedStartTime)
      })
    },
    updateRaceMeetingSummary (state, { race, meeting }) {
      Vue.set(state.meetingSummaryMeetings, meeting.id, meeting)
      const newMeeting = {}
      newMeeting.id = meeting.id
      newMeeting.track = meeting.track
      newMeeting.type = meeting.type
      newMeeting.externalIDs = meeting.externalIDs
      newMeeting.tabIndicator = meeting.tabIndicator
      const newRace = {
        ...race,
        isoStartTime: parseISO(race.startTime),
        meeting: newMeeting
      }

      // Object.freeze(newRace)
      Vue.set(state.meetingSummaryRaces, race.id, newRace)
    },
    setDate (state, d) {
      const date = zonedTimeToUtc(startOfDay(d), 'GMT')
      state.selectedDate = date
    },
    setTodaysDate (state, d) {
      state.todaysDate = d
    },
    setDateMeetingSummary (state, d) {
      const date = zonedTimeToUtc(d, 'GMT')
      state.selectedDateMeetingSummary = date
    },
    selectRace (state, { id }) {
      state.selectedRace = {}
      state.selectedRace.id = id
    },
    selectNavRace (state, { id }) {
      state.selectedNavRace.id = id
    },
    setApiKey (state, key) {
      state.apiKey = key
    },
    setMobile (state, v) {
      state.mobile = v
    },
    setEditMode (state) {
      state.editMode = !state.editMode
    },
    showNoFeatureAlert (state, m) {
      state.noFeatureAlert = m
    },
    showBettingAlert (state, m) {
      state.enableBettingAlert = m
    },
    setSelectRaceNav (state, m) {
      state.selectRaceNavSet = m
    },
    showThisMultiBetSlip (state, m) {
      state.openMultiBetSlip = m
    },
    socketOpen (state) {
      Vue.set(state.socket, 'isConnected', true)
    },
    socketClosed (state) {
      Vue.set(state.socket, 'isConnected', false)
    },
    setBettingAgencies (state, agencies) {
      state.availableAgencies = agencies
    },
    setDynamicRaceData (state, { data }) {
      state.dynamicRaceData = data
    },
    setAgencySettings (state, agencySettings) {
      state.agencySettings = agencySettings
    },
    updateAgencySettings (state, { key, agency }) {
      Vue.set(state.agencySettings, key, agency)
    },
    setThisMatchHeight (state, m) {
      state.setHeight = m
    },
    setAcMode (state, m) {
      state.acMode = m
    },
    setWatchlist (state) {
      state.dialogWatchlist = !state.dialogWatchlist
    },
    setAddToWatchlist (state) {
      state.dialogAddToWatchlist = !state.dialogAddToWatchlist
    },
    setAddPriceAlertProps (state, watchlist) {
      Vue.set(state, 'priceAlertRunner', watchlist)
    },
    clearBetFairPrices (state) {
      state.betFairPrices = {}
      // state.betFairTotalTraded = {}
    },
    updateBetFairPrice (state, { id, runner, priceType, price }) {
      const key = `${id}:${runner.number}:${priceType}`
      const updatedRunner = { ...runner }
      updatedRunner.priceType = priceType
      Object.freeze(updatedRunner)
      Vue.set(state.betFairPrices, key, updatedRunner)
      // update columnFlucs
      // const columnKey = `${id}:BF:WIN`
      // console.log(state.columnFlucs[columnKey])
      // if (state.columnFlucs[columnKey]) {
      //   const columnFlucs = { ...state.columnFlucs }
      //   prices.forEach(runner => {
      //     const runnerArray = columnFlucs[columnKey][runner.tabNo - 1] ? columnFlucs[columnKey][runner.tabNo - 1].lastTradedFlucs : null
      //     if (runnerArray && runnerArray[runnerArray.length - 1] && runnerArray[runnerArray.length - 1].price !== runner.price) {
      //       columnFlucs[columnKey][runner.tabNo - 1].flucs.push({ tabNo: runner.tabNo, price: runner.price, time: formatISO(Date.now()) })
      //       Object.freeze(columnFlucs[columnKey][runner.tabNo - 1])
      //     }
      //   })
      //   state.columnFlucs = columnFlucs
      // }
    },
    updateBetFairFlucs (state, { id, prices }) {
      // update columnFlucs
      // const columnKey = `${id}:BF:WIN`
      // if (state.columnFlucs[columnKey]) {
      //   const columnFlucs = { ...state.columnFlucs }
      //   prices.forEach(runner => {
      //     const runnerArray = columnFlucs[columnKey].find(f => f.flucs?.[0]?.tabNo === runner.tabNo) || null
      //     if (runnerArray && runnerArray[runnerArray.length - 1] && runnerArray[runnerArray.length - 1].price !== runner.price) {
      //       columnFlucs[columnKey][runner.tabNo - 1].flucs.push({ tabNo: runner.tabNo, price: runner.price, time: formatISO(Date.now()) })
      //       Object.freeze(columnFlucs[columnKey][runner.tabNo - 1])
      //     }
      //   })
      //   state.columnFlucs = columnFlucs
      // }
      // // update priceFlucs
      // if (state.flucs[columnKey]) {
      //   const flucs = { ...state.flucs }
      //   prices.forEach(runner => {
      //     const runnerArray = flucs[columnKey].find(f => f.flucs?.[0]?.tabNo === runner.tabNo) || null
      //     if (runnerArray && runnerArray[runnerArray.length - 1] && runnerArray[runnerArray.length - 1].price !== runner.price) {
      //       flucs[columnKey][runner.tabNo - 1].flucs.push({ tabNo: runner.tabNo, price: runner.price, time: formatISO(Date.now()) })
      //       Object.freeze(flucs[columnKey][runner.tabNo - 1])
      //     }
      //   })
      //   state.flucs = flucs
      // }
    },
    setCustomData (state, { id, value }) {
      state.customRaceData = {}
      state.customRaceData[id] = value
    },
    setCustomShowHideMeetingsData (state, { id, value }) {
      // state.customShowHideMeetingsData = {}
      // state.customShowHideMeetingsData[id] = value
      Vue.set(state.customShowHideMeetingsData, id, value)
    },
    setCustomShowHideRacesData (state, { id, value }) {
      // state.customShowHideRacesData = {}
      // state.customShowHideRacesData[id] = value
      Vue.set(state.customShowHideRacesData, id, value)
    },
    setCustomShowHideMeetingTypesData (state, { id, value }) {
      // state.customShowHideMeetingTypesData = {}
      // state.customShowHideMeetingTypesData[id] = value
      Vue.set(state.customShowHideMeetingTypesData, id, value)
    },
    setRacedayUpdatesEnabled (state, value) {
      state.raceDayUpdatesEnable = value
    },
    setPrevComments (state, value) {
      state.prevComments = value
    },
    setPrevComment (state, { tabNo, comment }) {
      Vue.set(state.prevComments, tabNo, comment)
    },
    addToMyRaces (state, { races }) {
      state.myRaces = races
    },
    removeFromMyRaces (state, { races }) {
      state.myRaces = state.myRaces.filter(id => !races.includes(id))
      const filteredRaces = state.myRacesFull.filter(race => !races.includes(race.id))
      state.myRacesFull = filteredRaces
    },
    AddToMyRacesFull (state, { race }) {
      const races = [...state.myRacesFull].filter(r => r.id !== race.id)
      races.push(race)
      state.myRacesFull = races
    },
    setPriceAlerts (state, { priceAlerts }) {
      state.priceAlerts = priceAlerts
    },
    setRacedayUpdates (state, { meetingDate, raceId, raceNumber, dateUpdate, raceName, updateType, oldValue, newValue }) {
      const todayDate = format(new Date(), 'yyyy-MM-dd')
      if (format(new Date(meetingDate), 'yyyy-MM-dd') === todayDate) {
        state.raceDayUpdates.push({ raceId: raceId, raceNumber: raceNumber, dateUpdate: new Date(dateUpdate), raceName: raceName, updateType: updateType, oldValue: oldValue, newValue: newValue })
      }
    },
    clearRaceDayUpdates (state) {
      state.raceDayUpdates = []
    },
    clearPriceAlerts (state) {
      state.priceAlerts = {}
    },
    addPriceAlert (state, { priceAlert }) {
      // Object.freeze(priceAlert)
      Vue.set(state.priceAlerts, priceAlert.alert_id, priceAlert)
    },
    addPriceAlertPrice (state, { priceAlert, price }) {
      const key = `${priceAlert.alert_id}`
      const prices = state.priceAlertPrices[key] || {}
      prices[price.type] = price
      Vue.set(state.priceAlertPrices, key, prices)
    },
    updateWatchListPriceSubscriptions (state, { id, subscription }) {
      if (state.watchListPriceSubscriptions && state.watchListPriceSubscriptions[id]) {
        state.watchListPriceSubscriptions[id].unsubscribe()
        state.watchListPriceSubscriptions[id] = subscription
      }
    },
    unsubscribeWatchListPrices (state) {
      Object.values(state.watchListPriceSubscriptions).forEach(s => s.unsubscribe())
      state.watchListPriceSubscriptions = {}
    },
    removePriceAlert (state, { alertId }) {
      Vue.delete(state.priceAlerts, alertId)
      Vue.delete(state.priceAlertPrices, alertId)
      if (state.watchListPriceSubscriptions[alertId]) state.watchListPriceSubscriptions[alertId].unsubscribe()
    },
    setPriceAlertTriggered (state, { alertId }) {
      const alert = { ...state.newPriceAlerts[alertId], triggered: true }
      Vue.set(state.newPriceAlerts, alertId, alert)
    },
    addAzList (state, az) {
      state.azList.push({
        meetingType: az?.meetingType,
        runner: az?.runner,
        jockeyDriver: az?.jockeyDriver,
        trainer: az?.trainer,
        race: az?.race,
        date: az?.date,
        competitorNumber: az?.competitorNumber,
        barrier: az?.barrier,
        handicap: az?.handicap,
        raceID: az?.raceID,
        startTime: az?.startTime,
        status: az?.status,
        raceName: az?.raceName,
        raceNumber: az?.raceNumber,
        formHistory: az?.formHistory,
        runnerId: az?.runnerId,
        externalMeetingID: az?.externalMeetingID,
        tabNo: az?.tabNo,
        raceCountry: az?.raceCountry,
        weight: az?.weight,
        scratched: az?.scratched,
        distance: az?.distance
      })

      if (typeof state.azListJockeysDrivers[az?.runner.charAt(0)] !== 'undefined') {
        state.azListRunners[az?.runner.charAt(0)].push({
          meetingType: az?.meetingType,
          runner: az?.runner,
          jockeyDriver: az?.jockeyDriver,
          trainer: az?.trainer,
          race: az?.race,
          date: az?.date,
          competitorNumber: az?.competitorNumber,
          barrier: az?.barrier,
          handicap: az?.handicap,
          raceID: az?.raceID,
          startTime: az?.startTime,
          status: az?.status,
          raceName: az?.raceName,
          raceNumber: az?.raceNumber,
          formHistory: az?.formHistory,
          runnerId: az?.runnerId,
          externalMeetingID: az?.externalMeetingID,
          tabNo: az?.tabNo,
          raceCountry: az?.raceCountry,
          weight: az?.weightTotal,
          scratched: az?.scratched,
          distance: az?.distance
        })
      }

      if (typeof state.azListJockeysDrivers[az?.jockeyDriver.charAt(0)] !== 'undefined') {
        state.azListJockeysDrivers[az?.jockeyDriver.charAt(0)].push({
          meetingType: az?.meetingType,
          runner: az?.runner,
          jockeyDriver: az?.jockeyDriver,
          trainer: az?.trainer,
          race: az?.race,
          date: az?.date,
          competitorNumber: az?.competitorNumber,
          barrier: az?.barrier,
          handicap: az?.handicap,
          raceID: az?.raceID,
          startTime: az?.startTime,
          status: az?.status,
          raceName: az?.raceName,
          raceNumber: az?.raceNumber,
          formHistory: az?.formHistory,
          runnerId: az?.runnerId,
          externalMeetingID: az?.externalMeetingID,
          tabNo: az?.tabNo,
          raceCountry: az?.raceCountry,
          weight: az?.weightTotal,
          scratched: az?.scratched,
          distance: az?.distance
        })
      }

      if (typeof state.azListJockeysDrivers[az?.trainer.charAt(0)] !== 'undefined') {
        state.azListTrainers[az?.trainer.charAt(0)].push({
          meetingType: az?.meetingType,
          runner: az?.runner,
          jockeyDriver: az?.jockeyDriver,
          trainer: az?.trainer,
          race: az?.race,
          date: az?.date,
          competitorNumber: az?.competitorNumber,
          barrier: az?.barrier,
          handicap: az?.handicap,
          raceID: az?.raceID,
          startTime: az?.startTime,
          status: az?.status,
          raceName: az?.raceName,
          raceNumber: az?.raceNumber,
          formHistory: az?.formHistory,
          runnerId: az?.runnerId,
          externalMeetingID: az?.externalMeetingID,
          tabNo: az?.tabNo,
          raceCountry: az?.raceCountry,
          weight: az?.weightTotal,
          scratched: az?.scratched,
          distance: az?.distance
        })
      }
    },
    setRaceLoading (state, { loading }) {
      state.raceLoading = loading
    },
    setNoRaces (state, { loading }) {
      state.noRaces = loading
    },
    updateBetFairTotalTraded (state, { id, priceType, totalTraded }) {
      const key = `${id}:${priceType}`
      Vue.set(state.betFairTotalTraded, key, totalTraded)
    },
    setRaceAlerts (state, { raceAlerts }) {
      state.raceAlerts = raceAlerts
    },
    setSessionIdInterval (state, { interval }) {
      if (state.sessionIdInterval) {
        state.sessionIdInterval.clear()
      }
      state.sessionIdInterval = interval
    },
    setRacesGroupByMeeting (state, { value }) {
      state.racesGroupByMeeting = value
    },
    setExoticsRaceSelected (state, { exotics }) {
      state.exoticsRaceSelected = exotics
    },
    setShowNoFAAccess (state, { value }) {
      state.showNoFAAccess = value
    },
    updateIsCheckedState (state, isChecked) {
      state.isAutoHide = isChecked
    },
    setOriginalPriceAlert (state, { priceAlerts }) {
      state.ogPriceAlerts = priceAlerts
    },
    updateDynamicFormData (state, { userData }) {
      if (userData.runnerBlackbook) {
        const blackbook = JSON.parse(userData.runnerBlackbook)
        state.runnerBlackbook = blackbook
      }

      if (userData.jockeyBlackbook) {
        const blackbook = JSON.parse(userData.jockeyBlackbook)
        state.jockeyBlackbook = blackbook
      }

      if (userData.trainerBlackbook) {
        const blackbook = JSON.parse(userData.trainerBlackbook)
        state.trainerBlackbook = blackbook
      }

      if (userData.runnerBlackbookComments) {
        const comments = JSON.parse(userData.runnerBlackbookComments)
        state.runnerBlackbookComments = comments
      }

      if (userData.jockeyBlackbookComments) {
        const comments = JSON.parse(userData.jockeyBlackbookComments)
        state.jockeyBlackbookComments = comments
      }

      if (userData.trainerBlackbookComments) {
        const comments = JSON.parse(userData.trainerBlackbookComments)
        state.trainerBlackbookComments = comments
      }
    },
    removeRunnerBlackbook (state, { runnerBlackbook }) {
      state.runnerBlackbook = runnerBlackbook
    },
    updateRunnerBlackbookComments (state, { comments }) {
      state.runnerBlackbookComments = comments
    },
    updateTrainerBlackbookComments (state, { comments }) {
      state.trainerBlackbookComments = comments
    },
    updateJockeyBlackbookComments (state, { comments }) {
      state.jockeyBlackbookComments = comments
    },
    removeJockeyFromBlackbook (state, { jockeyBlackbook }) {
      state.jockeyBlackbook = jockeyBlackbook
    },
    removeTrainerFromBlackbook (state, { trainerBlackbook }) {
      state.trainerBlackbook = trainerBlackbook
    },
    updateRunnerBlackbook (state, { blackbook, initialLoad }) {
      if (initialLoad) {
        state.runnerBlackbook = blackbook
      } else {
        state.runnerBlackbook.push(blackbook)
      }
    },
    updatedidSomethingGoWrong (state, { boolean }) {
      state.didSomethingGoWrong = boolean
    },
    updateDualAccepteances (state, { boolean }) {
      state.isDualAcceptances = boolean
    },
    updateDualAcceptances (state, { azList }) {
      // Only thoroughbred runners that has a status of `OPEN` - Australia/New Zealand Races (Demestic Races Only)
      const thoroughbred = azList?.filter(co => {
        return co.meetingType === 'THOROUGHBRED' && co.status === 'OPEN' && (co.raceCountry === 'AUS' || co.raceCountry === 'NZL')
      })

      // Runner Id collector
      const runnerIds = []

      // Remove duplicates
      const uniqueRunners = thoroughbred.filter(runner => {
        const isDuplicate = runnerIds.includes(runner.runnerId)

        if (!isDuplicate) {
          runnerIds.push(runner.runnerId)
          return true
        }

        return false
      })

      // Collect runenrs that are racing in multiple venues within a 4day pirod
      const multiAcceptances = []

      // Map thrugh uniqe runners and with no duplicates
      uniqueRunners.forEach((item) => {
        // Grab runner this is racing in multiple races at different times/venue
        const sameRunnerInMulipleRaces = thoroughbred.filter(t => t.runnerId === item.runnerId)

        if (sameRunnerInMulipleRaces.length >= 2) {
          multiAcceptances.push(sameRunnerInMulipleRaces)
        }
      })

      state.dualAcceptances = multiAcceptances
      state.dualAcceptancesHasLoaded = true
    },
    saveCompetitorPrices (state, { competitorObj, runnerId }) {
      Vue.set(state.myCompetitorPricesFromDB, runnerId, competitorObj)
    },
    saveCompetitorRatings (state, { competitorObj, runnerId }) {
      Vue.set(state.myCompetitorRatingsFromDB, runnerId, competitorObj)
    }
  },
  actions: {
    fetchUrl (context) {
      const GB_DEFAULT_GRID = ['highLightOdds', 'rightAlignPrices', 'showSilks', 'showJockey', 'showPriceFlucs', 'fractionalOdds', 'displayImperial']
      var country = getUserCountry().id
      if (country === 'AU') {
        context.commit('setUrlLocation', 'AU')
      }
      if (country === 'GB') {
        context.commit('setUrlLocation', 'GB')
        context.dispatch('updateRaceGridSettings', { filter: 'gridSettings', value: GB_DEFAULT_GRID })
      }
      if (country === 'US') {
        context.commit('setUrlLocation', 'US')
        context.dispatch('updateRaceGridSettings', { filter: 'gridSettings', value: GB_DEFAULT_GRID })
      }
    },
    socketOpen (context) {
      context.commit('socketOpen')
    },
    socketClosed (context) {
      context.commit('socketClosed')
    },
    updateClocks (context) {
      context.commit('updateRaceCountdown')
    },
    selectRaceNav (context, v) {
      context.commit('setSelectRaceNav', v)
    },
    setMatchHeight (context, v) {
      context.commit('setThisMatchHeight', v)
    },
    showFeatureAlert (context, m) {
      context.commit('showNoFeatureAlert', m)
    },
    showEnableBettingAlert (context, m) {
      context.commit('showBettingAlert', m)
    },
    acModeSwitch (context, m) {
      context.commit('setAcMode', m)
    },
    async watchListSwitch (context) {
      if (context.state.dialogWatchlist) {
        context.commit('unsubscribeWatchListPrices')
      }
      context.commit('setWatchlist')
    },
    addToWatchListSwitch (context, addToWatchlist) {
      context.commit('setAddToWatchlist')
      context.commit('setAddPriceAlertProps', addToWatchlist)
    },
    async addToWatchList (context, watchlist) {
      const config = context.getters['account/authConfig']
      await addToWatchList({ ...watchlist, config })
      context.dispatch('fetchWatchList')
    },
    async addPriceAlert (context, runnerInfo) {
      const config = context.getters['account/authConfig']
      await addPriceAlert({ ...runnerInfo, config })
      context.dispatch('fetchWatchList')
    },
    showMultiBetSlip (context, m) {
      context.commit('showThisMultiBetSlip', m)
    },
    setRaceLoading (context, { loading }) {
      context.commit('setRaceLoading', { loading })
    },
    async fetchRaces (context) {
      const config = context.getters['account/authConfig']
      const racesGroupByMeeting = await getRacesGroupByMeeting(config)
      context.commit('setRacesGroupByMeeting', { value: racesGroupByMeeting })
      const columns = context.rootState.userConfig.selectedColumns
      const codes = ALL_BOOKIES_CODES.map(c => c.source)
      const filteredColumns = columns.filter(c => c.source !== 'BPC' && c.source !== 'BTC' && codes.includes(c.source))
      const bestPriceColumn = columns.find(c => c.source === 'BPC')
      const bestToteColumn = columns.find(c => c.source === 'BTC')
      const OCCColumns = columns.filter(c => c.source === 'OCC' && c.filters?.value !== 'BF')
      let sources = bestPriceColumn ? ALL_BOOKIES_CODES.map(s => s.source) : bestToteColumn ? filteredColumns.map(s => s.source).concat(['V', 'N', 'Q']) : filteredColumns.map(s => s.source)
      sources.push('OP')
      sources = sources.filter(source => !isEmpty(source))
      const deletedBookies = DELETED_BOOKIES.map(bookie => bookie.source)
      sources = sources.filter(source => !deletedBookies.includes(source))
      sources = returnUniqueElementsFromArray({ array: sources })

      if (OCCColumns.length > 0) {
        OCCColumns.forEach(OCCColumn => {
          sources.push(OCCColumn.filters?.value || OCCColumn.filters)
          filteredColumns.push({ source: OCCColumn.filters?.value || OCCColumn.filters, display: 1, type: 'WIN_FIXED_ODDS' })
        })
      }

      const d = context.state.selectedDate
      // context.dispatch('account/updateAuthToken')
      context.commit('setRaceLoading', { loading: true })
      try {
        const date = JSON.stringify(d)
        const query = meetingsDated(date, JSON.stringify(sources))
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const meetings = result.data.meetingsDated
        context.commit('clearRaces')
        if (isSameDay(d, startOfDay(Date.now()))) {
          context.commit('clearTodaysRaces')
        }
        meetings.forEach(m => {
          m.races.forEach(r => {
            context.commit('updateRaceSummary', { race: r, meeting: m })
          })
        })

        const nextRace = context.getters.getNextToGo
        if (isEmpty(nextRace)) {
          // Break from function if `nextRace` is empty - This ussally means the user would have to adjust their race filters
          context.commit('setRaceLoading', { loading: false })
          context.commit('setNoRaces', { loading: true })
          return
        }

        if (nextRace.id) {
          context.commit('setRaceLoading', { loading: false })
          context.commit('setNoRaces', { loading: false })
          context.dispatch('selectRace', { id: nextRace.id })
        } else {
          const races = context.state.races
          const keys = Object.keys(races)
          const firstRace = races[keys[0]]
          if (firstRace.id) context.dispatch('selectRace', { id: firstRace.id })
        }
        context.commit('updateRaceCountdown')
        context.dispatch('subscribeRaceUpdates', { date })
        context.dispatch('subscribeScratching')
        context.dispatch('subscribeResults')
        context.dispatch('fetchWatchList')
        context.dispatch('fetchMyRaces')
        const dateMeetingSummary = format(context.state.selectedDateMeetingSummary, 'yyyy-MM-dd')
        context.dispatch('fetchCustomShowHideMeetingRacesData', { id: dateMeetingSummary })
      } catch (error) {
        // console.log(Object.getOwnPropertyNames(error), '-=-=-=-=--=-=-=-=-=-==-=-=-=-=-=-=-', error, error.message)
        context.commit('setRaceLoading', { loading: false })
        context.commit('updatedidSomethingGoWrong', { boolean: true })
      }
    },
    async reFetchRaces (context) {
      const d = context.state.selectedDate
      const columns = context.rootState.userConfig.selectedColumns
      const codes = ALL_BOOKIES_CODES.map(c => c.source)
      const filteredColumns = columns.filter(c => c.source !== 'BPC' && c.source !== 'BTC' && codes.includes(c.source))
      const bestPriceColumn = columns.find(c => c.source === 'BPC')
      const bestToteColumn = columns.find(c => c.source === 'BTC')
      const OCCColumns = columns.filter(c => c.source === 'OCC' && c.filters?.value !== 'BF')
      let sources = bestPriceColumn ? ALL_BOOKIES_CODES.map(s => s.source) : bestToteColumn ? filteredColumns.map(s => s.source).concat(['V', 'N', 'Q']) : filteredColumns.map(s => s.source)
      sources.push('OP')
      sources = sources.filter(source => !isEmpty(source))
      sources = returnUniqueElementsFromArray({ array: sources })

      if (OCCColumns.length > 0) {
        OCCColumns.forEach(OCCColumn => {
          sources.push(OCCColumn.filters?.value || OCCColumn.filters)
          filteredColumns.push({ source: OCCColumn.filters?.value || OCCColumn.filters, display: 1, type: 'WIN_FIXED_ODDS' })
        })
      }

      // context.dispatch('account/updateAuthToken')
      try {
        const date = JSON.stringify(d)
        const query = meetingsDated(date, JSON.stringify(sources))
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const meetings = result.data.meetingsDated
        context.commit('clearRaces')
        if (isSameDay(d, startOfDay(Date.now()))) {
          context.commit('clearTodaysRaces')
        }
        meetings.forEach(m => {
          m.races.forEach(r => {
            context.commit('updateRaceSummary', { race: r, meeting: m })
          })
        })
        context.dispatch('subscribeRaceUpdates', { date })
        context.dispatch('subscribeScratching')
        context.dispatch('subscribeResults')
        context.commit('socketOpen')
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('reFetchRaces')
        } })
      }
    },
    async fetchMeetingSummaryRaces (context) {
      const d = zonedTimeToUtc(startOfDay(context.state.selectedDateMeetingSummary), 'GMT')
      // context.dispatch('account/updateAuthToken')
      try {
        const date = JSON.stringify(d)
        const query = meetingsDated(date)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const meetings = result.data.meetingsDated
        context.commit('clearMeetingSummaryRaces')
        meetings.forEach(m => {
          m.races.forEach(r => {
            context.commit('updateRaceMeetingSummary', { race: r, meeting: m })
          })
        })
        // const today = format(new Date(), 'yyyy-MM-dd')
        // context.dispatch('fetchCustomShowHideMeetingRacesData', { id: today })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchMeetingSummaryRaces')
        } })
      }
    },
    async fetchRacedayUpdatesEnabled (context) {
      const config = context.getters['account/authConfig']
      const showRaceDayUpdates = await fetchShowRacedayUpdates('showRacedayUpdates', config)
      if (typeof showRaceDayUpdates !== 'undefined') {
        context.commit('setRacedayUpdatesEnabled', showRaceDayUpdates)
      }
    },
    async subscribeScratching (context) {
      try {
        const query = subscribeScratching()
        const result = await apolloClient.subscribe({
          query: gql`${query}`
        })
        const subscription = result.subscribe({
          next (data) {
            const scratching = data.data.scratching
            let alerts = context.state.ogPriceAlerts
            const scratchedAlert = alerts.find(a => a.meeting_id === scratching.meetingId && a.race_number === scratching.raceNumber && a.runner_number === scratching.runnerNumber)
            // there is alert set on the scratched runner
            if (scratchedAlert) {
              // re-fetch the watchlist as backend would update the runner number of the alert, delay with 1.5 seconds
              setTimeout(() => context.dispatch('fetchWatchList'), 1500)
            }
          },
          error (error) {
            context.commit('socketClosed')
            context.dispatch('account/updateTokenExpired', { callback: () => {
              context.dispatch('subscribeScratching')
            } })
            return error
          }
        })
        context.commit('updateScratchingSubscription', { subscription })
      } catch (error) {
      }
    },
    async subscribeRaceUpdates (context, { date }) {
      // context.dispatch('account/updateAuthToken')
      try {
        const query = subscribeRaceUpdates(date)
        const result = await apolloClient.subscribe({
          query: gql`${query}`
        })
        const subscription = result.subscribe({
          next (data) {
            const raceUpdate = data.data.raceUpdated
            const key = `${raceUpdate.id}`
            if (isSameDay(parseISO(raceUpdate.startTime), context.state.selectedDate) || isSameDay(parseISO(raceUpdate.startTime), startOfDay(Date.now()))) {
              const race = context.state.races[key] || context.state.todaysRaces[key]
              if (race) {
                const updates = {}
                if (race.status !== raceUpdate.status) {
                  updates.status = raceUpdate.status
                }
                if (race.startTime !== raceUpdate.startTime) {
                  updates.startTime = raceUpdate.startTime
                  updates.isoStartTime = parseISO(race.startTime)
                }
                // if (race.trackCondition !== raceUpdate.trackCondition) {
                //   updates.trackCondition = raceUpdate.trackCondition
                //   context.commit('setRacedayUpdates', { meetingDate: raceUpdate.meeting.date, raceId: raceUpdate.id, raceNumber: raceUpdate.number, dateUpdate: raceUpdate.updated, raceName: raceUpdate.meeting.track.name, updateType: 'Track Condition', oldValue: race.trackCondition, newValue: raceUpdate.trackCondition })
                // }
                if (Object.keys(updates).length > 0) {
                  const newRace = { ...race, ...updates }
                  context.commit('updateRace', { raceUpdate: newRace })
                }
              } else {
                context.commit('updateRaceSummary', { race: raceUpdate, meeting: raceUpdate.meeting })
              }
            }
            context.commit('socketOpen')
          },
          error (error) {
            context.commit('socketClosed')
            context.dispatch('account/updateTokenExpired', { callback: () => {
              context.dispatch('subscribeRaceUpdates')
            } })
            return error
          }
        })
        context.commit('updateRaceSubscription', { subscription })
      } catch (error) {
      }
    },
    async subscribeDetailedRace (context, { id, date }) {
      try {
        const raceId = JSON.stringify(id)
        const query = subscribeDetailedRace(raceId, date)
        const result = await apolloClient.subscribe({
          query: gql`${query}`
        })
        const subscription = result.subscribe({
          next (data) {
            const raceUpdate = data.data.raceUpdated
            const key = `${raceUpdate.id}`

            const detailedRace = context.state.detailedRaces[key]
            if (detailedRace) {
              if (raceUpdate.competitors && raceUpdate.competitors.length > 0) {
                const competitors = detailedRace?.competitors ? [...detailedRace.competitors] : []
                // raceUpdate.competitors.forEach(competitor => {
                //   const index = competitors.findIndex(c => c.tabNo === competitor.tabNo)
                //   if (index) {
                //     if (competitors[index]?.scratched !== competitor.scratched) {
                //       context.commit('setRacedayUpdates', { meetingDate: raceUpdate.meeting.date, raceId: raceUpdate.id, raceNumber: raceUpdate.number, dateUpdate: raceUpdate.updated, raceName: raceUpdate.meeting.track.name, updateType: 'Scratching', oldValue: false, newValue: competitor.tabNo + ' ' + competitor.name })
                //     }

                //     if (competitors[index]?.jockey !== competitor.jockey && competitor.jockey !== '') {
                //       context.commit('setRacedayUpdates', { meetingDate: raceUpdate.meeting.date, raceId: raceUpdate.id, raceNumber: raceUpdate.number, dateUpdate: raceUpdate.updated, raceName: raceUpdate.meeting.track.name, updateType: 'Rider', oldValue: competitors[index]?.tabNo + ' ' + competitors[index]?.jockey, newValue: competitor.jockey })
                //     }
                //     competitors[index] = { ...competitor, runner: { id: competitors[index]?.runner?.id } }
                //   }
                // })
                const newDetailedRace = { ...raceUpdate, competitors }
                context.commit('updateRaceDetail', { detailedRace: newDetailedRace })
              } else {
                const newDetailedRace = { ...raceUpdate, competitors: [...detailedRace.competitors] }
                context.commit('updateRaceDetail', { detailedRace: newDetailedRace })
              }
            }
          }
        })
        context.commit('socketOpen')
        context.commit('updateDetailedRaceSubscription', { subscription })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('subscribeDetailedRace', { id, date })
        } })
      }
    },
    async fetchRace (context, { id, columns }) {
      context.state.selectedBookieColumns = columns
      context.commit('setRaceLoading', { loading: true })
      context.commit('setNoRaces', { loading: false })
      try {
        const codes = ALL_BOOKIES_CODES.map(c => c.source)
        const filteredColumns = columns.filter(c => c.source !== 'BPC' && c.source !== 'BTC' && codes.includes(c.source))
        const bestPriceColumn = columns.find(c => c.source === 'BPC')
        const bestToteColumn = columns.find(c => c.source === 'BTC')
        const OCCColumns = columns.filter(c => c.source === 'OCC' && c.filters?.value !== 'BF')
        let sources = bestPriceColumn ? ALL_BOOKIES_CODES.map(s => s.source) : bestToteColumn ? filteredColumns.map(s => s.source).concat(['V', 'N', 'Q']) : filteredColumns.map(s => s.source)
        sources.push('OP')
        sources = sources.filter(source => !isEmpty(source))
        sources = returnUniqueElementsFromArray({ array: sources })
        if (OCCColumns.length > 0) {
          OCCColumns.forEach(OCCColumn => {
            sources.push(OCCColumn.filters?.value || OCCColumn.filters)
            filteredColumns.push({ source: OCCColumn.filters?.value || OCCColumn.filters, display: 1, type: 'WIN_FIXED_ODDS' })
          })
        }
        const raceID = JSON.stringify(id)
        const includeRatings = !!columns.find(c => c.type === 'RUNNER_HISTORY')
        const query = queryRace(raceID, JSON.stringify(sources.filter(s => !!s && s !== 'BF')), includeRatings)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const race = result.data.race
        const prices = race.priceSets
        delete race.priceSets
        context.commit('updateDetailedRace', { race })
        setTimeout(() => {
          context.dispatch('fetchBetfairPrices', { id, columns })
          context.dispatch('fetchRaceData', { race, columns })
          if (filteredColumns.some(c => c.display === 1)) {
            const flucsColumns = filteredColumns.filter(c => c.display === 1)
            flucsColumns.forEach(column => {
              context.dispatch('fetchColumnFlucs', { id, source: column.source, type: column.type })
            })
          }
        }, 10)
        context.dispatch('subscribePrices', { id, columns: filteredColumns })
        context.commit('selectRace', { id })
        context.commit('setRaceLoading', { loading: false })
        context.dispatch('handlePrices', { prices, id, filteredColumns })
        const d = context.state.selectedDate
        const date = JSON.stringify(d)
        context.dispatch('subscribeDetailedRace', { id, date })
      } catch (error) {
        context.commit('setRaceLoading', { loading: false })
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchRace', { id, columns })
        } })
      }
    },
    async handlePrices (context, { prices, id, filteredColumns }) {
      if (prices) {
        prices.forEach(x => {
          x.id = id
          context.commit('updatePrice', { price: x })
        })
        context.dispatch('subscribePrices', { id, columns: filteredColumns })
      }
    },
    async fetchRaceData (context, { race, columns }) {
      await context.dispatch('fetchDynamicRaceData', { race, columns })
      await context.dispatch('fetchSavedRaceData', { id: race.id })
      await context.dispatch('fetchPrevComments', { id: race.id, columns })
      // context.dispatch('updateNewColumns', columns)
    },
    async fetchRunner (context, id) {
      // context.dispatch('account/updateAuthToken')
      try {
        const runnerID = JSON.stringify(id)
        const query = queryRunner(runnerID)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const runner = result.data.runner
        context.commit('updateDetailedRunner', { runner })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchRunner', id)
        } })
      }
    },
    async fetchRunnerDO (context, id) {
      // context.dispatch('account/updateAuthToken')
      try {
        const runnerID = JSON.stringify(id)
        const query = queryRunnerDO(runnerID)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const runner = result.data.runner
        context.commit('updateDetailedRunner', { runner })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchRunnerDO', id)
        } })
      }
    },
    async fetchExoticsResults (context, { id }) {
      // context.dispatch('account/updateAuthToken')
      try {
        const raceID = JSON.stringify(id)
        const query = subscribeResultsQuery(raceID)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const exResults = result.data.race.results.dividends
        context.commit('updateExoticsResults', { exResults })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchExoticsResults', { id })
        } })
      }
    },
    async fetchFlucs (context, { source, type }) {
      if (!source || !type) return null
      // Don't fetch the same flucs multiple times
      const key = `${context.state.selectedRace.id}:${source}:${type}`
      const flucs = context.state.flucs[key]
      if (flucs && source !== 'BF') return

      // context.dispatch('account/updateAuthToken')
      try {
        if (source === 'BF') {
          const id = JSON.stringify(context.state.selectedRace.id)
          const query = queryFlucsBetFair(id)
          const result = await apolloClient.query({
            query: gql`${query}`
          })
          const flucs = result.data.race.betFairMarkets?.selections || []
          const mappedFlucs = flucs.map(f => {
            return {
              flucs: f.lastTradedFlucs
            }
          })
          context.commit('updateFlucs', { flucs: mappedFlucs, source, id: JSON.parse(id), type: 'WIN' })
        } else {
          const id = JSON.stringify(context.state.selectedRace.id)
          const sourceID = JSON.stringify(source)
          const query = queryFlucs(id, sourceID, type)
          const result = await apolloClient.query({
            query: gql`${query}`
          })
          const flucs = result.data.race.competitors
          context.commit('updateFlucs', { flucs, id: JSON.parse(id), source, type })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchFlucs', { source, type })
        } })
      }
    },
    async fetchPoolHistory (context, { source, type }) {
      if (!source || !type) {
        return null
      }
      const key = `${context.state.selectedRace.id}:${source}:${type}`
      const poolHistory = context.state.poolHistory[key]
      if (poolHistory) return

      // context.dispatch('account/updateAuthToken')
      try {
        const id = JSON.stringify(context.state.selectedRace.id)
        const sourceID = JSON.stringify(source)
        const query = queryPoolHistory(id, sourceID, type)
        const result = await apolloClient.query({
          query: gql`${query}`
        })
        const poolHistory = result.data.race.poolHistory
        context.commit('updatePoolHistory', { poolHistory, id: JSON.parse(id), source, type })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchPoolHistory', { source, type })
        } })
      }
    },
    async fetchColumnFlucs (context, { id, source, type }) {
      // context.dispatch('account/updateAuthToken')
      if (!source || !type) {
        context.rootState.userConfig.selectedColumns.forEach(async column => {
          if (column.display === 1 && column.source) {
            // const priceType = column.type
            getFlucs(id, source, type)
          }
        })
      } else if (source) {
        getFlucs(id, source, type)
      }
      async function getFlucs (id, source, priceType) {
        // context.dispatch('account/updateAuthToken')
        try {
          const raceID = JSON.stringify(id)
          const sourceID = JSON.stringify(source)
          if (source === 'BF') {
            const query = queryFlucsBetFair(raceID)
            const result = await apolloClient.query({
              query: gql`${query}`
            })
            const flucs = result.data.race.betFairMarkets?.selections || []
            const mappedFlucs = flucs.map(f => {
              return {
                flucs: f.lastTradedFlucs
              }
            })
            context.commit('setColumnFlucs', { flucs: mappedFlucs, source, id: JSON.parse(raceID), priceType })
          } else {
            const query = queryFlucs(raceID, sourceID, priceType)
            const result = await apolloClient.query({
              query: gql`${query}`
            })
            const flucs = result.data.race?.competitors
            context.commit('setColumnFlucs', { flucs, source, id: JSON.parse(raceID), priceType })
          }
        } catch (error) {
          context.dispatch('account/updateTokenExpired', { callback: () => {
            context.dispatch('fetchColumnFlucs', { id, source, type })
          } })
        }
      }
    },
    updateRaceSummary (context, { meeting, race }) {
      context.commit('updateRaceSummary', { race, meeting })
    },
    async getPrices (context, { id, columns }) {
      // context.commit('clearPrices')
      try {
        const codes = ALL_BOOKIES_CODES.map(c => c.source)
        const filteredColumns = columns.filter(c => c.source !== 'BPC' && c.source !== 'BTC' && codes.includes(c.source))
        const bestPriceColumn = columns.find(c => c.source === 'BPC')
        const bestToteColumn = columns.find(c => c.source === 'BTC')
        const OCCColumns = columns.filter(c => c.source === 'OCC' && c.filters?.value !== 'BF')
        let sources = bestPriceColumn ? PRICE_ALERT_BOOKIES.map(s => s.source) : bestToteColumn ? filteredColumns.map(s => s.source).concat(['V', 'N', 'Q']) : filteredColumns.map(s => s.source)
        sources.push('OP')
        sources = sources.filter(source => !isEmpty(source))
        sources = returnUniqueElementsFromArray({ array: sources })

        if (OCCColumns.length > 0) {
          OCCColumns.forEach(OCCColumn => {
            sources.push(OCCColumn.filters?.value || OCCColumn.filters)
            filteredColumns.push({ source: OCCColumn.filters?.value || OCCColumn.filters, display: 1, type: 'WIN_FIXED_ODDS' })
          })
        }
        // const betFairColumns = columns.filter(c => c.source === 'BF')
        // if there are flucs columns
        if (filteredColumns.some(c => c.display === 1)) {
          const flucsColumns = filteredColumns.filter(c => c.display === 1)
          flucsColumns.forEach(column => {
            context.dispatch('fetchColumnFlucs', { id, source: column.source, type: column.type })
          })
        }
        if (filteredColumns) {
          const raceID = JSON.stringify(id)
          const bestPriceColumn = columns.find(c => c.source === 'BPC')
          const bestToteColumn = columns.find(c => c.source === 'BTC')
          let sources = bestPriceColumn ? PRICE_ALERT_BOOKIES.map(s => s.source) : bestToteColumn ? filteredColumns.map(s => s.source).concat(['V', 'N', 'Q']) : filteredColumns.map(s => s.source)
          sources.push('OP')
          sources = sources.filter(source => !isEmpty(source))
          sources = returnUniqueElementsFromArray({ array: sources })

          const query = queryPrices(raceID, JSON.stringify(sources.filter(s => !!s && s !== 'BF')))
          const result = await apolloClient.query({
            query: gql`${query}`
          })
          const prices = result.data.race.priceSets
          if (prices) {
            prices.forEach(x => {
              x.id = id
              context.commit('updatePrice', { price: x })
            })
            context.dispatch('subscribePrices', { id, columns: filteredColumns })
          }
        }
        // get Bet Fair prices
        if (columns.find(c => c.source === 'BF')) {
          const raceID = JSON.stringify(id)
          const query = queryBetFairPrices(raceID, 'WIN')
          const result = await apolloClient.query({
            query: gql`${query}`
          })
          const prices = result.data.race.betFairMarkets
          if (prices && prices.priceType === 'WIN') {
            const exchangeRate = context.getters.getStateDynamicRaceData?.['exchange_type']
            const totalTraded = exchangeRate ? prices.totalTraded * exchangeRate : prices.totalTraded
            context.commit('updateBetFairTotalTraded', { id, priceType: prices.priceType, totalTraded })
            prices.selections.forEach(r => {
              const totalTraded = exchangeRate ? r.totalTraded * exchangeRate : r.totalTraded
              const runner = { ...r, totalTraded }
              context.commit('updateBetFairPrice', { id, runner, priceType: prices.priceType, price: runner })
            })
            context.dispatch('subscribeBetFair', { id, columns })
          }
          if (columns.find(c => c.source === 'BF' && c.priceType === 'PLACE')) {
            const raceID = JSON.stringify(id)
            const query = queryBetFairPrices(raceID, 'PLACE')
            const result = await apolloClient.query({
              query: gql`${query}`
            })
            const prices = result.data.race.betFairMarkets
            if (prices && prices.priceType === 'PLACE') {
              prices.selections.forEach(runner => {
                context.commit('updateBetFairPrice', { id, runner, priceType: prices.priceType })
              })
              context.dispatch('subscribeBetFair', { id, columns })
            }
          }
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('getPrices', { id, columns })
        } })
      }
    },
    async fetchBetfairPrices (context, { id, columns }) {
      try {
        // get Bet Fair prices
        context.commit('clearBetFairPrices')
        if (columns.find(c => c.source === 'BF')) {
          const raceID = JSON.stringify(id)
          const query = queryBetFairPrices(raceID, 'WIN')
          const result = await apolloClient.query({
            query: gql`${query}`
          })
          const prices = result.data.race.betFairMarkets
          if (prices && prices.priceType === 'WIN') {
            const exchangeRate = context.getters.getStateDynamicRaceData?.['exchange_type']
            const totalTraded = exchangeRate ? prices.totalTraded * exchangeRate : prices.totalTraded
            context.commit('updateBetFairTotalTraded', { id, priceType: prices.priceType, totalTraded })
            prices.selections.forEach(r => {
              const totalTraded = exchangeRate ? r.totalTraded * exchangeRate : r.totalTraded
              const runner = { ...r, totalTraded }
              context.commit('updateBetFairPrice', { id, runner, priceType: prices.priceType, price: runner })
            })
            context.dispatch('subscribeBetFair', { id, columns })
          }
          if (columns.find(c => c.source === 'BF' && c.priceType === 'PLACE')) {
            const raceID = JSON.stringify(id)
            const query = queryBetFairPrices(raceID, 'PLACE')
            const result = await apolloClient.query({
              query: gql`${query}`
            })
            const prices = result.data.race.betFairMarkets
            if (prices && prices.priceType === 'PLACE') {
              prices.selections.forEach(runner => {
                context.commit('updateBetFairPrice', { id, runner, priceType: prices.priceType })
              })
              context.dispatch('subscribeBetFair', { id, columns })
            }
          }
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchBetfairPrices', { id, columns })
        } })
      }
    },
    async subscribePrices (context, { id, columns }) {
      // context.dispatch('account/updateAuthToken')
      const race = context.state.detailedRaces?.[id] || context.state.races?.[id]
      const key = `${race.meeting.id}:${race.number}`
      try {
        const raceID = JSON.stringify(id)
        const bestPriceColumn = columns.find(c => c.source === 'BPC')
        const bestToteColumn = columns.find(c => c.source === 'BTC')
        const sources = bestPriceColumn ? PRICE_ALERT_BOOKIES.map(s => {
          return {
            source: s.source,
            type: s.type
          }
        }) : bestToteColumn ? [...columns, ...['V', 'N', 'Q']].map(s => {
          return {
            source: s.source,
            type: s.type
          }
        }) : columns.map(s => {
          return {
            source: s.source,
            type: s.type
          }
        })
        const query = subscribePrices(raceID, JSON.stringify(sources.filter(s => !!s.source && s.source !== 'BF' && !!s.type))
          .replace(/"(\w+)"\s*:/g, '$1:')
          .replace(/['"]+(WIN)/g, 'WIN')
          .replace(/(WIN)+['"]/g, 'WIN')
          .replace(/['"]+(PLACE)/g, 'PLACE')
          .replace(/(PLACE)+['"]/g, 'PLACE')
          .replace(/(ODDS)+['"]/g, 'ODDS'))
        const result = await apolloClient.subscribe({
          query: gql`${query}`
        })
        const subscription = result.subscribe({
          next (data) {
            const dataKey = `${data.data.priceUpdates.meetingID}:${data.data.priceUpdates.raceNumber}`
            if (dataKey !== key) return
            const price = {
              ...data.data.priceUpdates,
              prices: data.data.priceUpdates.prices.map(price => {
                return {
                  ...price,
                  price: price?.price ? Math.round(price.price * 100) / 100 : null
                }
              })
            }
            if (price) {
              price.id = id
              context.commit('updatePrice', { price })
              if (price.source === context.state.marketMoverBookie) {
                context.dispatch('getNewFlucs', { source: context.state.marketMoverBookie })
              }
            }
            context.commit('socketOpen')
          },
          error (error) {
            context.commit('socketClosed')
            return error
          }
        })
        context.commit('updatePriceSubscription', { subscription })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('subscribePrices', { id, columns })
        } })
      }
    },
    async subscribeBetFair (context, { id, columns }) {
      context.commit('updateBetFairPriceSubscription', { })
      const bf = columns.filter(c => c.source === 'BF')
      if (bf.length <= 0) {
        return
      }
      const priceTypes = Array.from(new Set(bf.map(c => c.priceType || 'WIN')))
      try {
        const raceID = JSON.stringify(id)
        const types = priceTypes
        const query = subscribeBetFairPrices(raceID, types)
        const result = await apolloClient.subscribe({
          query: gql`${query}`
        })
        const subscription = result.subscribe({
          next (result) {
            const prices = result.data.betFairUpdates
            const exchangeRate = context.getters.getStateDynamicRaceData?.['exchange_type']
            const totalTraded = exchangeRate ? prices.totalTraded * exchangeRate : prices.totalTraded
            context.commit('updateBetFairTotalTraded', { id, priceType: prices.priceType, totalTraded })
            result.data.betFairUpdates
              .selections.map(r => {
                const totalTraded = exchangeRate ? r.totalTraded * exchangeRate : r.totalTraded
                const runner = { ...r, totalTraded }
                context.commit('updateBetFairPrice', { id, runner, priceType: result.data.betFairUpdates.priceType })
              })
            context.commit('updateBetFairFlucs', { id, prices })
          },
          error (error) {
            context.commit('socketClosed')
            return error
          }
        })
        context.commit('updateBetFairPriceSubscription', { subscription })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('subscribeBetFair', { id, columns })
        } })
      }
    },
    async subscribeResults (context) {
      // context.dispatch('account/updateAuthToken')
      try {
        const query = subscribeResultsQuery()
        const result = await apolloClient.subscribe({
          query: gql`${query}`
        })
        const subscription = result.subscribe({
          next (res) {
            const results = res.data.results
            const key = `${results.raceId}`
            const race = context.state.races[key]

            const resultsArray = []
            resultsArray.push(results)

            if (race) {
              const newRace = { ...race, status: results.status, results: resultsArray }
              context.commit('updateRace', { raceUpdate: newRace })
            }
            const detailedRace = context.state.detailedRaces[key]
            if (detailedRace) {
              const newDetailedRace = { ...detailedRace, status: results.status, results: resultsArray }
              context.commit('updateRaceDetail', { detailedRace: newDetailedRace })
            }
          }
        })
        context.commit('updateResultsSubscription', { subscription })
        context.commit('socketOpen')
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('subscribeResults')
        } })
        context.commit('socketClosed')
      }
    },
    async fetchSpeedMap (context, { id }) {
      const config = context.getters['account/authConfig']
      const speedMap = await fetchSpeedMap(id, config)
      if (speedMap) {
        context.commit('setSpeedMap', speedMap)
      }
    },
    async fetchSavedRaceData (context, { id }) {
      try {
        const config = context.getters['account/authConfig']
        const speedMap = await fetchSpeedMap(id, config)
        if (speedMap) {
          context.commit('setSpeedMap', speedMap)
        }
        if (!speedMap) {
          context.dispatch('fetchDefaultSpeedMap', { id })
        }
        context.dispatch('fetchCustomData', { id })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchSavedRaceData', { id })
        } })
      }
    },
    async fetchPrevComments (context, { id, columns }) {
      try {
        const config = context.getters['account/authConfig']
        if (columns.find(c => c.source === 'prev-comment')) {
          context.commit('setPrevComments', {})
          const competitors = context.getters.getRaceCompetitors
          const prevComments = {}
          competitors.forEach(async competitor => {
            const comment = await getLastRunnerComment(competitor.runner.id, config)
            if (context.state.customRaceData[id]) {
              const myComment = context.state.customRaceData[id]['custom-comment'][competitor.runner.id]?.value
              if (myComment !== comment) {
                context.commit('setPrevComment', { tabNo: competitor.tabNo, comment })
                prevComments[competitor.tabNo] = comment
              }
            } else {
              context.commit('setPrevComment', { tabNo: competitor.tabNo, comment })
              prevComments[competitor.tabNo] = comment
            }
          })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchPrevComments', { id, columns })
        } })
      }
    },
    async fetchDefaultSpeedMap (context, { id }) {
      const race = context.state.detailedRaces[id]
      if (race) {
        const defaultRunners = race?.competitors?.map(c => {
          return {
            tabNo: c.tabNo,
            name: c.name,
            barrier: c.barrier,
            speedmapPosition: c.speedmapPosition,
            scratched: c.scratched,
            jockey: c.jockey,
            weight: c.weightAllocated
          }
        })
        context.commit('setSpeedMap', { defaultRunners })
      } else {
        // context.dispatch('account/updateAuthToken')
        try {
          const raceID = JSON.stringify(id)
          const query = speedMapQuery(raceID)
          const result = await apolloClient.query({
            query: gql`${query}`
          })
          context.commit('setSpeedMap', { defaultRunners: result.data.race.competitors })
        } catch (error) {
          context.dispatch('account/updateTokenExpired', { callback: () => {
            context.dispatch('fetchDefaultSpeedMap', { id })
          } })
        }
      }
    },
    saveSpeedMap (context, { id, speedMap }) {
      try {
        const config = context.getters['account/authConfig']
        context.commit('setSpeedMap', speedMap)
        setSpeedMap(id, speedMap, config)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('saveSpeedMap', { id, speedMap })
        } })
      }
    },
    resetSpeedMap (context, { id }) {
      try {
        const config = context.getters['account/authConfig']
        resetSpeedMap(id, config)
        context.dispatch('fetchDefaultSpeedMap', { id })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('resetSpeedMap', { id })
        } })
      }
    },
    setCustomData (context, { id, columnName, value }) {
      try {
        const config = context.getters['account/authConfig']
        if (columnName === 'meetings-selected') {
          const customMeetingData = context.state.customShowHideMeetingsData[columnName] || {}
          customMeetingData[columnName] = value
          setCustomShowHideMeetingsData(id, customMeetingData, config)
          context.commit('setCustomShowHideMeetingsData', { id, value: customMeetingData })
        } else if (columnName === 'races-selected') {
          const customRaceData = context.state.customShowHideRacesData[columnName] || {}
          customRaceData[columnName] = value
          setCustomShowHideRacesData(id, customRaceData, config)
          context.commit('setCustomShowHideRacesData', { id, value: customRaceData })
        } else if (columnName === 'meeting-types-selected') {
          const customMeetingTypeData = context.state.customShowHideMeetingTypesData[columnName] || {}
          customMeetingTypeData[columnName] = value
          setCustomShowHideMeetingTypesData(id, customMeetingTypeData, config)
          context.commit('setCustomShowHideMeetingTypesData', { id, value: customMeetingTypeData })
        } else {
          const customRaceData = context.state.customRaceData[id] || {}
          customRaceData[columnName] = value
          setCustomRaceData(id, customRaceData, config)
          context.commit('setCustomData', { id, value: customRaceData })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('setCustomData', { id, columnName, value })
        } })
      }
    },
    async fetchCustomData (context, { id }) {
      try {
        const config = context.getters['account/authConfig']
        const customData = await fetchCustomRaceData(id, config)
        if (customData) {
          context.commit('setCustomData', { id, value: customData })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchCustomData', { id })
        } })
      }
    },
    async fetchCustomShowHideMeetingRacesData (context, { id }) {
      try {
        const config = context.getters['account/authConfig']
        const customMeetingsData = await fetchCustomShowHideMeetingsData(id, config)
        if (customMeetingsData) {
          context.commit('setCustomShowHideMeetingsData', { id, value: customMeetingsData })
          // context.dispatch('updateRaceNavFilter', { filter: 'meetings', value: customMeetingsData['meetings-selected'] })
        }

        const customRacesData = await fetchCustomShowHideRacesData(id, config)
        if (customRacesData) {
          context.commit('setCustomShowHideRacesData', { id, value: customRacesData })
          // context.dispatch('updateRaceNavFilter', { filter: 'races', value: customRacesData['races-selected'] })
        }

        const customMeetingTypesData = await fetchCustomShowHideMeetingTypesData(id, config)
        if (customMeetingTypesData) {
          context.commit('setCustomShowHideMeetingTypesData', { id, value: customMeetingTypesData })
          // context.dispatch('updateRaceNavFilter', { filter: 'races', value: customRacesData['races-selected'] })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchCustomShowHideMeetingRacesData', { id })
        } })
      }
    },
    setRacedayUpdatesEnabled (context, { value }) {
      try {
        const config = context.getters['account/authConfig']
        setShowRacedayUpdates('showRacedayUpdates', value, config)
        context.commit('setRacedayUpdatesEnabled', value)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('setRacedayUpdatesEnabled', { value })
        } })
      }
    },
    updateSelectedColumns (context, { name, columns }) {
      try {
        const config = context.getters['account/authConfig']
        context.commit('setSelectedColumns', { name, columns })
        if (context.state.selectedRace.id) {
          context.dispatch('getPrices', { columns, id: context.state.selectedRace.id })
        }

        setUserFilter('selectedColumnsName', name, config)
        if (name !== 'AU' && name !== 'UK') {
          setColumnLayout(name, context.rootState.userConfig.selectedColumns, config)
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('updateSelectedColumns', { name, columns })
        } })
      }
    },
    async updateNewColumns (context) {
      const columns = context.state.selectedBookieColumns
      if (columns?.length <= 0) return
      const config = context.getters['account/authConfig']
      const keyList = [
        'selectedColumnsName'
      ]
      const results = await batchFetch(keyList, config)
      if (!results) {
        return
      }
      if (results.selectedColumnsName) {
        const name = JSON.parse(results.selectedColumnsName)
        const removeProperty = prop => ({ [prop]: _, ...rest }) => rest
        const removeKey = removeProperty('key')
        const items = columns.map(c => {
          return removeKey(c)
        }) || []
        // const updatedColumns = { ...items }
        const allColumns = RUNNER_INFO_COLUMNS
        let updateColumns = false
        const newCol = items.map(c => {
          if (allColumns.find(i => c.selection === i.selection && i.queryType && !c.queryType)) updateColumns = true
          return allColumns.find(i => c.selection === i.selection && i.queryType && !c.queryType) || c
        })
        if (updateColumns) context.dispatch('updateSelectedColumns', { name: name, columns: newCol })
      }
    },
    async updateNewColumnsData (context, columns) {
      if (columns <= 0) return
      const config = context.getters['account/authConfig']
      const keyList = [
        'selectedColumnsName'
      ]
      const results = await batchFetch(keyList, config)
      if (!results) {
        return
      }
      if (results.selectedColumnsName) {
        const name = JSON.parse(results.selectedColumnsName)
        const removeProperty = prop => ({ [prop]: _, ...rest }) => rest
        const removeKey = removeProperty('key')
        const items = columns.map(c => {
          return removeKey(c)
        }) || []
        // const updatedColumns = { ...items }
        const allColumns = RUNNER_INFO_COLUMNS
        let updateColumns = false
        const newCol = items.map(c => {
          if (allColumns.find(i => c.selection === i.selection && i.queryType && !c.queryType)) updateColumns = true
          return allColumns.find(i => c.selection === i.selection && i.queryType && !c.queryType) || c
        })
        if (updateColumns) context.dispatch('updateSelectedColumns', { name: name, columns: newCol })
      }
    },
    changeSelectedColumns (context, name) {
      try {
        const config = context.getters['account/authConfig']
        let columns
        switch (name) {
          case 'AU':
            columns = AU_COLUMNS
            context.commit('setSelectedColumns',
              {
                name,
                columns
              })
            setUserFilter('selectedColumnsName', name, config)
            if (context.state.selectedRace.id) {
              context.dispatch('getPrices', { columns, id: context.state.selectedRace.id })
            }
            break
          case 'UK':
            columns = UK_COLUMNS
            context.commit('setSelectedColumns',
              {
                name,
                columns
              })
            setUserFilter('selectedColumnsName', name, config)
            if (context.state.selectedRace.id) {
              context.dispatch('getPrices', { columns, id: context.state.selectedRace.id })
            }
            break
          default:
            context.dispatch('fetchSavedColumns', name)
            setUserFilter('selectedColumnsName', name, config)
            break
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('changeSelectedColumns', name)
        } })
      }
    },
    async fetchUserColumns (context, { name }) {
      try {
        const config = context.getters['account/authConfig']

        if (isEmpty(name)) {
          if (!config.headers && (context.state.urlLocation === 'AU' || context.state.urlLocation === 'Local')) {
            context.dispatch('changeSelectedColumns', 'AU')
            return
          }
          if (!config.headers && context.state.urlLocation === 'UK') {
            context.dispatch('changeSelectedColumns', 'UK')
            return
          }

          name = await fetchUserFilter('selectedColumnsName', config)
        }

        if (name === 'AU' || name === 'UK') {
          context.dispatch('changeSelectedColumns', name)
          return
        }
        const columns = await fetchColumnLayout(name, config)
        if (columns) {
          const filteredColumns = columnMigration(columns)
          if (filteredColumns.length !== columns.length) {
            context.dispatch('updateSelectedColumns', { name, columns: filteredColumns })
            context.commit('setSelectedColumns', { name, columns: filteredColumns })
          } else {
            context.commit('setSelectedColumns', { name, columns })
          }
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchUserColumns')
        } })
      }
    },
    async fetchSavedColumns (context, name) {
      try {
        const config = context.getters['account/authConfig']
        if (name === 'AU' || name === 'UK') {
          context.dispatch('changeSelectedColumns', name)
          return
        }
        const columns = await fetchColumnLayout(name, config)
        context.dispatch('updateNewColumnsData', columns)
        if (columns) {
          const filteredColumns = columnMigration(columns)
          if (filteredColumns.length !== columns.length) {
            context.dispatch('updateSelectedColumns', { name, columns: filteredColumns })
            context.commit('setSelectedColumns', { name, columns: filteredColumns })
          } else {
            context.commit('setSelectedColumns', { name, columns })
          }
          if (context.state.selectedRace.id) {
            context.dispatch('getPrices', { columns, id: context.state.selectedRace.id })
          }
        } else {
          context.commit('setSelectedColumns', { name, columns: [] })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchSavedColumns', name)
        } })
      }
    },
    updateSortPriceOrder (context, value) {
      try {
        const config = context.getters['account/authConfig']
        context.commit('updateSortPrices', value)
        setUserFilter('sortedPrices', value, config)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('updateSortPriceOrder', value)
        } })
      }
    },
    updateRaceGridSettings (context, { filter, value }) {
      try {
        const config = context.getters['account/authConfig']
        switch (filter) {
          case 'raceResults':
            context.commit('updateResultsShowHide', value)
            setUserFilter('raceResultsShowHide', value, config)
            break
          case 'priceAlertsAudio':
            context.commit('updateAudioPriceAlert', value)
            setUserFilter('audioPriceAlertToggle', value, config)
            break
          case 'raceFontSize':
            context.commit('updateRaceFontSize', value)
            setUserFilter('raceFontSizeSet', value, config)
            break
          case 'highlightBestOdds':
            context.commit('updateHighLightBestOdds', value)
            setUserFilter('highlightBestOddsSet', value, config)
            break
          case 'gridSettings':
            context.commit('updateGridSettings', value)
            setUserFilter('gridSettingsSet', value, config)
            break
          case 'betFair':
            context.commit('updateBetFairSettings', value)
            setUserFilter('betFairSettingsSet', value, config)
            break
          case 'betFairCommission':
            context.commit('updateBetFairComission', value)
            setUserFilter('betFairCommission', value, config)
            break
          case 'betLimits':
            context.commit('updatebetLimits', value)
            setUserFilter('betLimitsSet', value, config)
            break
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('updateRaceGridSettings', { filter, value })
        } })
      }
    },
    updateRaceNavFilter (context, { filter, value }) {
      try {
        const config = context.getters['account/authConfig']
        switch (filter) {
          case 'countries':
            context.commit('updateCountries', value)
            setUserFilter('filteredCountries', value, config)
            break
          case 'codes':
            context.commit('updateCodes', value)
            setUserFilter('filteredCodes', value, config)
            break
          case 'meetingTypes':
            context.commit('updateMeetingTypes', value)
            setUserFilter('filteredMeetingTypes', value, config)
            break
          case 'statuses':
            context.commit('updateStatuses', value)
            setUserFilter('filteredStatuses', value, config)
            break
          case 'starters':
            context.commit('updateStarters', value)
            setUserFilter('filteredStarters', value, config)
            break
          case 'distances':
            context.commit('updateDistances', value)
            setUserFilter('filteredDistances', value, config)
            break
          case 'conditions':
            context.commit('updateConditions', value)
            setUserFilter('filteredConditions', value, config)
            break
          case 'meetings':
            context.commit('updateMeetings', value)
            break
          case 'races':
            context.commit('updateRaces', value)
            break
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('updateRaceNavFilter', { filter, value })
        } })
      }
    },
    resetRaceFilters (context) {
      try {
        const config = context.getters['account/authConfig']
        context.commit('updateCountries', defaultCountries)
        setUserFilter('filteredCountries', defaultCountries, config)
        context.commit('updateCodes', defaultCodes)
        setUserFilter('filteredCodes', defaultCodes, config)
        context.commit('updateMeetingTypes', defaultMeetingTypes)
        setUserFilter('filteredMeetingTypes', defaultMeetingTypes, config)
        context.commit('updateStatuses', defaultStatuses)
        setUserFilter('filteredStatuses', defaultStatuses, config)
        context.commit('updateStarters', defaultStarters)
        setUserFilter('filteredStarters', defaultStarters, config)
        context.commit('updateDistances', defaultDistances)
        setUserFilter('filteredDistances', defaultDistances, config)
        context.commit('updateConditions', defaultConditions)
        setUserFilter('filteredConditions', defaultConditions, config)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('resetRaceFilters')
        } })
      }
    },
    async fetchUserFilters (context) {
      try {
        context.dispatch('fetchAgencySettings')
        const config = context.getters['account/authConfig']
        const keyList = [
          'filteredCountries',
          'filteredCodes',
          'sortedPrices',
          'filteredMeetingTypes',
          'filteredStatuses',
          'filteredStarters',
          'filteredDistances',
          'filteredConditions',
          'raceResultsShowHide',
          'audioPriceAlertToggle',
          'raceFontSizeSet',
          'highlightBestOddsSet',
          'gridSettingsSet',
          'betFairSettingsSet',
          'betFairCommission',
          'betLimitsSet',
          'selectedColumnsName'
        ]
        const results = await batchFetch(keyList, config)
        if (!results) {
          return
        }
        // const savedCountries = await fetchUserFilter('filteredCountries', config)
        if (results.selectedColumnsName) {
          context.dispatch('fetchUserColumns', { name: JSON.parse(results.selectedColumnsName) })
        } else {
          context.dispatch('fetchUserColumns', { name: '' })
        }
        if (results.filteredCountries) {
          context.commit('updateCountries', JSON.parse(results.filteredCountries))
        }
        // const savedCodes = await fetchUserFilter('filteredCodes', config)
        if (results.filteredCodes) {
          context.commit('updateCodes', JSON.parse(results.filteredCodes))
        }
        // const savedSortOrder = await fetchUserFilter('sortedPrices', config)
        if (results.sortedPrices) {
          context.commit('updateSortPrices', JSON.parse(results.sortedPrices))
        }
        // const savedMeetingTypes = await fetchUserFilter('filteredMeetingTypes', config)
        if (results.filteredMeetingTypes) {
          context.commit('updateMeetingTypes', JSON.parse(results.filteredMeetingTypes))
        }
        // const savedStatuses = await fetchUserFilter('filteredStatuses', config)
        if (results.filteredStatuses) {
          context.commit('updateStatuses', JSON.parse(results.filteredStatuses))
        }
        // const savedStarters = await fetchUserFilter('filteredStarters', config)
        if (results.filteredStarters) {
          context.commit('updateStarters', JSON.parse(results.filteredStarters))
        }
        // const savedDistances = await fetchUserFilter('filteredDistances', config)
        if (results.filteredDistances) {
          context.commit('updateDistances', JSON.parse(results.filteredDistances))
        }
        // const savedConditions = await fetchUserFilter('filteredConditions', config)
        if (results.filteredConditions) {
          context.commit('updateConditions', JSON.parse(results.filteredConditions))
        }
        // const savedResultsShowHide = await fetchUserFilter('raceResultsShowHide', config)
        if (results.raceResultsShowHide) {
          context.commit('updateResultsShowHide', JSON.parse(results.raceResultsShowHide))
        }
        // const savedAudioPriceAlert = await fetchUserFilter('audioPriceAlertToggle', config)
        if (results.audioPriceAlertToggle) {
          context.commit('updateAudioPriceAlert', JSON.parse(results.audioPriceAlertToggle))
        }
        // const savedRaceFontSize = await fetchUserFilter('raceFontSizeSet', config)
        if (results.raceFontSizeSet) {
          context.commit('updateRaceFontSize', JSON.parse(results.raceFontSizeSet))
        }
        // const savedHighlightBestOddsSet = await fetchUserFilter('highlightBestOddsSet', config)
        if (results.highlightBestOddsSet) {
          context.commit('updateHighLightBestOdds', JSON.parse(results.highlightBestOddsSet))
        }
        // const savedGridSettings = await fetchUserFilter('gridSettingsSet', config)
        if (results.gridSettingsSet) {
          context.commit('updateGridSettings', JSON.parse(results.gridSettingsSet))
        }
        // const savedBetFairSettings = await fetchUserFilter('betFairSettingsSet', config)
        if (results.betFairSettingsSet) {
          context.commit('updateBetFairSettings', JSON.parse(results.betFairSettingsSet))
        }
        // const savedBetFairComission = await fetchUserFilter('betFairCommission', config)
        if (results.betFairCommission) {
          context.commit('updateBetFairComission', Number(JSON.parse(results.betFairCommission)))
        }
        // const savedBetLimits = await fetchUserFilter('betLimitsSet', config)
        if (results.betLimitsSet) {
          context.commit('updatebetLimits', JSON.parse(results.betLimitsSet))
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchUserFilters')
        } })
      }
      // const races = results[`myRaces:${date}`]
      // context.commit('addToMyRaces', { races: [] })
      // if (races) {
      //   context.commit('addToMyRaces', { races })
      // }
    },
    async selectRace (context, { id }) {
      if (!id) return
      if (id === context.state.selectedRace.id) return
      await context.dispatch('fetchRace', { id, columns: context.rootState.userConfig.selectedColumns })
      // context.commit('setMarketMoverBookie', { bookie: 'SB2' })
      context.dispatch('getNewFlucs', { source: context.state.marketMoverBookie })
      context.commit('clearColumnFlucs')
      // Vue.nextTick()
      //   .then(function () {
      //     context.dispatch('fetchRace', { id, columns: context.rootState.userConfig.selectedColumns })
      //   }
    },
    selectNavRace (context, { id }) {
      context.commit('selectNavRace', { id })
    },
    async setDate (context, d) {
      context.commit('setDate', d)
      // await context.dispatch('fetchRaces')
      const date = format(d, 'yyyy-MM-dd')
      await context.dispatch('fetchCustomShowHideMeetingRacesData', { id: date })
    },
    async setDateMeetingSummary (context, d) {
      context.commit('setDateMeetingSummary', d)
      // await context.dispatch('fetchMeetingSummaryRaces')
      const date = format(d, 'yyyy-MM-dd')
      await context.dispatch('fetchCustomShowHideMeetingRacesData', { id: date })
    },
    setApiKey (context, key) {
      context.commit('setApiKey', key)
    },
    setMobile (context, v) {
      context.commit('setMobile', v)
    },
    setEditMode (context) {
      context.commit('setEditMode')
    },
    async fetchDynamicRaceData (context, { race, columns }) {
      const config = context.getters['account/authConfig']
      const gbsId = race.meeting.externalIDs[0].id
      const raceNum = race.number
      const data = await getDynamicRaceData(gbsId, raceNum, config)

      for (const competitor of race.competitors) {
        const runnerIndex = data.runners.findIndex(r => r.no === competitor.tabNo)
        const runnerId = competitor.runner.id
        const keysToBeFetched = [`competitorRatings-${runnerId}`, `competitorPrices-${runnerId}`]
        const userDataDynamicForm = await batchFetchDynamicFormData(keysToBeFetched, config)

        if (!isEmpty(userDataDynamicForm) && userDataDynamicForm[`competitorRatings-${runnerId}`]) {
          const competitorRatings = JSON.parse(userDataDynamicForm[`competitorRatings-${runnerId}`]) || []
          const ratingDataFromDB = competitorRatings[0]
          if (ratingDataFromDB.gbsMeetingId === race.meeting.externalIDs[0].id && ratingDataFromDB.raceNumber === race.number) {
            data.runners[runnerIndex].rating_dynamicform = ratingDataFromDB.rating
            await context.dispatch('setMyCompetitorRating', { rating: ratingDataFromDB.rating, runnerId: runnerId })
          }
        } else {
          data.runners[runnerIndex].rating_dynamicform = competitor?.rating?.dry
        }

        if (!isEmpty(userDataDynamicForm) && userDataDynamicForm[`competitorPrices-${runnerId}`]) {
          const competitorMyPrice = JSON.parse(userDataDynamicForm[`competitorPrices-${runnerId}`]) || []
          const myPriceDataFromDB = competitorMyPrice[0]
          if (myPriceDataFromDB.gbsMeetingId === race.meeting.externalIDs[0].id && myPriceDataFromDB.raceNumber === race.number) {
            data.runners[runnerIndex].my_price_dynamicform = myPriceDataFromDB.price
            await context.dispatch('setMyCompetitorPrice', { price: myPriceDataFromDB.price, runnerId: runnerId })
          }
        }

        data.runners[runnerIndex].odds_dynamicform = competitor.oddsToDisplay
      }
      context.commit('setDynamicRaceData', { data })
      context.dispatch('calculateOdds')
    },
    async fetchUserDetails (context) {
      await context.dispatch('account/fetchUser')
      context.dispatch('account/confirmBetting')
      // await context.dispatch('account/exchangeCoreAPIToken')
    },
    async fetchBettingAgencies (context) {
      const agencies = await getBettingAgencies()
      context.commit('setBettingAgencies', agencies)
    },
    async loginBetting (context) {
      try {
        await context.dispatch('account/loginBetting')
        context.dispatch('betting/fetchBetHistory')
        context.dispatch('fetchAgencySettings')
      } catch (error) {
      }
    },
    async logoutBetting (context) {
      try {
        await context.dispatch('account/logoutBetting')
      } catch (error) {
      }
    },
    async fetchAgencySettings (context) {
      try {
        const config = context.getters['account/authConfig']
        const agencySettings = await getAgencySettings(config)
        context.commit('setAgencySettings', agencySettings)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchAgencySettings')
        } })
      }
    },
    async setHideBalance (context, bookieCode) {
      try {
        const config = context.getters['account/authConfig']
        const agencySettings = context.state.agencySettings
        const agency = agencySettings[bookieCode] || {}
        if (agency) {
          agency.hideBalance = !agency.hideBalance
        } else {
          agency.hideBalance = false
        }
        agencySettings[bookieCode] = agency
        await setAgencySettings(agencySettings, config)
        context.dispatch('fetchAgencySettings')
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('setHideBalance', bookieCode)
        } })
      }
    },
    async createRaceAlert (context, { raceId, raceDate }) {
      const config = context.getters['account/authConfig']
      try {
        context.commit('saveRaceIDAlerts', { raceID: raceId })
        await axios.post('public/createRaceAlert', {
          raceId: raceId,
          raceDate: raceDate,
          triggered: 0
        }, config)
        context.dispatch('getRaceAlertsByUsername')
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('createRaceAlert', { raceId, raceDate })
        } })
      }
    },
    async removeRaceAlert (context, { raceId }) {
      const config = context.getters['account/authConfig']
      try {
        context.commit('clearRaceIDAlert', { raceID: raceId })
        await axios.post('public/removeRaceAlert', {
          raceId: raceId
        }, config)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('removeRaceAlert', { raceId })
        } })
      }
    },
    async saveConfigRaceAlerts (context, { alerts, soundsAlerts, timesAlerts }) {
      const config = context.getters['account/authConfig']
      var timesAlertsSelected = []
      timesAlerts.forEach(time => {
        if (time.value === '30 Sec') {
          timesAlertsSelected.push({ key: time.key, value: 30 })
        } else {
          const timeSplit = time.value.split(' ')
          timesAlertsSelected.push({ key: time.key, value: (timeSplit[0] * 60) })
        }
      })
      try {
        await axios.post('public/setUpRaceAlerts', {
          alerts: alerts,
          soundsAlerts: soundsAlerts,
          timeAlerts: timesAlertsSelected
        }, config)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('saveConfigRaceAlerts', { alerts, soundsAlerts, timesAlerts })
        } })
      }
    },
    async getRaceAlertsConfiguration (context) {
      const config = context.getters['account/authConfig']
      try {
        const result = await axios.post('public/getRaceAlertsConfiguration', {
        }, config)
        const raceAlertsConfiguration = result.data.configurationRaceAlert

        raceAlertsConfiguration.forEach(config => {
          var timeBeforeJump = config.timeBeforeJump
          if (timeBeforeJump === 30) {
            config.timeBeforeJump = timeBeforeJump + ' Sec'
          } else if (timeBeforeJump === 60) {
            config.timeBeforeJump = (timeBeforeJump / 60) + ' Min'
          } else {
            config.timeBeforeJump = (timeBeforeJump / 60) + ' Mins'
          }
        })

        return raceAlertsConfiguration
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('getRaceAlertsConfiguration')
        } })
      }
    },
    async getRaceAlertsByUsername (context) {
      const config = context.getters['account/authConfig']
      const date = context.state.raceAlertsDate
      try {
        const result = await axios.post('public/getRaceAlertsByUsername', {
          raceDate: date
        }, config)
        if (Object.keys(result.data).length >= 1) {
          context.commit('clearAllRaceIDAlerts')
          result.data.raceAlerts.forEach(alert => {
            context.commit('saveRaceIDAlerts', { raceID: alert.raceID })
          })
          context.commit('setRaceAlerts', { raceAlerts: result.data.raceAlerts })
          return result.data.raceAlerts
        }
        return null
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('getRaceAlertsByUsername')
        } })
      }
    },
    async addToMyRaces (context, { id }) {
      try {
        const config = context.getters['account/authConfig']
        const race = context.state.races[id]
        const date = formatISO(zonedTimeToUtc(startOfDay(new Date(race?.meeting?.date)), 'GMT'))
        const savedRaces = await getMyRaces(date, config)
        const localRaces = new Set([...context.state.myRaces])
        const races = new Set(savedRaces)
        localRaces.add(id)
        races.add(id)
        context.commit('addToMyRaces', { races: Array.from(localRaces) })
        // context.commit('AddToMyRacesFull', { race: context.state.races[id] })
        setMyRaces(date, Array.from(races), config)
        context.dispatch('populateMyRaces', { races: Array.from(races) })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('addToMyRaces', { id })
        } })
      }
    },
    async removeFromMyRaces (context, { races }) {
      try {
        const config = context.getters['account/authConfig']
        const race = context.state.myRacesFull?.find(r => races.includes(r.id))
        const date = formatISO(zonedTimeToUtc(startOfDay(new Date(race?.meeting?.date)), 'GMT')) || formatISO(context.state.selectedDate)
        context.commit('removeFromMyRaces', { races })
        const savedRaces = await getMyRaces(date, config)
        setMyRaces(date, savedRaces.filter(id => !races.includes(id)), config)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('removeFromMyRaces', { races })
        } })
      }
    },
    async fetchMyRaces (context) {
      try {
        const config = context.getters['account/authConfig']
        const date = formatISO(context.state.selectedDate)
        context.commit('addToMyRaces', { races: [] })
        const dateRange = eachDayOfInterval({ start: Date.now(), end: addDays(Date.now(), 6) })
                          .map(d => formatISO(zonedTimeToUtc(d, 'GMT')))
                          .filter(d => d !== date)
        dateRange.push(date)
        dateRange.forEach(async date => {
          const races = await getMyRaces(date, config)
          if (races) {
            context.commit('addToMyRaces', { races })
            context.dispatch('populateMyRaces', { races })
          }
        })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchMyRaces')
        } })
      }
    },
    async populateMyRaces (context, { races }) {
      try {
        races
        .filter(id => !context.state.myRacesFull.find(rf => rf.id === id))
        .forEach(async id => {
          if (context.state.races[id]) {
            context.commit('AddToMyRacesFull', { race: context.state.races[id] })
          } else {
            const raceID = JSON.stringify(id)
            const query = basicRaceQuery(raceID)
            const result = await apolloClient.query({
              query: gql`${query}`
            })
            const race = result.data.race
            context.commit('AddToMyRacesFull', { race })
          }
        })
      } catch (error) {

      }
    },
    async fetchWatchList (context) {
      try {
        const d = zonedTimeToUtc(startOfDay(new Date()), 'GMT')
        const config = context.getters['account/authConfig']
        /* var priceAlertObjects = []
        for (let i = 0; i < 6; i++) {
          const config = context.getters['account/authConfig']
          const meetingDate = formatISO(d)
          const priceAlerts = await getPriceAlerts({ meetingDate, config })
          priceAlertObjects = priceAlertObjects.concat(priceAlerts)
          d.setDate(d.getDate() + 1)
        } */

        const dateFrom = formatISO(d)
        const dateTo = formatISO(d.setDate(d.getDate() + 4))
        const priceAlertObjects = await getPriceAlertsBtwDates({ dateFrom, dateTo, config })

        const priceAlertObjectsGrouped = priceAlertObjects.reduce((acc, obj) => {
          const key = obj.meeting_id + '-' + obj.race_number + '-' + obj.agency
          acc[key] = acc[key] || []
          acc[key].push(obj)
          return acc
        }, Object.create(null))

        context.commit('clearPriceAlerts')
        context.commit('setOriginalPriceAlert', { priceAlerts: priceAlertObjects })
        // if (priceAlertObjects) {
        //   priceAlertObjects.forEach(priceAlert => {
        //     context.dispatch('fetchPriceAlertRunnerInfo', { priceAlert })
        //   })
        // }
        if (!isEmpty(priceAlertObjectsGrouped)) {
          context.dispatch('fetchPriceAlertRunnerInfo', { priceAlertObjectsGrouped })
        }
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchWatchList')
        } })
      }
    },
    async fetchPriceAlertRunnerInfo (context, { priceAlertObjectsGrouped }) {
      for (let key in priceAlertObjectsGrouped) {
        const separatedKey = key.split('-')
        const meetingId = JSON.stringify(separatedKey[0])
        const raceID = JSON.stringify(parseInt(separatedKey[1]))
        const sources = separatedKey[2] !== 'undefined' ? JSON.stringify([separatedKey[2]]) : JSON.stringify([])
        const priceAlerts = priceAlertObjectsGrouped[key]

        try {
          if (raceID) {
            // const sources = priceAlert.agency ? JSON.stringify([priceAlert.agency]) : JSON.stringify([])
            const query = priceAlertInfoQuery(meetingId, raceID, sources)
            const result = await apolloClient.query({
              query: gql`${query}`
            })

            priceAlerts.forEach(priceAlert => {
              const competitor = result.data.meeting.races?.[0].competitors.find(c => c.tabNo === priceAlert.runner_number)
              const race = result.data.meeting.races?.[0]
              const track = result.data.meeting.track.name
              const prices = result.data.meeting.races?.[0].priceSets.map(p => p.prices.find(x => x.tabNo === priceAlert.runner_number))
              const priceAlertObject = { ...priceAlert, race, track, ...competitor }
              context.commit('addPriceAlert', { priceAlert: priceAlertObject })
              prices.forEach(price => {
                context.commit('addPriceAlertPrice', { priceAlert, price })
              })
              context.dispatch('subscribeToWatchListPrices', { priceAlert: priceAlertObject })
            })
          }
        } catch (error) {
          context.dispatch('account/updateTokenExpired', { callback: () => {
            context.dispatch('fetchPriceAlertRunnerInfo', { priceAlertObjectsGrouped })
          } })
        }
      }
      // if (priceAlert) {
      //   const meetingId = JSON.stringify(priceAlert.meeting_id)
      //   const raceID = JSON.stringify(priceAlert.race_number)
      //   try {
      //     if (raceID) {
      //       const sources = priceAlert.agency ? JSON.stringify([priceAlert.agency]) : JSON.stringify([])
      //       const query = priceAlertInfoQuery(meetingId, raceID, sources)
      //       const result = await apolloClient.query({
      //         query: gql`${query}`
      //       })
      //       const competitor = result.data.meeting.races?.[0].competitors.find(c => c.tabNo === priceAlert.runner_number)
      //       const race = result.data.meeting.races?.[0]
      //       const track = result.data.meeting.track.name
      //       const prices = result.data.meeting.races?.[0].priceSets.map(p => p.prices.find(x => x.tabNo === priceAlert.runner_number))
      //       const priceAlertObject = { ...priceAlert, race, track, ...competitor }
      //       context.commit('addPriceAlert', { priceAlert: priceAlertObject })
      //       prices.forEach(price => {
      //         context.commit('addPriceAlertPrice', { priceAlert, price })
      //       })
      //       context.dispatch('subscribeToWatchListPrices', { priceAlert: priceAlertObject })
      //     }
      //   } catch (error) {
      //     context.dispatch('account/updateTokenExpired', { callback: () => {
      //       context.dispatch('fetchPriceAlertRunnerInfo', { priceAlert })
      //     } })
      //   }
      // }
    },
    async subscribeToWatchListPrices (context, { priceAlert }) {
      if (priceAlert.race.id && priceAlert.agency) {
        // context.dispatch('account/updateAuthToken')
        try {
          const raceID = JSON.stringify(priceAlert.race.id)
          const sources = JSON.stringify([priceAlert.agency])
          const query = subscribePrices(raceID, sources)
          const result = await apolloClient.subscribe({
            query: gql`${query}`
          })
          const subscription = result.subscribe({
            next (data) {
              const price = {
                ...data.data.priceUpdates,
                prices: data.data.priceUpdates.prices.map(price => {
                  return {
                    ...price,
                    price: Math.round((price.price + Number.EPSILON) * 100) / 100
                  }
                })
              }
              if (price) {
                const runnerPrice = price.prices.find(p => p.tabNo === priceAlert.runner_number)
                context.commit('addPriceAlertPrice', { priceAlert, price: runnerPrice })
              }
              context.commit('socketOpen')
            },
            error (error) {
              context.commit('socketClosed')
              return error
            }
          })
          context.commit('updateWatchListPriceSubscriptions', { id: priceAlert.alert_id, subscription })
        } catch (error) {
          context.dispatch('account/updateTokenExpired', { callback: () => {
            context.dispatch('subscribeToWatchListPrices', { priceAlert })
          } })
        }
      }
    },
    async deletePriceAlert (context, { alertId }) {
      try {
        const config = context.getters['account/authConfig']
        const meetingDate = formatISO(context.state.selectedDate)
        await deletePriceAlert({ alertId, meetingDate, config })
        context.commit('removePriceAlert', { alertId })

        const filteredOGAlerts = context.state.ogPriceAlerts.filter(alert => alert?.alert_id !== alertId)
        context.commit('setOriginalPriceAlert', { priceAlerts: filteredOGAlerts })
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('deletePriceAlert', { alertId })
        } })
      }
    },
    setPriceAlertTriggered (context, { alertId }) {
      context.commit('setPriceAlertTriggered', { alertId })
    },
    async fetchAzList (context) {
      // context.dispatch('account/updateAuthToken')
      const today = new Date()
      const d = zonedTimeToUtc(startOfDay(today), 'GMT')
      const t = zonedTimeToUtc(startOfDay(today.setDate(today.getDate() + 4)), 'GMT')
      try {
        const date = JSON.stringify(d)
        const to = JSON.stringify(t)
        const query = meetingsDatedAz(date, to)
        const result = await apolloClient.query({
          query: gql`${query}`
        })

        let meetings = []
        if (!isEmpty(result?.data?.meetingsDated)) {
          meetings = result.data.meetingsDated
        }

        context.commit('clearAzLists')
        meetings.forEach(m => {
          m.races.forEach(r => {
            r.competitors.forEach(c => {
              const formHistory = !isEmpty(c.formHistory) || []

              context.commit('addAzList', {
                meetingType: m?.type,
                runner: c?.name === '' ? '-' : c?.name,
                jockeyDriver: c?.jockey === '' ? '-' : c?.jockey,
                trainer: c?.trainer === '' ? '-' : c?.trainer,
                race: format(parseISO(r?.startTime), 'HH:mm') + ' ' + m?.track?.name + ' ' + r?.number,
                date: format(parseISO(r?.startTime), 'dd/MM/yyyy'),
                competitorNumber: c.tabNo,
                barrier: c?.barrier,
                handicap: c?.weightAllocated,
                raceID: r?.id,
                startTime: r?.startTime,
                status: r?.status,
                raceName: m?.track?.name,
                raceNumber: r?.number,
                formHistory,
                runnerId: c?.runner?.id,
                externalMeetingID: m?.externalIDs[0]?.id,
                tabNo: c?.tabNo,
                raceCountry: m?.track?.country,
                weight: c?.weightTotal,
                scratched: c?.scratched,
                distance: r?.distance
              })
            })
          })
        })

        if (context.getters.hasFeature('dualAcceptances')) context.commit('updateDualAcceptances', { azList: context.state.azList })
        context.commit('updateLoadAZListData', false)
      } catch (error) {
        context.dispatch('account/updateTokenExpired', { callback: () => {
          context.dispatch('fetchAzList')
        } })
      }
    },
    searchAzLists (context, { search }) {
      context.commit('clearAzSearchResults')

      if (typeof search === 'undefined') {
        context.commit('clearAzSearchResults')
      } else {
        context.state.azList.forEach(competitor => {
          if (competitor.runner.toLowerCase().includes(search.toLowerCase()) || competitor.jockeyDriver.toLowerCase().includes(search.toLowerCase()) || competitor.trainer.toLowerCase().includes(search.toLowerCase())) {
            context.state.azSearchResults.push(competitor)
          }
        })
      }
    },
    async getNewFlucs (context, { source }) {
      await context.dispatch('fetchFlucs', { source: source, type: BOOKMAKER_FLUC_TYPE[source] })
      const race = context.getters.getSelectedRace(context.getters.getColumns)
      let marketsMover = []
      const promises = []
      race.competitors.forEach((runner) => {
        promises.push(context.dispatch('getPriceShift', { source: source, runner: runner, raceID: race.id }))
      })
      const outputs = await Promise.all(promises)
      outputs.forEach((result) => {
        marketsMover = marketsMover.concat({
          competitor: result.tabNo + '. ' + result.runnerName,
          firstPrice: result.firstPrice,
          lastPrice: result.lastPrice,
          percentage: result.percentage
        })
      })
      let maxMover = Math.min(...marketsMover.map(function (m) { return m.percentage }))
      let objMaxMover = marketsMover.find(function (m) { return m.percentage === maxMover })
      context.commit('setMarketMover', objMaxMover)
    },
    async getPriceShift (context, { source, runner, raceID }) {
      // All flucs
      const runnerFlucs = await context.dispatch('getFlucs', { source: source, tabNo: runner.tabNo, raceID: raceID })
      // First Price
      const calcFirstPrice = runnerFlucs.map((b) => b.price)
      var firstPrice = calcFirstPrice[0]
      if (firstPrice === 0) {
        firstPrice = calcFirstPrice[1]
      }
      // Last Price
      const calcLastPrice = runnerFlucs.map((d) => d.price)
      const lastPrice = calcLastPrice[calcLastPrice.length - 1]
      // Percentage method
      const decreaseVal = lastPrice - firstPrice
      const percDifference = (decreaseVal / firstPrice) * 100
      let value = 0
      if (Math.round(percDifference * 10) / 10 > 0) {
        value = Math.round(percDifference * 10) / 10
        // percentages = percentages.concat(value)
        // return `+${Math.round(percDifference * 10) / 10}`
      } else {
        value = Math.round(percDifference * 10) / 10 || 0
        // percentages = percentages.concat(value)
        // Return
        // return Math.round(percDifference * 10) / 10 || 0
      }
      return { tabNo: runner.tabNo, runnerName: runner.name, firstPrice: firstPrice, lastPrice: lastPrice, percentage: value }
    },
    async getFlucs (context, { source, tabNo, raceID }) {
      const flucs = []
      const type = BOOKMAKER_FLUC_TYPE[source]
      const key = `${raceID}:${source}:${type}`
      if (context.state.flucs && context.state.flucs[key]) {
        const flucsFil = context.state.flucs[key].find(f => f.flucs[0]?.tabNo === tabNo)
        if (!flucsFil?.flucs) return []
        flucsFil.flucs.forEach(runner => {
          flucs.push(runner)
        })
      }
      return flucs
    },
    setMarketMoverBookie (context, { source }) {
      context.commit('setMarketMoverBookie', { bookie: source })
      const config = context.getters['account/authConfig']
      setMarketMoverBookie(source, config)
      context.dispatch('getNewFlucs', { source: source })
    },
    setSelectedBookie (context, { source }) {
      context.commit('setSelectedBookie', { bookie: source })
    },
    async fetchMarketMoverBookie (context) {
      const config = context.getters['account/authConfig']
      const marketMoverBookieSaved = await getMarketMoverBookie(config)

      if (typeof marketMoverBookieSaved !== 'undefined') {
        context.dispatch('setMarketMoverBookie', { source: marketMoverBookieSaved })
      }
    },
    setRacesGroupByMeeting (context, { value }) {
      const config = context.getters['account/authConfig']
      setRacesGroupByMeeting(value, config)
      context.commit('setRacesGroupByMeeting', { value: value })
    },
    async fetchExotics (context) {
      const raceID = JSON.stringify(context.state.selectedRace.id)
      const query = queryExotics(raceID)
      const result = await apolloClient.query({
        query: gql`${query}`
      })
      const exotics = result.data.race.exotics
      context.commit('setExoticsRaceSelected', { exotics: exotics })
    },
    async verifyUserFormAnalyser (context) {
      const config = context.getters['account/authConfig']
      try {
        const result = await axios.post('public/verifyUserFormAnalyser', {}, config)
        if (result.data.authorized) {
          const token = result.data.token
          const user = result.data.user
          window.open(`https://formanalyzer.gcpuatau.tbm.sh/login?token=${token}&user=${user}`, '_blank')
          // window.open(`http://localhost:8083/login?token=${token}&user=${user}`, '_blank')
        } else {
          context.commit('setShowNoFAAccess', { value: true })
        }
      } catch (error) {
        //
      }
    },
    updateShowNoFAAccess (context, { value }) {
      context.commit('setShowNoFAAccess', { value: value })
    },
    toggleIsAutoHide (context, isChecked) {
      context.commit('updateIsCheckedState', isChecked)
    },
    setTokenCoreApi (context) {
      const token = context.getters['account/getCoreToken']
      window.localStorage.setItem('token_core_api', token)
    },
    saveCoreToken (context) {
      const token = window.localStorage.getItem('token_core_api')
      context.commit('account/saveCoreApiToken', { token: token })
    },
    async fetchDynamicFormData (context) {
      try {
        const config = context.getters['account/authConfig']
        const keyList = [
          'runnerBlackbook',
          'runnerBlackbookComments',
          'jockeyBlackbook',
          'jockeyBlackbookComments',
          'trainerBlackbook',
          'trainerBlackbookComments'
        ]
        const userData = await batchFetchDynamicFormData(keyList, config)
        if (!isEmpty(userData)) {
          context.commit('updateDynamicFormData', { userData })
        }
      } catch (err) {
      }
    },
    async multiDeleteRunnersFromBlackbook (context, { blackbooks, runnersToDelete }) {
      const config = context.getters['account/authConfig']

      // dynamicFormSetUserData('runnerBlackbook', blackbooks, config)
      runnersToDelete.forEach(r => {
        deleteBlackbookEntry(r.runnerId, 'runnerBlackbook', config)
      })

      context.commit('removeRunnerBlackbook', { runnerBlackbook: blackbooks })
    },
    async deleteRunnerFromBlackbook (context, { blackbook }) {
      const config = context.getters['account/authConfig']

      // dynamicFormSetUserData('runnerBlackbook', runnerBlackbook, config)
      deleteBlackbookEntry(blackbook.runnerId, 'runnerBlackbook', config)
      const runnerBlackbook = context.state.runnerBlackbook.filter(co => `${co.runnerId}-${co.tabType}` !== `${blackbook.runnerId}-${blackbook.tabType}`)

      const runnerComments = { ...context.state.runnerBlackbookComments }
      delete runnerComments[blackbook.runnerId]

      context.commit('removeRunnerBlackbook', { runnerBlackbook })
      context.commit('updateRunnerBlackbookComments', { comments: runnerComments })
      dynamicFormSetUserData('runnerBlackbookComments', runnerComments, config)
    },
    async setBlackbookComments (context, { blackbook, newBlackbookComments }) {
      const config = context.getters['account/authConfig']

      const { runnerBlackbookComments, jockeyBlackbookComments, trainerBlackbookComments } = context.state

      if (blackbook.tabType === 'runnerTab') {
        const comments = { ...runnerBlackbookComments, ...newBlackbookComments }
        context.commit('updateRunnerBlackbookComments', { comments })
        dynamicFormSetUserData('runnerBlackbookComments', comments, config)
      } else if (blackbook.tabType === 'jockeyTab') {
        const comments = { ...jockeyBlackbookComments, ...newBlackbookComments }
        context.commit('updateJockeyBlackbookComments', { comments })
        dynamicFormSetUserData('jockeyBlackbookComments', comments, config)
      } else if (blackbook.tabType === 'trainerTab') {
        const comments = { ...trainerBlackbookComments, ...newBlackbookComments }
        context.commit('updateTrainerBlackbookComments', { comments })
        dynamicFormSetUserData('trainerBlackbookComments', comments, config)
      }
    },
    async deleteJockeyFromBlackbook (context, { blackbook }) {
      const config = context.getters['account/authConfig']

      // dynamicFormSetUserData('jockeyBlackbook', jockeyBlackbook, config)
      deleteBlackbookEntry(blackbook.runnerId, 'jockeyBlackbook', config)

      const jockeyBlackbook = context.state.jockeyBlackbook.filter(co => `${co.runnerId}-${co.tabType}` !== `${blackbook.runnerId}-${blackbook.tabType}`)

      const jockeyComments = { ...context.state.jockeyBlackbookComments }
      delete jockeyComments[blackbook.runnerId]

      context.commit('removeJockeyFromBlackbook', { jockeyBlackbook })
      context.commit('updateJockeyBlackbookComments', { comments: jockeyComments })
      dynamicFormSetUserData('jockeyBlackbookComments', jockeyComments, config)
    },
    async deleteTrainerFromBlackbook (context, { blackbook }) {
      const config = context.getters['account/authConfig']

      // dynamicFormSetUserData('trainerBlackbook', trainerBlackbook, config)
      deleteBlackbookEntry(blackbook.runnerId, 'trainerBlackbook', config)

      const trainerBlackbook = context.state.trainerBlackbook.filter(co => `${co.runnerId}-${co.tabType}` !== `${blackbook.runnerId}-${blackbook.tabType}`)

      const trainerComments = { ...context.state.trainerBlackbookComments }
      delete trainerComments[`${blackbook.runnerId}-${blackbook.tabType}`]

      context.commit('removeTrainerFromBlackbook', { trainerBlackbook })
      context.commit('updateTrainerBlackbookComments', { comments: trainerComments })
      dynamicFormSetUserData('trainerBlackbookComments', trainerComments, config)
    },
    async addToBlackBook (context, { blackbook }) {
      const config = context.getters['account/authConfig']

      if (blackbook.tabType === 'runnerTab') {
        // dynamicFormSetUserData('runnerBlackbook', context.state.runnerBlackbook, config)
        insertBlackbookEntry(blackbook.tabType, blackbook.runnerId, blackbook.selectedTabTypeName, blackbook.raceNumber, blackbook.meetingId, blackbook.meetingType, 'runnerBlackbook', config)
        context.commit('updateRunnerBlackbook', { blackbook, initialLoad: false })
      } else if (blackbook.tabType === 'jockeyTab') {
        // dynamicFormSetUserData('jockeyBlackbook', context.state.jockeyBlackbook, config)
        insertBlackbookEntry(blackbook.tabType, blackbook.runnerId, blackbook.selectedTabTypeName, blackbook.raceNumber, blackbook.meetingId, blackbook.meetingType, 'jockeyBlackbook', config)
        context.commit('updateJockeyBlackbook', { blackbook, initialLoad: false })
      } else if (blackbook.tabType === 'trainerTab') {
        // dynamicFormSetUserData('trainerBlackbook', context.state.trainerBlackbook, config)
        insertBlackbookEntry(blackbook.tabType, blackbook.runnerId, blackbook.selectedTabTypeName, blackbook.raceNumber, blackbook.meetingId, blackbook.meetingType, 'trainerBlackbook', config)
        context.commit('updateTrainerBlackbook', { blackbook, initialLoad: false })
      }
    },
    async multiDeleteJockeyFromBlackbook (context, { blackbooks, jockeysToDelete }) {
      const config = context.getters['account/authConfig']

      // dynamicFormSetUserData('jockeyBlackbook', blackbooks, config)
      jockeysToDelete.forEach(r => {
        deleteBlackbookEntry(r.runnerId, 'jockeyBlackbook', config)
      })
      context.commit('removeJockeyFromBlackbook', { jockeyBlackbook: blackbooks })
    },
    async multiDeleteTrainerFromBlackbook (context, { blackbooks, trainersToDelete }) {
      const config = context.getters['account/authConfig']

      // dynamicFormSetUserData('trainerBlackbook', blackbooks, config)
      trainersToDelete.forEach(r => {
        deleteBlackbookEntry(r.runnerId, 'trainerBlackbook', config)
      })

      context.commit('removeTrainerFromBlackbook', { trainerBlackbook: blackbooks })
    },
    toggleDualAcceptances (context, { boolean }) {
      context.commit('updateDualAccepteances', { boolean })
    },
    async setMyCompetitorPrice (context, { price, runnerId }) {
      const isTrial = context.getters['account/getIsTrial']
      const config = context.getters['account/authConfig']

      const race = context.getters.getSelectedRace()
      race.competitors.find(co => co.runner.id === runnerId).myPrice = parseFloat(price)
      context.commit('updateDetailedRace', { race })

      const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
      const raceNumber = race?.number

      const { myCompetitorPricesFromDB } = context.state

      const competitorPrices = myCompetitorPricesFromDB[runnerId] ? [...myCompetitorPricesFromDB[runnerId]] : []

      if (isEmpty(competitorPrices)) {
        competitorPrices.push({ runnerId, price, gbsMeetingId, raceNumber })
      } else {
        const currentRunnerIndex = competitorPrices.findIndex(runner => runner.gbsMeetingId === gbsMeetingId && runner.raceNumber === raceNumber)
        if (currentRunnerIndex > -1) {
          competitorPrices[currentRunnerIndex] = { runnerId, price, gbsMeetingId, raceNumber }
        } else {
          competitorPrices.push({ runnerId, price, gbsMeetingId, raceNumber })
        }
      }

      context.commit('saveCompetitorPrices', { competitorObj: competitorPrices, runnerId })

      if (!isTrial) {
        dynamicFormSetUserData(`competitorPrices-${runnerId}`, competitorPrices, config)
      }
    },
    async setMyCompetitorRating (context, { rating, runnerId }) {
      const isTrial = context.getters['account/getIsTrial']
      const config = context.getters['account/authConfig']

      const race = context.getters.getSelectedRace()
      const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
      const raceNumber = race?.number

      const { myCompetitorRatingsFromDB } = context.state

      const competitorRatings = myCompetitorRatingsFromDB[runnerId] ? [...myCompetitorRatingsFromDB[runnerId]] : []

      if (isEmpty(competitorRatings)) {
        competitorRatings.push({ runnerId, rating, gbsMeetingId, raceNumber })
      } else {
        const currentRunnerIndex = competitorRatings.findIndex(runner => runner.gbsMeetingId === gbsMeetingId && runner.raceNumber === raceNumber)
        if (currentRunnerIndex > -1) {
          competitorRatings[currentRunnerIndex] = { runnerId, rating, gbsMeetingId, raceNumber }
        } else {
          competitorRatings.push({ runnerId, rating, gbsMeetingId, raceNumber })
        }
      }

      context.commit('saveCompetitorRatings', { competitorObj: competitorRatings, runnerId })

      if (!isTrial) {
        dynamicFormSetUserData(`competitorRatings-${runnerId}`, competitorRatings, config)
      }

      context.dispatch('calculateOdds')
    },
    async calculateOdds (context) {
      try {
        const race = context.getters.getSelectedRace()
        const raceNumber = race.number
        const gbsMeetingId = race.meeting?.externalIDs[0].id
        const config = context.getters['account/authConfig']
        const dataFilteredToOdds = []
        const { myCompetitorRatingsFromDB } = context.state

        for (const c of race.competitors) {
          const runnerId = c.runner.id
          if (!isEmpty(myCompetitorRatingsFromDB[runnerId])) {
            const customizedRating = myCompetitorRatingsFromDB[runnerId].find(rating => rating.gbsMeetingId === gbsMeetingId && rating.raceNumber === raceNumber)
            const ratingDryAdjusted = !isEmpty(customizedRating) ? customizedRating.rating : c.rating?.dry
            dataFilteredToOdds.push({ ratingDry: parseFloat(ratingDryAdjusted), ratingWet: c.rating?.wet, scratched: c.scratched })
          } else {
            dataFilteredToOdds.push({ ratingDry: c.rating?.dry, ratingWet: c.rating?.wet, scratched: c.scratched })
          }
        }

        const calculationResult = await axios.post(`${process.env.VUE_APP_DYNAMIC_FORM_API}/api.public.Public/OddsCalculation`, {
          competitors: dataFilteredToOdds,
          marketRange: context.state.marketRange,
          marketPercentage: context.state.marketPercentage,
          oddsToUse: context.state.oddsToUse,
          oddsFormat: context.state.oddsFormat,
          ratingToUse: context.state.ratingToUse
        }, config)

        const oddsCalculation = calculationResult.data.competitorOdds

        race.competitors.forEach((c, index) => {
          c.oddsToDisplay = oddsCalculation[index]?.oddsToDisplay ?? ''
          if (!isEmpty(context.state.dynamicRaceData)) {
            const runnerIndex = context.state.dynamicRaceData.runners.findIndex(r => r.no === c.tabNo)
            context.state.dynamicRaceData.runners[runnerIndex].odds_dynamicform = oddsCalculation[index]?.oddsToDisplay ?? ''
          }
        })
      } catch (error) {
        // context.dispatch('reloginInformTrigger', { error })
      }
    }
  },
  getters: {
    getAllRaces (state) {
      return function (meetingID) {
        const races = [...state.meetings[meetingID].races]
        return races
      }
    },
    getAllMeetings (state) {
      return function () {
        const meetings = state.meetings
        return meetings
      }
    },
    getAllMeetingSummaryRaces (state) {
      return function (meetingID) {
        const races = [...state.meetingSummaryMeetings[meetingID].races]
        return races
      }
    },
    getAllMeetingSummaryMeetings (state) {
      return function () {
        const meetings = state.meetingSummaryMeetings
        return meetings
      }
    },
    getStateDynamicRaceData (state) {
      return state.dynamicRaceData
    },
    getRunners (state) {
      return function (id) {
        const runner = state.detailedRunner[id]
        return runner
      }
    },
    hasFeature (state) {
      return function (feature) {
        const features = process.env.VUE_APP_FEATURE_FLAGS.split(',')
        return features.some((x) => x.trim() === feature.trim())
      }
    },
    getRdcConfig (getters) {
      const features = process.env.VUE_APP_RDC_MODE
      if (features === 'rdc') return true
      return false
    },
    getSelectedMeeting (state) {
      if (state.selectedRace.id === null) {
        return null
      }
      const race = state.races[state.selectedRace.id] || state.meetingSummaryRaces[state.selectedRace.id]
      if (race) {
        const meeting = state.meetings[race.meeting.id] || state.meetingSummaryMeetings[race.meeting.id]
        return meeting
      }
      return null
    },
    getNextToGo (state, getters) {
      const r = Object.values([...getters.getTodaysRaces, ...getters.getRaces])
      let res = null
      r.sort((a, b) => isBefore(a.isoStartTime, b.isoStartTime) ? -1 : 1)
      r.some((x) => {
        if (x.status === 'OPEN') {
          res = x
          return true
        }
      })
      return res
    },
    getTodaysRaces (state, getters) {
      return filterRacesByCondition(filterRacesByDistance(filterRacesByStarters(filterRacesByStatuses(filterRacesByMeetingType(filterRacesByCodes(filterRacesByCountry(filterRacesByShowHideMeeting(filterRacesByShowHideRace(state.todaysRaces, getters.getHideRacesSelected), getters.getShowHideMeetingsFilter), getters.getCountriesFilter), getters.getIsCodesSelectedFilter), getters.getMeetingTypesSelectedFilter), getters.getStatusesFilter), getters.getStartersFilter), getters.getDistanceFilter), getters.getConditionsFilter)
    },
    getRaces (state, getters) {
      const races = filterRacesByCondition(filterRacesByDistance(filterRacesByStarters(filterRacesByStatuses(filterRacesByMeetingType(filterRacesByCodes(filterRacesByCountry(filterRacesByShowHideMeeting(filterRacesByShowHideRace(state.races, getters.getHideRacesSelected), getters.getShowHideMeetingsFilter), getters.getCountriesFilter), getters.getIsCodesSelectedFilter), getters.getMeetingTypesSelectedFilter), getters.getStatusesFilter), getters.getStartersFilter), getters.getDistanceFilter), getters.getConditionsFilter)
      // console.log(races.length, Object.keys(state.races).length)
      // console.log(Object.values(state.races).filter(r => !races.find(b => b.id === r.id)))
      return races
    },
    getAllRacesFilteredOut (state, getters) {
      const races = state.races
      const filteredRaces = getters.getRaces
      if (filteredRaces.length === 0 && Object.keys(races).length > 0) return true
      return false
    },
    getRaceDetails (state) {
      return function (id) {
        const race = state.races[id] || state.meetingSummaryRaces[id]
        return race
      }
    },
    getAvailableAgencies (state) {
      return state.availableAgencies
    },
    getSelectedMeetingsRaces (state, getters) {
      return Object.values(state.races).filter(race => race?.meeting?.id === getters.getSelectedMeeting?.id)
    },
    getSourcePrices (state) {
      return function (source, tabNo, raceId) {
        if (!state.prices) {
          return []
        }
        const keys = Object.keys(state.prices).filter(k => k.includes(`${raceId}:${source}`))
        const prices = keys.map(k => {
          return { type: state.prices[k].type, ...state.prices[k].find(r => r.tabNo === tabNo) }
        })
        return prices
      }
    },
    getRaceAlertHistory (state) {
      return state.raceAlertHistory || {}
    },
    getAgencySettings (state) {
      return state.agencySettings
    },
    getBetFairPrices (state, getters) {
      return state.betFairPrices
    },
    getRaceSortOrder (state, getters) {
      const sortOrder = state.userConfig.priceSorting
      const competitors = state.detailedRaces[state.selectedRace?.id]?.competitors || []
      const runnersScrExclude = getters.getGridSettings
      const key = `${state.selectedRace.id}:${sortOrder.bookieCode}:${sortOrder.priceType}`
      const race = state.detailedRaces[state.selectedRace?.id]
      let p = []
      if (key.includes(':BF:')) {
        const type = sortOrder.type || 'WIN'
        p = Object.keys(getters.getBetFairPrices)
          .filter(k => k.includes(state.selectedRace.id) && k.includes(type))
          .map(p => {
            return { price: matchPriceToType(sortOrder.priceType, getters.getBetFairPrices[p], getters.getExchangeRate), tabNo: getters.getBetFairPrices[p].number }
          })
      } else if (sortOrder.priceType === 'CUSTOM_DATA') {
        const data = getters.getCustomColumnData(race.id, sortOrder.bookieCode)
        p = competitors.map(c => {
          const id = c?.runner?.id
          if (!id) return null
          return { price: data[id]?.value, tabNo: c.tabNo }
        }).filter(p => !!p)
      } else if (sortOrder.bookieCode === 'BTC') {
        p = competitors.map(c => {
          return { price: getters.getBestTotePrice(c.tabNo), tabNo: c.tabNo }
        })
      } else if (sortOrder.bookieCode === 'BPC') {
        const column = getters.getColumns[sortOrder.columnNumber]
        const filters = column?.filters || []
        p = competitors.map(c => {
          const prices = getters.getBestPriceSets(c.tabNo, filters)
          return { price: prices?.[0]?.price, tabNo: c.tabNo }
        })
      } else if (sortOrder.priceType === 'RUNNER_HISTORY') {
        const column = getters.getColumns[sortOrder.columnNumber]
        const selection = column?.selection
        const raceData = getters.getStateDynamicRaceData
        p = competitors.map(c => {
          const runner = raceData?.runners?.find(r => r.no === c.tabNo)
          const price = runner?.[selection]
          return { price, tabNo: c.tabNo }
        })
      } else if (sortOrder.priceType === 'RUNNER_INFO') {
        // Only sorts by barrier - We need to chenge the runnerInfoColumn to use the store or find another way to sort by that info
        p = competitors.map(c => {
          return { price: c.barrier, tabNo: c.tabNo }
        })
      } else if (sortOrder.bookieCode === 'OCC') {
        const columnFlucs = getters.getColumnFlucsFromKey(`${state.selectedRace.id}:${sortOrder.filters?.value}:WIN_FIXED_ODDS`)
        p = competitors.map(c => {
          return { price: getOCCShift(c.tabNo, columnFlucs), tabNo: c.tabNo }
        })
      } else {
        p = state.prices[key]?.filter(p => p.price)
      }
      const filteredCompetitors = runnersScrExclude.includes('excludeScratched') ? competitors.filter(r => !r.scratched) : competitors
      if ((race.status === 'FINAL' || race.status === 'INTERIM') && runnersScrExclude.includes('sortByResults')) {
        filteredCompetitors.sort(function (a, b) {
          if ((typeof a.finalPosition === 'undefined' || a.finalPosition === 0) && a.result > 0) {
            a.finalPosition = a.result
          }
          if ((typeof b.finalPosition === 'undefined' || b.finalPosition === 0) && b.result > 0) {
            b.finalPosition = b.result
          }
          if (typeof a.finalPosition !== 'undefined' && typeof b.finalPosition !== 'undefined') {
            if (a.finalPosition === 0) return 1 // Return 1 so that b goes first
            if (b.finalPosition === 0) return -1 // Return -1 so that a goes first
            return a.finalPosition - b.finalPosition
          }
        })
        if (runnersScrExclude.includes('excludeScratched')) {
          const scratched = competitors.filter(c => c.scratched)
          const allCompetitors = [...filteredCompetitors, ...scratched]
          return allCompetitors.map(r => r.tabNo)
        }
        return filteredCompetitors.map(r => r.tabNo)
      }
      if (!p && !runnersScrExclude.includes('excludeScratched')) {
        return null
      }
      if (!p && runnersScrExclude.includes('excludeScratched')) {
        const findScrComps = competitors.find(r => r.scratched)
        if (findScrComps) {
          competitors.sort(function (a, b) {
            if (a.scratched) return 1
            if (b.scratched) return -1
            return a.scratched - b.scratched
          })
          return competitors.map(r => r.tabNo)
        }
        return null
      }
      const competitorsWithPrices = p.map(price => {
        const findComp = competitors.find(c => c.tabNo === price.tabNo)
        return { scratched: findComp?.scratched, ...price }
      })
      if (p && runnersScrExclude.includes('excludeScratched')) {
        const compScr = competitorsWithPrices.sort(function (a, b) {
          if (sortOrder.context === 'lowToHigh') {
            if (a.price === '' || typeof a.price === 'undefined') return 1
            if (b.price === '' || typeof b.price === 'undefined') return -1
            return a.price - b.price
          } else {
            return b.price - a.price
          }
        })
        const sortedArray = compScr.sort(function (a, b) {
          if (a.scratched) return 1
          if (b.scratched) return -1
          return a.scratched - b.scratched
        })
        return sortedArray.map(r => r.tabNo)
      }
      if (sortOrder.context === 'lowToHigh' || sortOrder.context === 'highToLow') {
        p.sort(function (a, b) {
          if (sortOrder.context === 'lowToHigh') {
            if (a.price === '' || typeof a.price === 'undefined') return 1
            if (b.price === '' || typeof b.price === 'undefined') return -1
            return a.price - b.price
          } else {
            return b.price - a.price
          }
        })
      }
      return p.map(r => r.tabNo)
    },
    getCustomColumnData (state, getters) {
      return function (id, source) {
        const data = getters.getCustomData(id)
        let columnData = {}
        if (data && data[source]) {
          columnData = data[source]
        }
        return columnData
      }
    },
    getColumnFlucs (state) {
      return state.columnFlucs
    },
    getColumnFlucsFromKey (state) {
      return function (key) {
        return state.columnFlucs?.[key]
      }
    },
    getExoticsResults (state) {
      return state.exoticsResults
    },
    getSortedOrderBookie (state) {
      return state.userConfig.priceSorting
    },
    getUnsortedRaceCompetitors (state) {
      const competitors = state.detailedRaces[state.selectedRace?.id]?.competitors || []
      return competitors
    },
    getRaceCompetitors (state, getters) {
      const sortedArray = getters.getRaceSortOrder
      let sortedCompetitors = []
      const competitors = state.detailedRaces[state.selectedRace?.id]?.competitors || []
      if (sortedArray) {
        sortedArray.forEach(tabNo => {
          const c = competitors.find(r => r.tabNo === tabNo)
          sortedCompetitors.push(c)
        })
        competitors.forEach(r => {
          if (!sortedArray.includes(r.tabNo)) {
            sortedCompetitors.push(r)
          }
        })
      } else {
        sortedCompetitors = competitors
      }
      return sortedCompetitors
    },
    getRunnerFlucs (state) {
      return state.flucs
    },
    getCustomData (state) {
      return function (id) {
        return state.customRaceData[id]
      }
    },
    getCustomShowHideMeetingsData (state) {
      return function (id) {
        return state.customShowHideMeetingsData[id]
      }
    },
    getCustomShowHideRacesData (state) {
      return function (id) {
        return state.customShowHideRacesData[id]
      }
    },
    getCustomShowHideMeetingTypesData (state) {
      return function (id) {
        return state.customShowHideMeetingTypesData[id]
      }
    },
    getHideRacesSelected (state) {
      return state.customShowHideRacesData?.[format(state.selectedDate, 'yyyy-MM-dd')]?.['races-selected']
    },
    getPrevComments (state) {
      return state.prevComments
    },
    getSocketStatus (state) {
      return state.socket.isConnected
    },
    getMyRaces (state) {
      return state.myRacesFull || []
      // const races = state?.myRaces.map(id => {
      //   return state.races[id]
      // }) || []
      // return races.filter(r => !!r)
    },
    getPrices (state) {
      return state.prices
    },
    getBestPrice (state, getters) {
      return function (tabNo, key) {
        const race = state.detailedRaces[state.selectedRace.id]
        // var betFairLevel = getters.getBetFairPrices[key]?.availableToBack.find(p => p.level === 0)
        const betfairKey = key ? key.replace('_FIXED_ODDS', '') : null
        const betFairPrice = getters.getBetfairPrice(betfairKey, 'back-1')
        const columns = getters.getColumns.map(c => c.source)

        if (race && race.competitors) {
          const racePrices = []
          Object.keys(getters.getPrices).forEach(key => {
            if (key.includes(state.selectedRace.id)) {
              racePrices.push(getters.getPrices[key])
            }
          })
          const priceSets = racePrices.filter(p => columns.includes(p.source))
          const prices = priceSets.map(priceList => {
            if (getters.getHighLightBestOdds.includes('ignoreToteOdds')) {
              if (priceList.type === 'WIN' || priceList.type === 'PLACE') return 0
              return priceList.find(p => p.tabNo === tabNo)?.price || 0
            }
            return priceList.find(p => p.tabNo === tabNo)?.price || 0
          })
          var allPrices = columns.includes('BF') ? [...prices, betFairPrice] : [...prices]
          return Math.max(...allPrices)
        }
        return null
      }
    },
    getBestPricePlace (state, getters) {
      return function (tabNo) {
        const race = state.detailedRaces[state.selectedRace.id]
        const columns = getters.getColumns.map(c => c.source)
        if (race && race.competitors) {
          const racePrices = []
          Object.keys(getters.getPrices).forEach(key => {
            if (key.includes(state.selectedRace.id)) {
              racePrices.push(getters.getPrices[key])
            }
          })
          const priceSets = racePrices.filter(p => columns.includes(p.source))
          const prices = priceSets.map(priceList => {
            if (priceList.type === 'WIN' || priceList.type === 'PLACE') return 0
            if (priceList.type === 'WIN_FIXED_ODDS') return 0
            return priceList.find(p => p.tabNo === tabNo)?.price || 0
          })
          return Math.max(...prices)
        }
        return null
      }
    },
    getBestPriceSets (state, getters) {
      return function (tabNo, filters) {
        const race = state.detailedRaces[state.selectedRace.id]
        const columns = getters.getColumns.map(c => c.source)
        if (race && race.competitors) {
          const racePrices = []
          Object.keys(getters.getPrices).forEach(key => {
            if (key.includes(state.selectedRace.id)) {
              racePrices.push(getters.getPrices[key])
            }
          })
          let prices = racePrices.filter(p => p.source !== 'BF').map(priceList => {
            const runner = priceList.find(p => p.tabNo === tabNo)
            return { ...runner, source: priceList.source, type: priceList.type, updated: priceList.updated }
          })
          const selectedColumnSources = getters.getSelectedColumns.map(column => column.source)
          prices = prices.filter(price => selectedColumnSources.includes(price.source))
          const allPrices = filters.includes('ALL_BOOKIES') ? prices : prices.filter(p => columns.includes(p.source))
          const filteredPrices = allPrices.filter(ps => Array.isArray(filters) && filters.includes(ps.type))
          const bestPrice = Math.max(...filteredPrices.map(p => {
            return !isEmpty(p.price) ? p.price : 0
          }))
          return filteredPrices.filter(p => p.price === bestPrice)
        }
      }
    },
    getBestTotePrice (state, getters) {
      return function (tabNo) {
        const race = state.detailedRaces[state.selectedRace.id]
        if (race && race.competitors) {
          const racePrices = []
          Object.keys(getters.getPrices).forEach(key => {
            if (key.includes(state.selectedRace.id)) {
              racePrices.push(getters.getPrices[key])
            }
          })
          const prices = racePrices.map(priceList => {
            if (priceList.type === 'WIN_FIXED_ODDS' || priceList.type === 'PLACE_FIXED_ODDS' || priceList.type === 'PLACE') return 0
            return priceList.find(p => p.tabNo === tabNo)?.price || 0
          })
          return prices.length > 0 ? Math.max(...prices) : null
        }
        return null
      }
    },
    getPrice (state) {
      return function (tabNo, key) {
        const prices = state.prices
        const bookiePrices = prices[key]
        return bookiePrices?.find(p => p.tabNo === tabNo) || null
      }
    },
    getBetfairPrice (state, getters) {
      return function (key, type) {
        const prices = state.betFairPrices[key]
        const useCommission = getters.getGridSettings?.includes('commission')
        const commission = getters.getBetFairCommission
        if (!prices) return null
        if (type === 'back-1') {
          const price = prices.availableToBack?.find(p => p.level === 0)
          return useCommission ? calculateBfBackCommission(price?.price, commission) : price?.price
        }
        if (type === 'lay-1') {
          const price = prices.availableToLay?.find(p => p.level === 0)
          return useCommission ? calculateBfLayCommission(price?.price, commission) : price?.price
        }
        if (type === 'total-matched') {
          const price = prices.totalTraded
          return useCommission ? calculateBfLayCommission(price, commission) : price
        }
      }
    },
    getMarketPercentage (state) {
      return function (key) {
        const p = state.prices[key]
        if (p) {
          return Math.round(p.MarketPercentage * 10) / 10
        }
      }
    },
    getBetFairMarketPercentage (state, getters) {
      return function (column) {
        if (!SHOW_MARKET_PERCENTAGE[column.type]) return null
        const racePrices = []
        Object.keys(state.betFairPrices).forEach(key => {
          if (key.includes(state.selectedRace.id)) {
            racePrices.push(state.betFairPrices[key])
          }
        })
        let marketPercentage = 0
        const priceType = column.priceType || 'WIN'
        racePrices
          .filter(r => !r.isScratched)
          .filter(p => p.priceType === priceType)
          .forEach(x => {
            const price = matchPriceToType(column.type, x, getters.getExchangeRate)
            if (price) {
              marketPercentage += 1 / price
            }
          })
        const result = marketPercentage * 100.0
        return Math.round(result * 10) / 10
      }
    },
    getPoolSize (state, getters) {
      return function (key, priceType, type) {
        if (key.includes(':BF:')) {
          if (type) {
            const totalTradedKey = `${state.selectedRace.id}:${priceType || 'WIN'}`
            const total = state.betFairTotalTraded[totalTradedKey]
            return total ? (Math.round(total) / 1000).toFixed(1) : null
          } else {
            const competitors = getters.getUnsortedRaceCompetitors
            const prices = getters.getBetFairPrices
            const total = competitors.map(c => {
              const key = `${state.selectedRace.id}:${c.tabNo}:${priceType}`
              const price = prices?.[key]
              return matchPoolToType(type, price)
            })?.filter(p => !!p).reduce((a, b) => {
              return Number(a) + Number(b)
            }, [])
            return total ? Math.floor(total) : null
          }
        }
        const p = state.prices[key]
        return p?.poolSize ? (p.poolSize / 1000).toFixed(1) : null
      }
    },
    getLastPriceChanged (state) {
      return function (key) {
        const p = state.prices[key]
        if (p) {
          if (p.updated) {
            return p.updated
          }
        }
      }
    },
    getColumns (state, getters) {
      const sourcesToExclude = ['BD2']
      return getters.getSelectedColumns.filter((bookie) => {
        return !sourcesToExclude.includes(bookie.source)
      }).map(bookie => {
        return {
          columnHeader: bookie.display === COLUMN_TYPE_FLUCS ? 'Flucs' : getColumnHeader(bookie),
          displayType: bookie.display || 0,
          source: bookie.source,
          type: bookie.type,
          time: bookie.display === COLUMN_TYPE_FLUCS ? 'All' : 0,
          // prices,
          settings: bookie.settings,
          key: `${state.selectedRace.id}:${bookie.source}:${bookie.type}`,
          ...bookie,
          priceType: bookie.priceType
        }
      })
    },
    getSelectedRace (state, getters) {
      return function () {
        if (state.selectedRace.id === null) {
          return null
        }
        const race = state.detailedRaces[state.selectedRace.id]
        return {
          ...race,
          competitors: getters.getRaceCompetitors
        }
      }
    },
    getMeetingSummaryRaces (state, getters) {
      return filterRacesByCodes(filterRacesByCountry(state.meetingSummaryRaces, getters.getCountriesFilter), getters.getIsCodesSelectedFilter)
    },
    getRacedayUpdates (state) {
      return state.raceDayUpdates
    },
    getRacedayUpdatesEnable (state) {
      return state.raceDayUpdatesEnable
    },
    getRace (state, getters) {
      return function (meetingId, raceNumber) {
        const meeting = state.meetings[meetingId]
        const race = meeting?.races?.find(r => r.number === raceNumber)
        return { ...race, meeting }
      }
    },
    getWatchListRunners (state) {
      return Object.values(state.priceAlerts).filter(p => p.race?.id === state.selectedRace.id).map(p => p.runner_number)
    },
    getWatchList (state) {
      const alerts = Object.values(state.priceAlerts).map(p => {
        if (p.agency) {
          const prices = state.priceAlertPrices[p.alert_id] ? Object.values(state.priceAlertPrices[p.alert_id]) : []
          const alert = { ...p }
          alert.prices = prices
          return alert
        } else {
          return p
        }
      })
      return alerts
    },
    getNewPriceAlerts (state) {
      // return [{ alert_id: 1, triggered_date: { seconds: 0 } }]
      return state.newPriceAlerts
    },
    getAzList (state) {
      return function (letter, option, filters) {
        if (option === 'aZTrainers') {
          return state.azListTrainers[letter]
            .filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
        } else if (option === 'aZJockeysDrivers') {
          return state.azListJockeysDrivers[letter]
            .filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
        } else if (option === 'aZRunners') {
          return state.azListRunners[letter]
            .filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
        } else if (option === 'search') {
          return state.azSearchResults.filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
        } else {
          return []
        }
      }
    },
    getLoadAZListData (state) {
      return state.loadAZListData
    },
    getRaceAlerts (state) {
      return state.raceAlerts
    },
    getMarketMover (state) {
      return state.marketMover
    },
    getMarketMoverBookie (state) {
      return state.marketMoverBookie
    },
    getSelectedBookie (state) {
      return state.selectedBookie
    },
    getRacesGroupByMeeting (state) {
      return state.racesGroupByMeeting
    },
    getExchangeRate (state, getters) {
      return getters.getStateDynamicRaceData?.['exchange_type']
    },
    getExoticsRaceSelected (state) {
      return state.exoticsRaceSelected
    },
    getShowNoFAAccess (state) {
      return state.showNoFAAccess
    }
  },
  modules: {}
}

export default store
