<template>
  <td v-if="!item.scratched" class="text-start font-weight-medium pl-1" :class="acMode ? 'acModeRunner runnerNameDiv' : 'stdMode runnerNameDiv'" :style="resultsMode">
    <div style="float:left;" :class="getPosition ? 'runnerNameContainerResulted' : 'runnerNameContainer'">
      <div v-if="!mobile" class="runnerNameBoxContent">

        <v-tooltip id="tooltipRunner" right nudge-bottom="190" color="transparent" open-delay="100" v-if="hasFeature('runnerLastStarts') && showForm && getBets && getBets.length && showJockeys">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="runnerNameBoxBets mt-n1">
              <div @mouseover="runnerQuery(item.runner)" v-if="!getPosition">
                <div :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="float-left">
                  {{ item.name }}
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="betIndicator" style="background:orange;height:14px;width:14px;font-size:10px;color:#fff;display:inline-block;text-align:center;">{{getBets.length}}</div>
                  </template>
                  <span class="caption betIndicator-tooltip" style="font-family: 'Roboto', sans-serif;">{{getBetsToolTip}}</span>
                </v-tooltip>
                <div v-if="meeting.type === 'THOROUGHBRED'" class="barrierText d-inline float-right">
                  <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

                  <v-menu open-on-hover :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                        <p class="ma-0">
                          <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                        </p>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  ({{ item.barrier }})<span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
                </div>
                <div class="mt-n2">
                <div style="clear:both"></div>
                <div v-if="item.jockey" :class="acMode ? 'caption mt-0 jockeyName mt-n1 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n1 float-left'">{{item.jockey}}</div>
                <div v-if="item.trainer && meeting.type === 'GREYHOUND'" :class="acMode ? 'caption mt-0 jockeyName mt-n1 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n1 float-left'">{{item.trainer}}</div>
                <div v-if="meeting.type === 'THOROUGHBRED'" class="caption font-weight-light mt-n1  float-right runnerWeight">{{weightSet(item.weightAllocated)}}</div>
                </div>
              </div>
              <div @mouseover="runnerQuery(item.runner)" v-else>
                <div :class="acMode ? 'runnerNameResulted font-weight-black d-inline mr-2' : 'runnerNameResulted font-weight-medium d-inline mr-2'" class="float-left">
                  {{ item.name }}
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="betIndicator" style="background:orange;height:14px;width:14px;font-size:10px;color:#fff;display:inline-block;text-align:center;">{{getBets.length}}</div>
                  </template>
                  <span class="caption betIndicator-tooltip" style="font-family: 'Roboto', sans-serif;">{{getBetsToolTip}}</span>
                </v-tooltip>
                <div v-if="meeting.type === 'THOROUGHBRED'" class="barrierText d-inline float-right">
                  <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

                  <v-menu open-on-hover :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                        <p class="ma-0">
                          <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                        </p>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  ({{ item.barrier }})<span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
                </div>
                <div class="mt-n2">
                <div style="clear:both"></div>
                <div v-if="item.jockey" :class="acMode ? 'caption mt-0 jockeyName mt-n1 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n1 float-left'">{{item.jockey}}</div>
                <div v-if="meeting.type === 'THOROUGHBRED'" class="caption font-weight-light mt-n1  float-right runnerWeight">{{weightSet(item.weightAllocated)}}</div>
                </div>
              </div>
            </div>
          </template>
          <RunnerLastStarts :meeting="meeting" :item="item"/>
        </v-tooltip>
        <div v-else-if="!showForm">
          <div v-if="getBets && getBets.length && showJockeys" class="runnerNameBoxBets mt-n1">
            <div :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2 float-left">
              {{ item.name }}
            </div>
            <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="betIndicator" style="background:orange;height:14px;width:14px;font-size:10px;color:#fff;display:inline-block;text-align:center;">{{getBets.length}}</div>
                  </template>
                  <span class="caption betIndicator-tooltip" style="font-family: 'Roboto', sans-serif;">{{getBetsToolTip}}</span>
                </v-tooltip>
            <div v-if="meeting.type === 'THOROUGHBRED'" class="barrierText d-inline float-right">
              <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

              <v-menu open-on-hover :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                </template>
                <v-list class="pa-0">
                  <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                    <p class="ma-0">
                      <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                    </p>
                  </v-list-item>
                </v-list>
              </v-menu>

              ({{ item.barrier }}) <span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
            </div>
            <div class="mt-n2">
            <div style="clear:both"></div>
            <div v-if="item.jockey" :class="acMode ? 'caption mt-0 jockeyName mt-n1 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n1 float-left'">{{item.jockey}}</div>
            <div v-if="item.trainer && meeting.type === 'GREYHOUND'" :class="acMode ? 'caption mt-0 jockeyName mt-n1 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n1 float-left'">{{item.trainer}}</div>
            <div v-if="meeting.type === 'THOROUGHBRED'" class="caption font-weight-light mt-n1  float-right runnerWeight">{{weightSet(item.weightAllocated)}}</div>
            </div>
          </div>
        </div>

        <v-tooltip open-delay="100" color="transparent" right nudge-bottom="190" v-if="hasFeature('runnerLastStarts') && showForm">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div v-if="getBets && getBets.length && !showJockeys" class="runnerNameBoxBets runnerNameBoxEnlarge mt-n1">
                <div @mouseover="runnerQuery(item.runner)">
                  <div :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2 float-left">
                    {{ item.name }}
                  </div>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="betIndicator" style="background:orange;height:14px;width:14px;font-size:10px;color:#fff;display:inline-block;text-align:center;">{{getBets.length}}</div>
                  </template>
                  <span class="caption betIndicator-tooltip" style="font-family: 'Roboto', sans-serif;">{{getBetsToolTip}}</span>
                </v-tooltip>
                  <div v-if="meeting.type === 'THOROUGHBRED'" class="barrierText d-inline float-right">
                    <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

                    <v-menu open-on-hover :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                          <p class="ma-0">
                            <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                          </p>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    ({{ item.barrier }})<span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
                  </div>
                </div>
              </div>
              <div v-else-if="!getBets || !getBets.length" class="runnerNameBox" v-bind:class="{runnerNameBoxEnlarge: !showJockeys}">
                <div @mouseover="runnerQuery(item.runner)">
                  <div v-if="!getPosition" :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2">
                    {{ item.name }}
                  </div>
                  <div v-else :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2">
                    {{ item.name }}
                  </div>
                  <div v-if="meeting.type === 'THOROUGHBRED' && !hideDetails" class="barrierText d-inline float-right">
                    <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

                    <v-menu open-on-hover :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                          <p class="ma-0">
                            <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                          </p>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    ({{ item.barrier }})<span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
                  </div>
                  <div style="clear:both"></div>
                  <div v-if="item.jockey && !hideDetails && showJockeys" :class="acMode ? 'caption mt-0 jockeyName mt-n2 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n2 float-left'">{{item.jockey}} <span v-if="allowanceWeight">{{allowanceWeight}}</span></div>
                  <div v-if="item.trainer && meeting.type === 'GREYHOUND'" :class="acMode ? 'caption mt-0 jockeyName mt-n2 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n2 float-left'">{{item.trainer}}</div>
                  <div v-if="meeting.type === 'THOROUGHBRED' && !hideDetails && showJockeys" class="caption font-weight-light mt-n2  float-right runnerWeight">{{weightSet(item.weightAllocated)}}</div>
                  <div v-else style="height: 22px"></div>
                </div>
              </div>
            </div>
            </template>
          <RunnerLastStarts :meeting="meeting" :item="item"/>
        </v-tooltip>
        <div v-else-if="!showForm">
          <div v-if="getBets && getBets.length && !showJockeys" class="runnerNameBoxBets runnerNameBoxEnlarge mt-n1">
              <div :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2 float-left">
                {{ item.name }}
              </div>
              <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="betIndicator" style="background:orange;height:14px;width:14px;font-size:10px;color:#fff;display:inline-block;text-align:center;">{{getBets.length}}</div>
                  </template>
                  <span class="caption betIndicator-tooltip" style="font-family: 'Roboto', sans-serif;">{{getBetsToolTip}}</span>
                </v-tooltip>
              <div v-if="meeting.type === 'THOROUGHBRED'" class="barrierText d-inline float-right">
                <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

                <v-menu open-on-hover :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                      <p class="ma-0">
                        <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                      </p>
                    </v-list-item>
                  </v-list>
                </v-menu>

                ({{ item.barrier }}) <span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
              </div>
            </div>
            <div v-else-if="!getBets || !getBets.length" class="runnerNameBox" v-bind:class="{runnerNameBoxEnlarge: !showJockeys}">
              <div v-if="!getPosition" :class="acMode ? 'runnerNameResulted font-weight-black d-inline mr-2' : 'runnerNameResulted font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2">
                {{ item.name }}
              </div>
              <div v-else :class="acMode ? 'runnerName font-weight-black d-inline mr-2' : 'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2">
                {{ item.name }}
              </div>

              <div class="barrierText d-inline float-right">
                <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

                <v-menu v-if="isDualAcceptance" open-on-hover :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                      <p class="ma-0">
                        <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                      </p>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <div v-if="meeting.type === 'THOROUGHBRED' && !hideDetails" class="barrierText d-inline float-right">
                ({{ item.barrier }})<span v-if="item.emergencyIndicator" class="red--text font-weight-bold ml-1 emergencyIndicator">E</span>
              </div>
              <div style="clear:both"></div>
              <div v-if="item.jockey && !hideDetails && showJockeys" :class="acMode ? 'caption mt-0 jockeyName mt-n2 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n2 float-left'">{{item.jockey}} <span v-if="allowanceWeight">{{allowanceWeight}}</span></div>
              <div v-if="item.trainer && meeting.type === 'GREYHOUND'" :class="acMode ? 'caption mt-0 jockeyName mt-n2 float-left' : 'caption font-weight-light mt-0 jockeyName mt-n2 float-left'">{{item.trainer}}</div>
              <div v-if="meeting.type === 'THOROUGHBRED' && !hideDetails && showJockeys" class="caption font-weight-light mt-n2  float-right runnerWeight">{{weightSet(item.weightAllocated)}}</div>
            </div>
        </div>
      </div>

      <div v-else class="runnerNameBoxMobile">
        <span class="runnerName">
          {{ item.name }}
        </span>
        <span v-if="meeting.type === 'THOROUGHBRED'" class="barrierText">
          <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

          <v-menu open-on-hover :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
            </template>
            <v-list class="pa-0">
              <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`"  @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                <p class="ma-0">
                  <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                </p>
              </v-list-item>
            </v-list>
          </v-menu>

          ({{ item.barrier }})<span v-if="item.emergencyIndicator" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
        </span>
        <br />
        <span v-if="item.jockey && showJockeys" class="caption font-weight-light mt-0 jockeyName">{{item.jockey}}</span>
        <span v-if="meeting.type === 'THOROUGHBRED' && showJockeys" class="caption font-weight-light mt-0 float-right runnerWeight">{{weightSet(item.weightAllocated)}}</span>
      </div>
    </div>
    <div v-if="!mobile" style="float:right;-webkit-flex: 1;-moz-flex: 1;flex: 1;">
      <span v-if="getPosition === 1" class="placingFirst">{{ getPosition }}st</span>
      <span v-else-if="getPosition === 2" class="placingSecond">{{ getPosition }}nd</span>
      <span v-else-if="getPosition === 3" class="placingThird">{{ getPosition }}rd</span>
      <span v-else-if="getPosition" class="placingFourth">{{ getPosition }}th</span>
    </div>
    <div v-else style="float:right;-webkit-flex: 1;-moz-flex: 1;flex: 1;">
      <span v-if="getPosition === 1" class="placingFirst">{{ getPosition }}st</span>
      <span v-else-if="getPosition === 2" class="placingSecond">{{ getPosition }}nd</span>
      <span v-else-if="getPosition === 3" class="placingThird">{{ getPosition }}rd</span>
      <span v-else-if="getPosition" class="placingFourth">{{ getPosition }}th</span>
    </div>
  </td>
  <td v-else-if="item.scratched" :class="this.acMode ? 'acModeRunner' : 'stdMode'" class="text-start font-weight-medium pl-1 runnerNameDiv">
    <div class="runnerNameContainer" style="float:left;opacity:0.5;width:100%;">
      <div class="runnerNameBox">
        <div :class="item.scratchingType === 'LATESCRATCHING' ? 'runnerName-lateScratching' : 'runnerNameScratched d-inline float-left'">
          {{ item.name }}
        </div>
        <div class="d-inline float-right">
          <v-icon v-if="isBlackbook" class="mr-0 ml-1" x-small>mdi-book-open-page-variant</v-icon>

          <v-menu open-on-hover :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="isDualAcceptance" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
            </template>
            <v-list class="pa-0">
              <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`"  @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                <p class="ma-0">
                  <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                </p>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-if="item.emergencyIndicator && meeting.type === 'THOROUGHBRED'" class="red--text font-weight-bold barrierText ml-1 emergencyIndicator">E</span>
        </div>
        <div style="clear:both"></div>
        <div class="scratched font-weight-light mt-n2 float-left"><strong>SCRATCHED</strong><span class="ml-2"> {{ item.scratchingType === 'LATESCRATCHING' ? 'LS' : null}}</span></div>
      </div>
      <v-tooltip bottom v-if="getBets && getBets.length">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="betIndicator" style="background:orange;height:14px;width:14px;font-size:10px;color:#fff;display:inline-block;text-align:center;">{{getBets.length}}</div>
          </template>
          <span class="caption betIndicator-tooltip" style="font-family: \'Roboto\', sans-serif;">{{getBetsToolTip}}</span>
        </v-tooltip>
    </div>
  </td>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import RunnerLastStarts from './RunnerLastStarts'
import { BOOKMAKER_NAMES_LOOKUP, BETTYPE } from '../common/constants'
import numeral from 'numeral'
import { formattDateToWords, isEmpty } from '../common/methods'
import { format } from 'date-fns'

export default {
  data: () => ({
    showMenu: true
  }),
  props: {
    item: Object,
    meeting: Object,
    raceId: String,
    results: Array,
    mobile: Boolean,
    acMode: Boolean,
    resultsMode: String,
    hideDetails: Boolean,
    showJockeys: Boolean,
    betHistory: Array
  },
  components: {
    RunnerLastStarts
  },
  computed: {
    ...mapState(['runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook', 'dualAcceptances', 'dualAcceptancesHasLoaded']),
    ...mapGetters(['hasFeature', 'getGridSettings', 'getGridSettings', 'getSelectedRace']),
    ...mapGetters('betting', ['getTodaysBets']),
    race: function () {
      return this.getSelectedRace()
    },
    showForm () {
      if (!this.getGridSettings.includes('showForm')) return false
      return true
    },
    displayImperial: function () {
      return this.getGridSettings.includes('displayImperial')
    },
    allowanceWeight () {
      if (!this.item?.allowanceWeight) {
        return
      }
      return `(${this.item?.allowanceWeight})`
    },
    getBetsInfo () {
      const betInfo = this.betHistory?.filter(b => b.tabNo === this.item?.tabNo)
      if (!betInfo) return []
      var bets = []
      betInfo.forEach(b => {
        const betString = b.id || b.bet_id || b.bet.bet_id
        bets.push(betString)
      })
      return bets
    },
    getBetsToolTip () {
      const betInfo = this.getTodaysBets?.filter((item) => this.getBetsInfo.includes(item.id))
      var bets = []
      betInfo.forEach(b => {
        if (b.status === 9 || b.status === 10 || b.status === 14 || b.status === 13) {
          const stake = b?.bet?.['partial_stake'] || b.stake
          const betString = `$${stake} @ $${b.price || '*'} - ${b.bet_type_description || BETTYPE[b.betType]} - ${b.agency_name || BOOKMAKER_NAMES_LOOKUP[b.bookieCode]} | `
          bets.push(betString)
        }
        // if (b.status === 13) {
        //   const betString = `$${b.stake} @ $${b.price || '*'} - ${b.bet_type_description || BETTYPE[b.betType]} - ${b.agency_name || BOOKMAKER_NAMES_LOOKUP[b.bookieCode]} | `
        //   bets.push(betString)
        // }
      })
      return bets.toString().replace(',', ' ')
    },
    getBets: function () {
      if (this.resultsMode) return null
      const filteredBets = this.getTodaysBets?.filter(b => b.raceId === this.raceId)
      if (!filteredBets) return []
      return (this.item && filteredBets) ? filteredBets?.filter(b => b.tabNo === this.item?.tabNo && b.runnerName === this.item?.name).filter(b => b.status === 9 || b.status === 10 || b.status === 14 || b.status === 13) : null
    },
    getPosition: function () {
      if (this.item?.finalPosition && this.item?.finalPosition > 0) {
        return this.item?.finalPosition
      }
      if (this.results && this.results[0] && this.results[0].positions) {
        const position = this.results[0].positions.find(p => p.tabNo === this.item?.tabNo)
        if (position) {
          return position.position
        }
      }
      if (this.results && this.results[0] && this.results[0].positions && this.results[0].positions[0].competitor) {
        const position = this.results[0].positions.find(p => p.competitor.tabNo === this.item?.tabNo)
        if (position) {
          return position.position
        }
      }
      return null
    },
    isBlackbook: function () {
      if ((this.meeting.type === 'THOROUGHBRED' || this.meeting.type === 'GREYHOUND') && this.hasFeature('blackbook')) {
        const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
        const found = combined.find(co => co?.runnerId === this.item?.runner?.id && co?.tabType === 'runnerTab')
        return !isEmpty(found)
      } else {
        return false
      }
    },
    isDualAcceptance: function () {
      if (isEmpty(this.dualAcceptancesHasLoaded)) return false

      if (this.meeting.type === 'THOROUGHBRED') {
        const found = this.dualAcceptances?.find(co => co[0]?.runnerId === this.item?.runner?.id)
        return !isEmpty(found)
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions(['fetchRunner', 'fetchRunnerDO', 'selectRace']),
    ...mapGetters(['getRunners']),
    ...mapState(['detailedRunner']),
    numeral,
    weightSet (weight) {
      const weightInLbs = weight * 2.205
      if (this.displayImperial) {
        return `${numeral(weightInLbs).format('0,00')}lbs`
      } else {
        return `${weight}kg`
      }
    },
    runnerQuery: function (runner) {
      if (this.meeting.type === 'THOROUGHBRED') {
        if (runner && Object.keys(this.detailedRunner(runner?.id)).includes(runner?.id)) {
          return
        }
        this.fetchRunnerDO(this.item?.runner?.id)
      }
      if (this.meeting.type === 'HARNESS' || this.meeting.type === 'GREYHOUND') {
        if (runner && Object.keys(this.detailedRunner(runner?.id)).includes(runner?.id)) {
          return
        }
        this.fetchRunner(this.item?.runner?.id)
      }
    },
    onReturnRunnerAcceptances ({ acceptance }) {
      if (isEmpty(this.dualAcceptances)) return []
      const runnerAcceptance = this.dualAcceptances.find(co => co[0]?.runnerId === acceptance.runner?.id)

      if (!isEmpty(runnerAcceptance)) {
        const removeCurrentRaceFromAcceptances = runnerAcceptance?.filter(acceptance => acceptance?.raceID !== this.race?.id)
        return removeCurrentRaceFromAcceptances
      } else {
        return []
      }
    },
    acceptanceRaceData ({ acceptance }) {
      const startTime = format(new Date(acceptance?.startTime), 'HH:mmaaa')
      return {
        startTime,
        runnerName: acceptance?.runner
      }
    },
    dateToWord ({ acceptance }) {
      const toYyyyMmDd = acceptance?.date?.split('/').reverse().join('-')
      return formattDateToWords(toYyyyMmDd)
    }
  }
}
</script>

<style>
.v-tooltip__content {
  opacity: 1 !important;
}
.runnerNameBoxContent {
  width:250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runnerNameBoxContentResulted {
  width:200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runnerNameContainer {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  max-width:185px;
  height:30px;
}
.runnerNameContainerResulted {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  max-width:130px;
  height:30px;
}
.runnerNameBox {
  float: left;
  font-size: 12px;
  width: 100%;
  height:29px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runnerNameDiv {
  /* background:#fff !important; */
}
.runnerNameBoxEnlarge {
  float: left;
  width: 100%;
  height:29px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:1.1rem !important
}
.runnerNameBoxBets {
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding-top:3px;
  text-overflow: ellipsis;
}
.runnerNameBoxBetsEnlarge {
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  padding-top:3px;
  text-overflow: ellipsis;
  font-size:1.3rem !important
}
.runnerNameRow {
  padding-left: 5px;
  padding-right: 5px;
  max-height: 30px;
}
.runnerNameBoxMobile {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 1;
}
.runnerNameRowMobile {
  padding-left: 5px;
  padding-right: 5px;
  max-height: 39px;
}
.placingFirst {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #057faf;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.placingSecond {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #ec0101;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.placingThird {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #00a500;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.placingFourth {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #9b9b9b;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.barrierText {
  font-weight: 600;
  color: #333;
  font-size: 0.8em;
}
.runnerNameResulted {
  float:left;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:100px;
}
.runnerName {
  float:left;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:180px;
}
.runnerNameScratched {
  text-decoration: line-through;
  font-size: 0.9em;
  font-weight: bold;
  color: #333;
}
.runnerName-lateScratching {
  text-decoration: line-through;
  font-size: 0.9em;
  font-weight: bold;
  color: #333;
  text-decoration-color: red;
}
.jockeyName {
  font-weight: 300;
  font-size: 0.6em;
  color: #333;
  float: left;
  padding-top:2px;
  min-height: 22px;
}
.runnerWeight {
  font-weight: 300;
  color: #333;
  font-size: 0.8em;
  float: left;
  margin-left: 10px;
  padding-top:2px;
}
span.runnerWeight {
  font-weight: 300;
  color: #333;
  font-size: 0.8em;
  float: left;
  margin-left: 10px;
}
.scratched {
  text-transform: uppercase;
  color: red;
  padding-top:5px;
  font-size: 10px;
}

.acceptance {
  font-size: 13px;
}

.acceptance-scratched {
  background: repeating-linear-gradient(
    180deg,
    red 0%,
    red 100%
  );
  background-size: 100% 1px !important;
  background-position: center;
  background-repeat: no-repeat;
  color: #777 !important;
  opacity: 0.7 !important;
}
.acceptance-scratched img {
  opacity: 0.4 !important;
}

.Today {
  color: #4caf50;
  font-weight: bold;
}

.Tomorrow {
  font-weight: bold;
  color: orange;
}

.emergencyIndicator {
  position: relative;
  top: 1px;
}
</style>
