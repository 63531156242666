export const moveable = {
  inserted: function (el) {
    makeMoveable(el.parentElement)
  }
}

function makeMoveable (el) {
  const d = {}
  el.addEventListener('mousedown', e => {
    d.el = el // element which should be moved
    d.mouseStartX = e.clientX
    d.mouseStartY = e.clientY
    d.elStartX = d.el.getBoundingClientRect().left
    d.elStartY = d.el.getBoundingClientRect().top
    d.el.style.position = 'fixed'
    d.el.style.margin = 0
    d.oldTransition = d.el.style.transition
    d.el.style.transition = 'none'
    d.el.classList.add('dragging')
    d.el.classList.remove('draggable')
  })
  el.addEventListener('mousemove', e => {
    if (!d.el) return
    d.el.style.left = Math.min(
      Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
      window.innerWidth - d.el.getBoundingClientRect().width
    ) + 'px'
    d.el.style.top = Math.min(
      Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
      window.innerHeight - d.el.getBoundingClientRect().height
    ) + 'px'
  })
  el.addEventListener('mouseup', () => {
    if (!d.el) return
    d.el.style.transition = d.oldTransition
    d.el.classList.remove('dragging')
    d.el.classList.add('draggable')
    d.el = null
  })
}
