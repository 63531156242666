<template>
  <div>
    <v-toolbar color="red" dark dense>
      <div class="titleBar-content">
        <v-toolbar-title class="raceResults-title">Bet Error (Price Changed)</v-toolbar-title>
        <v-btn class="cancelButton" icon small @click="closePriceChange" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <br>
    <h2 style="text-align: center">There was a price change on your recently placed bet</h2>
    <h2 style="text-align: center">Bet Details</h2>
    <p style="text-align: center">{{ selectedBetText() }}</p>
    <h4 style="text-align: center">Please refer to the bet history button at the top of the screen for more details or open the runner bet slip to re-place the bet.</h4>
    <br>
    <div class="priceChangedButt">
      <v-btn color="success" @click="handlePriceClick()">Open Runner Betslip</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { BOOKMAKER_NAMES_LOOKUP, BETTYPE } from '../common/constants'
export default {
  data () {
    return {
      BOOKMAKER_NAMES_LOOKUP,
      BETTYPE
    }
  },
  name: 'PriceChangedAlert',
  methods: {
    ...mapState('betting', ['selectedBet']),
    ...mapGetters('betting', ['getSelectedBet']),
    closePriceChange () {
      this.$emit('clicked')
    },
    handlePriceClick: function () {
      const selectedBet = this.getSelectedBet()
      this.clickPrice(selectedBet)
      this.closePriceChange()
    },
    selectedBet () {
      return this.getSelectedBet() || {}
    },
    selectedBetText () {
      const bet = this.selectedBet()
      return `${bet?.runner?.name} - ${bet?.price} - ${BOOKMAKER_NAMES_LOOKUP[bet?.bookie]} - ${BETTYPE[bet?.type]} - ${bet?.priceType}`
    }
  },
  props: {
    clickPrice: Function
  }
}
</script>

<style>
.priceChangedButt {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px
}
</style>
