<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="featureInBeta">
            <v-card-title class="headline"><v-icon class="red--text mr-2">mdi-golf</v-icon>Bogey!</v-card-title>
            <v-card-text>
              Sorry, this feature is for subscribers ONLY, <br>Please become an active subscriber or <strong>Sign In</strong>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                to="/login"
                @click="$emit('close')"
              >
                Sign In
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="$emit('close')"
              >
                OK
              </v-btn>
            </v-card-actions>
          </div>
          <div v-else>
            <v-card-title class="headline"><v-icon class="purple--text mr-2">mdi-ghost</v-icon> Woops!</v-card-title>
            <v-card-text class="font-weight-bold">
              Sorry, this feature is not yet available in the <br><span class="red--text">BETA</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="$emit('close')"
              >
                OK
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'NoFeature',
  props: {
    featureInBeta: Boolean
  }
}
</script>

<style>

</style>
