<template>
  <v-card class="raceNotesModal">
    <v-toolbar color="primary" dark dense>
      <div class="titleBar-content">
        <v-toolbar-title class="raceResults-title"> My Notes - {{meeting.track.name}} R{{race.number}}</v-toolbar-title>
        <v-btn class="cancelButton" icon small @click="closeNotes" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <textarea class="raceNotesText" style="padding:10px;" rows="10" @input="(e) => handleChange(e)" :value="message">Enter Custom Data</textarea>
  </v-card>
</template>
<script>
import { debounce } from 'debounce'
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    newValue: {}
  }),
  props: {
    closeRaceNotes: Function,
    race: Object,
    meeting: Object,
    runners: Array,
    message: String
  },
  methods: {
    ...mapActions(['setCustomData']),
    closeNotes () {
      this.$emit('clicked')
    },
    handleChange: function (e) {
      const value = e.target.value
      const id = this.race.id
      const raceId = this.race.id
      const meeting = this.meeting.track.name
      const raceNumber = this.race.number
      const runnerObject = { value, meeting, raceNumber, raceId }
      this.newValue[raceId] = runnerObject
      debounce(this.setCustomData({ id: id, columnName: 'race-notes', value: this.newValue }), 200)
    }
  },
  computed: {
    ...mapState(['acMode'])
  }
}
</script>

<style>
  .raceNotesText {
    width:100%;
    height:100%;
  }
  .raceNotesModal {
    min-height:300px;
  }
</style>
