<template>
  <div class="apolloColumn customCol" :style="getColumnStyle(index)">
   <ApolloQuery
      :query="raceQuery"
      v-if="id"
    >
      <template v-slot="{ result: { data } }">
        <div :class="overflowedList ? 'raceTableColumnContainer-overflowed' : 'raceTableColumnContainer'">
          <div class="raceTableColumnMainContainer">
            <div :class="acMode ? 'raceTableColumnDiv-priceAcMode' : 'raceTableColumnDiv'" :style="getColumnStyle(index)">
              <!-- <div v-if="loading" class="runnerInfo-loading">Loading...</div> -->
              <div :class="['runnerInfo-content', 'columnWidth-' + source]">
                <div v-if="editMode" class="raceHeaderCol">
                  <div :class="['raceTableColumn-header save-font']" >
                    <div :class="['infoColumnEditMode']">
                      {{ title }}
                    </div>
                    <div v-if="editMode" class="columnActions editingDecorations">
                      <AddColumnButton :columnLength="columnLength" :position="index"/>
                      <DeleteColumnButton :columnNumber="index"/>
                      <EditColumnButton :columnNumber="index"/>
                    </div>
                  </div>
                </div>
                <div v-if="source === 'prev-comment'" v-bind:class="classObject">
                  <div v-for="(competitor, i) in sortedCompetitors" :key="`${competitor.tabNo}:${i}`" :class="isInWatchList(competitor.tabNo) ? 'highlight customDataDiv' : 'customDataDiv'" :style="getColumnStyle(index)">
                    <input
                      v-on:keyup.enter="handleEnter(data)"
                      v-focus="focused === i"
                      :value="competitor.comment"
                      :class="acMode ? 'customColumn-textFieldAcMode columnWidth-' + source : 'customColumn-textField columnWidth-' + source"
                      disabled
                    >
                    <div class="input_info" :style="getColumnStyle(index)">{{competitor.comment}}</div>
                  </div>
                </div>
                <div v-else v-bind:class="classObject">
                  <div v-for="(competitor, i) in sortedCompetitors" :key="`${competitor.tabNo}:${i}`" :class="isInWatchList(competitor.tabNo) ? 'highlight customDataDiv' : 'customDataDiv'" >
                    <v-menu :transition="false" open-on-hover right offset-x>
                      <template v-slot:activator="{ on }">
                        <input
                          :key="`${competitor.tabNo}:${i}`"
                          type="text"
                          v-on:keyup.enter="handleEnter(data, $event, competitor, source)"
                          v-focus="focused === i"
                          v-model="competitor.comment"
                          @click="handleClick(i)"
                          @blur="handleClickAway(i, $event, competitor, data, source)"
                          :class="acMode ? 'customColumn-textFieldAcMode columnWidth-' + source : 'customColumn-textField columnWidth-' + source"
                          :style="getColumnStyle(index)"
                          v-on="on"
                        >
                        <!-- <input
                          :key="`${competitor.tabNo}:${i}`"
                          type="text"
                          v-on:keyup.enter="handleEnter(data)"
                          v-focus="focused === i"
                          v-model="competitor.comment"
                          @click="handleClick(i)"
                          @blur="handleClickAway(i, $event, competitor, data, source)"
                          :class="acMode ? 'customColumn-textFieldAcMode columnWidth-' + source : 'customColumn-textField columnWidth-' + source"
                          :style="getColumnStyle(index)"
                          v-on="on"
                        > -->
                        <div class="input_info" :style="getColumnStyle(index)">{{competitor.comment}}</div>
                      </template>
                      <v-list v-if="competitor.comment" dense :class="source.includes('rating') ? 'rating' : 'fullComment'" elevation="0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-card elevation="0" height="100%">
                              <v-card-text style="padding: 0px !important;">
                                {{ competitor.comment }}
                              </v-card-text>
                            </v-card>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
             </div>
           </div>
         </div>
       </div>
     </template>
   </ApolloQuery>
 </div>
</template>

<script>
import { customColumnQuery } from '../common/queries'
import { debounce } from 'debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import AddColumnButton from './menus/AddColumnMenu'
import DeleteColumnButton from './menus/DeleteColumnMenu'
import EditColumnButton from './menus/EditColumnMenu'
import gql from 'graphql-tag'

export default {
  props: {
    id: String,
    overflowedList: Boolean,
    selection: String,
    source: String,
    editMode: Boolean,
    title: String,
    index: Number,
    columnLength: Number,
    getColumnStyle: Function
  },
  data: () => ({
    focused: null
  }),
  components: {
    AddColumnButton,
    DeleteColumnButton,
    EditColumnButton
  },
  computed: {
    ...mapState(['acMode', 'prevComments']),
    ...mapGetters(['getCustomData', 'getRaceSortOrder', 'getPrevComments', 'getRaceCompetitors', 'getWatchListRunners', 'getGridSettings']),
    classObject: function () {
      return {
        rightAlignPricesDiv: this.getGridSettings.includes('rightAlignPrices'),
        hovertheComment: true
      }
    },
    raceQuery () {
      return customColumnQuery(this.id)
    },
    value () {
      const data = this.getCustomData(this.id)
      let newValue = {}
      if (data && data[this.source]) {
        newValue = data[this.source]
      }
      return newValue
    },
    sortedCompetitors: function () {
      const competitors = this.getRaceCompetitors
      const comments = this.getPrevComments
      return competitors?.map(competitor => {
        if (this.source === 'prev-comment') {
          const comment = comments[competitor.tabNo]
          return { ...competitor, comment }
        } else {
          const comment = this.getValue(competitor)
          return { ...competitor, comment }
        }
      }) || []
    }
  },
  methods: {
    gql,
    ...mapActions(['setCustomData']),
    ...mapGetters('account', ['authConfig']),
    getPrevComment: function (tabNo) {
      return this.prevComments[tabNo]
    },
    handleEnter: function (data, $event, competitor, source) {
      this.handleChange($event, competitor, data, source)
      this.focused = this.focused += 1
      if (this.focused >= data?.race?.competitors.length) {
        this.focused = null
      }
    },
    handleClick: function (i) {
      this.focused = i
    },
    handleClickAway: function (i, $event, competitor, data, source) {
      this.handleChange($event, competitor, data, source)
      if (this.focused === i) this.focused = null
    },
    handleChange: function (e, competitor, data, source) {
      if (!competitor?.runner?.id) return
      // if (!this.focused) return
      const value = e.target.value
      const meeting = data?.race?.meeting?.track?.name
      const raceNumber = data?.race?.number
      const meetingDate = data?.race?.meeting?.date
      const raceId = data?.race?.id
      const runnerName = competitor?.name
      const runnerNumber = competitor?.tabNo
      const runnerId = competitor?.runner?.id
      const runnerObject = { value, meeting, raceNumber, meetingDate, raceId, runnerName, runnerNumber, runnerId }
      this.value[runnerId] = runnerObject
      debounce(this.setCustomData({ id: this.id, columnName: this.source, value: this.value }), 200)
    },
    getValue: function (competitor) {
      const id = competitor?.runner?.id
      if (!id) return null
      return this.value[id]?.value || null
    },
    isInWatchList (tabNo) {
      return this.getWatchListRunners.includes(tabNo)
    }
  }
}
</script>

<style>
.rightAlignPricesDiv {
  text-align:right;
}
.fieldset {
  position: relative;
  display: table;
  /* margin: auto; */
}

.input_info {
  position: absolute;
  left: 100%;
  background: #000;
  border: 1px solid #fff;
  color: #fff;
  max-width:300px;
  overflow-wrap: break-word;
  display: none;
  padding:5px;
  border-radius:5px;
}
.hovertheComment :hover + .input_info {
  display: block;
}
input[type="text"]:focus + .input_info {
  display: block;
}
.customColumn {
  min-width: 42px;
  border-bottom:0.5px solid #ddd;
}
.customColumn-textField {
  width: 100%;
  height: 32px;
  border-bottom:0.5px solid #ddd;
  padding-left:4px;
}
.customColumn-textFieldAcMode {
 width: 100%;
 height: 32px;
 border-bottom:0.5px solid #333;
 background:#fff;
}
.customCol {
  background:#fff;
}
.columnWidth-custom-comment {
 width:149px;
}
.columnWidth-rating-1 {
 width:42px;
 text-align:center;
}
.columnWidth-rating-2 {
 width:42px;
 text-align:center;
}
.columnWidth-rating-3 {
 width:42px;
 text-align:center;
}
.columnWidth-rating-4 {
 width:42px;
 text-align:center;
}
.columnWidth-rating-5 {
 width:42px;
 text-align:center;
}
.columnWidth-rating-6 {
 width:42px;
 text-align:center;
}
.columnWidth-prev-comment {
 width:149px;
}
.columnWidthFooter-rating-1 {
 width:42px;
}
.columnWidthFooter-rating-2 {
 width:42px;
}
.columnWidthFooter-rating-3 {
 width:42px;
}
.columnWidthFooter-rating-4 {
 width:42px;
}
.columnWidthFooter-rating-5 {
 width:42px;
}
.columnWidthFooter-rating-6 {
 width:42px;
}
.columnWidthFooter-prev-comment {
 width:149px !important;
}
.columnWidthFooter-custom-comment {
 width:149px !important;
}
.fullComment{
  width: 150px;
  overflow-y: scroll;
  height: 200px;
}
.rating{
  height: 35px;
  width: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
}
.customDataDiv {
  background-color: #fff;
}
</style>
