<template>
  <v-dialog v-model="triggeredPriceAlert" transition="none" persistent hide-overlay max-width="300px" >
    <v-card class="priceAlert" v-moveable>
      <v-toolbar color="primary" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title>Price Alert</v-toolbar-title>
          <v-btn class="cancelButton" icon small @click="handleAlertClose" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="priceAlert-content">
        <div class="content-section">
          <div class="content-title">Event</div>
          <div>{{ getMeetingInfo(priceAlert) }}</div>
        </div>
        <v-divider></v-divider>
        <div class="content-section">
          <div class="content-title">Time Until Start</div>
          <CountDown :formattedStartTime="startTime" />
        </div>
        <v-divider></v-divider>
        <div class="content-section">
          <div class="content-title">Runner</div>
          <div>
            {{ priceAlert.runner_number }}.
            {{ priceAlert.name }}
          </div>
        </div>
        <v-divider></v-divider>
        <div class="content-section">
          <div class="content-title">Agency</div>
          <div v-if="priceAlert.agency" style="margin-left: 5px; margin-right: 10px; zoom: 80%" :class="getLogoClass(priceAlert.agency)"></div>
          <div v-else>All</div>
        </div>
        <v-divider></v-divider>
        <div class="content-section">
          <div class="content-title">Trigger Price</div>
          <div>${{  numeral(priceAlert.trigger_price).format('(0.00)') }}</div>
        </div>
        <v-divider></v-divider>
        <div class="content-section">
          <div class="content-title">Current Price</div>
          <div>${{ numeral(priceAlert.triggered_price).format('(0.00)') }}</div>
        </div>
        <v-divider></v-divider>
        <div class="priceAlert-actions">
          <v-btn @click="clickRace(priceAlert.race.id,  priceAlert.race.startTime)" color="primary">Load Event</v-btn>
          <v-btn :disabled="!priceAlert.agency" color="success" @click="handleBetNowClick">Bet Now</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { startOfDay, isSameDay, parseISO } from 'date-fns'
import { mapActions, mapState } from 'vuex'
import CountDown from './Countdown'
import numeral from 'numeral'

export default {
  props: {
    priceAlert: Object,
    openBetslipModal: Function,
    audio: String
  },
  components: {
    CountDown
  },
  computed: {
    ...mapState(['races']),
    startTime: function () {
      return this.races[this.priceAlert?.race?.id]?.formattedStartTime
    },
    triggeredPriceAlert: {
      get () {
        const sound = this.audio
        if (sound === 'BELL' && !this.priceAlert.triggered) {
          var audioSound = new Audio('../assets/sounds/price_alert.mp3')
          audioSound.autoplay = true
          audioSound.play()
          return !this.priceAlert.triggered
        }
        return !this.priceAlert.triggered
      },
      set (alert) {
        this.setPriceAlertTriggered({ alertId: this.priceAlert.alert_id })
      }
    }
  },
  methods: {
    numeral,
    ...mapActions(['setPriceAlertTriggered', 'setDate', 'selectRace', 'reFetchRaces']),
    handleAlertClose: function () {
      this.setPriceAlertTriggered({ alertId: this.priceAlert.alert_id })
    },
    async clickRace (raceId, date) {
      if (isSameDay(startOfDay(Date.now()), parseISO(date))) {
        this.selectRace({
          id: raceId
        })
      } else {
        await this.setDate(startOfDay(new Date(date)))
        await this.reFetchRaces()
        this.selectRace({
          id: raceId
        })
      }
    },
    getMeetingInfo: function (item) {
      return `${item.race_number} ${item.track}`
    },
    getLogoClass: function (agency) {
      return `logo-${agency}`
    },
    handleBetNowClick: function () {
      const runner = { name: this.priceAlert.name, tabNo: this.priceAlert.runner_number }
      const race = this.priceAlert.race
      const meeting = this.priceAlert.meeting
      const price = this.priceAlert.triggered_price
      const bookie = this.priceAlert.agency
      const type = 'WIN_FIXED_ODDS'
      this.openBetslipModal({ runner, race, meeting, price, bookie, type })
      this.handleAlertClose()
    }
  }
}
</script>

<style scoped>
.priceAlert {
  width: 300px;
}
.priceAlert-content {
  margin-top: 20px;
}
.content-title {
  width: 120px;
}
.content-section {
  display: flex;
}
.priceAlert-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
