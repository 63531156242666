import axios from 'axios'

export const addPriceAlert = async function ({ meetingId, raceNumber, runnerNumber, types, agency, priceAlertType, triggerPrice, meetingDate, config }) {
  try {
    await axios.post('public/addPriceAlert', { meetingId, raceNumber, runnerNumber, types, agency, priceAlertType, triggerPrice, meetingDate }, config)
  } catch (error) {}
}

export const addToWatchList = async function ({ meetingId, raceNumber, runnerNumber, meetingDate, config }) {
  try {
    await axios.post('public/addPriceAlert', { meetingId, raceNumber, runnerNumber, meetingDate }, config)
    const priceAlerts = await getPriceAlerts({ meetingDate, config })
    return priceAlerts
  } catch (error) {}
}

export const getPriceAlerts = async function ({ meetingDate, config }) {
  try {
    const result = await axios.post('public/getPriceAlerts', { meetingDate }, config)
    if (result.data.price_alerts) {
      return result.data.price_alerts
    }
  } catch (error) {}
}

export const getPriceAlertsBtwDates = async function ({ dateFrom, dateTo, config }) {
  try {
    const result = await axios.post('public/getPriceAlertsBtwDates', { dateFrom, dateTo }, config)
    if (result.data.price_alerts) {
      return result.data.price_alerts
    }
  } catch (error) {}
}

export const deletePriceAlert = async function ({ alertId, config }) {
  try {
    await axios.post('public/deletePriceAlert', { alertId }, config)
  } catch (error) {}
}
