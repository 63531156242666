<template>
  <div class="d-inline ml-2" style="margin-left: 0px !important;">
    <v-btn x-small class="white--text" color="primary" @click="show(); watchSizeDelay();">
      Flucs
    </v-btn>
    <template>
      <modal @resize="watchSize" @opened="() => dialog = true" @closed="() => dialog = false" name="flucs-modal" draggable="" resizable :clickToClose=false :minWidth=400 :minHeight=300 width=1000px height=600px>
        <v-card class="allFlucs">
          <v-toolbar color="primary" dark dense>
            <div style="width:100%;">
              <v-toolbar-title class="float-left"> {{ title }} - {{race.name}} R{{race.number}}</v-toolbar-title>
              <v-btn class="float-right" icon small @click="hide"><v-icon class="white--text">mdi-close</v-icon></v-btn>
              <v-btn @click="hide" class="float-right white--text" icon small :href="'flucs/'+race.id" target="_blank"><v-icon class="mr-2">mdi-open-in-new</v-icon></v-btn>
            </div>
            <template v-slot:extension>
              <v-tabs v-model="tab" class="mt-n10">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab class="overline">Bookmaker Flucs</v-tab>
                <v-tab class="overline">Tote History</v-tab>
                <v-tab class="overline">Flucs Charts</v-tab>
                <v-tab-item class="fluc-items">
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="2" style="margin-left: 10px">
                      <v-select hide-details dense :items="bookies" v-model="selectedBookie" v-on:change="getNewFlucs()" outlined label="Bookmaker">
                        <template v-slot:item="{ item }">
                          <div style="margin-left: 5px; margin-right: 10px;"><RaceBookmakers :bookieCode="item.value"/></div>
                          <span>{{ item.text }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                      <div class="lastUpdate" style="font-size: 13px">Last Update: {{lastUpdate}}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="12" style="padding: 0">
                      <v-container
                        class="px-0"
                        fluid
                        style="padding: 0; margin-left: 20px"
                      >
                        <v-radio-group hide-details :column=false v-model="radioGroup">
                          <v-radio
                            style="margin-right: 10px; margin-bottom: 5px;"
                            v-for="n in timeItems"
                            :key="n.value"
                            :label="n.label"
                            :value="n.value"
                          ></v-radio>
                        </v-radio-group>
                      </v-container>
                    </v-col>
                  </v-row>
                  <div class="tote-table">
                    <v-simple-table height="auto" fixed-header dense class="tableColumn3" style="padding-right: 0px">
                      <template v-slot:default>
                        <thead class="headerNamesFlucs">
                          <tr>
                            <th style="padding-left: 2px;background:#BBDEFB">No.</th>
                            <th style="padding-left: 5px;overflow:hidden;white-space:nowrap;background:#BBDEFB">Runner Name</th>
                            <th class="optionsCells" style="width: 30px; background:#BBDEFB">Rst</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(d, index) in raceCompetitors" :key="index">
                            <td id="runnerNoCell" style="padding-left: 5px; padding-right: 5px; font-size: 11px !important">
                              {{d.tabNo}}.
                            </td>
                            <td id="runnerNameCell" style="overflow-x: auto; white-space: nowrap;background:#fff; font-size: 11px !important; padding: 0; padding-left: 2px;" v-if="d.scratched">
                              <strike>{{d.name}}</strike> <b>scr</b>
                            </td>
                            <td id="runnerNameCell" style="overflow-x: auto; white-space: nowrap; font-size: 11px !important; padding: 0; padding-left: 2px;" v-else>
                              {{d.name}}
                            </td>
                            <td class="optionsCells" style="max-width: 30px; min-width: 30px; padding: 0; width: 30px; font-size: 11px !important">{{checkFinalPosition(d.finalPosition)}}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td style="font-size: 12px !important; padding: 0; padding-left: 2px;"><strong>Market %</strong></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table height="auto" fixed-header dense class="tableColumn" style="padding-left: 0px">
                      <template v-slot:default>
                        <thead class="headerNamesFlucs">
                          <tr>
                            <th class="optionsCells" style="background:#BBDEFB">Open</th>
                            <th class="optionsCells" style="background:#BBDEFB; white-space: nowrap !important;" v-for="n in getLongestFluc() - checkIfEmpty" :key="n">{{n}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(d, index) in raceCompetitors" :key="index">
                          <td id="runnerPriceCell" style="max-width: 35px; min-width: 35px; padding: 0; width: 45px; font-size: 11px !important" v-for="(f, index) in filteredFlucs(d.tabNo)" :key="index" class="flucsBox" :class="[getPriceFlucsStyled(index, d.tabNo) ? 'drop' : 'normal']">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span style="display: block" v-bind="attrs" v-on="on">
                                  {{f.price}}
                                </span>
                              </template>
                              <span>Odds: {{f.price}}</span>
                              <br>
                              <span>Timestamp: {{format(new Date(f.time), 'HH:mm:ss')}} ({{format(new Date(f.time), 'EEEE dd/MM/yyyy')}})</span>
                            </v-tooltip>
                          </td>
                            <td v-for="n in (getLongestFluc() - filteredFlucs(d.tabNo).length)" :key="'fluc'+n"></td>
                          </tr>
                          <tr>
                            <td style="text-align: center; padding: 0; padding-left: 2px; width: 50px; font-size: 11px !important"><strong>{{getOpenMarketPercent(selectedBookie)}}</strong></td>
                            <td v-for="n in getLongestFluc() - checkIfEmpty" :key="n"></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table height="auto" fixed-header dense class="tableColumn2">
                      <template v-slot:default>
                        <thead>
                          <th class="optionsCells nowPrice" style="background:#BBDEFB">Now</th>
                          <th class="optionsCells" style="background:#BBDEFB">AVG</th>
                          <th class="optionsCells" style="background:#BBDEFB">Last Fluc</th>
                          <th class="optionsCells" style="background:#BBDEFB">% Shift</th>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <th class="optionsCells" v-bind="attrs" v-on="on" style="background:#BBDEFB">
                                <u>% OCC</u>
                              </th>
                            </template>
                            <span style="padding: 0px; margin: 0px">Odds Chance Change % - This is calculated based on the runners percentage chance at Open price minus it’s percentage chance at the current price.</span>
                          </v-tooltip>
                        </thead>
                        <tbody>
                          <tr v-for="(d, index) in raceCompetitors" :key="index">
                            <!-- Current Price -->
                            <td class="flucsBox2 nowPrice" style="font-size: 11px !important" :class="[getPriceFlucsStyledNow(getNowPrice(d.tabNo), d.tabNo) ? 'drop' : 'normal']"><strong>{{getNowPrice(d.tabNo)}}</strong></td>
                            <!-- Average Price -->
                            <td class="flucsBox2 averagePrice" style="font-size: 11px !important">{{getAveragePrice(d.tabNo)}}</td>
                            <!-- Last Price Change Time -->
                            <td class="flucsBox2 shiftPerc" style="font-size: 11px !important">{{getLastFlucTime(d.tabNo)}}</td>
                            <!-- % Shift from OG Price -->
                            <td class="flucsBox2 shiftPerc" style="font-size: 11px !important">{{getPriceShift(d.tabNo)}}</td>
                            <!-- ???? -->
                            <td class="flucsBox2 occPerc" style="font-size: 11px !important">{{getOCCShift(d.tabNo)}}</td>
                          </tr>
                          <tr>
                            <td style="text-align: center; padding: 0; width: 50px; font-size: 11px !important"><strong>{{getMarketPercent(selectedBookie)}}</strong></td>
                            <td v-for="n in 4" :key="n"></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <p style="margin-left: 15px; font-size: 11px">*Note: The 'Open' price is the first price detected by our systems. This may vary from the agencies 'actual' open price due to delays in us receiving the information</p>
                </v-tab-item>
                <v-tab-item class="fluc-items">
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="2" style="margin-left: 10px">
                      <v-select hide-details dense :items="tabList" v-model="selectedTabBookie" v-on:change="getNewPoolHistory()" outlined label="Bookmaker">
                        <template v-slot:item="{ item }">
                          <div style="margin-left: 5px; margin-right: 10px;"><RaceBookmakers :bookieCode="item.value"/></div>
                          <span>{{ item.text }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- <v-col class="d-flex" cols="12" sm="4">
                      <div class="toteHistoryColours" style="background-color: rgb(255,204,255);">15 to 20%</div>
                      <div class="toteHistoryColours" style="background-color: rgb(252, 252, 151);">20 to 25%</div>
                      <div class="toteHistoryColours" style="background-color: rgb(96, 240, 144);">25% +</div>
                    </v-col> -->
                  </v-row>
                  <div class="tote-table">
                    <v-simple-table height="400" fixed-header dense class="tableColumn" :calculate-widths="false">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th style="text-align: center;width:30px !important;background:#BBDEFB">Time</th>
                            <th style="text-align: center;width:40px !important;background:#BBDEFB">Pool</th>
                            <th style="text-align: center;width:20px !important;background:#BBDEFB" v-for="(d, i) in getUnsortedRaceCompetitors" :key="`${d.tabNo}:${i}`">
                              <!-- <p v-if="d.scratched" style="color: red; margin-bottom: 0"><strike>{{d.tabNo}}</strike></p> -->
                              <p style="margin-bottom: 0">{{d.tabNo}}</p>
                            </th>
                            <th style="background:#BBDEFB"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(d, index) in getPoolHistory(selectedTabBookie)" :key="index">
                            <td style="text-align: right;width:30px !important;">{{format(new Date(d.updated), 'HH:mm:ss')}}</td>
                            <td class="sortnr" style="text-align: right;width:40px !important;">{{d.poolSize}}</td>
                            <td style="text-align:right;width:20px !important;" v-for="(a, i) in d.prices" :key="`${d.tabNo}:${i}`">{{a.price}}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <v-row>
                    <v-col style="margin-left: 10px" class="d-flex" cols="12" sm="2">
                      <v-select hide-details dense :items="bookies" v-model="selectedBookie" v-on:change="getNewFlucs()" outlined label="Bookmaker">
                        <template v-slot:item="{ item }">
                          <div style="margin-left: 5px; margin-right: 10px;"><RaceBookmakers :bookieCode="item.value"/></div>
                          <span>{{ item.text }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="d-flex" cols="4" sm="2">
                      <v-select hide-details dense :items="chartValues" v-model="yaxisValue" outlined label="Max Value">
                      </v-select>
                    </v-col>
                  </v-row>
                  <div id="chart">
                    <apexchart type="line" height="400" ref="chart" :options="flucsOptions" :series="flucsSeries"></apexchart>
                  </div>
                </v-tab-item>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-card>
      </modal>
    </template>
  </div>
</template>

<script>
import { BOOKMAKER_NAMES_LOOKUP, BOOKMAKER_FLUC_TYPE, PRICE_CHANGE_BOOKIES } from '../common/constants'
import { mapState, mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'
import numeral from 'numeral'
import RaceBookmakers from './RaceBookmakers'

export default {
  name: 'Flucs',
  data: (props) => ({
    flucHover: false,
    format,
    numeral,
    radioGroup: 'All',
    panel: 0,
    opacity: 0,
    dialog: false,
    tab: null,
    lastUpdate: '',
    currPrice: '',
    avgPrice: '',
    lastPriceChange: '',
    priceShift: '',
    occShift: '',
    yaxisValue: 100,
    selectedTabBookie: 'V',
    items: PRICE_CHANGE_BOOKIES,
    tabItems: [
      { value: 'V' },
      { value: 'N' },
      { value: 'Q' }
    ],
    timeItems: [ // 2015-03-25T12:00:00Z"
      { label: '1 min', value: '00:01:00' },
      { label: '3 min', value: '00:03:00' },
      { label: '5 min', value: '00:05:00' },
      { label: '10 min', value: '00:10:00' },
      { label: '15 min', value: '00:15:00' },
      { label: '30 min', value: '00:30:00' },
      { label: '1 hour', value: '01:00:00' },
      { label: '3 hours', value: '03:00:00' },
      { label: '6 hours', value: '06:00:00' },
      { label: '12 hours', value: '12:00:00' },
      { label: 'All', value: 'All' }
    ],
    chartValues: [
      { value: 10, text: '10' },
      { value: 20, text: '20' },
      { value: 30, text: '30' },
      { value: 40, text: '40' },
      { value: 50, text: '50' },
      { value: 60, text: '60' },
      { value: 70, text: '70' },
      { value: 80, text: '80' },
      { value: 90, text: '90' },
      { value: 100, text: '100' },
      { value: 200, text: '200' },
      { value: 300, text: '300' },
      { value: 500, text: '500' }
    ]
  }),
  watch: {
    flucs () {
      this.getLastUpdate()
    },
    dialog (open) {
      this.getNewFlucs()
      this.getNewPoolHistory()
    },
    race () {
      if (this.dialog) {
        this.getNewFlucs()
        this.getNewPoolHistory()
      }
    }
  },
  components: {
    RaceBookmakers
  },
  computed: {
    ...mapGetters(['getMarketPercentage', 'getUnsortedRaceCompetitors', 'getSelectedBookie']),
    selectedBookie: {
      get () {
        return this.getSelectedBookie
      },
      set (bookie) {
        this.setSelectedBookie({ source: bookie })
      }
    },
    title: function () {
      return `Price Flucs`
    },
    bookies: function () {
      return this.items.map(x => {
        return {
          value: x.value,
          text: BOOKMAKER_NAMES_LOOKUP[x.value]
        }
      })
    },
    tabList: function () {
      return this.tabItems.map(x => {
        return {
          value: x.value,
          text: BOOKMAKER_NAMES_LOOKUP[x.value]
        }
      })
    },
    checkIfEmpty: function () {
      if (!this.getLongestFluc()) {
        return 0
      }
      return 1
    },
    flucsOptions: function () {
      const options = {
        chart: {
          id: 'realtime',
          height: 400,
          type: 'line',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 100
            }
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          title: {
            text: 'Toggle Runners'
          }
        },
        yaxis: {
          title: {
            text: 'Price'
          },
          max: this.yaxisValue
        },
        tooltip: {
          enabled: true,
          // fillSeriesColor: true,
          theme: 'dark'
        },
        legend: {
          show: true
        }
      }
      return options
    },
    flucsSeries: function () {
      const competitorList = new Set()
      if (this.raceCompetitors) {
        this.raceCompetitors.forEach((d) => competitorList?.add({ tabNo: d?.tabNo, name: d?.name }))
      }
      const competitors = Array.from(competitorList).map((h) => ({
        name: h.tabNo + ` - ` + h.name,
        data: this.getFlucs(this.selectedBookie, h.tabNo).map((b) => (b.price))
      }))
      return competitors
    },
    ...mapState(['flucs', 'poolHistory'])
  },
  props: {
    clickFeature: Function,
    race: Object,
    raceCompetitors: Array
  },
  methods: {
    ...mapActions(['fetchFlucs', 'fetchPoolHistory', 'setSelectedBookie', 'setTokenCoreApi']),
    watchSize () {
      const modal = document.querySelector('.vm--modal').clientHeight
      // const fluc = document.querySelector('.flucs-items')
      // console.log('reached method')
      document.querySelector('.fluc-items').style.maxHeight = `${modal - 72}px`
      document.querySelector('.fluc-items').style.overflow = scroll
    },
    watchSizeDelay () {
      window.setTimeout(() => {
        const modal = document.querySelector('.vm--modal').clientHeight
        // const fluc = document.querySelector('.flucs-items')
        // console.log('reached method2')
        document.querySelector('.fluc-items').style.maxHeight = `${modal - 72}px`
        document.querySelector('.fluc-items').style.overflow = scroll
      }, 100)
      return ''
    },
    show () {
      this.$modal.show(
        'flucs-modal'
      )
    },
    hide () {
      this.$modal.hide(
        'flucs-modal'
      )
      this.setTokenCoreApi()
    },
    filteredFlucs: function (tabNo) {
      // create array of arrays
      const flucs = this.getFlucs(this.selectedBookie, tabNo)
      const timeArray = flucs.map((b) => {
        const nowDate = new Date(this.race.startTime)
        // Time right now
        const nowTime = new Date(nowDate).getTime()
        // Time of price change
        const flucTime = new Date(b.time).getTime()
        // Time to take away from now
        const radioTime = this.radioGroup.split(':').reduce((acc, time) => (60 * acc) + +time)
        const rTime = radioTime * 1000
        // Now time - radio time
        const radioTimeFormat = nowTime - rTime
        // Return all prices
        if (this.radioGroup === 'All') {
          if (b.price === 0) {
            return
          }
          return b// time <= now && now <= nextTime
        }
        // Now time / fluc time / radio time
        // if fluc time is between now time and radio time return
        if (this.radioGroup !== 'All') {
          if (flucTime <= nowTime && flucTime >= radioTimeFormat) {
            if (b.price === 0) {
              return
            }
            return b
          }
        }
      }).filter(f => f)
      // Return new arrays
      // timeArray[0] = flucs[0]
      return timeArray
    },
    getPriceFlucsStyled: function (i, tabNo) {
      const oldArr = this.getFlucs(this.selectedBookie, tabNo)
      const timeArray = oldArr.map((b) => {
        const nowDate = new Date(this.race.startTime)
        // Time right now
        const nowTime = new Date(nowDate).getTime()
        // Time of price change
        const flucTime = new Date(b.time).getTime()
        // Time to take away from now
        const radioTime = this.radioGroup.split(':').reduce((acc, time) => (60 * acc) + +time)
        const rTime = radioTime * 1000
        // Now time - radio time
        const radioTimeFormat = nowTime - rTime
        // Return all prices
        if (this.radioGroup === 'All') {
          if (b.price === 0) {
            return
          }
          return b.price// time <= now && now <= nextTime
        }
        // Now time / fluc time / radio time
        // if fluc time is between now time and radio time return
        if (this.radioGroup !== 'All') {
          if (flucTime <= nowTime && flucTime >= radioTimeFormat) {
            if (b.price === 0) {
              return
            }
            return b.price
          }
        }
      }).filter(f => f)
      if (timeArray) {
        return timeArray[i] < timeArray[i - 1]
      } else {
        return false
      }
    },
    getPriceFlucsStyledNow: function (i, tabNo) {
      const runnerFlucs = this.getFlucs(this.selectedBookie, tabNo) || ''
      // Opening Price
      const calcOpenPrice = runnerFlucs.map((b) => b.price)
      var openPrice = calcOpenPrice[0]
      if (openPrice === 0) {
        openPrice = calcOpenPrice[1]
      }
      const oldArr = runnerFlucs.map((b) => b.price)
      if (oldArr) {
        return oldArr[oldArr.length - 1] < openPrice
      } else {
        return false
      }
    },
    eHandler (data) {
      this.width = data.width
      this.height = data.height
      this.left = data.left
      this.top = data.top
      this.event = data.eventName
    },
    getTitle: function (value) {
      return BOOKMAKER_NAMES_LOOKUP[value]
    },
    getLastUpdate: function () {
      const competitorList = new Set()
      if (this.raceCompetitors) {
        this.raceCompetitors.forEach((d) => competitorList?.add(d.tabNo))
      }
      // create array of arrays
      const oldArr = Array.from(competitorList).map((h) => (this.getFlucs(this.selectedBookie, h).map((b) => b.time)))
      // loop through each array and return last item (most recent price change time)
      const newArr = new Set()
      oldArr.forEach((g) => newArr.add(g[g?.length - 1]))
      const filtered = Array.from(newArr).filter(function (el) {
        return el != null
      })
      if (filtered.length === 0) {
        return
      }
      // map array and return latest time and format time
      const lastDate = format(new Date((Math.max(...filtered.map(e => new Date(e))))), 'HH:mm:ss')
      if (newArr.length === 0) {
        this.lastUpdate = ''
      }
      this.lastUpdate = lastDate
    },
    checkFinalPosition: function (finalP) {
      if (finalP === 0) {
        return
      }
      return numeral(finalP).format('0o')
    },
    getMarketPercent (source) {
      const type = BOOKMAKER_FLUC_TYPE[source]
      const key = `${this.race.id}:${source}:${type}`
      return this.getMarketPercentage(key)
    },
    getOpenMarketPercent (source) {
      let openMarketPercent = 0
      const competitorList = new Set()
      if (this.raceCompetitors) {
        this.raceCompetitors.forEach((d) => competitorList?.add(d.tabNo))
      }
      // create array of arrays
      const oldArr = Array.from(competitorList).map((h) => (this.getFlucs(this.selectedBookie, h).map((b) => b.price)))
      // loop through each array and return last item (most recent price change time)
      const newArr = []
      oldArr.forEach((g) => {
        if (g[0] === 0) {
          newArr.push(g[1])
        }
        newArr.push(g[0])
      })
      const filtered = Array.from(newArr).filter(function (el) {
        return el != null
      })
      if (filtered.length === 0) {
        return
      }
      filtered.forEach(x => {
        if (x) {
          openMarketPercent += 1 / x
        }
      })
      filtered.mp = openMarketPercent * 100.0
      return Math.round(filtered.mp * 10) / 10
    },
    getFlucs (source, tabNo) {
      const flucs = []
      const type = BOOKMAKER_FLUC_TYPE[source]
      const key = `${this.race.id}:${source}:${type}`
      if (this.flucs && this.flucs[key]) {
        const flucsFil = this.flucs[key].find(f => f.flucs[0]?.tabNo === tabNo)
        if (!flucsFil?.flucs) return []
        flucsFil.flucs.forEach(runner => {
          flucs.push(runner)
        })
      }
      return flucs
    },
    getNewFlucs: function () {
      if (this.fetchFlucs) {
        this.fetchFlucs({
          source: this.selectedBookie,
          type: BOOKMAKER_FLUC_TYPE[this.selectedBookie]
        })
      }
    },
    getPoolHistory (source) {
      var poolHistory = []
      const type = BOOKMAKER_FLUC_TYPE[source]
      const key = `${this.race.id}:${source}:${type}`
      if (this.poolHistory[key]) {
        poolHistory = this.poolHistory[key]
      }
      return poolHistory
    },
    getNewPoolHistory: function () {
      if (this.fetchPoolHistory) {
        this.fetchPoolHistory({
          source: this.selectedTabBookie,
          type: BOOKMAKER_FLUC_TYPE[this.selectedTabBookie]
        })
      }
    },
    getLongestFluc: function () {
      const competitorList = new Set()
      if (this.raceCompetitors) {
        this.raceCompetitors.forEach((d) => competitorList?.add(d.tabNo))
      }
      // create array of arrays
      const oldArr = Array.from(competitorList).map((h) => (this.getFlucs(this.selectedBookie, h).map((b) => b.price)))
      // loop through each array and return last item (most recent price change time)
      const newArr = Math.max(...(oldArr.map(el => el.length)))
      return newArr
    },
    // Get latest price for runner
    getNowPrice: function (tabNo) {
      const runnerFlucs = this.getFlucs(this.selectedBookie, tabNo) || ''
      const runnerPrice = runnerFlucs.map((b) => b.price)
      return runnerPrice[runnerPrice.length - 1] || 0
    },
    // Get average price for runner
    getAveragePrice: function (tabNo) {
      const runnerFlucs = this.getFlucs(this.selectedBookie, tabNo) || ''
      const runnerPrice = runnerFlucs.map((b) => {
        if (b.price === 0) {
          return
        }
        return b.price
      }).filter(f => f)
      // Get average of runnerPrice
      var totalSum = 0
      for (var i in runnerPrice) {
        totalSum += runnerPrice[i]
      }
      var arrCount = runnerPrice.length
      var avgPrice = totalSum / arrCount
      return Math.round(avgPrice * 10) / 10 || 0
    },
    // Get last fluc change time for runner
    getLastFlucTime: function (tabNo) {
      // Return Last Price Change for this runner
      const runnerFlucs = this.getFlucs(this.selectedBookie, tabNo) || ''
      const runnerTime = runnerFlucs.map((b) => b.time)
      const filtered = Array.from(runnerTime).filter(function (el) {
        return el != null
      })
      if (filtered.length === 0) {
        return
      }
      const lastTime = filtered[filtered.length - 1]
      const formattedTime = format(new Date(lastTime), 'HH:mm:ss')
      return formattedTime || 0
    },
    // Calculate % price shift from opening price
    getPriceShift: function (tabNo) {
      // All flucs
      const runnerFlucs = this.getFlucs(this.selectedBookie, tabNo) || ''
      // First Price
      const calcFirstPrice = runnerFlucs.map((b) => b.price)
      var firstPrice = calcFirstPrice[0]
      if (firstPrice === 0) {
        firstPrice = calcFirstPrice[1]
      }
      // Last Price
      const calcLastPrice = runnerFlucs.map((d) => d.price)
      const lastPrice = calcLastPrice[calcLastPrice.length - 1]
      // Percentage method
      const decreaseVal = lastPrice - firstPrice
      const percDifference = (decreaseVal / firstPrice) * 100
      if (Math.round(percDifference * 10) / 10 > 0) {
        return `+${Math.round(percDifference * 10) / 10}`
      }
      // Return
      return Math.round(percDifference * 10) / 10 || 0
    },
    getOCCShift: function (tabNo) {
      // All Flucs
      const runnerFlucs = this.getFlucs(this.selectedBookie, tabNo) || ''
      // Opening Price
      const calcOpenPrice = runnerFlucs.map((b) => b.price)
      var openPrice = calcOpenPrice[0]
      if (openPrice === 0) {
        openPrice = calcOpenPrice[1]
      }
      // Curr Price
      const calcCurrPrice = runnerFlucs.map((d) => d.price)
      const currPrice = calcCurrPrice[calcCurrPrice.length - 1]
      // Open Price % 1/OpenPrice
      const openPricePerc = 1 / openPrice
      // Curr Price % 1/CurrPrice
      const currPricePerc = 1 / currPrice
      // Open% - Curr% * 100
      const occPerc = (openPricePerc - currPricePerc) * 100
      if (Math.round(occPerc * 10) / 10 > 0) {
        return `+${Math.round(occPerc * 10) / 10}`
      }
      // Return Blah
      return Math.round(occPerc * 10) / 10 || 0
    }
  },
  filters: {
    checkEmpty (value) {
      return typeof value !== 'number' ? 0 : value
    }
  }
}
</script>

<style>
.v-window__container {
  box-shadow: none !important;
}
.vm--container {
  max-width: 0px !important;
  max-height: 0px !important;
}
.vm--overlay {
  max-width: 0px !important;
  max-height: 0px !important;
}
.v-radio .v-label {
  font-size: 11px
}
.nowPrice {
  max-width:31px;
  min-width:31px;
  padding-left:0px !important;
  padding-right:5px !important;
}
.averagePrice {
  max-width:31px;
  min-width:31px;
  padding-left:0px !important;
  padding-right:5px !important;
}
.shiftPerc {
  max-width:31px;
  min-width:31px;
  padding-left:0px !important;
  padding-right:5px !important;
}
.occPerc {
  max-width:31px;
  min-width:31px;
  padding-left:0px !important;
  padding-right:5px !important;
}
.tableColumn {
  padding: 5px;
  overflow: auto;
  flex-grow:4;
  flex-basis: 600px;
  /* border:1px solid #d8d8d8; */
  empty-cells: show;
}
.tableColumn td, .tableColumn th {
    border: 0.5px solid #d8d8d8;
    height: 25px !important;
}
.tableColumn tbody td:nth-of-type(even) {
  background: white !important;
}
.tableColumn2 td, .tableColumn2 th {
    border: 0.5px solid #d8d8d8;
    height: 25px !important;
}
.tableColumn2 tbody td:nth-of-type(even) {
  background: white !important;
}
.tableColumn tbody tr:hover {
    background-color: transparent !important;
}
.tableColumn2 tbody tr:hover {
    background-color: transparent !important;
}
.tableColumn2 {
  flex-grow:2;
  padding: 5px;
  flex-basis: 150px;
  /* border:1px solid #d8d8d8; */
  margin-left:5px;
  min-width: 300px;
}
.tableColumn3 {
  padding: 5px;
  flex-grow:1;
  flex-basis: 150px;
  /* border:1px solid #d8d8d8; */
  empty-cells: show;
}
.tableColumn3 td, .tableColumn3 th {
    border: 0.5px solid #d8d8d8;
    height: 25px !important;
}
.tableColumn3 tbody td:nth-of-type(even) {
  background: white !important;
}
.tote-table {
  padding-left:10px;
  padding-right:10px;
  display:flex;
  width:100%;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content: flex-start;
  margin-bottom:20px;
  /* max-height: 400px;
  overflow-y: scroll; */
}
.allFlucs {
  overflow-y:auto;
  height:100%;
}
.flucsBox2 {
  text-align:right;
  font-size:13px !important;
}
.optionsCells {
  text-align:center !important;
}
.v-window__container {
  box-shadow: 5px 5px 20px grey;
}
/* .drag-container-1,
.drag-container-2 {
  width: 100%;
  height: 20px;
  background: red;
  color: white;
  text-align: center;
  cursor: pointer;
} */
.resizable {
  background-position: top left;
  width: 1000px;
  height: 500px;
  padding: 0;
  font-weight: normal;
  color: #ffffff;
  position: relative;
}
#runnerNoCell {
  width: 25px;
  background:#fff
}
.flucsBox {
  min-width:41px;
  max-width:41px;
  text-align: right;
  white-space: nowrap;
  padding-left:0px !important;
  padding-right:5px !important;
  font-size:13px !important;
}
.toteHistoryColours {
  text-align: center;
  margin-right: 10px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 5px;
}
.lastUpdate {
  text-align: left;
  margin-right: 10px;
  width: auto;
  height: 40px;
  line-height: 40px;
}
.flucs-container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 0;
  padding-bottom: 15%;
  background-color: white;
}
.fluc-items {
  color: black;
  /* max-height: 500px; */
  overflow-y: auto;
  overflow-x: auto;
}
.table-data {
  margin-left: 5%;
  display: block;
}
.drop{
  color: orange;
  font-weight: bold;
}
.normal{
  color:#1a237e;
}
.newWindowButton {
  position: absolute;
  right: 15px;
}
</style>
