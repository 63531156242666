import Vue from 'vue'

const socket = {
  subscribedBets: {},
  subscribedRaceAlerts: {},
  authenticate (token) {
    const d = {
      command: 'authorise',
      payload: {
        authToken: token
      }
    }
    Vue.prototype.$socket.sendObj(d)
  },
  subscribeBetStatus (betId) {
    const d = {
      command: 'subscribe',
      payload: {
        roomName: this.roomForBetStatus(betId)
      }
    }
    this.subscribedBets[betId] = betId
    Vue.prototype.$socket.sendObj(d)
  },
  unsubscribeBetStatus (betId) {
    if (betId) {
      const d = {
        command: 'unsubscribe',
        payload: {
          roomName: this.roomForBetStatus(betId)
        }
      }
      Vue.prototype.$socket.sendObj(d)
    }
  },
  roomForBetStatus (id) {
    return `bet-status:${id.toString()}`
  },
  subscribeAppVersion () {
    const d = {
      command: 'subscribe',
      payload: {
        roomName: this.roomForAppVersion()
      }
    }
    Vue.prototype.$socket.sendObj(d)
  },
  unsubscribeAppVersion () {
    const d = {
      command: 'unsubscribe',
      payload: {
        roomName: this.roomForAppVersion()
      }
    }
    Vue.prototype.$socket.sendObj(d)
  },
  roomForAppVersion () {
    return `appVersion`
  },
  subscribeRaceAlerts (username) {
    const d = {
      command: 'subscribe',
      payload: {
        roomName: this.roomForRaceAlerts(username)
      }
    }
    this.subscribedRaceAlerts[username] = username
    Vue.prototype.$socket.sendObj(d)
  },
  unsubscribeRaceAlerts (username) {
    if (username) {
      const d = {
        command: 'unsubscribe',
        payload: {
          roomName: this.roomForRaceAlerts(username)
        }
      }
      Vue.prototype.$socket.sendObj(d)
    }
  },
  roomForRaceAlerts (username) {
    return `race-alerts-username:${username.toString()}`
  },
  subscribeBroadcastMessage () {
    const d = {
      command: 'subscribe',
      payload: {
        roomName: this.roomForBroadcastMessage()
      }
    }
    Vue.prototype.$socket.sendObj(d)
  },
  unsubscribeBroadcastMessage () {
    const d = {
      command: 'unsubscribe',
      payload: {
        roomName: this.roomForBroadcastMessage()
      }
    }
    Vue.prototype.$socket.sendObj(d)
  },
  roomForBroadcastMessage () {
    return 'broadcastMessage'
  }
}

export default socket
