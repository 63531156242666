<template>
  <v-card class="pa-2 mb-2" elevation="0">
    <div class="d-flex align-center">
      <v-avatar size="30" tile height="30" class="mr-3">
        <v-img v-if="upcoming.meetingType === 'THOROUGHBRED'" :src="'https://1ae2v478chfucobmr4slnqs1d6.silks.betmakers.com/' + upcoming.externalMeetingID + '_' + upcoming.raceNumber + '_' + upcoming.tabNo + '_64x64.png'"></v-img>
        <HarnessSprite v-else-if="upcoming.meetingType === 'HARNESS'" :externalMeetingId="upcoming.externalMeetingID" :tabNo="upcoming.tabNo" :raceNumber="upcoming.raceNumber" />
        <GreyHoundSprite
          v-else-if="upcoming.meetingType === 'GREYHOUND'"
          :meetingType="upcoming.meetingType"
          :meetingCountry="upcoming.raceCountry"
          :tabNo="upcoming.tabNo" />
      </v-avatar>

      <div class="d-flex width100">
        <div clas="mr-auto text-sm-body-2">
          <p class="font-weight-medium ma-0 cursor" :title="`${upcoming.appendedSelectedTabTypeName}`">
            <Tooltip :text="`${upcoming.appendedSelectedTabTypeName} (${upcoming.barrier})`" :maxCharacters="10" />
          </p>
          <p @click="onOpenNewRace({ upcoming })" class="font-weight-light cursor ma-0 race-venue">{{ upcoming.raceName }} R{{ upcoming.raceNumber }} {{ raceDate }}</p>
        </div>

        <div class="ml-auto text-sm-body-2">
          <span class="ml-auto inline-block">{{ raceTime }}</span> (<Countdown
            :key="upcoming.appendedId"
            :startTime="upcoming.startTime"
            :formattedStartTime="formattedStartTime({ upcoming })"
          />)
        </div>
        <div v-if="isCheckboxMode" class="d-flex align-center ml-2">
          <v-simple-checkbox
            v-if="isCheckboxMode"
            color="black"
            class="checkbox ma-0 px-0 mr-1"
            :ripple="false"
            @click="onToggleDynamicCheckbox({ blackbook: currentBlackbook })"
            :value="!isCheckboxMode2 ? onReturnCheckboxState({ blackbook: currentBlackbook }) : true"
            hide-details
          ></v-simple-checkbox>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Countdown from './Countdown.vue'
import { formattDateToWords, isEmpty } from '../common/methods'
import Tooltip from './Tooltip'
import { formatDistance } from 'date-fns'
import GreyHoundSprite from './GreyHoundSprite.vue'
import HarnessSprite from './HarnessSprite.vue'

export default {
  name: 'BlackbookUpcomingRunners',
  props: {
    blackbooks: {
      type: Array,
      required: true
    },
    upcoming: {
      type: Object,
      required: true
    },
    isCheckboxMode: {
      type: Boolean,
      required: true
    },
    isCheckboxMode2: {
      type: Boolean,
      required: true
    },
    onReturnCheckboxState: {
      type: Function,
      required: true
    },
    onToggleDynamicCheckbox: {
      type: Function,
      required: true
    }
  },
  components: {
    Countdown,
    Tooltip,
    GreyHoundSprite,
    HarnessSprite
  },
  computed: {
    ...mapState(['races']),
    raceTime: function () {
      return this.upcoming.race ? this.upcoming.race.split(' ')[0] : null
    },
    raceDate () {
      if (!formattDateToWords(new Date(this.upcoming.startTime)) === 'Today') return `(${this.upcoming.date})`
      return ''
    },
    currentBlackbook () {
      const found = this.blackbooks?.find(blackbook => `${blackbook?.runnerId}-${blackbook?.tabType}` === this.upcoming?.appendedId)
      return found || {}
    }
  },
  methods: {
    isEmpty,
    truncate (text) {
      if (text.length > 10) {
        return text.substring(0, 10) + '...'
      }
      return text
    },
    onOpenNewRace ({ upcoming }) {
      this.$emit('open-new-race', { upcoming })
    },
    formattedStartTime ({ upcoming }) {
      if (isEmpty(this.races[upcoming.raceID])) {
        const today = new Date()
        let daysBetween = formatDistance(today, new Date(upcoming.startTime))

        daysBetween.split(' ')
        daysBetween = daysBetween[0] + 'd'

        return daysBetween
      } else {
        return this.races[upcoming.raceID]?.formattedStartTime
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upcoming {

}

.indicators {
  &__indicator:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: pink;
  }
  &__indicator--nomination::before {
    background: #c70066;
  }
  &__indicator--weight::before {
    background: #0032c7;
  }
  &__indicator--acceptance::before {
    background: #eb8402;
  }
}

.race-venue {
  font-size: 14.5px !important;
  &:hover {
    font-weight: 400 !important;
    cursor: pointer;
  }
}

.width100 {
  width: 100%;
}

.checkbox i {
  font-size: 20px;
}
</style>
