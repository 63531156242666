<template>
  <div>
    <v-toolbar color="red" dark dense>
      <div class="titleBar-content">
        <v-toolbar-title class="raceResults-title">Bet Error (Partially Accepted)</v-toolbar-title>
        <v-btn class="cancelButton" icon small @click="closeAlert" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <br>
    <h2 style="text-align: center">Your recently placed bet was partially accepted</h2>
    <h4 style="text-align: center">This means that part of the bet was not accepted</h4>
    <h4 style="text-align: center">Please refer to the bet slip for more details on your placed bet</h4>
    <br>
    <div class="betslipButton">
      <v-btn color="success" @click="showBetslip">Open Betslip</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartiallyAcceptedAlert',
  methods: {
    closeAlert () {
      this.$emit('clicked')
    },
    showBetslip () {
      this.$modal.show('betslip-modal')
      this.closeAlert()
    }
  }
}
</script>

<style>
.betslipButton {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px
}
</style>
