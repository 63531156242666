<template>
  <div :class="overflowedList ? 'raceTableColumnContainer-overflowed' : 'raceTableColumnContainer'">
    <div class="raceTableColumnMainContainer">
      <div :class="acMode ? 'raceTableColumnDiv-priceAcMode' : 'raceTableColumnDiv'" :style="getColumnStyle(index)">
        <div class="raceTableColumn">
          <div v-if="editMode" class="raceHeaderCol">
            <div :class="['logo-head ' + 'logoColor-' + column.source, 'raceTableColumn-header', 'columnWidth-' + column.source]" >
              <RaceBookmakers :bookieCode="column.source" :columnHeader="column.columnHeader" :editMode="editMode" :columnType="column.type"/>
              <div v-if="editMode" class="columnActions editingDecorations">
                <AddColumnButton :columnLength="columnLength" :position="index"/>
                <DeleteColumnButton :columnNumber="index"/>
                <EditColumnButton :columnNumber="index"/>
              </div>
            </div>
          </div>
          <template v-for="(competitor) in getRaceCompetitors">
            <div :key="`${competitor.tabNo}`" :class="acMode ? 'raceTableColumn-priceAcMode' : 'raceTableColumn-price'" :style="getColumnStyle(index)">
              <div style="width:100%; height:100%;" :class="isInWatchList(competitor.tabNo) ? 'highlight' : ''">
                <RacePriceColumn
                  v-if="!editMode"
                  :acMode="acMode"
                  :columnFlucs="columnFlucs"
                  :clickPrice="clickPrice"
                  :selectedTime="selectedTime"
                  :bookie="column.source"
                  :priceFlucs="getFlucs(column.source, competitor.tabNo, column.type)"
                  :fetchFlucs="fetchFlucs"
                  :authorized="authorized"
                  :testMode="testMode"
                  :editMode="editMode"
                  :type="column.type"
                  :priceKey="`${raceID}:${column.source}:${column.type}`"
                  :competitor="competitor"
                  :column="column"
                  :raceID="raceID"
                  :class="isInWatchList(competitor.tabNo) ? 'highlight' : ''"
                  />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RaceBookmakers from './RaceBookmakers'
import RacePriceColumn from './RacePriceColumn'
import AddColumnButton from './menus/AddColumnMenu'
import DeleteColumnButton from './menus/DeleteColumnMenu'
import EditColumnButton from './menus/EditColumnMenu'
import { formatDistance } from 'date-fns'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    RaceBookmakers,
    RacePriceColumn,
    AddColumnButton,
    DeleteColumnButton,
    EditColumnButton
  },
  props: {
    overflowedList: Boolean,
    column: Object,
    columnLength: Number,
    columnFlucs: Object,
    selectedTime: String,
    clickPrice: Function,
    index: Number,
    getFlucs: Function,
    fetchFlucs: Function,
    meeting: Object,
    raceID: String,
    widget: Boolean,
    authorized: Boolean,
    testMode: Boolean,
    editMode: Boolean,
    getColumnStyle: Function
  },
  computed: {
    ...mapGetters(['getRaceCompetitors', 'getWatchListRunners']),
    ...mapState(['acMode'])
  },
  methods: {
    formatDistance,
    isInWatchList (tabNo) {
      return this.getWatchListRunners.includes(tabNo)
    }
  }
}
</script>

<style>
  .raceTableColumnDiv {
    box-sizing:border-box;
    height: 100%;
    color:#000;
    font-weight:400;
  }

  .raceTableColumnDiv-priceAcMode {
    box-sizing:border-box;
    height: 100%;
    color:#000;
    font-weight:500;
  }

  .raceTableColumnMainContainer {
    z-index:200;
    position:relative;
    height: 100%;
  }
  .raceTableColumnContainer {
    height:100%;
    border-top:1px solid #ddd;
  }
  .raceTableColumnContainer-overflowed {
    border-top:1px solid #ddd;
  }
  .raceHeaderCol {
    height:91px;
    background:#fff;
  }
  .evenColor {
     background-color: #efefef;
  }
  .raceTableColumn {
    word-wrap:break-word;
    width: 100%;
    height: 100%;
  }
  .spacerHeader-SPC {
    height:65px;
  }
  .spacerHeader-INF {
    height:60px;
  }
  .raceTableColumn-priceAcMode {
    border-bottom: 0.5px solid #333;
    text-align: right;
    text-align: right;
    min-width: 42px;
    height:32px !important;
    text-align: center;
    background:#fff;
  }
  .raceTableColumn-price {
    border-bottom: 0.5px solid lightgrey;
    text-align: right;
    text-align: right;
    min-width:42px;
    height:32px !important;
    text-align: center;
    box-sizing: border-box;
    background:#fff;
  }
  .pricesChangedColumn {
    max-width:50px;
    white-space: wrap;
    text-overflow: ellipsis;
    padding-top:10px;
    padding-left:4px;
    padding-right:4px;
    text-align:center;
  }
  .columnActions{
    background:#fff !important;
  }
</style>
