import axios from 'axios'
import { startOfDay, addDays, subDays } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const bettingLogin = async function (config) {
  try {
    const result = await axios.post('public/betting/login', {}, config)
    const sessionId = result.data.session_id ? result.data.session_id : null
    const memberId = result.data.member_id || null
    if (sessionId) {
      return { sessionId, memberId }
    }
  } catch {
  }
}
export const getBettingAgencies = async function () {
  try {
    const result = await axios.post('public/betting/getBettingAgencies')
    const agencies = result.data.agencies ? result.data.agencies : null
    if (agencies) {
      // got no idea why the backend not returning agency_id, but returning as agencyId instead
      // anyway, handle it manually if there is no agency_id in the reponse
      agencies.forEach(a => {
        a.agency_id = a.agency_id || a.agencyId
      })
      return agencies
    }
  } catch {
  }
}

export const loginAgency = async function (username, password, bookieCode, sessionId, config) {
  try {
    const result = await axios.post('public/betting/loginAgency', { username, password, agency_id: bookieCode, session_id: sessionId }, config)
    return result
  } catch (error) {
  }
}

export const logoutAgency = async function (bookieCode, sessionId, config) {
  try {
    const result = axios.post('public/betting/logoutAgency', { agency_id: bookieCode, session_id: sessionId }, config)
    if (result) {
      return result.data.success
    }
  } catch (error) {
  }
}

export const getBalances = async function (sessionId, config) {
  try {
    const result = await axios.post('public/betting/getBalances', { session_id: sessionId }, config)
    const balances = result.data
    if (balances) {
      return balances
    }
  } catch (error) {
  }
}

export const getBetTypes = async function (raceNumber, bookieCode, gbsMeetingId, sessionId, config) {
  try {
    const result = await axios.post('public/betting/getBetTypes', { race_number: raceNumber, agency_id: bookieCode, gbs_meeting_id: gbsMeetingId, session_id: sessionId }, config)
    if (result.data.bet_types) {
      return result.data.bet_types
    }
  } catch (error) {
  }
}

export const submitBet = async function (raceNumber, runnerNumber, runnerName, bookieCode, betTypeId, stake, price, gbsMeetingId, sessionId, config) {
  try {
    const result = await axios.post('public/betting/submitBet', {
      race_number: raceNumber,
      runner_number: runnerNumber,
      runner_name: runnerName,
      agency_id: bookieCode,
      bettype_id: betTypeId,
      stake: stake,
      price,
      gbs_meeting_id: gbsMeetingId,
      session_id: sessionId
    }, config)
    if (result) {
      return result.data
    }
  } catch (error) {
  }
}

export const getBetHistory = async function ({ sessionId, date }, config) {
  const today = zonedTimeToUtc(startOfDay(new Date()), 'GMT')
  const result = await axios.post('public/betting/getBetHistory', { session_id: sessionId, date_from: date || subDays(today, 6), date_to: addDays(today, 6) }, config)
  return result
}

export const addToBetHistory = async function ({ bet, raceId, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId, date, config }) {
  try {
    const newBet = { id: bet.bet_id, raceId, tabNo, runnerName, bookieCode, betTypeId, betType, stake, price, gbsMeetingId, bet }
    const key = bet.bet_id
    await axios.post('public/upsertUserKey', { key: `betHistoryData:${date}:${key}`, value: JSON.stringify(newBet) }, config)
  } catch (error) {
  }
}

export const getUserBetHistoryRange = async function (keys, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: 'betHistoryData:', prefixed: true }, config)
    const betHistory = result.data.records ? Object.keys(result.data.records).map(k => {
      // const key = JSON.parse(k.replace(dateKey, ''))
      return JSON.parse(result.data.records[k])
    })
      : []
    if (betHistory) {
      return betHistory
    }
  } catch (error) {
  }
}

export const getUserBetHistory = async function (date, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `betHistoryData:${date}`, prefixed: true }, config)
    const betHistory = result.data.records ? Object.keys(result.data.records).map(k => {
      return JSON.parse(result.data.records[k])
    })
      : []
    if (betHistory) {
      return betHistory
    }
  } catch (error) {
  }
}

export const getAgencySettings = async function (config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: 'agencySettings' }, config)
    const settings = result.data.records ? JSON.parse(result.data.records.agencySettings) : {}
    if (settings) {
      return settings
    } else {
      return {}
    }
  } catch (error) {
  }
}

export const setAgencySettings = async function (settings, config) {
  try {
    await axios.post('public/upsertUserKey', { key: 'agencySettings', value: JSON.stringify(settings) }, config)
  } catch (error) {
  }
}

export const setClearedBets = async function (date, value, config) {
  try {
    await axios.post('public/upsertUserKey', { key: `clearedBets:${date}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const getClearedBets = async function (date, config) {
  try {
    const result = await axios.post('public/getUserKeys', { key: `clearedBets:${date}`, prefixed: true }, config)
    const clearedBets = JSON.parse(result.data.records?.[`clearedBets:${date}`])
    if (clearedBets) {
      return clearedBets
    }
  } catch (error) {
  }
}
