<template>
  <div>
    <div class="username" style="width: 150px" @click="openUserProfile"><v-icon>mdi-account</v-icon> <span style="font-size: 16px; position: relative; top: 2px;">{{getUsername}}</span> </div>
    <v-dialog v-model="userProfileDialog" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense>
          <div class="titleBar-content">
            <v-toolbar-title class="raceResults-title"> User Profile </v-toolbar-title>
            <v-btn class="cancelButton" icon small ><v-icon class="white--text" @click="userProfileDialog = false">mdi-close</v-icon></v-btn>
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <span><strong>Subscription:</strong></span>
          <v-chip
            class="ma-2"
            :color="!subscriptionLevel.subStatus ? 'red' : 'green'"
            label
            outlined
          >
            <v-icon :color="!subscriptionLevel.subStatus ? 'red' : 'green'" small style="margin-right: 6px;">mdi-checkbox-blank-circle</v-icon>
            {{ !subscriptionLevel.subStatus ? 'Not Active' : 'Active' }}
          </v-chip>
          <v-btn v-if="subscriptionLevel.subscriptionType === 'RECURRING' && subscriptionLevel.paymentType ==='creditcard'" class="ml-4" @click="onClickUpdateCard" small>Update Credit Card</v-btn>
          <!--<v-text-field outlined dense label="Next Payment Date" disabled hide-details v-model="nextPaymentDate" class="mt"></v-text-field>-->
          <v-list>
            <v-list-group
              prepend-icon="mdi-account-circle"
            >
              <template v-slot:activator>
                <v-list-item-title>User Details</v-list-item-title>
              </template>

              <v-list-item>
                <v-text-field outlined dense :disabled="!showDetails"  label="Email Address" v-model="email" class="mt"></v-text-field>
                <v-text-field v-if="showEmaildCode" outlined dense label="Email Code" type="number" min="0" class="mt ml" v-model="emailCode" style="width: 10px;" append-outer-icon="mdi-send" @click:append-outer="sendCode"></v-text-field>
              </v-list-item>

              <VuePhoneNumberInput v-model="phoneNumber" :disabled="!showDetails" style="margin-left: 16px; width: 521px;" default-country-code="AU" @update="updatePhoneNumber"/>
              <v-list-item v-if="!showDetails">
                <v-btn @click="changeUserDetails" small>Change Details</v-btn>
              </v-list-item>
              <v-list-item v-if="showDetails">
                <v-btn color="success" small @click="saveUserDetails">Save</v-btn>
              </v-list-item>

              <!--<v-list-group
                prepend-icon="mdi-account-circle"
              >
                <template v-slot:activator>
                  <v-list-item-title>Change Password</v-list-item-title>
                </template>

                <v-list-item>
                  <v-text-field outlined dense label="Old Password" v-model="oldPassword"></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field outlined dense label="New Password" v-model="newPassword"></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-btn>Change</v-btn>
                </v-list-item>
              </v-list-group>-->
            </v-list-group>
            <v-list-group
              prepend-icon="mdi-shield-key"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Change Password</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item>
                  <v-text-field label="Old Password" v-model="oldPassword" type="password"></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="password"
                    label="Enter Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show = !show"
                  ></v-text-field>

                  <v-text-field
                    class="ml-2"
                    v-model="newPassword"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, passwordConfirmationRule]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Re-enter Password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="changeUserPassword" v-if="!hideSave && !savedPword" color="success" small :disabled="(oldPassword === '' || newPassword === '' || password === '') || (newPassword !== password)">Save</v-btn>
                  <v-list-item-subtitle v-if="savedPword && !hideSave" class="green--text ml">Password changed succesfully!</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="savedPword && hideSave" class="red--text ml">Sorry, there has been an error, please try again or contact us</v-list-item-subtitle>
                </v-list-item>
              </v-list-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import VuePhoneNumberInput from 'vue-phone-number-input'
import { updateCreditCard } from './../services/userAccount'
  // import 'vue-phone-number-input/dist/vue-phone-number-input.css'

  export default {
    name: 'UserProfile',
    data: () => ({
      userProfileDialog: false,
      email: '',
      phoneNumber: '',
      newPassword: '',
      oldPassword: '',
      subscriptionLevel: '',
      nextPaymentDate: '',
      showEmaildCode: false,
      emailCode: null,
      phoneNumberWithCode: null,
      showDetails: false,
      savedPword: false,
      hideSave: false,
      show: false,
      show1: false,
      password: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => "The password you entered don't match"
      }
    }),
    components: {
      VuePhoneNumberInput
    },
    computed: {
      ...mapGetters('account', ['getUsername', 'getUser']),
      ...mapState('account', ['newSubscriptionInfo']),
      ...mapGetters(['hasFeature']),
      passwordConfirmationRule () {
        return this.password === this.newPassword || 'Password must match'
      }
    },
    methods: {
      ...mapActions('account', ['updateUserDetails', 'changePassword', 'sendEmailCode', 'getSubscriptionLevel']),
      async openUserProfile () {
        this.userProfileDialog = true
        this.email = this.getUser.attributes.email
        this.phoneNumber = this.getUser.attributes.phone_number
        this.subscriptionLevel = await this.getSubscriptionLevel()
        this.nextPaymentDate = '2021-06-15'
      },
      saveUserDetails () {
        if (this.email !== this.getUser.attributes.email) {
          this.showEmaildCode = true
        }

        const result = this.updateUserDetails({ email: this.email, phoneNumber: this.phoneNumberWithCode })
        if (result === 'SUCCESS') {
          this.email = this.getUser.attributes.email
          this.phoneNumber = this.getUser.attributes.phone_number
        }
        this.showDetails = false
      },
      changeUserDetails () {
        this.showDetails = true
      },
      onClickUpdateCard () {
        this.userProfileDialog = false // to avoid call stack issue when focus outside the user profile dialog
        updateCreditCard()
      },
      async changeUserPassword () {
        const result = await this.changePassword({ oldPassword: this.oldPassword, newPassword: this.newPassword })
        if (result === 'SUCCESS') {
          this.savedPword = true
          this.newPassword = ''
          this.oldPassword = ''
          this.password = ''
          setTimeout(() => {
            this.savedPword = false
            this.hideSave = false
          }, 4000)
        }
        if (result !== 'SUCCESS') {
          this.newPassword = ''
          this.oldPassword = ''
          this.password = ''
          this.savedPword = true
          this.hideSave = true
          setTimeout(() => {
            this.savedPword = false
            this.hideSave = false
          }, 4000)
        }
      },
      async sendCode () {
        const result = await this.sendEmailCode({ emailCode: this.emailCode })
        if (result === 'SUCCESS') {
          this.emailCode = null
          this.showEmaildCode = false
          this.userProfileDialog = false
        }
      },
      updatePhoneNumber (data) {
        if (data.isValid) {
          this.phoneNumberWithCode = data.formattedNumber
        }
      }
    }
  }
</script>

<style>
  .mt {
    margin-top: 12px !important;
  }

  .ml {
    margin-left: 12px !important;
  }
</style>
