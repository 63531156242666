<template>
  <v-dialog v-if="hasFeature('raceAlerts')" persistent v-model="triggerAlert" hide-overlay transition="none" scrollable max-width="600px">
    <v-card style="overflow-y: auto">
      <v-toolbar color="primary" dark dense>
        <div class="titleBar-content">
          <v-toolbar-title class="raceResults-title"><v-icon medium color="warning" style="margin-right: 10px;">mdi-bell-alert</v-icon>Race Alarm</v-toolbar-title>
          <v-btn @click="clearAlert" class="cancelButton" icon small ><v-icon class="white--text">mdi-close</v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div v-for="(d, index) in allAlerts" :key="index">
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">{{getAlertDetails(d.race_id, d.time_before_jump, d.sound_enabled, d.alert_id)}} <v-btn x-small @click="showRace(d.race_id)" style="float: right" color="success"><v-icon x-small>mdi-arrow-top-right-bold-outline</v-icon></v-btn></v-card-text>
        <v-divider></v-divider>
      </div>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="clearAlert">Clear All</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'RaceAlert',
  data: () => ({
  }),
  computed: {
    ...mapState(['races']),
    ...mapGetters(['getRaceAlertHistory', 'hasFeature']),
    allAlerts () {
      const raceAlerts = this.getRaceAlertHistory
      return raceAlerts
    },
    triggerAlert: {
      get () {
        const raceAlerts = this.getRaceAlertHistory
        for (const key in raceAlerts) {
          const raceID = raceAlerts[key].race_id
          const race = this.races[raceID]
          if (typeof race !== 'undefined' && race.status === 'OPEN') {
            const soundEnabled = raceAlerts[key].sound_enabled
            if (soundEnabled === 1) {
              var audio = new Audio('../assets/sounds/race_alarm2.mp3')
              audio.autoplay = true
              audio.play()
            }
            return true
          }
        }
        return false
      },
      set (v) {
        this.$store.commit('clearRaceAlertHistory', this.raceAlertHistoryKey)
        return false
      }
    }
  },
  methods: {
    ...mapActions(['selectRace']),
    getAlertDetails: function (raceID, time, sound, key) {
      const race = this.races[raceID]
      if (time === 30) {
        var timeRaceAlert = time + ' seconds'
      } else {
        timeRaceAlert = (time / 60) + ' minutes'
      }
      return `${race?.meeting.track.name} Race ${race?.number} - ${timeRaceAlert}  remaining until scheduled start.`
    },
    showRace: function (raceID) {
      this.selectRace({ id: raceID })
      this.triggerAlert = false
    },
    clearAlert () {
      const allKeys = this.allAlerts
      for (const key in allKeys) {
        this.$store.commit('clearRaceIDAlert', allKeys[key].race_id)
        this.$store.commit('clearRaceAlertHistory', key)
      }
      return false
    }
  }
}
</script>
