<template>
  <v-card class="myRaces mb-n1 pb-n2" elevation="0">
    <v-tabs v-model="tab" show-arrows height="25" class="myRaces-selections" hide-slider>
      <v-tab v-for="race in races" :key="race.id" @click="handleRaceClick(race)" :active-class="racesMatchingMyRaces(race.id)">
        {{ getRaceText(race) }}
        <v-btn color="primary" class="removeMyRaceButton" icon x-small @click="handleRemoveRace(race)" ><v-icon>mdi-close</v-icon></v-btn>
      </v-tab>
    </v-tabs>
    <v-btn class="removeAllMyRacesButton  mt-1 mr-3" @click="handleRemoveAllMyRaces" x-small color="warning"><v-icon small class="mr-1">mdi-trash-can-outline</v-icon>Clear My Races</v-btn>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    races: Array,
    selectedRace: String
  },
  computed: {
    tab: {
      get () {
        const index = this.races.findIndex(r => r?.id === this.selectedRace)
        return index >= 0 ? index : this.races.length - 1
      },
      set (value) {
        return value
      }
    }
  },
  methods: {
    ...mapActions(['selectRace', 'removeFromMyRaces']),
    handleRemoveAllMyRaces: function () {
      const races = this.races.map(r => r.id)
      this.removeFromMyRaces({ races })
    },
    handleRemoveRace: function (race) {
      this.removeFromMyRaces({ races: [race.id] })
    },
    handleRaceClick: function (race) {
      this.selectRace({ id: race.id })
    },
    getRaceText: function (race) {
      return `${race.meeting.track.name} ${race.number}`
    },
    racesMatchingMyRaces (race) {
      if (race === this.selectedRace) return 'activeClass'
      return 'notActive'
    }
  }
}
</script>

<style>
.myRaces {
  display: flex;
  width:100%;
}
.myRaces-label {
  width: 100px;
  text-align: center;
}
.removeMyRaceButton {
  margin-left: 10px;
}
.myRaces-selections {
  width:80%;
  border-bottom:1px solid rgba(0, 0, 0, 0.12);
}
.activeClass {
  background:rgba(107, 140, 189, 0.555) !important
}
.notActive {
  color:grey !important
}
</style>
