<template>
<div class="raceTableColumnSubContainer">
  <div :class="acMode ? 'raceTableColumnFooterContainerAcMode' : 'raceTableColumnFooterContainer'">
    <div :class="[index % 2 ? 'raceTableColumn' : 'raceTableColumn', 'colColor-' + column.source]">
      <div v-if="column.columnHeader === 'Flucs'"  style="width:110px" :style="getColumnStyle(index)" :class="acMode ? 'runnerFooterAcMode columnWidthFooter-' + column.source : 'runnerFooter'">
        <span v-if="column.source !== 'SPC'">{{ column.displayType === 1 ? '' : marketPercentage() }}</span>
      </div>
      <div v-else :style="getColumnStyle(index)" :class="acMode ? 'runnerFooterAcMode columnWidthFooter-' + column.source : 'runnerFooter columnWidthFooter-' + column.source">
        <BestPriceColumn v-if="column.source === 'BPC'" :id="race.id" :index="index" :column="column" :marketPercentage="true"/>
        <span v-if="column.source !== 'SPC'">{{ column.displayType === 1 ? '' : marketPercentage() }}</span>
      </div>
      <div v-if="column.columnHeader === 'Flucs'"  style="width:110px" :style="getColumnStyle(index)" :class="acMode ? 'runnerFooterAcMode columnWidthFooter-' + column.source : 'runnerFooter columnWidthFooter-' + column.source">
        <span v-if="column.source !== 'SPC'"> {{ column.displayType === 1 ? '' : '00:00'}}</span>
      </div>
      <div v-else :style="getColumnStyle(index)" :class="acMode ? 'runnerFooterAcMode columnWidthFooter-' + column.source : 'runnerFooter columnWidthFooter-' + column.source">
        <span v-if="column.source !== 'SPC'"> {{ column.displayType === 1 ? '' : getPoolSize(column.key, column.priceType, column.type)}}</span>
      </div>
      <div v-if="column.columnHeader === 'Flucs'"  style="width:110px" :style="getColumnStyle(index)" :class="acMode ? 'runnerFooterAcMode priceChangeTime' : 'runnerFooter priceChangeTime columnWidthFooter-' + column.source">
        <span v-if="priceChanged && column.source !== 'SPC'">{{ column.displayType === 1 ? '' : priceChanged}}</span>
      </div>
      <div v-else :style="getColumnStyle(index)" :class="acMode ? 'runnerFooterTimeAcMode columnWidthFooter-' + column.source : 'runnerFooterTime columnWidthFooter-' + column.source">
        <BestPriceColumn v-if="column.source === 'BPC'" :id="race.id" :index="index" :column="column" :lastPriceChange="true"/>
        <span v-if="priceChanged && column.source !== 'SPC'">{{ column.displayType === 1 ? '' : priceChanged}}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { isEmpty } from '../common/methods'
import BestPriceColumn from './BestPriceColumn'

export default {
  props: {
    column: Object,
    index: Number,
    race: Object,
    getColumnStyle: Function,
    acMode: Boolean
  },
  components: {
    BestPriceColumn
  },
  computed: {
    ...mapGetters(['getBetFairMarketPercentage', 'getMarketPercentage', 'getPoolSize', 'getLastPriceChanged', 'getCustomData', 'getBestTotePrice']),
    priceChanged: function () {
      const time = this.getLastPriceChanged(this.column.key)
      return time ? format(time, 'HH:mm:ss') : null
    }
  },
  methods: {
    format,
    marketPercentage () {
      if (this.column.type === 'RUNNER_HISTORY') {
      }
      if (this.column.type === 'CUSTOM_DATA' && this.column.source.includes('rating-')) {
        const data = this.getCustomData(this.race.id)
        if (data && data[this.column.source]) {
          const value = data[this.column.source]
          const prices = Object.values(value).map(v => Number(v.value))
          const dec = prices.filter(p => p > 0 && Number(p)).reduce((a, b) => {
            return a + (1 / b)
          }, 0) * 100
          return Math.round(dec)
        }
      }
      if (this.column.source === 'BF') {
        return this.getBetFairMarketPercentage(this.column)
      } else if (this.column.source === 'BTC') {
        const competitors = this?.race?.competitors ?? {}
        const competitorsThatAreNotScrached = competitors.filter(runner => !runner.scratched)

        let marketPercentage = 0

        competitorsThatAreNotScrached.forEach(runner => {
          const price = this.getBestTotePrice(runner.tabNo)
          if (!isEmpty(price)) marketPercentage += 1 / price
        })

        const result = marketPercentage * 100.0
        return Math.round(result * 10) / 10
      } else {
        return this.getMarketPercentage(this.column.key)
      }
    }
  }
}
</script>

<style scoped>
  .raceTableColumnFooterContainer {
    border-top:1px solid #ddd;
    border-right:1px solid #d8d8d8;
  }
  .raceTableColumnFooterContainerAcMode {
    border-top:1px solid #333;
     border-right:1px solid #d8d8d8;
  }
  .runnerFooter {
    height:22px;
    text-align:center;
    border-bottom: 0.5px solid #d8d8d8;
    width:42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height:22px;
    font-weight:500;
    color:#000;
    font-size: 0.7em !important;
  }
  .runnerFooterAcMode {
    height:22px;
    text-align:center;
    border-bottom: 0.5px solid #333;
    width:42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height:22px;
    font-weight:500;
    font-size: 1em !important;
  }
  .runnerFooterTime {
    height:22px;
    text-align:center;
    border-bottom: 0.5px solid #d8d8d8;
    width:42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height:22px;
    font-weight:500;
    color:#000;
    font-size: 0.7em !important;
  }
  .runnerFooterTimeAcMode {
    height:22px;
    text-align:center;
    border-bottom: 0.5px solid #333;
    width:42px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height:22px;
    font-weight:500;
    font-size: 0.7em !important;
  }
</style>
