<template>
  <div :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
    <template>
      <v-btn :disabled="!hasFeature('raceAlerts')" outlined color="normal" @click="loadConfigurations()" x-small class="ma-1 float-left" style="margin: 2px !important;">
        <v-icon small>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-dialog v-model="dialog" max-width="600" :overlay-opacity="opacity" retain-focus>
      <v-card style="max-width:600px;">
        <v-card-title class="primary white--text">
          <div style="width:100%">
            <div class="float-left">
              <span class="headline">Race Alert Settings</span>
            </div>
            <v-btn class="float-right" icon small @click="saveConfigurationRaceAlerts"  @click.stop="dialog = false" ><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <div class="pa-3">
            <RaceAlertConfiguration :saveConfigurationRaceAlerts="saveConfigurationRaceAlerts" :alerts="alerts" :soundsAlerts="soundsAlerts" :timesAlerts="timesAlerts"/>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RaceAlertConfiguration from '../RaceAlertConfiguration'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'RaceNavigationSettings',
  data: () => ({
    opacity: 0.02,
    dialog: false
  }),
  props: {
    saveConfigurationRaceAlerts: Function,
    alerts: Array,
    soundsAlerts: Array,
    timesAlerts: Array,
    getConfigurationRaceAlert: Function
  },
  components: {
    RaceAlertConfiguration
  },
  computed: {
    ...mapState(['acMode']),
    ...mapGetters(['hasFeature'])
  },
  methods: {
    loadConfigurations () {
      this.dialog = true
      this.getConfigurationRaceAlert()
    }
  }
}
</script>
