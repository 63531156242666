<template>
  <div v-if="hasFeature('multiBetSlip')">
    <div class="float-right mt-n5">
    <v-badge
          color="purple"
          :content="getBetSlip.length"
          left
          overlap
          :value="showUnsent"
          class="pendingBetsChip"
        >
        <v-btn class="multiBetslip_button d-inline mr-2" color="black" dark x-small @click="fetchBetHistory()" v-on:click="show" :style="visibleBetsShow()">
          <v-icon small class="mr-1">mdi-file-document-outline</v-icon>BetSlip
      <v-chip
        x-small
        class="mr-1 ml-2"
        color="warning"
        label
        :disabled="countByPending === '0'"
      >
      <v-icon left small>
        mdi-rotate-right
      </v-icon>
        {{countByPending}}
      </v-chip>
      <v-chip
        class="mr-1"
        x-small
        label
        color="success"
        :disabled="countByApproved === '0'"
      >
      <v-icon left small>
        mdi-check
      </v-icon>
        {{countByApproved}}
      </v-chip>
      <v-chip
        x-small
        label
        color="error"
        :disabled="countByRejected === '0'"
      >
      <v-icon left small>
        mdi-alert-circle
      </v-icon>
        {{countByRejected}}
      </v-chip>

        </v-btn>
      </v-badge>
    </div>
    <div class="multiBetslip">
      <modal name="betslip-modal" draggable="" :clickToClose=false width=350px height=auto>
        <v-card
          dark
          class="multiBetslip_card"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title float-left">
                Bet slip <v-btn class="float-right" icon small @click="hide"><v-icon class="white--text">mdi-close</v-icon></v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-tabs dark v-model="tab" fixed-tabs width="300">
            <v-tab style="padding-left: 0px; padding-right: 0px">
              <v-badge
              v-if="getBetSlip.length"
                color="purple"
                :content="getBetSlip.length"
                offset-x="0"
                offset-y="8"
                bordered
              >Unsent</v-badge>
              <div v-else>Unsent</div>
            </v-tab>
            <v-tab style="padding-left: 0px; padding-right: 0px">
              <v-badge
                color="orange"
                :content="countByPending"
                offset-x="20"
                offset-y="15"
                bordered
              >
                <v-icon color="orange" medium class="ml-2" style="padding-right: 16px;">mdi-rotate-right</v-icon>
              </v-badge>
            </v-tab>
            <v-tab style="padding-left: 0px; padding-right: 0px">
              <v-badge
                color="green"
                :content="countByApproved"
                offset-x="20"
                offset-y="15"
                bordered
              >
                <v-icon color="green" medium class="ml-2" style="padding-right: 16px;">mdi-check</v-icon>
              </v-badge>
            </v-tab>
            <v-tab style="padding-left: 0px; padding-right: 0px">
              <v-badge
                color="red"
                :content="countByRejected"
                offset-x="20"
                offset-y="15"
                bordered
              >
                <v-icon color="red" medium class="ml-2" style="padding-right: 16px;">mdi-alert-circle</v-icon>
              </v-badge>
            </v-tab>
            <v-tab-item>
              <v-list class="multiBetslip_bets" dark style="border-radius: 0px">
                <v-list-item
                  v-for="(item,i) in getBetSlip"
                  :key="item.key"
                  :id="item.key"
                  link
                  class="mb-2 pb-2 multiBetslip_bet_wrapper"
                  style="border-bottom:1px solid rgba(255, 255, 255, 0.12);"
                >
                  <MultiBetSlipList :item="item" :i="i" :ref="i"/>
                </v-list-item>
              </v-list>
              <v-list-item style="background-color: rgb(27,27,27)" dark>
                <v-list-item-content>
                  <v-list-item-title class="subtitle">
                    <span style="float:left">Slip Total:</span> <span style="float:right;color:#00E676"> ${{totalRequest}}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template>
                <div class="pb-2" style="text-align:center; background-color: rgb(27,27,27)">
                  <v-btn class="d-inline mr-2 clearBets_button" :disabled="getBetSlip.length <= 0" @click="clearBets()" small color="error">
                    <span>Clear Slip</span>
                  </v-btn>
                  <v-btn v-if="!confirmSendBets" :disabled="sent || getBetSlip.length <= 0" small class="sendBets_button" color="warning" @click="confirmSend()">
                    <span>Send Bets</span>
                    <!--<v-progress-circular v-if="loadCircle" indeterminate color="primary">
                    </v-progress-circular>-->
                  </v-btn>
                  <v-btn v-if="confirmSendBets" :disabled="sent || getBetSlip.length <= 0" small class="sendBets_button" color="success" @click="placeAllBets()">
                    <span>Confirm</span>
                    <!--<v-progress-circular v-if="loadCircle" indeterminate color="primary">
                    </v-progress-circular>-->
                  </v-btn>
                </div>
              </template>
            </v-tab-item>
            <v-tab-item v-if="getTodaysBets">
              <v-list class="multiBetslip_bets" dark style="border-radius: 0px">
                <v-list-item
                  v-for="(item,i) in pendingBets"
                  :key="item.key"
                  :id="item.key"
                  link
                  class="mb-2 pb-2 multiBetslip_bet_wrapper"
                  style="border-bottom:1px solid rgba(255, 255, 255, 0.12);"
                >
                  <BetHistoryList :item="item" :i="i"/>
                </v-list-item>
              </v-list>
              <div class="pb-2" style="text-align:center; background-color: rgb(27,27,27)">
                  <v-btn class="d-inline mr-2 clearBets_button" :disabled="pendingBets.length < 0" @click="clearBetslip(pendingBets)" small color="error">
                    <span>Clear</span>
                  </v-btn>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getTodaysBets">
              <v-list class="multiBetslip_bets" dark style="border-radius: 0px">
                <v-list-item
                  v-for="(item,i) in successfulBets"
                  :key="item.key"
                  :id="item.key"
                  link
                  class="mb-2 pb-2 multiBetslip_bet_wrapper"
                  style="border-bottom:1px solid rgba(255, 255, 255, 0.12);"
                >
                  <BetHistoryList :item="item" :i="i"/>
                </v-list-item>
              </v-list>
              <div class="pb-2" style="text-align:center; background-color: rgb(27,27,27)">
                  <v-btn class="d-inline mr-2 clearBets_button" :disabled="successfulBets.length < 0" @click="clearBetslip(successfulBets)" small color="error">
                    <span>Clear</span>
                  </v-btn>
              </div>
            </v-tab-item>
            <v-tab-item v-if="getTodaysBets">
              <v-list class="multiBetslip_bets" dark style="border-radius: 0px">
                <v-list-item
                  v-for="(item,i) in rejectedBets"
                  :key="item.key"
                  :id="item.key"
                  link
                  class="mb-2 pb-2 multiBetslip_bet_wrapper"
                  style="border-bottom:1px solid rgba(255, 255, 255, 0.12);"
                >
                  <BetHistoryList :item="item" :i="i"/>
                </v-list-item>
              </v-list>
              <div class="pb-2" style="text-align:center; background-color: rgb(27,27,27)">
                  <v-btn class="d-inline mr-2 clearBets_button" :disabled="rejectedBets.length < 0" @click="clearBetslip(rejectedBets)" small color="error">
                    <span>Clear</span>
                  </v-btn>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </modal>
    </div>
  </div>
  <!-- app -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MultiBetSlipList from '../components/MultiBetSlipList'
import BetHistoryList from '../components/BetHistoryList'

export default {
  data () {
    return {
      confirmSendBets: false,
      sent: false,
      dialog: false,
      tab: 0,
      showOptionSelected: 'today'
    }
  },
  computed: {
    ...mapGetters(['hasFeature']),
    ...mapGetters('betting', ['getTodaysBets', 'getBetSlip', 'getClearedBets']),
    filteredBets () {
      return this.getTodaysBets.filter(bet => !this.getClearedBets?.find(id => id === bet.id)) || this.getTodaysBets
    },
    totalRequest () {
      return this.getBetSlip.reduce((acc, item) => acc + parseInt(item.stake), 0)
    },
    showUnsent () {
      if (this.getBetSlip.length !== 0) return true
      return false
    },
    countByPending: function () {
      const updates = this.filteredBets
      const status = updates?.filter(a => {
        return a.status === 2 || a.status === 12 || a.status === 13
      })
      return status?.length || '0'
    },
    countByRejected: function () {
      const updates = this?.filteredBets
      const status = updates?.filter(a => {
        return a.status === 4 || a.status === 0 || a.status === 1 || a.status === 3 || a.status === 5 || a.status === 6 || a.status === 7 || a.status === 8 || a.status === 11 || a.status === 15 || a.status === 16
      })
      return status?.length || '0'
    },
    countByApproved: function () {
      const updates = this.filteredBets
      const status = updates?.filter(a => {
        return a.status === 9 || a.status === 10 || a.status === 14
      })
      return status?.length || '0'
    },
    pendingBets () {
      const updates = this.filteredBets
      const bets = updates?.filter(a => {
        return a.status === 2 || a.status === 12 || a.status === 13
      })
      return bets?.reverse()
    },
    rejectedBets () {
      const updates = this?.filteredBets
      const bets = updates?.filter(bet => {
        return bet.status === 4 || bet.status === 0 || bet.status === 1 || bet.status === 3 || bet.status === 5 || bet.status === 6 || bet.status === 7 || bet.status === 8 || bet.status === 11 || bet.status === 15 || bet.status === 16
      })
      return bets?.reverse()
    },
    successfulBets () {
      const updates = this.filteredBets
      const bets = updates?.filter(a => {
        return a.status === 9 || a.status === 10 || a.status === 14
      })
      return bets?.reverse()
    }
  },
  methods: {
    ...mapActions('betting', ['clearBets', 'fetchBetHistory', 'setClearedBets']),
    ...mapActions(['showMultiBetSlip']),
    show () {
      if (this.$modal) {
        this.$modal.show(
          'betslip-modal'
        )
      }
    },
    hide () {
      if (this.$modal) {
        this.$modal.hide(
          'betslip-modal'
        )
      }
    },
    confirmSend () {
      this.confirmSendBets = true
      setTimeout(() => { this.confirmSendBets = false }, 5000)
    },
    placeAllBets () {
      this.confirmSendBets = false
      this.sent = true
      this.getBetSlip.forEach((bet, i) => {
        this.$refs[i][0].placeSingleBet()
      })
      setTimeout(() => { this.sent = false }, 5000)
    },
    visibleBets () {
      if (this.getBetSlip.length !== 0) {
        return 'display:block'
      } else {
        return 'display:none'
      }
    },
    visibleBetsShow () {
      if (this.getBetSlip.length === 0) {
        return 'display:block'
      } else {
        return 'display:none'
      }
    },
    clearBetslip (bets) {
      this.setClearedBets(bets.map(b => b.id))
    }
  },
  components: {
    MultiBetSlipList,
    BetHistoryList
  }
}
</script>

<style scoped>
  .multiBetslip {
    height: auto;
    max-height: 80vh;
    width: 420px;
  }
  .multiBetslip_card {}
  .multiBetslip_bets {
    max-height: 600px;
    overflow-y: auto;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  .v-tab {
    font-size: 10px;
    min-width: 40px;
  }
</style>
