<template>
  <v-row :class="this.acMode ? 'raceFiltersAcMode' : 'stdMode'">
      <v-checkbox class="scheduleLabels mr-2 statusFilter" label="Open" color="primary" v-model="selected" value="Open" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels mr-2 statusFilter" label="Closed" color="primary" v-model="selected" value="Closed" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels mr-2 statusFilter" label="Interim/Paying" color="primary" v-model="selected" value="Interim/Paying" hide-details></v-checkbox>
      <v-checkbox class="scheduleLabels mr-2 statusFilter" label="Abandoned" color="primary" v-model="selected" value="Abandoned" hide-details></v-checkbox>
  </v-row>
</template>

<script>
export default {
  props: {
    statuses: Array,
    onClick: Function,
    acMode: Boolean
  },
  computed: {
    selected: {
      get () {
        return this.statuses
      },
      set (value) {
        return this.onClick(value)
      }
    }
  }
}
</script>
