<template>
  <div class="d-inline display:flex;flex-wrap:nowrap;flex-direction:row;width:100%;justify-content:space-between;">
    <v-dialog
      v-model="isEditModeLoading"
      max-width="310"
      hide-overlay
      :transition="false">
      <v-card>
        <v-card-title class="text-center primary--text" > Please wait a moment...</v-card-title>
        <v-progress-linear buffer-value="0" stream color="green"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-menu :close-on-content-click="true" :nudge-width="200" offset-y class="mr-2" z-index="202">
      <template v-slot:activator="{ on }">
        <v-tab class="d-inline pl-0 pr-0 mr-1">
        <v-badge
          color="success"
          overlap
          bordered
          left
          :content="getSelectedColumnsName"
        >
          <v-btn color="pink" dark v-on="on" x-small class="layoutsButton"  :ripple="false">
            <v-icon small class="mr-1">mdi-page-next-outline</v-icon>Layouts
          </v-btn>
        </v-badge>
      </v-tab>
      </template>
      <v-card class="pa-2 layoutsMenu" v-if="!editMode">
        <v-btn :ripple="false" x-small class="ml-1 d-inline" :disabled="editMode" :color="depressed('AU') ? 'success' : 'normal'" @click="changeSelectedColumns('AU')">AU</v-btn>
        <v-btn :ripple="false" x-small class="ml-1 mr-1 d-inline" :disabled="editMode" :color="depressed('UK') ? 'success' : 'normal'" @click="changeSelectedColumns('UK')">UK</v-btn>
        <v-btn :ripple="false" x-small class="ml-1 d-inline" :disabled="!hasFeature('columnEditMode') || !authorized" :color="depressed('A') ? 'success' : 'normal'" @click="changeSelectedColumns('A')">A</v-btn>
        <v-btn :ripple="false" x-small class="ml-1 d-inline" :disabled="!hasFeature('columnEditMode') || !authorized" :color="depressed('B') ? 'success' : 'normal'" @click="changeSelectedColumns('B')">B</v-btn>
        <v-btn :ripple="false" x-small class="ml-1 d-inline" :disabled="!hasFeature('columnEditMode') || !authorized" :color="depressed('C') ? 'success' : 'normal'" @click="changeSelectedColumns('C')">C</v-btn>
        <v-btn :ripple="false" x-small class="ml-1 d-inline" :disabled="!hasFeature('columnEditMode') || !authorized" :color="depressed('D') ? 'success' : 'normal'" @click="changeSelectedColumns('D')">D</v-btn>
        <v-btn :ripple="false" x-small class="ml-1 d-inline" :disabled="!hasFeature('columnEditMode') || !authorized" :color="depressed('E') ? 'success' : 'normal'" @click="changeSelectedColumns('E')">E</v-btn>
      </v-card>
    </v-menu>
    <v-tooltip bottom v-if="!editMode && !hasFeature('columnEditMode') || !authorized">
      <template v-slot:activator="{ on: tooltip }">
        <div class="d-inline" v-on="{ ...tooltip }">
        <v-btn :ripple="false" v-if="!editMode" disabled color="orange" x-small class="ml-1 d-inline white--text mr-2 editLayoutsButton" @click="setEditMode()"><v-icon :ripple="false" small class="mr-1">mdi-pencil-plus-outline</v-icon>Edit Layout</v-btn>
        </div>
      </template>
      <span v-if="!hasFeature('columnEditMode')">Not Available</span>
      <span v-else-if="!authorized">Sign in to edit columns</span>
    </v-tooltip>
    <v-tooltip bottom v-else-if="!editMode && !depressed('A,B,C,D,E')" color="error">
      <template v-slot:activator="{ on: tooltip }">
        <div class="d-inline" v-on="{ ...tooltip }">
          <v-badge
          :value="!depressed('A,B,C,D,E')"
          color="error"
          class="ml-2"
          overlap
          bordered
          left
          dot
        >
        <v-btn :ripple="false" v-if="!editMode" disabled color="orange" x-small class="ml-1 d-inline white--text mr-2 editLayoutsButton" @click="setEditMode()"><v-icon small class="mr-1">mdi-pencil-plus-outline</v-icon>Edit Layout</v-btn>
        </v-badge>
        </div>
      </template>
      <span>To customize layout select pink LAYOUTS button: A B C D E</span>
    </v-tooltip>
    <v-btn :ripple="false" v-else-if="!editMode && depressed('A,B,C,D,E')" color="orange" x-small class="ml-1 d-inline white--text mr-2 editLayoutsButton" @click="onSetEditModeLoading"><v-icon small class="mr-1">mdi-pencil-plus-outline</v-icon>Edit Layout</v-btn>
    <div class="settingsButtons">
      <RaceGridSettings v-if="hasFeature('marketMover')" :fontSizeSetting="getRaceFontSize" :gridSettings="getGridSettings" :betFairSettings="getBetFairSettings" :highlightSetting="getHighLightBestOdds" :commissionRate="getBetFairCommission"/>
      <BetLimitSettings v-if="hasFeature('betLimits') && hasFeature('marketMover')" :betLimits="getBetLimits"/>
    </div>
    <v-btn :ripple="false" v-if="editMode" x-small class="ml-1 d-inline mr-2 finishEditingButton editButtons" color="success" @click="onSetEditModeLoading"><v-icon small class="mr-1">mdi-pencil-remove-outline</v-icon>Save &amp; Finish Editing</v-btn>
    <v-btn v-if="selection === 'betButton'" outlined label color="red" x-small class="float-right mt-1 mr-2 d-inline mt-n5" style="height: 18px !important;" @click="showLoginModal = true">Betting Disabled</v-btn>
    <MultiBetSlip v-if="!getRdcConfig"/>
    <LoginModal v-if="showLoginModal" @close="showLoginModal = false" :loginBetting="loginBetting"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import MultiBetSlip from '../components/MultiBetSlip'
import LoginModal from './LoginModal'
import BetLimitSettings from './menus/BetLimitSettings'
import RaceGridSettings from './menus/RaceGridSettings'
import { isEmpty } from '../common/methods'

export default {
  data: () => ({
    isOpenMarket: false,
    isEditModeLoading: false,
    menu: false,
    showLoginModal: false,
    marketMovers: [
      { title: '16. Muioi Tieu $151 to $1.01 %20' },
      { title: '1. Ardee $151 to $1.01 %20' },
      { title: '4. Perfect $151 to $1.01 %20' },
      { title: '5. Gold Rhapsody $151 to $1.01 %20' }
    ],
    BookmakerFlucs: ['BetEasy', 'Neds', 'TAB']
  }),
  props: {
    clickFeature: Function,
    getSelectedColumnsName: String
  },
  components: {
    MultiBetSlip,
    LoginModal,
    BetLimitSettings,
    RaceGridSettings
  },
  computed: {
    ...mapState(['editMode']),
    ...mapState('account', ['authorized']),
    ...mapGetters(['getColumns', 'hasFeature', 'getRdcConfig', 'getAgencySettings', 'getAvailableAgencies', 'getRaceFontSize', 'getGridSettings', 'getBetFairSettings', 'getHighLightBestOdds', 'getBetFairCommission', 'getBetLimits', 'getSelectedRace']),
    ...mapGetters('betting', ['getBetSlip']),
    ...mapGetters('account', ['getBalances', 'getSessionId']),
    race: function () {
      const race = this.getSelectedRace()
      return race
    },
    balances: function () {
      return this.getBalances
    },
    selection: function () {
      if (!this.bettingEnabled) {
        return 'betButton'
      }
      return null
    },
    sessionId: function () {
      return this.getSessionId
    },
    bettingEnabled: function () {
      return !!this.sessionId
    },
    selectedColumns: function () {
      return this.getColumns
    }
  },
  watch: {
    editMode () {
      if (isEmpty(this.selectedColumns)) return
     this.isEditModeLoading = !this.isEditModeLoading
    }
  },
  methods: {
    ...mapActions(['changeSelectedColumns', 'setEditMode', 'showMultiBetSlip']),
    ...mapActions(['loginBetting']),
    isEmpty,
    depressed: function (value) {
      const values = value ? value.split(',') : null
      const result = values.filter(v => v === this.getSelectedColumnsName)
      return result.length
    },
    openMultiBetSlip () {
      this.showMultiBetSlip(true)
    },
    visibleBets () {
      if (this.getBetSlip.length !== 0) {
        return 'display:block'
      } else {
        return 'display:none'
      }
    },
    visibleBetsShow () {
      if (this.getBetSlip.length === 0) {
        return 'display:block'
      } else {
        return 'display:none'
      }
    },
    onSetEditModeLoading () {
      if (!isEmpty(this.selectedColumns)) {
        this.isEditModeLoading = true
      }

      setTimeout(() => { this.setEditMode() }, 0)
    }
  }
}
</script>

<style scoped>
.layoutsMenu {
  position: relative;
  z-index: 2003;
}
.editButtons {
  position: relative;
  z-index: 2003;
}
.settingsButtons {
  display: -webkit-inline-box;
}
.finishEditingButton {
  font-size: 14px;
  left: -18px;
  width: 230px;
  top: 0px;
  font-weight: 400;
  height: 32px !important;
  text-transform: uppercase;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2000000;
  background-color: red;
  transform: translate(-50%,-50%);
}
</style>
