<template>
  <v-btn @click.stop="handleClick" x-small color="success" class="betButton white--text">
      Bet
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    setDialog: Function
  },
  methods: {
    ...mapActions(['loginBetting']),
    handleClick () {
      this.setDialog()
    }
  }
}
</script>

<style scoped>
.button {
  margin-left: 5px;
}
.betButton {
  cursor:pointer;
}
</style>
